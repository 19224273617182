import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getHomeRemediesAPI, updateHomeRemediesAPI, deactivateHomeRemediesAPI, createdHomeRemediesAPI } from "api/homeRemediesAPI";

export const getHomeRemediesList = createAsyncThunk(
    'homeRemedies/list-all',
    async (sortingMode) => {
        try {
            const res = await getHomeRemediesAPI(sortingMode);
            if (res.success) {
                return res.data;
            } else {
                throw new Error('Error Fetching Data');
            }
        } catch (error) {
            throw error;
        }
    }
);

export const createHomeRemedies = createAsyncThunk(
    'homeRemedies/create',
    async (params) => {
        try {
            const res = await createdHomeRemediesAPI(params);
            if (res.success) {
                return res;
            } else {
                throw new Error('Error Creating home remedies');
            }
        } catch (error) {
            throw error;
        }
    }
);

export const updateHomeRemedies = createAsyncThunk(
    'homeRemedies/update',
    async (params) => {
        try {
            const res = await updateHomeRemediesAPI(params);
            if (res.success) {
                return res;
            } else {
                throw new Error('Error Updating home remedies');
            }
        } catch (error) {
            throw error;
        }
    }
);

export const deleteHomeRemedies = createAsyncThunk(
    'homeRemedies/delete',
    async (params) => {
        try {
            const res = await deactivateHomeRemediesAPI(params);
            if (res.success) {
                return res;
            } else {
                throw new Error('Error deleteing home remedies');
            }
        } catch (error) {
            throw error;
        }
    }
);

const homeRemediesSlice = createSlice({
    name: 'homeRemedies',
    initialState: {
        data: [],
        loading: false,
        error: null,
    },
    reducer: {},
    extraReducers: (builder) => {
        builder
            .addCase(getHomeRemediesList.pending, (state) => {
                state.loading = true;
            })
            .addCase(getHomeRemediesList.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(getHomeRemediesList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(createHomeRemedies.pending, (state) => {
                state.loading = true;
            })
            .addCase(createHomeRemedies.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(createHomeRemedies.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(updateHomeRemedies.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateHomeRemedies.fulfilled, (state, action) => {
                state.loading = false;
                // const userId = action?.payload?.user?.id;
                // state.data = state.data.map((user) =>
                //     user.id === userId
                //         ? { ...user, isActive: !user.isActive }
                //         : user
                // );
            })
            .addCase(deleteHomeRemedies.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(deleteHomeRemedies.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteHomeRemedies.fulfilled, (state, action) => {
                state.loading = false;
                // state.data = state.data.filter((user) => user.id !== action.payload);
                // const userId = action?.payload?.user?.id;
                // state.data = state.data.map((user) =>
                //     user.id === userId
                //         ? { ...user, userType: !user.userType }
                //         : user
                // );
            })

    },
});
export const homeRemediesAction = homeRemediesSlice.actions;
export default homeRemediesSlice;
