import { config } from "./confg";
import { axiosCallAdvanced } from "./main";

export const getRecipe = async () => {
    return (
        (
            await axiosCallAdvanced({
                baseURL: config.recipe.root,
                path: config.recipe.list,
            })
        )?.data || {}
    );
};
export const createRecipe = async (data) => {
    return (
        (
            await axiosCallAdvanced({
                baseURL: config.recipe.root,
                path: config.recipe.createOne,
                body: { ...data },
                method: config.methodType.post,
            })
        )?.data || {}
    );
};

export const updateRecipe = async (parameters) => {
    return (
        (
            await axiosCallAdvanced({
                baseURL: config.recipe.root,
                path: `${config.recipe.updateOne}/${parameters.id}`,
                body: parameters.data ,
                method: config.methodType.put,
            })
        )?.data || {}
    );
};

export const deleteRecipe = async (parameters) => {
    return (
        (
            await axiosCallAdvanced({
                baseURL: config.recipe.root,
                path: `${config.recipe.deleteOne}/${parameters}`,
                method: config.methodType.patch,
            })
        )?.data || {}
    );
};

