import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getBrainActivity, createBrainActivity, updateBrainActivity, deleteBrainActivity } from "api/brainActivityAPI";

export const getBrainActivityList = createAsyncThunk("brain-activity/list", async () => {
  try {
    const res = await getBrainActivity();
    if (res.success) {
      return res.data;
    } else {
      throw new Error("Error fetching Brain Activity.");
    }
  } catch (error) {
    throw error;
  }
});
export const createOneBrainActivity = createAsyncThunk(
  "brain-activity/create-one",
  async (data) => {
    try {
      const res = await createBrainActivity(data);
      if (res.success) {
        return res.data;
      } else {
        throw new Error("Error creating Brain Activity.");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const deleteOneBrainActivity = createAsyncThunk(
  "brain-activity/delete-one",
  async (id) => {
    try {
      const res = await deleteBrainActivity(id);
      if (res.success) {
        return id;
      } else {
        throw new Error("Error deleting Brain Activity.");
      }
    } catch (error) {
      throw error;
    }
  }
);
export const updateOneBrainActivity = createAsyncThunk(
  "brain-activity/update-one",
  async ({ id, data }) => {
    try {
      const res = await updateBrainActivity(id, data);
      if (res.success) {
        return res.data;
      } else {
        throw new Error("Error updating Brain Activity.");
      }
    } catch (error) {
      throw error;
    }
  }
);

const BrainActivitySlice = createSlice({
  name: "brain-activity",
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBrainActivityList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBrainActivityList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getBrainActivityList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createOneBrainActivity.pending, (state) => {
        state.loading = true;
      })
      .addCase(createOneBrainActivity.fulfilled, (state, action) => {
        state.loading = false;
        state.data.push(action.payload);
      })
      .addCase(createOneBrainActivity.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateOneBrainActivity.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateOneBrainActivity.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        const BrainActivityIndex = state.data.findIndex(
          (BrainActivity) => BrainActivity.id === action.payload.id
        );
        if (BrainActivityIndex !== -1) {
          state.data[BrainActivityIndex] = action.payload;
        }
      })

      .addCase(updateOneBrainActivity.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteOneBrainActivity.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteOneBrainActivity.fulfilled, (state, action) => {
        state.loading = false;
        state.data = state.data.filter((brainActivity) => brainActivity.id !== action.payload);
      })
      .addCase(deleteOneBrainActivity.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const BrainActivityAction = BrainActivitySlice.actions;
export default BrainActivitySlice;
