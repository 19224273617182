import React from "react";

const Input = ({
  label,
  type = "text",
  onChange,
  value,
  defaultValue,
  rows = "4",
  textarea,
  select,
  selected,
  options,
  isRequired = false,
}) => {
  return (
    <div className="flex flex-wrap">
      <div className="w-full lg:w-12/12 px-4">
        <div className="relative w-full mb-3">
          <label
            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
            {label} {isRequired && <span className="red-asterisk"></span>}
          </label>
          {textarea ? (
            <textarea
              type={type}
              onChange={onChange}
              value={value}
              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              defaultValue={defaultValue}
              rows={rows}
            ></textarea>
          ) : (
            <>
              {select ? (
                <select
                  type={type}
                  onChange={onChange}
                  value={value}
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  defaultValue={defaultValue}
                >
                  <option disabled value="">
                    Select an option
                  </option>
                  {options?.map((item) => (
                    <option
                      selected={item?.id === selected}
                      key={item?.id}
                      value={item?.id}
                    >
                      {item.value}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  type={type}
                  onChange={onChange}
                  value={value}
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  defaultValue={defaultValue}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Input;
