import React, { useState, useRef } from 'react';
import toast from 'react-hot-toast';
import ReactQuill from 'react-quill';
import styles from '../../lib/Modal.module.css';
import { useDispatch, useSelector } from 'react-redux';
import Input from 'lib/Input';
import Modal from 'lib/Modal';
import PictureUpload from 'lib/PictureUpload';
import Loader from 'components/Loader/Loader';
import ProductTable from 'components/Cards/ProductTable';
import { createOne } from 'redux/productSlice';
import { constants } from 'utils/constants';
import { handleImageFile } from 'api/fileUploadAPI';

const productTemplate = {
    product: {
        title: '',
        metaTitle: '',
        featureImageUrl: '',
        slug: '',
        summary: '',
        userCategory: '',
        startsAt: null,
        endsAt: null,
        isActive: true,
    },
    productCategories: [],
    productVariants: [],
    productImages: [],
};

const productVariantTemplate = {
    variantName: '',
    sku: '',
    actualPrice: '',
    displayPrice: '',
    discount: '',
    stockQuantity: '',
    isActive: true,
};

const Product = () => {
    const editor = useRef(null);
    const dispatch = useDispatch();

    const [modalOpen, setModalOpen] = useState(false);
    const [variantModal, setVariantModal] = useState(false);
    const [imageModal, setImageModal] = useState(false);

    const [data, setData] = useState(productTemplate);
    const [productVariants, setProductVariants] = useState(
        productVariantTemplate
    );
    const [productImages, setProductImages] = useState({
        imageURL: '',
        isActive: true,
    });
    const [isIntroLoader, setIsIntroLoader] = useState(false);
    const [selectedCategoryId, setSelectedCategoryId] = useState([]);
    const [isImageUploading, setIsImageUploading] = useState(false);
    const [formError, setFormError] = useState(false);

    const categoryList = useSelector((state) => state.productCategory.data);

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleOpenImageModal = () => {
        setImageModal(true);
    };

    const handleCloseImageModal = () => {
        setImageModal(false);
    };

    const handleOpenVariantModal = () => {
        setVariantModal(true);
    };

    const handleCloseVariantModal = () => {
        setVariantModal(false);
    };

    const handleFeaturedImage = async (file) => {
        if (file === undefined) {
            setData({
                ...data,
                product: { ...data.product, featureImageUrl: '' },
            });
            return;
        }

        try {
            setIsIntroLoader(true);
            const res = await handleImageFile(file);
            if (res.success) {
                setData({
                    ...data,
                    product: { ...data.product, featureImageUrl: res.file_url },
                });
                toast.success('Product Image Added Successfully');
            } else {
                throw new Error('Picture Not Successfully Uploaded');
            }
            setIsIntroLoader(false);
        } catch (error) {
            toast.error(error);
        }
    };

    const handleProductImage = async (file) => {
        if (file === undefined) {
            setProductImages({ ...productImages, imageURL: '' });
            return;
        }

        try {
            setIsImageUploading(true);
            const res = await handleImageFile(file);
            if (res.success) {
                setProductImages({ ...productImages, imageURL: res.file_url });
                toast.success('Product Image Added Successfully');
            } else {
                throw new Error('Picture Not Successfully Uploaded');
            }
            setIsImageUploading(false);
        } catch (error) {
            toast.error(error);
        }
    };

    const handleQuillChange = (value) => {
        setData({ ...data, product: { ...data.product, summary: value } });
    };

    const handleSelectChange = (e) => {
        const selectedOption = Array.from(
            e.target.selectedOptions,
            (option) => option.value
        );
        setSelectedCategoryId(selectedOption);
    };

    const handleProductVariantSubmit = () => {
        if (
            productVariants.variantName === '' ||
            productVariants.sku === '' ||
            productVariants.actualPrice === '' ||
            productVariants.displayPrice === '' ||
            productVariants.discount === 0 ||
            productVariants.stockQuantity === 0
        ) {
            setFormError(true);
            return;
        }

        setData({
            ...data,
            productVariants: [
                ...data.productVariants,
                {
                    ...productVariants,
                    actualPrice: parseInt(productVariants.actualPrice),
                    displayPrice: parseInt(productVariants.displayPrice),
                    discount: parseInt(productVariants.discount),
                    stockQuantity: parseInt(productVariants.stockQuantity),
                },
            ],
        });
        toast.success('Product Variant Added Successfully');
        handleCloseVariantModal();
        setProductVariants(productVariantTemplate);
    };

    const handleProductImageSubmit = () => {
        if (productImages.imageURL === '') {
            setFormError(true);
            return;
        }

        setData({
            ...data,
            productImages: [...data.productImages, productImages],
        });
        setProductImages({ imageURL: '', isActive: true });
        handleCloseImageModal();
    };

    const handleProductVariantDelete = (index) => {
        setData({
            ...data,
            productVariants: data.productVariants.filter(
                (_, idx) => idx !== index
            ),
        });
        toast.success('Product Variant Deleted Successfully');
    };

    const handleProductImageDelete = (index) => {
        setData({
            ...data,
            productImages: data.productImages.filter((_, idx) => idx !== index),
        });
        toast.success('Product Image Deleted Successfully');
    };

    const handleCreate = () => {
        if (
            data.product.title === '' ||
            data.product.metaTitle === '' ||
            data.product.featureImageUrl === '' ||
            data.product.slug === '' ||
            data.product.summary === '' ||
            data.product.userCategory === '' ||
            selectedCategoryId.length === 0 ||
            data.productVariants.length === 0 ||
            data.productImages.length === 0
        ) {
            setFormError(true);
            return;
        }
        
        const categoryId = selectedCategoryId.map((id) => ({ categoryId: id }));

        const payload = {
            ...data,
            product: {
                ...data.product,
                startsAt: null || data.product?.startsAt,
                endsAt: null || data.product?.endsAt,
            },
            productCategories: [...data.productCategories, ...categoryId],
        };

        dispatch(createOne(payload));
        setData(productTemplate);
        setModalOpen(false);
        setSelectedCategoryId([]);
        setFormError(false);
    };

    return (
        <>
            {/* Main Edit Modal */}
            <Modal isOpen={modalOpen} onClose={handleCloseModal}>
                <div>
                    <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                    >
                        Product:
                    </label>
                    <Input
                        label="Product Title"
                        onChange={(e) =>
                            setData({
                                ...data,
                                product: {
                                    ...data.product,
                                    title: e.target.value,
                                },
                            })
                        }
                        value={data?.product?.title}
                        type="text"
                        isRequired
                    />
                    <Input
                        label="Meta Title"
                        onChange={(e) =>
                            setData({
                                ...data,
                                product: {
                                    ...data.product,
                                    metaTitle: e.target.value,
                                },
                            })
                        }
                        value={data?.product?.metaTitle}
                        type="text"
                        isRequired
                    />
                    {isIntroLoader && <Loader />}
                    <PictureUpload
                        label="Featured Image"
                        onChange={handleFeaturedImage}
                        defaultImage={data?.product?.featureImageUrl}
                        isRequired
                    />
                    <Input
                        label="Slug"
                        onChange={(e) =>
                            setData({
                                ...data,
                                product: {
                                    ...data.product,
                                    slug: e.target.value,
                                },
                            })
                        }
                        value={data?.product?.slug}
                        type="text"
                        isRequired
                    />
                    <ReactQuill
                        id="quill-toolbar"
                        ref={editor}
                        className="ql-container ql-snow"
                        onChange={handleQuillChange}
                        defaultValue={data?.product?.summary}
                        style={{
                            marginLeft: 15,
                            marginRight: 15,
                            background: '#fff',
                        }}
                        modules={constants.modules}
                        formats={constants.formats}
                    />
                    <Input
                        label="User Category"
                        onChange={(e) =>
                            setData({
                                ...data,
                                product: {
                                    ...data.product,
                                    userCategory: e.target.value,
                                },
                            })
                        }
                        value={data?.product?.userCategory}
                        select
                        options={constants.category}
                        isRequired
                    />
                    <Input
                        label='Starts At (To show the product upon creation until stock ends, keep the "Starts At" and "Ends At" fields blank)'
                        onChange={(e) =>
                            setData({
                                ...data,
                                product: {
                                    ...data.product,
                                    startsAt: e.target.value,
                                },
                            })
                        }
                        value={data?.product?.startsAt || ''}
                        type="date"
                    />
                    <Input
                        label="Ends At"
                        onChange={(e) =>
                            setData({
                                ...data,
                                product: {
                                    ...data.product,
                                    endsAt: e.target.value,
                                },
                            })
                        }
                        value={data?.product?.endsAt || ''}
                        type="date"
                    />
                    <Input
                        label="Status"
                        onChange={(e) =>
                            setData({
                                ...data,
                                product: {
                                    ...data.product,
                                    isActive: e.target.value,
                                },
                            })
                        }
                        defaultValue={data?.product?.isActive}
                        select
                        options={[
                            { id: true, value: 'Active' },
                            { id: false, value: 'Inactive' },
                        ]}
                        isRequired
                    />
                </div>
                <div>
                    <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                    >
                        Product Category :
                    </label>
                    <div className="flex flex-wrap">
                        <div className="w-full lg:w-12/12 px-4">
                            <div className="relative w-full mb-3">
                                <label
                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                    htmlFor="grid-password"
                                >
                                    Category List
                                </label>
                                {Array.isArray(categoryList) &&
                                categoryList?.length > 0 ? (
                                    <select
                                        multiple
                                        onChange={handleSelectChange}
                                        value={selectedCategoryId}
                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    >
                                        <option disabled>
                                            Select Category
                                        </option>
                                        {categoryList?.map((category) => (
                                            <option
                                                key={category.id}
                                                value={category.id}
                                            >
                                                {category.title}
                                            </option>
                                        ))}
                                    </select>
                                ) : (
                                    <p>NO DATA</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                    >
                        Product Variant :
                        <button
                            className={`${styles.openButton} ml-2`}
                            onClick={handleOpenVariantModal}
                        >
                            <i className="fas fa-plus"></i>
                        </button>
                    </label>
                </div>
                <div>
                    <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                    >
                        Product Images :
                        <button
                            className={`${styles.openButton} ml-2`}
                            onClick={handleOpenImageModal}
                        >
                            <i className="fas fa-plus"></i>
                        </button>
                    </label>
                </div>
                {formError && (
                    <p className="mx-4 text-red-500 mb-2">
                        Please fill all the details!
                    </p>
                )}
                <div className="flex gap-2 mt-4">
                    <button
                        onClick={handleCreate}
                        className="bg-pink text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="button"
                    >
                        Save
                    </button>
                </div>
            </Modal>

            {/* Product Variant Modal */}
            <Modal isOpen={variantModal} onClose={handleCloseVariantModal}>
                {data.productVariants.length > 0 && (
                    <div className="my-4">
                        <table className="items-center w-full bg-transparent border-collapse">
                            <thead>
                                <tr>
                                    <th className="px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                        Sr No
                                    </th>
                                    <th className="px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                        Variant Name
                                    </th>
                                    <th className="px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                        SKU
                                    </th>
                                    <th className="px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                        Actual Price
                                    </th>
                                    <th className="px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                        Display Price
                                    </th>
                                    <th className="px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                        Discount
                                    </th>
                                    <th className="px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                        Stock Quantity
                                    </th>
                                    <th className="px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.productVariants?.map((item, index) => (
                                    <tr key={index}>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs text-center whitespace-nowrap p-2                                                                                             ">
                                            {index + 1}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs text-center whitespace-nowrap p-2">
                                            {item?.variantName}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs text-center whitespace-nowrap p-2">
                                            {item?.sku}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs text-center whitespace-nowrap p-2">
                                            {item?.actualPrice}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs text-center whitespace-nowrap p-2">
                                            {item?.displayPrice}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs text-center whitespace-nowrap p-2">
                                            {item?.discount}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs text-center whitespace-nowrap p-2">
                                            {item?.stockQuantity}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle flex justify-center border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            <button
                                                className="bg-pink text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-2 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                                type="button"
                                                onClick={() =>
                                                    handleProductVariantDelete(
                                                        index
                                                    )
                                                }
                                            >
                                                Delete
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
                <Input
                    label="Variant Name"
                    onChange={(e) =>
                        setProductVariants({
                            ...productVariants,
                            variantName: e.target.value,
                        })
                    }
                    value={productVariants?.variantName}
                    type="text"
                    isRequired
                />
                <Input
                    label="SKU"
                    onChange={(e) =>
                        setProductVariants({
                            ...productVariants,
                            sku: e.target.value,
                        })
                    }
                    value={productVariants?.sku}
                    type="text"
                    isRequired
                />
                <Input
                    label="Actual Price"
                    onChange={(e) =>
                        setProductVariants({
                            ...productVariants,
                            actualPrice: e.target.value,
                        })
                    }
                    value={productVariants?.actualPrice}
                    type="text"
                    isRequired
                />
                <Input
                    label="Display Price"
                    onChange={(e) =>
                        setProductVariants({
                            ...productVariants,
                            displayPrice: e.target.value,
                        })
                    }
                    value={productVariants?.displayPrice}
                    type="text"
                    isRequired
                />
                <Input
                    label="Discount"
                    onChange={(e) =>
                        setProductVariants({
                            ...productVariants,
                            discount: e.target.value,
                        })
                    }
                    value={productVariants?.discount}
                    type="text"
                    isRequired
                />
                <Input
                    label="Stock Quantity"
                    onChange={(e) =>
                        setProductVariants({
                            ...productVariants,
                            stockQuantity: e.target.value,
                        })
                    }
                    value={productVariants?.stockQuantity}
                    type="text"
                    isRequired
                />
                {formError && (
                    <p className="mx-4 text-red-500 mb-2">
                        Please fill all Variants!
                    </p>
                )}
                <div className="flex gap-2 mt-4">
                    <button
                        type="button"
                        onClick={handleProductVariantSubmit}
                        className="bg-pink text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    >
                        Save & Add New Variant
                    </button>
                </div>
            </Modal>

            {/* Product Images Modal */}
            <Modal isOpen={imageModal} onClose={handleCloseImageModal}>
                {data.productImages.length > 0 && (
                    <div className="my-4">
                        <table className="items-center w-full bg-transparent border-collapse">
                            <thead>
                                <tr>
                                    <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                        Sr No
                                    </th>
                                    <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                        Image Preview
                                    </th>
                                    <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.productImages?.map((item, index) => (
                                    <tr key={index}>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs text-center whitespace-nowrap p-4">
                                            {index + 1}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            <img
                                                src={item?.imageURL}
                                                className="w-20 aspect-square"
                                                alt={`product-${index}`}
                                            />
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            <button
                                                className="bg-pink text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                                type="button"
                                                onClick={() =>
                                                    handleProductImageDelete(
                                                        index
                                                    )
                                                }
                                            >
                                                Delete
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
                {isImageUploading && <Loader />}
                <PictureUpload
                    label="Image URL"
                    onChange={handleProductImage}
                    defaultImage={productImages?.imageURL}
                    isRequired
                />
                {formError && (
                    <p className="mx-4 text-red-500 mb-2">
                        Please fill all Images!
                    </p>
                )}
                <div className="flex gap-2 mt-4">
                    <button
                        type="button"
                        onClick={handleProductImageSubmit}
                        disabled={isImageUploading}
                        className="bg-pink text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    >
                        Save & Add New Image
                    </button>
                </div>
            </Modal>

            <div className="flex flex-wrap mt-4">
                <div className="w-full mb-12 px-4">
                    <ProductTable
                        handleOpenModal={handleOpenModal}
                        color="dark"
                    />
                </div>
            </div>
        </>
    );
};

export default Product;
