import { config } from "./confg";
import { axiosCallAdvanced } from "./main";

export const getBabyMessage = async () => {
  return (
    (
      await axiosCallAdvanced({
        baseURL: config.babyMessage.root,
        path: config.babyMessage.getBabyMessage,
      })
    )?.data || {}
  );
};

export const createBabyMessage = async (data) => {
  return (
    (
      await axiosCallAdvanced({
        baseURL: config.babyMessage.root,
        path: config.babyMessage.createBabyMessage,
        body: { ...data },
        method: config.methodType.post,
      })
    )?.data || {}
  );
};

export const updateBabyMessage = async (parameters) => {
  return (
    (
      await axiosCallAdvanced({
        baseURL: config.babyMessage.root,
        path: `${config.babyMessage.updateBabyMessage}/${parameters.id}`,
        body: parameters.body,
        method: config.methodType.put,
      })
    )?.data || {}
  );
};

export const deleteBabyMessage = async (id) => {
  return (
    (
      await axiosCallAdvanced({
        baseURL: config.babyMessage.root,
        path: `${config.babyMessage.deleteBabyMessage}/${id}`,
        method: config.methodType.patch,
      })
    )?.data || {}
  );
};
