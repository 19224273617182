import React, { useState } from "react";
import GreetingTable from "components/Cards/GreetingTable";
import Modal from "lib/Modal";
import Input from "lib/Input";
import DatePicker from "react-datepicker";
import { constants } from "utils/constants";
import { useDispatch } from "react-redux";
import { createNewGreetings } from "redux/greetingSlice";

const Greeting = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [text, setText] = useState("");
  const [formError, setFormError] = useState(false);
  const [isActive, setIsActive] = useState(true);

  const dispatch = useDispatch();

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleCreateNew = () => {
    if (day !== "" && text.trim() !== "" && month !== "") {
      const newGreeting = {
        day: day,
        month: month,
        text: text.trim(),
        isActive: isActive,
      };
      dispatch(createNewGreetings(newGreeting));

      setModalOpen(false);
      setDay("");
      setMonth("");
      setText("");
      setFormError(false);
    } else {
      setFormError(true);
    }
  };

  const dayOptions = Array.from({ length: 31 }, (_, i) => ({
    value: i + 1,
    label: `Day ${i + 1}`,
  }));

  return (
    <>
      <Modal isOpen={modalOpen} onClose={handleCloseModal}>
        <div>
          <Input
            onChange={(e) => setDay(parseInt(e.target.value))}
            value={day}
            label="Day"
            isRequired
            options={dayOptions}
            select
          />
          <Input
            onChange={(e) => setMonth(e.target.value)}
            value={month}
            label="Month"
            isRequired
            options={constants.monthsOptions}
            select
          />
          <Input
            onChange={(e) => setText(e.target.value)}
            value={text}
            label="Greeting Text"
            isRequired
          />
          <Input
            onChange={(e) => setIsActive(e.target.value)}
            defaultValue={isActive}
            options={[
              { id: true, value: "Active" },
              { id: false, value: "Inactive" },
            ]}
            select
            label="Visibility State"
            isRequired
          />
          {formError && (
            <p className="mx-4 text-red-500 mb-2">
              Please fill all the details!
            </p>
          )}
          <div className="flex gap-2">
            <button
              onClick={handleCreateNew}
              className="bg-pink mx-4 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
            >
              Save
            </button>
          </div>
        </div>
      </Modal>

      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <GreetingTable handleOpenModal={handleOpenModal} />
        </div>
      </div>
    </>
  );
};

export default Greeting;
