import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getSeminar,
  createSeminar,
  deleteSeminar,
  updateSeminar,
} from "api/liveSeminarAPI";

export const getList = createAsyncThunk('seminars/list', async () => {
    try {
        const res = await getSeminar();
        if (res.success) {
            return res.data;
        } else {
            throw new Error('Error fetching Diet.');
        }
    } catch (error) {
        throw error;
    }
});

export const createOne = createAsyncThunk(
    'seminars/create-one',
    async (data) => {
        try {
            const res = await createSeminar(data);
            if (res.success) {
                return res.data;
            } else {
                throw new Error('Error creating seminar.');
            }
        } catch (error) {
            throw error;
        }
    }
);

export const updateOne = createAsyncThunk(
    'seminars/update-one',
    async (data) => {
        try {
            const res = await updateSeminar(data);
            if (res.success) {
                return res.data;
            } else {
                throw new Error('Error updating seminar.');
            }
        } catch (error) {
            throw error;
        }
    }
);

export const deleteOne = createAsyncThunk('seminars/delete-one', async (id) => {
    try {
        const res = await deleteSeminar(id);
        if (res.success) {
            return id;
        } else {
            throw new Error('Error deleting seminar.');
        }
    } catch (error) {
        throw error;
    }
});

const seminarSlice = createSlice({
    name: 'seminars',
    initialState: {
        data: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            //* GET 
            .addCase(getList.pending, (state) => {
                state.loading = true;
            })
            .addCase(getList.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(getList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            //* CREATE
            .addCase(createOne.pending, (state) => {
                state.loading = true;
            })
            .addCase(createOne.fulfilled, (state, action) => {
                state.loading = false;
                if (action.payload) {
                    state.data.push(action.payload);
                }
            })
            .addCase(createOne.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            //* UPDATE 
            .addCase(updateOne.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateOne.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                const seminarIndex = state.data.findIndex(
                    (seminar) => seminar.id === action.payload.id
                );
                if (seminarIndex !== -1) {
                    state.data[seminarIndex] = action.payload;
                }
            })
            .addCase(updateOne.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            //* DELETE
            .addCase(deleteOne.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteOne.fulfilled, (state, action) => {
                state.loading = false;
                state.data = state.data.filter(
                    (seminar) => seminar.id !== action.payload
                );
            })
            .addCase(deleteOne.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const seminarAction = seminarSlice.actions;
export default seminarSlice;
