import DeliveredTable from 'components/Cards/DeliveredTable';
import React from 'react';

const Confirmed = () => {
    return (
        <>
            <div className="flex flex-wrap mt-4">
                <div className="w-full mb-12 px-4">
                    <DeliveredTable />
                </div>
            </div>
        </>
    );
};

export default Confirmed;
