import { config } from "./confg";
import { axiosCallAdvanced } from "./main";

export const getCoupon = async () => {
    return (
        (
            await axiosCallAdvanced({
                baseURL: config.coupon.root,
                path: config.coupon.list,
            })
        )?.data || {}
    );
};
export const createCoupon = async (data) => {
    return (
        (
            await axiosCallAdvanced({
                baseURL: config.coupon.root,
                path: config.coupon.createOne,
                body: { ...data },
                method: config.methodType.post,
            })
        )?.data || {}
    );
};

export const deleteCoupon = async (id) => {
    return (
        (
            await axiosCallAdvanced({
                baseURL: config.coupon.root,
                path: `${config.coupon.deleteOne}/${id}`,
                method: config.methodType.patch,
            })
        )?.data || {}
    );
};

