import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
    createNewProduct,
    getProduct,
    updateProduct,
    deleteProduct,
} from 'api/productAPI';

export const getList = createAsyncThunk('product/list', async () => {
    try {
        const res = await getProduct();
        if (res.success) {
            return res.data;
        } else {
            throw new Error('Error fetching List.');
        }
    } catch (error) {
        throw error;
    }
});

export const createOne = createAsyncThunk(
    'product/create-one',
    async (data) => {
        try {
            const res = await createNewProduct(data);
            if (res.success) {
                const allProducts = await getProduct();
                if (allProducts.success) {
                    return allProducts.data;
                }
            }
        } catch (error) {
            throw error;
        }
    }
);

export const updateOne = createAsyncThunk(
    'product/update-one',
    async (data) => {
        try {
            const res = await updateProduct(data);
            if (res.success) {
                return res.data;
            } else {
                throw new Error('Error updating Product.');
            }
        } catch (error) {
            throw error;
        }
    }
);

export const deleteOne = createAsyncThunk('product/delete-one', async (id) => {
    try {
        const res = await deleteProduct(id);
        if (res.success) {
            return id;
        }
    } catch (error) {
        throw error;
    }
});

const productSlice = createSlice({
    name: 'products',
    initialState: {
        data: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            //* GET
            .addCase(getList.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getList.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.data = action.payload;
            })
            .addCase(getList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            //* CREATE
            .addCase(createOne.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(createOne.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                //! get api is being called to fetch all the data, so instead of pushing the data, directly assign it to state.data
                if (action.payload) {
                    state.data = action.payload;
                }
            })
            .addCase(createOne.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            //* UPDATE
            .addCase(updateOne.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateOne.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                console.log(action.payload);
                const productIndex = state.data.findIndex(
                    (item) => item?.product.id === action.payload?.product.id
                );
                if (productIndex !== -1) {
                    state.data[productIndex] = action.payload;
                }
            })
            .addCase(updateOne.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            //* DELETE
            .addCase(deleteOne.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteOne.fulfilled, (state, action) => {
                state.loading = false;
                state.data = state.data.filter(
                    (item) => item?.product.id !== action.payload
                );
            })
            .addCase(deleteOne.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const ProductAction = productSlice.actions;
export default productSlice;
