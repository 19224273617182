import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createOne, getFaqs, updateOne, deleteOne } from 'api/faqAPI';
import { getFaqCategories } from 'api/faqCategoryAPI';

export const getAllFaqs = createAsyncThunk('faq/list', async () => {
    try {
        const res = await getFaqs();

        if (res.success) {
            return res.data;
        } else {
            throw new Error('Error fetching faqs');
        }
    } catch (error) {
        throw error;
    }
});

export const getAllFaqCategories = createAsyncThunk(
    'faq/category/list',
    async () => {
        try {
            const res = await getFaqCategories();

            if (res.success) {
                return res.data;
            } else {
                throw new Error('Error fetching faq categories');
            }
        } catch (error) {
            throw error;
        }
    }
);

export const createFaq = createAsyncThunk('faq/create-one', async (data) => {
    try {
        const res = await createOne(data);

        if (res.success) {
            return res.data;
        } else {
            throw new Error('Error creating faq');
        }
    } catch (error) {
        throw error;
    }
});

export const updateFaq = createAsyncThunk('faq/update-one', async (data) => {
    try {
        const res = await updateOne(data);

        if (res.success) {
            return res.data;
        } else {
            throw new Error('Error updating faq');
        }
    } catch (error) {
        throw error;
    }
});

export const deleteFaq = createAsyncThunk('faq/delete-one', async (id) => {
    try {
        const res = await deleteOne(id);
        
        if (res.status) {
            return id;
        } else {
            throw new Error('Error deleting faq');
        }
    } catch (error) {
        throw error;
    }
});

const faqSlice = createSlice({
    name: 'faq',
    initialState: {
        faqCategory: [],
        data: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            //* List
            .addCase(getAllFaqs.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllFaqs.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(getAllFaqs.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            //* FaqCategory
            .addCase(getAllFaqCategories.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllFaqCategories.fulfilled, (state, action) => {
                state.loading = false;
                state.faqCategory = action.payload;
            })
            .addCase(getAllFaqCategories.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            //* CreateOne
            .addCase(createFaq.pending, (state) => {
                state.loading = true;
            })
            .addCase(createFaq.fulfilled, (state, action) => {
                state.loading = false;
                state.data.push(action.payload);
            })
            .addCase(createFaq.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            //* UpdateOne
            .addCase(updateFaq.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateFaq.fulfilled, (state, action) => {
                state.loading = false;
                const faqIndex = state.data.findIndex(
                    (item) => item.id === action.payload.id
                );
                if (faqIndex !== -1) {
                    state.data[faqIndex] = action.payload;
                }
            })
            .addCase(updateFaq.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            //* DeleteOne
            .addCase(deleteFaq.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteFaq.fulfilled, (state, action) => {
                state.loading = false;

                console.log(action.payload);
                state.data = state.data.filter(
                    (faq) => faq.id !== action.payload
                );
            })
            .addCase(deleteFaq.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const faqActions = faqSlice.actions;

export default faqSlice;
