import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCoupon, createCoupon, deleteCoupon } from "api/couponAPI";

export const getCouponList = createAsyncThunk('coupon/list', async () => {
    try {
        const res = await getCoupon();
        if (res.success) {
            return res.data;
        } else {
            throw new Error('Error fetching Coupon.');
        }
    } catch (error) {
        throw error;
    }
});
export const createOne = createAsyncThunk(
    'coupon/create-one',
    async (data) => {
        try {
            const res = await createCoupon(data);
            if (res.success) {
                const allCoupons = await getCoupon();
                if (allCoupons.success) {
                    return allCoupons.data;
                }
            } else {
                throw new Error('Error creating Coupon.');
            }
        } catch (error) {
            throw error;
        }
    }
);

export const deleteOne = createAsyncThunk(
    'coupon/delete-one',
    async (id) => {
        try {
            const res = await deleteCoupon(id);
            if (res.success) {
                return id;
            } else {
                throw new Error('Error deleting Coupon.');
            }
        } catch (error) {
            throw error;
        }
    }
);

const CouponSlice = createSlice({
    name: 'coupon',
    initialState: {
        data: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCouponList.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCouponList.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(getCouponList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(createOne.pending, (state) => {
                state.loading = true;
            })
            .addCase(createOne.fulfilled, (state, action) => {
                state.loading = false;
                if (action.payload) {       //! Coupons are fetched with get API, so direct assign it to data
                    state.data = (action.payload);
                }
            })
            .addCase(createOne.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(deleteOne.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteOne.fulfilled, (state, action) => {
                state.loading = false;
                state.data = state.data.filter(
                    (coupon) => coupon.id !== action.payload
                );
            })
            .addCase(deleteOne.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const couponAction = CouponSlice.actions;
export default CouponSlice;
