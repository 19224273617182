import Input from "lib/Input";
import SaveButton from "lib/SaveButton";
import { useState } from "react";
import Modal from "lib/Modal";
import DeleteButton from "lib/DeleteButton";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  updateNewProductCategory,
  deleteNewProductCategory,
  getNewProductCategory,
} from "redux/productCategorySlice";
import Loader from "components/Loader/Loader";
import { Toaster } from "react-hot-toast";

const ProductCategoryTable = ({ color, data, handleOpenModal }) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const dispatch = useDispatch();
  const productCategoryData = useSelector(
    (state) => state.productCategory.data
  );
  const loading = useSelector((state) => state.productCategory.loading);

  const handleOpenEditModal = (item) => {
    setModalData(item);
    setOpenEditModal(true);
  };

  useEffect(() => {
    dispatch(getNewProductCategory());
  }, [dispatch]);

  const handleUpdate = () => {
    const updatedData = {
      parentId: null,
      title: modalData.title.trim(),
      metaTitle: modalData.metaTitle.trim(),
      slug: modalData.slug.trim(),
      categoryDetails: modalData.categoryDetails.trim(),
      isActive: modalData?.isActive,
    };
    dispatch(
      updateNewProductCategory({ id: modalData?.id, data: updatedData })
    );
    setOpenEditModal(false);
  };

  const handleDeleteButton = async (id) => {
    dispatch(await deleteNewProductCategory(id));
  };

  function handleEditButton() {
    handleUpdate();
  }
  return (
    <>
      <Toaster />
      <Modal isOpen={openEditModal} onClose={() => setOpenEditModal(false)}>
        <div>
          <Input
            onChange={(e) =>
              setModalData({ ...modalData, title: e.target.value })
            }
            value={modalData?.title}
            label="Title"
            isRequired
          />
          <Input
            onChange={(e) =>
              setModalData({ ...modalData, metaTitle: e.target.value })
            }
            value={modalData?.metaTitle}
            label="Meta Title (same as title)"
            isRequired
          />
          <Input
            onChange={(e) =>
              setModalData({ ...modalData, slug: e.target.value })
            }
            value={modalData?.slug}
            label="Slug"
            isRequired
          />
          <Input
            onChange={(e) =>
              setModalData({ ...modalData, categoryDetails: e.target.value })
            }
            value={modalData?.categoryDetails}
            label="Category Details"
            isRequired
          />
          <Input
            onChange={(e) => setModalData({ ...modalData, isActive: e.target.value })}
            value={modalData?.isActive}
            options={[
              { id: true, value: "Active" },
              { id: false, value: "Inactive" },
            ]}
            select
            label="Is Active"
            isRequired
          />
        </div>
        <div className="flex gap-2">
          <SaveButton handleEditButton={handleEditButton}></SaveButton>
        </div>
      </Modal>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
        <div className="rounded-t mb-0 px-4 py-4 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3 className="font-semibold text-lg text-blueGray-700">
                Product Category
              </h3>
            </div>
            <button
              onClick={handleOpenModal}
              className="bg-pink text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
            >
              Add New
            </button>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Timeline table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  ID
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Title
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Meta Title
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Slug
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Category Details
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Created At
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Created By
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Updated At
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Updated By
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Is Active
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              <>
                {loading && (
                  <div className="flex items-center justify-center h-20">
                    <Loader />
                  </div>
                )}
                {productCategoryData?.map((item) => (
                  <tr key={item.id}>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {item?.id}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {item?.title}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {item?.metaTitle}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {item?.slug}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {item?.categoryDetails}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {new Date(item?.createdAt).toLocaleString()}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {new Date(item?.createdBy).toLocaleString()}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {new Date(item?.updatedAt).toLocaleString()}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {new Date(item?.updatedBy).toLocaleString()}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {item?.isActive ? "Active" : "Inactive"}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      <div className="flex justify-evenly">
                        <button
                          onClick={() => handleOpenEditModal(item)}
                          className="focus:outline-none	"
                          type="button"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 mr-2 "
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                            />
                          </svg>
                        </button>
                        <DeleteButton
                          handleDeleteButton={() => {
                            handleDeleteButton(item?.id);
                          }}
                        ></DeleteButton>
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ProductCategoryTable;
