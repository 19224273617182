import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCareers, deleteCareers } from "api/CareersAPI";

export const getCareersList = createAsyncThunk("join-us/list", async () => {
  try {
    const res = await getCareers();
    if (res.success) {
      return res.data;
    } else {
      throw new Error("Error fetching BJoin Team.");
    }
  } catch (error) {
    throw error;
  }
});

export const deleteOne = createAsyncThunk(
  "join-us/delete-one",
  async (id) => {
    try {
      const res = await deleteCareers(id);
      if (res.success) {
        return id;
      } else {
        throw new Error("Error deleting Join Team.");
      }
    } catch (error) {
      throw error;
    }
  }
);

const careersSlice = createSlice({
  name: "join-us",
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCareersList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCareersList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getCareersList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteOne.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteOne.fulfilled, (state, action) => {
        state.loading = false;
        state.data = state.data.filter((blogs) => blogs.id !== action.payload);
      })
      .addCase(deleteOne.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const blogsAction = careersSlice.actions;
export default careersSlice;
