import React, { useEffect, useRef, useState } from 'react';
import Modal from 'lib/Modal';
import Input from 'lib/Input';
import ReactQuill from 'react-quill';
import 'quill/dist/quill.snow.css';
import '../../assets/styles/richText.css';
import toast, { Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import {
    getList,
    updateOne,
    deleteOne,
} from 'redux/dietAndNutritionSlice';
import SaveButton from 'lib/SaveButton';
import DeleteButton from 'lib/DeleteButton';
import PictureUpload from 'lib/PictureUpload';
import Loader from 'components/Loader/Loader';
import { constants } from 'utils/constants';
import { handleImageFile, handleMediaFile } from 'api/fileUploadAPI';

const DietAndNutritionTable = ({ color, handleOpenModal }) => {
    const editor = useRef(null); //react quill
    const dispatch = useDispatch();
    const [openEditModal, setOpenEditModal] = useState(false);

    const [modalData, setModalData] = useState({});
    const [isIntroLoader, setIsIntroLoader] = useState(false);
    const [isThumbnailLoader, setIsThumbnailLoader] = useState(false);
    
    const Data = useSelector((state) => state.diet.data);
    const loading = useSelector((state) => state.diet.loading);
    
    const MAX_LENGTH = 50;

    const handleOpenEditModal = (item) => {
        setModalData(item);
        setOpenEditModal(true);
    };

    function handleEditButton() {
        handleUpdate();
    }

    useEffect(() => {
        dispatch(getList());
    }, [dispatch]);

    const handleUpdate = () => {
        const data = {
            userCategory: modalData.userCategory,
            month: parseInt(modalData.month),
            // gender: modalData.gender,
            title: modalData.title.trim(),
            content: modalData.content.trim(),
            thumbnailURL: modalData.thumbnailURL,
            mediaType: modalData.mediaType,
            mediaURL: modalData.mediaURL,
            isActive: modalData.isActive,
        };
        dispatch(updateOne({ id: modalData?.id, body: { ...data } }));
        setOpenEditModal(false);
    };

    const handleDeleteButton = async (item) => {
        dispatch(await deleteOne(item.id));
    };

    const userCategory = [
        { value: 'Pre Pregnancy', id: '1' },
        { value: 'Pregnant', id: '2' },
        { value: 'Parenting', id: '3' },
    ];

    const mediaOption = [
        { value: 'No Media', id: '0' },
        { value: 'Image', id: '1' },
        { value: 'Audio', id: '2' },
        { value: 'Video', id: '3' },
    ];

    const handleQuillChange = (value) => {
        setModalData({ ...modalData, content: value });
    };

    const handleIntroMedia = async (file) => {
        if (file === undefined) {
            setModalData({ ...modalData, mediaURL: '' });
            return;
        }

        if (modalData?.mediaType === '1') {
            try {
                setIsIntroLoader(true);
                const res = await handleImageFile(file);
                if (res.success) {
                    setModalData({ ...modalData, mediaURL: res.file_url });
                    toast.success('Successfully Uploaded the Image!');
                } else {
                    throw new Error('Picture Not Successfully Uploaded');
                }
                setIsIntroLoader(false);
            } catch (error) {
                toast.error(error);
            }
        } else {
            //audio and video type are strings
            try {
                setIsIntroLoader(true);
                const res = await handleMediaFile(file);
                if (res.success) {
                    setModalData({ ...modalData, mediaURL: res.file_url });
                    modalData?.introMediaType === '2'
                        ? toast.success('Successfully Uploaded the Audio!')
                        : toast.success('Successfully Uploaded the video!');
                } else {
                    throw new Error('Picture Not Successfully Uploaded');
                }
                setIsIntroLoader(false);
            } catch (error) {
                toast.error(error);
            }
        }
    };

    const handleThumbnail = async (file) => {
        if (file === undefined) {
            setModalData({ ...modalData, thumbnailURL: '' });
            return;
        }

        try {
            setIsThumbnailLoader(true);
            const res = await handleImageFile(file);
            if (res.success) {
                setModalData({ ...modalData, thumbnailURL: res.file_url });
                toast.success('Successfully Uploaded the Image!');
            } else {
                throw new Error('Picture Not Successfully Uploaded');
            }
            setIsThumbnailLoader(false);
        } catch (error) {
            toast.error(error);
        }
    };


    return (
        <>
            {/* Edit modal */}
            <Toaster />
            <Modal
                isOpen={openEditModal}
                onClose={() => setOpenEditModal(false)}
            >
                <div>
                    <Input
                        onChange={(e) =>
                            setModalData({
                                ...modalData,
                                userCategory: e.target.value,
                            })
                        }
                        value={modalData?.userCategory}
                        label="User Category"
                        isRequired
                        options={userCategory}
                        select
                    />
                    <Input
                        label="Month"
                        isRequired
                        value={modalData.month}
                        onChange={(e) =>
                            setModalData({
                                ...modalData,
                                month: parseInt(e.target.value),
                            })
                        }
                        select
                        options={constants.monthsOptions}
                    />
                    {/* <Input
                            label="Gender"
                            isRequired
                            defaultValue={modalData.gender}
                            onChange={(e) =>
                                setModalData({ ...modalData, gender: e.target.value })
                            }
                            options={constants.gender}
                            select
                            /> */}
                    <Input
                        label="Diet Title"
                        isRequired
                        value={modalData?.title || ''}
                        onChange={(e) =>
                            setModalData({
                                ...modalData,
                                title: e.target.value,
                            })
                        }
                    />
                    <ReactQuill
                        style={{
                            marginLeft: 15,
                            marginRight: 15,
                            background: '#fff',
                        }}
                        className="ql-container ql-snow"
                        ref={editor}
                        modules={constants.modules}
                        formats={constants.formats}
                        defaultValue={modalData.content}
                        onChange={handleQuillChange}
                    />
                    <Input
                        label="Media Type"
                        isRequired
                        defaultValue={modalData?.mediaType}
                        onChange={(e) =>
                            setModalData({
                                ...modalData,
                                mediaType: e.target.value,
                            })
                        }
                        select
                        options={mediaOption}
                    />
                    {isIntroLoader && <Loader />}
                    {modalData?.introMediaType !== '0' && (
                        <PictureUpload
                            onChange={handleIntroMedia}
                            defaultValue={modalData?.mediaURL}
                            label={
                                modalData?.introMediaType === '3'
                                    ? 'Intro Video'
                                    : modalData?.introMediaType === '2'
                                    ? 'Intro Audio'
                                    : 'Intro Image'
                            }
                            isRequired
                            accept="image/*, video/*"
                        />
                    )}
                    {isThumbnailLoader && <Loader />}
                    <PictureUpload
                        label="Thumbnail Image"
                        isRequired
                        onChange={handleThumbnail}
                        defaultValue={modalData?.thumbnailURL}
                    />
                    <Input
                        onChange={(e) =>
                            setModalData({
                                ...modalData,
                                isActive: e.target.value,
                            })
                        }
                        defaultValue={modalData.isActive}
                        options={[
                            { id: true, value: 'Active' },
                            { id: false, value: 'Inactive' },
                        ]}
                        select
                        label="IS ACTIVE"
                        isRequired
                    />
                    <div className="flex gap-2">
                        <SaveButton
                            handleEditButton={!isIntroLoader && !isThumbnailLoader ? handleEditButton : undefined}
                        ></SaveButton>
                    </div>
                </div>
            </Modal>
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
                <div className="rounded-t mb-0 px-4 py-4 border-0">
                    <div className="flex flex-wrap items-center">
                        <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                            <h3 className="font-semibold text-lg text-blueGray-700">
                                Diet
                            </h3>
                        </div>
                        <button
                            onClick={handleOpenModal}
                            className="bg-pink text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                            type="button"
                        >
                            Add New
                        </button>
                    </div>
                </div>
                <div className="block w-full overflow-x-auto">
                    <table className="w-full bg-transparent border-collapse">
                        <thead>
                            <tr>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    ID
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    User Category
                                </th>

                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Month
                                </th>
                                {/* <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                      Gender
                                    </th> */}
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Title
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Description
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Thumbnail Url
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Images URL
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Created At
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Created By
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Updated At
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Updated By
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Is Active
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading && (
                                <tr>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        <div className="flex items-center justify-center h-20">
                                            <Loader />
                                        </div>
                                    </td>
                                </tr>
                            )}
                            {Data?.map((item) => (
                                <tr key={item?.id}>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.id}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.userCategory === '1'
                                            ? 'Pre Pregnancy'
                                            : item?.userCategory === '2'
                                            ? 'Pregnant'
                                            : item?.userCategory === '3'
                                            ? 'Parenting'
                                            : 'N/A'}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.month}
                                    </td>
                                    {/* <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                          {item?.gender === "0"
                                            ? "General"
                                            : item?.gender === "1"
                                            ? "Female"
                                            : item?.gender === "2"
                                            ? "Male"
                                            : "N/A"}
                                        </td> */}
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.title}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {constants.trimText(
                                            item?.content,
                                            MAX_LENGTH
                                        )}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        <a
                                            href={item.thumbnailURL}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-blue-600 underline hover:text-blue-800"
                                        >
                                            {item?.thumbnailURL}
                                        </a>
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        <a
                                            href={item.mediaURL}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-blue-600 underline hover:text-blue-800"
                                        >
                                            {item?.mediaURL}
                                        </a>
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {new Date(
                                            item?.createdAt
                                        ).toLocaleString()}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.createdBy}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {new Date(
                                            item?.updatedAt
                                        ).toLocaleString()}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.updatedBy}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.isActive ? 'Active' : 'Inactive'}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        <div className="flex justify-evenly">
                                            <button
                                                onClick={() =>
                                                    handleOpenEditModal(item)
                                                }
                                                className="focus:outline-none	"
                                                type="button"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="h-5 w-5 mr-2 "
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    strokeWidth="2"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                                    />
                                                </svg>
                                            </button>
                                            <DeleteButton
                                                handleDeleteButton={() => {
                                                    handleDeleteButton(item);
                                                }}
                                            ></DeleteButton>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default DietAndNutritionTable;