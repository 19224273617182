import React from "react";
import styles from "./Modal.module.css";

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className={`${styles.overlay} ${isOpen ? styles.open : ""}`}>
      <div className={`${styles.modal} ${isOpen ? styles.open : ""}`}>
        <div className={`${styles.header}`}>
          <button className={styles.closeButton} onClick={onClose}>
            <i className="fas fa-times"></i>
          </button>
        </div>

        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
