import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createStrotra,
  getStrotra,
  updateStrotra,
  deleteStrotra,
} from "api/strotraAPI";

// Async thunk to fetch shlokas list
export const getList = createAsyncThunk("strotras/list", async () => {
  try {
    const res = await getStrotra();
    if (res.success) {
      return res.data;
    } else {
      throw new Error("Error fetching shlokas.");
    }
  } catch (error) {
    throw error;
  }
});

// Async thunk to create a new shloka
export const createNewStrotra = createAsyncThunk(
  "strotras/create-one",
  async (data) => {
    try {
      const res = await createStrotra(data);
      return res.data;
    } catch (error) {
      throw error;
    }
  }
);

// Async thunk to update a shloka
export const updateNewStrotra = createAsyncThunk(
  "strotras/update-one",
  async (data) => {
    try {
      const res = await updateStrotra(data);
      return res.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteNewStrotra = createAsyncThunk(
  "strotras /update-one",
  async (id) => {
    try {
      const res = await deleteStrotra(id);
      return id;
    } catch (error) {
      throw error;
    }
  }
);


const initialState = {
  strotras: [],
  loading: false,
  error: null,
};

const strotraSlice = createSlice({
  name: "strotras",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
        .addCase(getList.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(getList.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
            state.strotras = action.payload;
        })
        .addCase(getList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        })

        .addCase(createNewStrotra.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(createNewStrotra.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
            state.strotras.push(action.payload); // Add the newly created shloka to the list
        })
        .addCase(createNewStrotra.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        })

        .addCase(updateNewStrotra.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(updateNewStrotra.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
            const updatedStrotraIndex = state.strotras.findIndex(
                (strotra) => strotra.id === action.payload.id
            );
            if (updatedStrotraIndex !== -1) {
                state.strotras[updatedStrotraIndex] = action.payload;
            }
        })
        .addCase(updateNewStrotra.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        })

        .addCase(deleteNewStrotra.pending, (state, action) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(deleteNewStrotra.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
            state.strotras = state.strotras.filter(
                (shrota) => shrota.id !== action.payload
            );
        })
        .addCase(deleteNewStrotra.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
  },
});

export const strotraAction = strotraSlice.actions;

export default strotraSlice;
