import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// components

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import HeaderStats from "components/Headers/HeaderStats.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";

// views

import Dashboard from "views/admin/Dashboard.js";
import Maps from "views/admin/Maps.js";
import Settings from "views/admin/Settings.js";
import UserTable from "views/admin/UserTable.js";
import MasterTable from "views/admin/MasterTable.js";
import Faqs from "views/admin/Faqs";
import RecordedSeminars from "views/admin/RecordedSeminars";
import DevelopmentTimeline from "views/admin/DevelopmentTimeline";
import DailyActivities from "views/admin/DailyActivities";
import BabyHealth from "views/admin/BabyHealth";
import LiveSeminars from "views/admin/LiveSeminars";
import MessageFromBaby from "views/admin/MessageFromBaby";
import Contact from "views/admin/Contact";
import MessageFromSoul from "views/admin/MessageFromSoul";
import Strotra from "views/admin/Strotra";
import Shloka from "views/admin/ShlokaPage";
import Story from "views/admin/Story";
import GarbhaSamvad from "views/admin/GarbhaSamvad";
import RecipeOfTheDay from "views/admin/RecipeOfTheDay";
import RagSanskar from "views/admin/RagSanskar";
import YogaPractices from "views/admin/YogaPractices";
import DietAndNutrition from "views/admin/DietAndNutrition";
import BrainActivity from "views/admin/BrainActivity";
import Greeting from "views/admin/Greeting";
import Notification from "views/admin/Notification";
import Product from "views/admin/Product";
import Customer from "views/admin/Customer";
import SubscriptionOrder from 'views/admin/SubscriptionOrder';
import Received from 'views/admin/Received';
import InTransit from 'views/admin/InTransit';
import Delivered from 'views/admin/Delivered';
import Cancelled from 'views/admin/Cancelled';
import Coupons from 'views/admin/Coupons';
import ProductCategory from 'views/admin/ProductCategory';
import Testimonials from 'views/admin/Testimonials';
import Blogs from 'views/admin/Blogs';
import Careers from 'views/admin/Careers';
import Consultation from 'views/admin/Consultation';
import DeliveryDateTable from 'components/Cards/DeliveryDateTable';
import ErrorPage from "views/admin/ErrorPage";
import HomeRemediesPage from "views/admin/HomeRemedies";

export default function Admin() {
    const token = localStorage.getItem('accessToken');
    useEffect(() => {
        if (!token) {
            window.location.href = '/auth/login';
        }
    }, [token])

    return (
        <>
            <Sidebar />
            <div className="relative md:ml-64 bg-blueGray-100">
                <AdminNavbar />
                {/* Header */}
                <HeaderStats />
                <div className="px-4 md:px-10 mx-auto w-full -m-24">
                    <Switch>
                        <Route
                            path="/admin/dashboard"
                            exact
                            component={Dashboard}
                        />
                        <Route path="/admin/maps" exact component={Maps} />
                        <Route
                            path="/admin/settings"
                            exact
                            component={Settings}
                        />
                        <Route
                            path="/admin/user-tables"
                            exact
                            component={UserTable}
                        />
                        <Route
                            path="/admin/delivery-dates"
                            exact
                            component={DeliveryDateTable}
                        />
                        <Route
                            path="/admin/master-tables"
                            exact
                            component={MasterTable}
                        />
                        <Route
                            path="/admin/home-remedies"
                            exact
                            component={HomeRemediesPage}
                        />

                        <Route path="/admin/faqs" exact component={Faqs} />
                        <Route
                            path="/admin/recorded-seminars"
                            exact
                            component={RecordedSeminars}
                        />
                        <Route
                            path="/admin/baby-health"
                            exact
                            component={BabyHealth}
                        />
                        <Route
                            path="/admin/produt-categories"
                            exact
                            component={ProductCategory}
                        />
                        <Route
                            path="/admin/development-timeline"
                            exact
                            component={DevelopmentTimeline}
                        />
                        <Route
                            path="/admin/message-from-baby"
                            exact
                            component={MessageFromBaby}
                        />
                        <Route
                            path="/admin/message-from-soul"
                            exact
                            component={MessageFromSoul}
                        />
                        <Route
                            path="/admin/recipe-of-the-day"
                            exact
                            component={RecipeOfTheDay}
                        />
                        <Route
                            path="/admin/rag-sanskar"
                            exact
                            component={RagSanskar}
                        />
                        <Route
                            path="/admin/yoga-practices"
                            exact
                            component={YogaPractices}
                        />
                        <Route
                            path="/admin/strotra"
                            exact
                            component={Strotra}
                        />
                        <Route path="/admin/shloka" exact component={Shloka} />
                        <Route path="/admin/story" exact component={Story} />
                        <Route
                            path="/admin/garbha-samvad"
                            exact
                            component={GarbhaSamvad}
                        />
                        <Route
                            path="/admin/live-seminars"
                            exact
                            component={LiveSeminars}
                        />
                        <Route
                            path="/admin/daily-activities"
                            exact
                            component={DailyActivities}
                        />
                        <Route
                            path="/admin/brain-activity"
                            exact
                            component={BrainActivity}
                        />
                        <Route
                            path="/admin/diet-and-nutrition"
                            exact
                            component={DietAndNutrition}
                        />
                        <Route
                            path="/admin/greeting-table"
                            exact
                            component={Greeting}
                        />
                        <Route
                            path="/admin/notification"
                            exact
                            component={Notification}
                        />
                        <Route
                            path="/admin/contact"
                            exact
                            component={Contact}
                        />
                        <Route
                            path="/admin/product"
                            exact
                            component={Product}
                        />
                        <Route
                            path="/admin/coupons"
                            exact
                            component={Coupons}
                        />

                        <Route
                            path="/admin/orders/subscription"
                            exact
                            component={SubscriptionOrder}
                        />
                        <Route
                            path="/admin/orders/confirm"
                            exact
                            component={Received}
                        />
                        <Route
                            path="/admin/orders/in-transit"
                            exact
                            component={InTransit}
                        />
                        <Route
                            path="/admin/orders/delivered"
                            exact
                            component={Delivered}
                        />
                        <Route
                            path="/admin/orders/cancelled"
                            exact
                            component={Cancelled}
                        />

                        <Route
                            path="/admin/Customer"
                            exact
                            component={Customer}
                        />
                        <Route
                            path="/admin/testimonials"
                            exact
                            component={Testimonials}
                        />
                        <Route path="/admin/blogs" exact component={Blogs} />
                        <Route
                            path="/admin/careers"
                            exact
                            component={Careers}
                        />
                        <Route
                            path="/admin/consultation"
                            exact
                            component={Consultation}
                        />

                        {/* <Redirect from="/admin" to="/admin/dashboard" /> */}
                        <Route
                            path='*'
                            exact
                            component={ErrorPage}
                        />
                    </Switch>
                    {/* <FooterAdmin /> */}
                </div>
            </div>
        </>
    );
}
