import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {  getYoga, createYoga, updateYoga, deleteYoga } from "api/YogaAPI";

export const getList = createAsyncThunk('yoga/list', async () => {
    try {
        const res = await getYoga();
        if (res.success) {
            return res.data;
        } else {
            throw new Error('Error fetching Yoga');
        }
    } catch (error) {
        throw error;
    }
});

export const createNewYoga = createAsyncThunk(
    'yoga/create-one',
    async (data) => {
        try {
            const res = await createYoga(data);
            if (res.success) {
                return res.data;
            } else {
                throw new Error('Error creating Yoga');
            }
        } catch (error) {
            throw error;
        }
    }
);

export const updateNewYoga = createAsyncThunk(
    'yoga/update-one',
    async (data) => {
        try {
            const res = await updateYoga(data);
            return res.data;
        } catch (error) {
            throw error;
        }
    }
);

export const deleteNewYoga = createAsyncThunk('yoga/delete-one', async (id) => {
    try {
        const res = await deleteYoga(id);
        if (res.success) {
            return id;
        }
    } catch (error) {
        throw error;
    }
});

const yogaSlice = createSlice({
    name: 'yoga',
    initialState: {
        data: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            //* Get List
            .addCase(getList.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getList.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.data = action.payload;
            })
            .addCase(getList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            //* Create New Yoga
            .addCase(createNewYoga.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(createNewYoga.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;

                if (action.payload) {
                    state.data.push(action.payload);
                }
            })
            .addCase(createNewYoga.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            //* Update A Yoga
            .addCase(updateNewYoga.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateNewYoga.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                const updatedYogaIndex = state.data.findIndex(
                    (yoga) => yoga.id === action.payload.id
                );
                if (updatedYogaIndex !== -1) {
                    state.data[updatedYogaIndex] = action.payload;
                }
            })
            .addCase(updateNewYoga.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            //* Delete A Yoga
            .addCase(deleteNewYoga.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteNewYoga.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;

                state.data = state.data.filter(
                    (yoga) => yoga.id !== action.payload
                );
            })
            .addCase(deleteNewYoga.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const dietAction = yogaSlice.actions;
export default yogaSlice;
