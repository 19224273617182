import React, { useState } from "react";
import Modal from "lib/Modal";
import Input from "lib/Input";
import PictureUpload from "lib/PictureUpload";
import { useDispatch, useSelector } from "react-redux";
import { getBabyHealthList } from "redux/babyHealthSlice";
import { useEffect } from "react";
import DeleteButton from "lib/DeleteButton";
import { Toaster, toast } from "react-hot-toast";
import { deleteOneBabyHealth } from "redux/babyHealthSlice";
import { updateOneBabyHealth } from "redux/babyHealthSlice";
import { handleImageFile } from "api/fileUploadAPI";
import Loader from "components/Loader/Loader";
import SaveButton from "lib/SaveButton";

export default function BabyHealthTable({ color, data, handleOpenModal }) {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [isIntroLoader, setIsIntroLoader] = useState(false);
  const dispatch = useDispatch();
  const babyHealthData = useSelector((state) => state.babyHealth.data);
  const loading = useSelector((state) => state.babyHealth.loading);

  useEffect(() => {
    dispatch(getBabyHealthList());
  }, [dispatch]);

  const handleOpenEditModal = (item) => {
    setModalData(item);
    setOpenEditModal(true);
  };

  const handleImageUpload = async (file, imageType) => {
    if(file === undefined) {
      setModalData({ ...modalData, [imageType]: "" });
      return;
    }

    try {
      setIsIntroLoader({ ...isIntroLoader, [imageType]: true });
      const res = await handleImageFile(file);
      if (res.success) {
        setModalData({ ...modalData, [imageType]: res.file_url });
        toast.success(`Successfully Uploaded the ${imageType} Image!`);
        setIsIntroLoader({ ...isIntroLoader, [imageType]: false });
      } else {
        throw new Error(`${imageType} Image Not Successfully Uploaded`);
      }
    } catch (error) {
      toast.error(error.message);
      setIsIntroLoader({ ...isIntroLoader, [imageType]: false });
    }
  };

  function handleEditButton() {
    handleUpdate();
  }
  const handleUpdate = () => {
    const updatedData = {
      week: parseInt(modalData?.week),
      size: modalData?.size,
      description: modalData?.description,
      weight: modalData?.weight,
      height: modalData?.height,
      imageURL: modalData?.imageURL,
      sizeImgURL: modalData?.sizeImgURL,
      isActive: modalData?.isActive,
    };
    console.log(updatedData);
    dispatch(updateOneBabyHealth({ id: modalData?.id, data: updatedData }));
    setOpenEditModal(false);
  };

  const handleDeleteBabyHealth = async (id) => {
    dispatch(await deleteOneBabyHealth(id));
  };

  return (
    <>
      {/* Edit modal */}
      <Toaster />
      <Modal isOpen={openEditModal} onClose={() => setOpenEditModal(false)}>
        <div>
          <Input
            label="Week"
            isRequired
            value={modalData?.week}
            onChange={(e) =>
              setModalData({ ...modalData, week: e.target.value })
            }
            type="text"
          />
          <Input
            label="Baby Size"
            isRequired
            value={modalData?.size}
            onChange={(e) =>
              setModalData({ ...modalData, size: e.target.value })
            }
          />
          <Input
            label="Baby Description (Don't add more than 200 characters)"
            isRequired
            value={modalData?.description}
            onChange={(e) =>
              setModalData({ ...modalData, description: e.target.value })
            }
          />
          <Input
            label="Baby Weight"
            isRequired
            value={modalData?.weight}
            onChange={(e) =>
              setModalData({ ...modalData, weight: e.target.value })
            }
          />
          <Input
            label="Baby Height"
            isRequired
            value={modalData?.height}
            onChange={(e) =>
              setModalData({ ...modalData, height: e.target.value })
            }
          />
          <PictureUpload
            label="Baby Media URL"
            isRequired
            defaultValue={modalData?.imageURL}
            onChange={(file) => handleImageUpload(file, "media")}
          />
          {isIntroLoader["media"] && <Loader />}
          <PictureUpload
            label="Baby Size URL"
            isRequired
            defaultValue={modalData?.sizeImgURL}
            onChange={(file) => handleImageUpload(file, "size")}
          />
          {isIntroLoader["size"] && <Loader />}
          <Input
            onChange={(e) =>
              setModalData({ ...modalData, isActive: e.target.value })
            }
            defaultValue={modalData?.isActive}
            options={[
              { id: true, value: "Active" },
              { id: false, value: "Inactive" },
            ]}
            select
            label="Visibility State"
            isRequired
          />
          <div className="flex gap-2">
            <SaveButton handleEditButton={handleEditButton}></SaveButton>
          </div>
        </div>
      </Modal>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
        <div className="rounded-t mb-0 px-4 py-4 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3 className="font-semibold text-lg text-blueGray-700">
                Baby Health
              </h3>
            </div>
            <button
              onClick={handleOpenModal}
              className="bg-pink text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
            >
              Add New
            </button>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Baby health table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  ID
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Week
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Baby Size
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Baby Description
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Baby Weight (in KG's)
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Baby Height (in CM)
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Baby Media Url
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Baby Size Url
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Created At
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Created By
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Updated At
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Updated By
                </th>

                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  is Active
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {loading && (
                <tr>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    <div className="flex items-center justify-center h-20">
                      <Loader />
                    </div>
                  </td>
                </tr>
              )}
              {babyHealthData?.map((item) => (
                <tr key={item?.id}>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item?.id}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item?.week}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item?.size}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item?.description}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item?.weight}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item?.height}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item?.imageURL}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item?.sizeImgURL}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {new Date(item?.createdAt).toLocaleString()}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item?.createdBy}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {new Date(item?.updatedAt).toLocaleString()}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item?.updatedBy}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item?.isActive ? "Active" : "Inactive"}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    <div className="flex justify-evenly">
                      <button
                        onClick={() => handleOpenEditModal(item)}
                        className="focus:outline-none	"
                        type="button"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5 mr-2 "
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                          />
                        </svg>
                      </button>
                      <DeleteButton
                        handleDeleteButton={() =>
                          handleDeleteBabyHealth(item?.id)
                        }
                      ></DeleteButton>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
