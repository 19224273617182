import { config } from './confg';
import { axiosCallAdvanced } from './main';

export const getUsers = async () => {
    return (
        (
            await axiosCallAdvanced({
                baseURL: config.stats.root,
                path: config.stats.allUsers,
            })
        )?.data || {}
    );
};

export const getSubscribers = async () => {
    return (
        (
            await axiosCallAdvanced({
                baseURL: config.stats.root,
                path: config.stats.allSubscribers,
            })
        )?.data || {}
    );
}

export const getNewOrders = async () => {
    return (
        (
            await axiosCallAdvanced({
                baseURL: config.stats.root,
                path: config.stats.newOrders,
            })
        )?.data || {}
    );
}

export const getShippedOrders = async () => {
    return (
        (
            await axiosCallAdvanced({
                baseURL: config.stats.root,
                path: config.stats.shippedOrders,
            })
        )?.data || {}
    );
}
