import { config } from "./confg";
import { axiosCallAdvanced } from "./main";

export const getShlokas = async () => {
  return (
    (
      await axiosCallAdvanced({
        baseURL: config.shlokas.root,
        path: config.shlokas.allShlokasData,
      })
    )?.data || {}
  );
};

export const createShlokas = async (data) => {
  return (
    (
      await axiosCallAdvanced({
        baseURL: config.shlokas.root,
        path: config.shlokas.createNew,
        body: { ...data },
        method: config.methodType.post,
      })
    )?.data || {}
  );
};


export const updateShlokas = async (parameters) => {
  return (
    (
      await axiosCallAdvanced({
        baseURL: config.shlokas.root,
        path: `${config.shlokas.updateOne}/${parameters.id}`,
        body: parameters.body,
        method: config.methodType.put,
      })
    )?.data || {}
  );
};


export const deleteShlokas = async (id) => {
  return (
      (
          await axiosCallAdvanced({
              baseURL: config.shlokas.root,
              path: `${config.shlokas.deleteOne}/${id}`,
              method: config.methodType.patch,
          })
      )?.data || {}
  );
};

