import React, { useState } from 'react';
import Modal from 'lib/Modal';
import Input from 'lib/Input';
import SaveButton from 'lib/SaveButton';
import { constants } from 'utils/constants';
import DeleteButton from 'lib/DeleteButton';
import Loader from 'components/Loader/Loader';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllFaqs, getAllFaqCategories, updateFaq, deleteFaq } from 'redux/faqSlice';
import { Toaster } from 'react-hot-toast';

const FaqTable = ({ handleOpenModal }) => {
    const [openEditModal, setOpenEditModal] = useState(false);
    const dispatch = useDispatch();
    const [modalData, setModalData] = useState({});

    const MAX_LENGTH = 100;
    const faqCategory = useSelector((state) => state.faq?.faqCategory);
    const Data = useSelector((state) => state.faq.data);
    const loading = useSelector((state) => state.faq.loading);

    const handleOpenEditModal = (item) => {
        setModalData(item);
        setOpenEditModal(true);
    };

    function handleEditButton() {
        handleUpdate();
    }
    function handleDeleteButton(id) {
        dispatch(deleteFaq(id));
    }

    console.log(modalData)

    const handleUpdate = () => {
        const updatedData = {
            categoryId: modalData?.FAQCategoryId,
            question: modalData?.question,
            answer: modalData?.answer,
            isActive: modalData?.isActive,
        };

        console.log(updatedData);
        dispatch(updateFaq({ id: modalData?.id, body: { ...updatedData } }));
        setOpenEditModal(false);
    };

    useEffect(() => {
        dispatch(getAllFaqCategories());
        dispatch(getAllFaqs());
    }, [dispatch]);

    return (
        <>
            {/* Edit modal */}
            <Toaster />
            <Modal
                isOpen={openEditModal}
                onClose={() => setOpenEditModal(false)}
            >
                <div>
                    <Input
                        onChange={(e) =>
                            setModalData({
                                ...modalData,
                                categoryId: e.target.value,
                            })
                        }
                        options={faqCategory.map((item) => {
                            return {
                                id: item?.id,
                                value: item?.faqCategory?.toUpperCase(),
                            };
                        })}
                        defaultValue={modalData?.FAQCategoryId}
                        label="Category"
                        select
                        isRequired
                    />

                    <Input
                        onChange={(e) =>
                            setModalData({
                                ...modalData,
                                question: e.target.value,
                            })
                        }
                        value={modalData?.question}
                        label="Question"
                        type="text"
                        isRequired
                    />
                    <Input
                        onChange={(e) =>
                            setModalData({
                                ...modalData,
                                answer: e.target.value,
                            })
                        }
                        value={modalData?.answer}
                        label="Answer"
                        isRequired
                        textarea
                    />
                    <Input
                        onChange={(e) =>
                            setModalData({
                                ...modalData,
                                isActive: e.target.value,
                            })
                        }
                        defaultValue={modalData?.isActive}
                        options={[
                            { id: true, value: 'Active' },
                            { id: false, value: 'Inactive' },
                        ]}
                        select
                        label="Visibility State"
                    />
                    <div className="flex gap-2">
                        <SaveButton
                            handleEditButton={handleEditButton}
                        ></SaveButton>
                    </div>
                </div>
            </Modal>
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
                <div className="rounded-t mb-0 px-4 py-4 border-0">
                    <div className="flex flex-wrap items-center">
                        <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                            <h3 className="font-semibold text-lg text-blueGray-700">
                                FAQ
                            </h3>
                        </div>
                        <button
                            onClick={handleOpenModal}
                            className="bg-pink text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                            type="button"
                        >
                            Add New
                        </button>
                    </div>
                </div>
                <div className="block w-full overflow-x-auto">
                    {/* Faq table */}
                    <table className="items-center w-full bg-transparent border-collapse">
                        <thead>
                            <tr>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    ID
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Question
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Answer
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Category
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Active
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        {loading ? (
                            <Loader />
                        ) : (
                            <tbody>
                                {Data?.map((item) => (
                                    <tr key={item.id}>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {item?.id}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {constants.trimText(
                                                item?.question,
                                                MAX_LENGTH
                                            )}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {constants.trimText(
                                                item?.answer,
                                                MAX_LENGTH
                                            )}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {item?.FAQCategory?.faqCategory}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {item?.isActive ? "Active" : "Inactive"}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            <div className="flex justify-evenly">
                                                <button
                                                    onClick={() =>
                                                        handleOpenEditModal(
                                                            item
                                                        )
                                                    }
                                                    className="focus:outline-none	"
                                                    type="button"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="h-5 w-5 mr-2 "
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                        strokeWidth="2"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                                        />
                                                    </svg>
                                                </button>
                                                <DeleteButton
                                                    handleDeleteButton={() => {
                                                        handleDeleteButton(
                                                            item?.id
                                                        );
                                                    }}
                                                ></DeleteButton>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        )}
                    </table>
                </div>
            </div>
        </>
    );
};

export default FaqTable;
