import { config } from './confg';
import { axiosCallAdvanced } from './main';

// const data = localStorage.getItem('userData');
// //! Add this token in authorization header afterwards
//
// export const getFaqCategories = async (data) => {
//   try {
//     const res = await axios.get(config.root + config.faqCategories.list);
//     return res.data;
//   } catch (error) {
//     return error;
//   }
// };

export const getFaqCategories = async () => {
    return (
        (
            await axiosCallAdvanced({
                baseURL: config.faqCategories.root,
                path: config.faqCategories.list,
            })
        )?.data || {}
    );
};

export const createOne = async (data) => {
    return (
        (
            await axiosCallAdvanced({
                baseURL: config.faqCategories.root,
                path: config.faqCategories.createOne,
                body: { ...data },
                method: config.methodType.post,
            })
        )?.data || {}
    );
};

export const updateOne = async (parameters) => {
    return (
        (
            await axiosCallAdvanced({
                baseURL: config.faqCategories.root,
                path: `${config.faqCategories.updateOne}/${parameters.id}`,
                body: parameters.body,
                method: config.methodType.put,
            })
        )?.data || {}
    );
};

export const deleteOne = async (id) => {
    return (
        (
            await axiosCallAdvanced({
                baseURL: config.faqCategories.root,
                path: `${config.faqCategories.deleteOne}/${id}`,
                method: config.methodType.patch,
            })
        )?.data || {}
    );
};
