import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
    getOrder,
} from 'api/subscriptionOrder'; //file name

export const getAllOrder = createAsyncThunk('order/list-subscriptions', async () => {
    console.log("inside api");
    try {
        const res = await getOrder();
        console.log("red", res);
        if (res.success) {
            return res.data;
        } else {
            throw new Error('Error fetching data');
        }
    } catch (error) {
        throw error;
    }
});

const subscriptionOrderSlice = createSlice({
    name: 'subscriptionOrder',
    initialState: {
        data: [],
        loading: false,
        error: null,
    },
    reducer: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllOrder.pending, (state) => {
                console.log("Hellos");
                state.loading = true;
            })
            .addCase(getAllOrder.fulfilled, (state, action) => {
                console.log("Hello");
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(getAllOrder.rejected, (state, action) => {
                console.log('Hellop');
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const subscriptionOrderAction = subscriptionOrderSlice.actions;
export default subscriptionOrderSlice;
