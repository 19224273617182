import { config } from "./confg";
import { axiosCallAdvanced } from "./main";

export const getYoga = async () => {
    return (
        (
            await axiosCallAdvanced({
                baseURL: config.yogaPractices.root,
                path: config.yogaPractices.list,
            })
        )?.data || {}
    );
};

export const createYoga = async (data) => {
    return (
        (
            await axiosCallAdvanced({
                baseURL: config.yogaPractices.root,
                path: config.yogaPractices.createNewYoga,
                body: { ...data },
                method: config.methodType.post,
            })
        )?.data || {}
    );
};

export const updateYoga = async (parameters) => {
    return (
        (
            await axiosCallAdvanced({
                baseURL: config.yogaPractices.root,
                path: `${config.yogaPractices.updateOne}/${parameters.id}`,
                body: parameters.body,
                method: config.methodType.put,
            })
        )?.data || {}
    );
};

export const deleteYoga = async (id) => {
    return (
        (
            await axiosCallAdvanced({
                baseURL: config.yogaPractices.root,
                path: `${config.yogaPractices.deleteOne}/${id}`,
                method: config.methodType.patch,
            })
        )?.data || {}
    );
};

