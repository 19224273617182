import { config } from "./confg";
import { axiosCallAdvanced } from "./main";

export const getGarbha = async () => {
  return (
    (
      await axiosCallAdvanced({
        baseURL: config.garbhaSamvad.root,
        path: config.garbhaSamvad.list,
      })
    )?.data || {}
  );
};

export const createGarbha = async (data) => {
  return (
    (
      await axiosCallAdvanced({
        baseURL: config.garbhaSamvad.root,
        path: config.garbhaSamvad.createNewGarbhaSamvad,
        body: { ...data },
        method: config.methodType.post,
      })
    )?.data || {}
  );
};

export const updateGarbha = async (data) => {
  return (
    (
      await axiosCallAdvanced({
        baseURL: config.garbhaSamvad.root,
        path: `${config.garbhaSamvad.updateOne}/${data.id}`,
        body: data.body,
        method: config.methodType.put,
      })
    )?.data || {}
  );
};

export const deleteGarbha = async (parameters) => {
  return (
    (
      await axiosCallAdvanced({
        baseURL: config.garbhaSamvad.root,
        path: `${config.garbhaSamvad.deleteOne}/${parameters}`,
        method: config.methodType.patch,
      })
    )?.data || {}
  );
};

