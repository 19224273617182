import axios from "axios";
import { config } from "./confg";
import { axiosCallAdvanced } from "./main";

export const getProductCategory = async () => {
  return (
    (
      await axiosCallAdvanced({
        baseURL: config.productCategory.root,
        path: config.productCategory.getProductCategory,
      })
    )?.data || {}
  );
};

export const createProductCategory = async (data) => {
  return (
    (
      await axiosCallAdvanced({
        baseURL: config.productCategory.root,
        path: config.productCategory.createProductCategory,
        body: { ...data },
        method: config.methodType.post,
      })
    )?.data || {}
  );
};

export const updateProductCategory = async (id, data) => {
  return (
    (
      await axiosCallAdvanced({
        baseURL: config.productCategory.root,
        path: `${config.productCategory.updateProductCategory}/${id}`,
        body: { ...data },
        method: config.methodType.put,
      })
    )?.data || {}
  );
};

export const deleteProductCategory = async (parameters) => {
  return (
    (
      await axiosCallAdvanced({
        baseURL: config.productCategory.root,
        path: `${config.productCategory.deleteProductCategory}/${parameters}`,
        method: config.methodType.patch,
      })
    )?.data || {}
  );
};

export const handleMediaFile = async (file) => {
  console.log(file);
  const formData = new FormData();
  formData.append("file", file);
  const res = await axios.post(config.root + "/api/image-upload", formData, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
    },
    withCredentials: true,
  });
  return res.data;
};
