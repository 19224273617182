import { config } from "./confg";
import { axiosCallAdvanced } from "./main";


export const getGreetings = async () => {
  console.log("inside api file");
  return (
    (
      await axiosCallAdvanced({
        baseURL: config.dailyGreetings.root,
        path: config.dailyGreetings.list,
      })
    )?.data || {}
  );
};

export const createGreetings = async (data) => {
  return (
    (
      await axiosCallAdvanced({
        baseURL: config.dailyGreetings.root,
        path: config.dailyGreetings.createOne,
        body: { ...data },
        method: config.methodType.post,
      })
    )?.data || {}
  );
};

export const updateGreetings = async (parameters) => {
  return (
    (
      await axiosCallAdvanced({
        baseURL: config.dailyGreetings.root,
        path: `${config.dailyGreetings.updateOne}/${parameters.id}`,
        body: parameters.body,
        method: config.methodType.put,
      })
    )?.data || {}
  );
};

export const deleteGreetings = async (id) => {
  return (
    (
      await axiosCallAdvanced({
        baseURL: config.dailyGreetings.root,
        path: `${config.dailyGreetings.deleteOne}/${id}`,
        method: config.methodType.patch,
      })
    )?.data || {}
  );
};