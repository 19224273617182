import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getConsultation, updateConsultation } from 'api/consultationAPI';

// TODO change the join-us path

export const getConsultationList = createAsyncThunk(
    'consultation/list',
    async () => {
        try {
            const res = await getConsultation();
            if (res.success) {
                return res.data;
            } else {
                throw new Error('Error fetching the consultation list.');
            }
        } catch (error) {
            throw error;
        }
    }
);

export const updateOne = createAsyncThunk(
    'consultation/update-one',
    async (data) => {
        try {
            const res = await updateConsultation(data);

            if (res.success) {
                const item = (await getConsultation())?.data?.findIndex(
                    (item) => item.id === data.id
                );
                return item;
            } else {
                throw new Error('Error deleting the Consultation.');
            }
        } catch (error) {
            throw error;
        }
    }
);

const consultationSlice = createSlice({
    name: 'consultation',
    initialState: {
        data: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getConsultationList.pending, (state) => {
                state.loading = true;
            })
            .addCase(getConsultationList.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(getConsultationList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(updateOne.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateOne.fulfilled, (state, action) => {
                state.loading = false;
                const consultationIndex = state?.data?.findIndex(
                    (consult) => consult?.id === action?.payload?.id
                );
                if (consultationIndex !== -1) {
                    state.data[consultationIndex] = action.payload;
                }
            })
            .addCase(updateOne.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const consultationAction = consultationSlice.actions;
export default consultationSlice;
