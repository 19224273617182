import React, { useState } from "react";

const PictureUpload = ({ onChange, label, isRequired = false }) => {
  const [file, setFile] = useState(null);
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    onChange(selectedFile); // Call the onChange prop with the selected file
  };

  return (
    <div className="flex flex-wrap">
      <div className="w-full lg:w-12/12 px-4">
        <div className="relative w-full mb-3">
          <label
            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            htmlFor="file-upload"
          >
            {label} {isRequired && <span className="red-asterisk"></span>}
          </label>
          <input
            type="file"
            accept="image/jpg, jpeg, GIF"
            onChange={handleFileChange}
            id="file-upload"
            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          />
          {file && (
            <div
              style={{
                marginTop: "10px",
                width: "100px",
                height: "inherit",
                marginBottom: "20px",
              }}
            >
              {file.type.startsWith("image/") ? (
                <img
                  src={URL.createObjectURL(file)}
                  alt="Preview"
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "cover",
                  }}
                />
              ) : (
                <video
                  controls
                  src={URL.createObjectURL(file)}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "cover",
                  }}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PictureUpload;
