import React, { useEffect } from "react";
import Loader from "components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import {
  getCustomerList,
  toggleCustomerSelection,
  toggleSelectAll,
} from "redux/customerSlice";
import * as XLSX from "xlsx";

const CustomersTable = () => {
  const customerData = useSelector((state) => state.customers.data);
  const selectedCustomerIds = useSelector(
    (state) => state.customers.selectedCustomerIds
  );
  const selectAll = useSelector((state) => state.customers.selectAll);
  const loading = useSelector((state) => state.customers.loading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCustomerList());
  }, [dispatch]);

  const handleSelectAllChange = () => {
    dispatch(toggleSelectAll());
  };

  const handleCheckboxChange = (customerId) => {
    dispatch(toggleCustomerSelection(customerId));
  };

  const handleExport = () => {
    const selectedUsers = customerData.filter((user) =>
      selectedCustomerIds.includes(user?.id)
    );

    if (selectedUsers.length > 0) {
      const selectedUsersSheet = XLSX.utils.json_to_sheet(selectedUsers);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, selectedUsersSheet, "Selected Users");
      XLSX.writeFile(wb, "CustomerData.xlsx");
    } else {
      // Handle the case where no users are selected for export
      console.log("No users selected for export.");
      // You can show an alert or perform other actions as needed
    }
  };

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
        <div className="rounded-t mb-0 px-4 py-4 border-0">
          <div className="flex flex-wrap items-center justify-between">
            <div className="flex ">
              <div className="relative w-full px-4 max-w-full flex-grow flex-1 mt-3">
                <h3 className="font-semibold text-lg text-blueGray-700">
                  All Customers
                </h3>
              </div>
              {/* <div>
                <form className="md:flex hidden flex-row flex-wrap items-center lg:ml-auto mr-3">
                  <div className="relative flex w-full flex-wrap items-stretch">
                    <input
                      type="text"
                      name="search"
                      placeholder="Quick Search..."
                      value={searchText}
                      onInput={(e) => setSearchText(e.target.value)}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-grey relative  bg-white rounded text-sm shadow outline-none focus:outline-none focus:ring w-full pl-10"
                    />
                    <button className="z-10 h-full leading-snug font-normal  text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
                      <i className="fas fa-search"></i>
                    </button>
                  </div>
                </form>
              </div> */}
            </div>
            <div className="flex gap-4">
              <div>
                <div>
                  <button
                    className="bg-pink text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={handleExport}
                    disabled={selectedCustomerIds.length === 0} // Disable button when no users are selected
                  >
                    Export
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th
                  className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                >
                  <div className="flex item-center">
                    <input
                      type="checkbox"
                      checked={selectAll}
                      onChange={handleSelectAllChange}
                      className="mb-1 mx-4"
                    />
                    Name
                  </div>
                </th>
                <th
                  className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                >
                  <div className="flex item-center">
                    Phone
                  </div>
                </th>
                <th
                  className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                >
                  <div className="flex item-center">
                    Email
                  </div>
                </th>
                <th
                  className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                >
                  <div className="flex item-center">
                    Subscription Type

                  </div>
                </th>
                <th
                  className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                >
                  <div className="flex item-center">
                    Subscription Name
                  </div>
                </th>

                <th
                  className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                >
                  <div className="flex item-center">
                    Premium Start Date (YYYY-MM-DD)

                  </div>
                </th>
                <th
                  className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                >
                  <div className="flex item-center">
                    Premium End Date (YYYY-MM-DD)

                  </div>
                </th>
              </tr>
            </thead>
            {loading && <Loader />}
            <tbody>
              {customerData?.map((item) => (
                <tr key={item?.id}>
                  <td className="border-t-0 px-6 flex  align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    <input
                      type="checkbox"
                      checked={selectedCustomerIds.includes(item?.id)}
                      onChange={() => handleCheckboxChange(item?.id)}
                      className="mt-4 mx-4"
                    />
                    <p className="inline-flex mt-4 mx-4">
                      {item?.firstName + " " + item?.lastName}
                    </p>
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item?.phone}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item?.email}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item?.subscriptionType}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item?.subscriptionName}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item?.premiumStartDate?.split('T')[0]} UTC
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item?.premiumEndDate?.split('T')[0]} UTC
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
export default CustomersTable;
