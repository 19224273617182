import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getProductCategory,
  createProductCategory,
  updateProductCategory,
  deleteProductCategory,
} from "api/productCategoryAPI";

export const getNewProductCategory = createAsyncThunk(
  "productCategory/list",
  async () => {
    try {
      const res = await getProductCategory();
      if (res.success) {
        return res.data;
      } else {
        throw new Error("Error fetching product category.");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const createNewProductCategory = createAsyncThunk(
  "productCategory/create-one",
  async (data) => {
    try {
      const res = await createProductCategory(data);
      if (res.success) {
        return res.data;
      } else {
        throw new Error("Error creating seminar.");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const updateNewProductCategory = createAsyncThunk(
  "productCategory/update-one",
  async ({ id, data }) => {
    try {
      const res = await updateProductCategory(id, data);
      if (res.success) {
        return res.data;
      } else {
        throw new Error("Error updating seminar.");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const deleteNewProductCategory = createAsyncThunk(
  "productCategory/delete-one",
  async (id) => {
    try {
      const res = await deleteProductCategory(id);
      if (res.success) {
        return id;
      } else {
        throw new Error("Error deleting seminar.");
      }
    } catch (error) {
      throw error;
    }
  }
);

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const productCategorySlice = createSlice({
  name: "productCategory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNewProductCategory.pending, (state) => {
        state.loading = true;
      })
      .addCase(getNewProductCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getNewProductCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(createNewProductCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createNewProductCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.data.push(action.payload);
      })
      .addCase(createNewProductCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(updateNewProductCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateNewProductCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        const updatedProductIndex = state.data.findIndex(
          (category) => category.id === action.payload.id
        );
        if (updatedProductIndex !== -1) {
          state.data[updatedProductIndex] = action.payload;
        }
      })
      .addCase(updateNewProductCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteNewProductCategory.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteNewProductCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.data = state.data.filter(
          (category) => category.id !== action.payload
        );
      })
      .addCase(deleteNewProductCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const productCategoryAction = productCategorySlice.actions;

export default productCategorySlice;
