import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import Modal from "lib/Modal";
import Input from "lib/Input";
import PictureUpload from "lib/PictureUpload";
import DeleteButton from "lib/DeleteButton";
import { useDispatch, useSelector } from "react-redux";
import { constants } from "utils/constants";
import { handleImageFile, handleMediaFile } from "api/fileUploadAPI";
import { Toaster, toast } from "react-hot-toast";
import Loader from "components/Loader/Loader";
import {
  deleteOneBrainActivity,
  updateOneBrainActivity,
  getBrainActivityList,
} from "redux/brainActivitySlice";
import SaveButton from "lib/SaveButton";
import ReactQuill from "react-quill";

const BrainActivityTable = ({ color, handleOpenModal }) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [isIntroLoader, setIsIntroLoader] = useState(false);
  const [isMediaLoader, setIsMediaLoader] = useState(false);
  const dispatch = useDispatch();
  const MAX_LENGTH = 50;
  const editor = useRef(null);
  const brainActivityData = useSelector((state) => state.brainActivity.data);
  const loading = useSelector((state) => state.brainActivity.loading);

  const handleOpenEditModal = (item) => {
    setModalData(item);
    setOpenEditModal(true);
  };

  function handleEditButton() {
    handleUpdate();
  }

  const handleUpdate = () => {
    const updatedData = {
      userCategory: modalData?.userCategory,
      day: parseInt(modalData?.day),
      thumbnailURL: modalData?.thumbnailURL,
      title: modalData?.title,
      content: modalData?.content,
      answer: modalData?.answer,
      mediaType: modalData?.mediaType,
      mediaURl: modalData?.mediaURL,
      mood: modalData?.mood,
      isActive: modalData?.isActive,
    };
    dispatch(updateOneBrainActivity({ id: modalData?.id, data: updatedData }));
    setOpenEditModal(false);
  };

  const handleDeleteStory = async (id) => {
    dispatch(await deleteOneBrainActivity(id));
  };

  const handleIntroMedia = async (file) => {
    if (file === undefined) {
        setModalData({ ...modalData, mediaURL: "" });
        return;
    }

    if (modalData?.mediaType === "1") {
      try {
        setIsIntroLoader(true);
        const res = await handleImageFile(file);
        if (res.success) {
          setModalData({ ...modalData, mediaURL: res.file_url });

          toast.success("Successfully Uploaded the Image!");
        } else {
          throw new Error("Picture Not Successfully Uploaded");
        }
        setIsIntroLoader(false);
      } catch (error) {
        toast.error(error);
      }
    } else {
      try {
        setIsMediaLoader(true);
        const res = await handleMediaFile(file);
        if (res.success) {
          setModalData({ ...modalData, mediaURL: res.file_url });
          modalData?.mediaType === "2"
            ? toast.success("Successfully Uploaded the Audio!")
            : toast.success("Successfully Uploaded the video!");
        } else {
          throw new Error("Picture Not Successfully Uploaded");
        }
        setIsMediaLoader(false);
      } catch (error) {
        toast.error(error);
      }
    }
  };

  const handleThumbnailUrl = async (file) => {
    if (file === undefined) {
      setModalData({ ...modalData, thumbnailURL: "" });
      return;
    }

    try {
      setIsMediaLoader(true);
      const res = await handleImageFile(file);
      if (res.success) {
        setModalData({ ...modalData, thumbnailURL: res.file_url });

        toast.success("Successfully Uploaded the Image!");
      } else {
        throw new Error("Picture Not Successfully Uploaded");
      }
      setIsMediaLoader(false);
    } catch (error) {
      toast.error(error);
    }
  };

  const handleSelectChange = (e) => {
    console.log(e.target.selectedOptions);
    const selectedValues = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    setModalData({ ...modalData, mood: selectedValues.toString() });
  };

  const handleQuillChange = (value) => {
    setModalData({ ...modalData, content: value });
  };

  useEffect(() => {
    dispatch(getBrainActivityList());
  }, [dispatch]);

  return (
    <>
      {/* Edit modal */}
      <Toaster />
      <Modal isOpen={openEditModal} onClose={() => setOpenEditModal(false)}>
        <div>
          <Input
            onChange={(e) =>
              setModalData({
                ...modalData,
                userCategory: e.target.value,
              })
            }
            label="UserCategory"
            isRequired
            defaultValue={modalData?.userCategory}
            select
            options={constants.dailyActivityCategory}
          />
          <Input
            onChange={(e) =>
              setModalData({ ...modalData, day: e.target.value })
            }
            value={modalData?.day}
            type="text"
            label="Day"
            isRequired
          />
          {isIntroLoader && <Loader />}
          <PictureUpload
            label="Thumbnail  URL"
            isRequired
            onChange={handleThumbnailUrl}
          />
          <Input
            defaultValue={modalData?.title}
            label="Story Title"
            isRequired
            onChange={(e) =>
              setModalData({ ...modalData, title: e.target.value })
            }
          />
          <div className="flex flex-wrap">
            <div className="w-full lg:w-12/12 px-4">
              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Content <span className="red-asterisk"></span>
                </label>
                <ReactQuill
                  style={{
                    background: "#fff",
                  }}
                  className="ql-container ql-snow"
                  ref={editor}
                  modules={constants.modules}
                  formats={constants.formats}
                  defaultValue={modalData?.content}
                  onChange={handleQuillChange}
                />
              </div>
            </div>
          </div>
          <Input
            defaultValue={modalData?.answer}
            label="Add Answer"
            isRequired
            onChange={(e) =>
              setModalData({ ...modalData, answer: e.target.value })
            }
          />
          {isMediaLoader && <Loader />}
          <Input
            onChange={(e) =>
              setModalData({ ...modalData, mediaType: e.target.value })
            }
            label="Media Type"
            isRequired
            defaultValue={modalData?.mediaType}
            select
            options={constants.mediaOption}
          />
          {modalData?.mediaType === "0" ? null : (
            <PictureUpload
              onChange={handleIntroMedia}
              defaultValue={modalData?.mediaURL}
              label={
                modalData?.mediaType === "3"
                  ? "Intro Video"
                  : modalData?.mediaType === "2"
                  ? "Intro Audio"
                  : "Intro Image"
              }
              isRequired
            />
          )}
          <div className="flex flex-wrap">
            <div className="w-full lg:w-12/12 px-4">
              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Mood <span className="red-asterisk"></span>
                </label>
                <select
                  multiple
                  onChange={handleSelectChange}
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                >
                  {constants.garbhaMood?.map((category) => (
                    <option key={category.id}>{category.value}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <Input
            onChange={(e) =>
              setModalData({ ...modalData, isActive: e.target.value })
            }
            defaultValue={modalData?.isActive}
            options={[
              { id: true, value: "Active" },
              { id: false, value: "Inactive" },
            ]}
            select
            label="Visibility State"
            isRequired
          />
          <div className="flex gap-2">
            <SaveButton handleEditButton={handleEditButton}></SaveButton>
          </div>
        </div>
      </Modal>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
        <div className="rounded-t mb-0 px-4 py-4 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3 className="font-semibold text-lg text-blueGray-700">
                Brain Activity
              </h3>
            </div>
            <button
              onClick={handleOpenModal}
              className="bg-pink text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
            >
              Add New
            </button>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Activity table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  ID
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  User category
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Day
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Story Title
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Content
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Answer
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Story Thumbnail URL
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Media Type
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Media URL
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Mood
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Created At
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Created By
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Updated At
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Updated By
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Is Active
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {loading && (
                <tr>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    <div className="flex items-center justify-center h-20">
                      <Loader />
                    </div>
                  </td>
                </tr>
              )}
              {brainActivityData?.map((item) => (
                <tr key={item.id}>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item?.id}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item?.userCategory === "1"
                      ? "Pre Pregnancy"
                      : item?.userCategory === "2"
                      ? "Pregnant"
                      : item?.userCategory === "3"
                      ? "Post Pregnancy"
                      : "N/A"}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item?.day}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item?.title}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {constants.trimText(item?.content, MAX_LENGTH)}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item?.answer}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item?.thumbnailURL}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item?.mediaType === "1"
                      ? "Image"
                      : item?.mediaType === "2"
                      ? "Audio"
                      : item?.mediaType === "3"
                      ? "Video"
                      : item?.mediaType === "0"
                      ? "No Media"
                      : "N/A"}
                  </td>
                  <td className="border-t-0{ite px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item?.mediaURL || "N/A"}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item?.mood}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {new Date(item?.createdAt).toLocaleString()}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item?.createdBy}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {new Date(item?.updatedAt).toLocaleString()}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item?.updatedBy}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item.isActive ? "Active" : "Inactive"}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    <div className="flex justify-evenly">
                      <button
                        onClick={() => handleOpenEditModal(item)}
                        className="focus:outline-none	"
                        type="button"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5 mr-2 "
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                          />
                        </svg>
                      </button>
                      <DeleteButton
                        handleDeleteButton={() => handleDeleteStory(item?.id)}
                      ></DeleteButton>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

BrainActivityTable.defaultProps = {
  color: "light",
};

BrainActivityTable.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
  data: PropTypes.array.isRequired,
  handleOpenModal: PropTypes.func.isRequired,
};

export default BrainActivityTable;
