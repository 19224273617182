import React, { useState, useRef } from "react";
import ReactQuill from "react-quill";
import { useDispatch } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import Modal from "lib/Modal";
import Input from "lib/Input";
import PictureUpload from "lib/PictureUpload";
import Loader from "components/Loader/Loader";
import DailyActivitiesTable from "components/Cards/DailyActivitiesTable";
import { constants } from "utils/constants";
import { handleImageFile } from "api/fileUploadAPI";
import { handleMediaFile } from "api/fileUploadAPI";
import { createNewDailyActivity } from "redux/dailyActivitiesslice";

const MindActivities = () => {
    const dispatch = useDispatch();
    const editor = useRef(null);

    const [modalOpen, setModalOpen] = useState(false);
    const [userCategory, setUserCategory] = useState('');
    const [title, setTitle] = useState('');
    const [day, setDay] = useState('');
    const [content, setContent] = useState('');
    const [mediaType, setMediaType] = useState('');
    const [mediaURL, setMediaURL] = useState('');
    const [thumbnailURL, setThumbnailURL] = useState('');
    const [isIntroLoader, setIsIntroLoader] = useState(false);
    const [isMediaLoader, setIsMediaLoader] = useState(false);

    const [isActive, setIsActive] = useState('true');
    const [formError, setFormError] = useState(false);

    const [thumbnailOption, setThumbnailOption] = useState(1);
    const [mediaURLOption, setMediaURLOption] = useState(1);

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setDay('');
        setUserCategory('');
        setTitle('');
        setContent('');
        setThumbnailURL('');
        setMediaURL('');
        setMediaType('1');
        setFormError(false);
    };

    const handleCreateNew = () => {
        if (
            userCategory !== '' &&
            day !== '' &&
            mediaType !== '' &&
            title.trim() !== '' &&
            content.trim() !== '' &&
            thumbnailURL !== ''
        ) {
            const newActivity = {
                userCategory: userCategory,
                day: parseInt(day),
                title: title.trim(),
                content: content.trim(),
                thumbnailURL: thumbnailURL,
                mediaType: mediaType,
                mediaURL: mediaURL,
                isActive: isActive,
            };
            dispatch(createNewDailyActivity(newActivity));
            setModalOpen(false);
            setDay('');
            setUserCategory('');
            setTitle('');
            setContent('');
            setThumbnailURL('');
            setMediaURL('');
            setMediaType('1');
            setFormError(false);

            setThumbnailOption(1);
            setMediaURLOption(1);
        } else {
            setFormError(true);
        }
    };

    const handleIntroMedia = async (file) => {
        if (file === undefined) {
            setMediaURL('');
            return;
        }

        if (mediaType === '1') {
            try {
                setIsMediaLoader(true);
                const res = await handleImageFile(file);
                if (res.success) {
                    setMediaURL(res.file_url);

                    toast.success('Successfully Uploaded the Image!');
                } else {
                    throw new Error('Picture Not Successfully Uploaded');
                }
                setIsMediaLoader(false);
            } catch (error) {
                toast.error(error);
            }
        } else {
            try {
                setIsMediaLoader(true);
                const res = await handleMediaFile(file);
                if (res.success) {
                    setMediaURL(res.file_url);
                    mediaType === '2'
                        ? toast.success('Successfully Uploaded the Audio!')
                        : toast.success('Successfully Uploaded the video!');
                } else {
                    throw new Error('Picture Not Successfully Uploaded');
                }
                setIsMediaLoader(false);
            } catch (error) {
                toast.error(error);
            }
        }
    };

    const handleThumbnail = async (file) => {
        if (file === undefined) {
            setThumbnailURL('');
            return;
        }

        try {
            setIsIntroLoader(true);
            const res = await handleImageFile(file);
            if (res.success) {
                setThumbnailURL(res.file_url);

                toast.success('Successfully Uploaded the Image!');
            } else {
                throw new Error('Picture Not Successfully Uploaded');
            }
            setIsIntroLoader(false);
        } catch (error) {
            toast.error(error);
        }
    };

    const handleQuillChange = (value) => {
        setContent(value);
    };

    return (
        <>
            <Toaster />
            <Modal isOpen={modalOpen} onClose={handleCloseModal}>
                <div>
                    <Input
                        label="User Category"
                        onChange={(e) => setUserCategory(e.target.value)}
                        defaultValue={userCategory}
                        select
                        options={constants.dailyActivityCategory}
                        isRequired
                    />
                    <Input
                        label="Day"
                        onChange={(e) => setDay(e.target.value)}
                        value={day}
                        type="text"
                        isRequired
                    />
                    <Input
                        label="Thumbnail Option"
                        onChange={(e) =>
                            setThumbnailOption(parseInt(e.target.value))
                        }
                        value={thumbnailOption}
                        select
                        options={[
                            { id: 1, value: 'Upload Thumbnail' },
                            { id: 2, value: 'Thumbnail URL' },
                        ]}
                        isRequired
                    />
                    {isIntroLoader && <Loader />}
                    {thumbnailOption === 1 ? (
                        <PictureUpload
                            label="thumbnailURL"
                            onChange={handleThumbnail}
                            defaultValue={thumbnailURL}
                            isRequired
                        />
                    ) : (
                        <Input
                            label="Thumbnail URL"
                            onChange={(e) => setThumbnailURL(e.target.value)}
                            value={thumbnailURL}
                            type="text"
                            isRequired
                        />
                    )}
                    <Input
                        label=" Title"
                        onChange={(e) => setTitle(e.target.value)}
                        value={title}
                        type="text"
                        isRequired
                    />
                    <ReactQuill
                        style={{
                            marginLeft: 15,
                            marginRight: 15,
                            background: '#fff',
                        }}
                        className="ql-container ql-snow"
                        ref={editor}
                        modules={constants.modules}
                        formats={constants.formats}
                        defaultValue={content}
                        onChange={handleQuillChange}
                    />
                    <Input
                        label="Media Type"
                        onChange={(e) => setMediaType(e.target.value)}
                        isRequired
                        defaultValue={mediaType}
                        select
                        options={constants.mediaOption}
                    />
                    {mediaType === '0' ? null : (
                        <Input
                            label="Media Option"
                            onChange={(e) =>
                                setMediaURLOption(parseInt(e.target.value))
                            }
                            value={mediaURLOption}
                            select
                            options={[
                                { id: 1, value: 'Upload Media' },
                                { id: 2, value: 'Media URL' },
                            ]}
                            isRequired
                        />
                    )}
                    {isMediaLoader && <Loader />}
                    {mediaType === '0' ? null : mediaURLOption === 1 ? ( // null for no media
                        <PictureUpload
                            onChange={handleIntroMedia}
                            defaultValue={mediaURL}
                            label={
                                mediaType === '3'
                                    ? 'Intro Video'
                                    : mediaType === '2'
                                    ? 'Intro Audio'
                                    : 'Intro Image'
                            }
                            isRequired
                        />
                    ) : (
                        <Input
                            label="Media URL"
                            onChange={(e) => setMediaURL(e.target.value)}
                            value={mediaURL}
                            type="text"
                            isRequired
                        />
                    )}
                    <Input
                        label="Visibility State"
                        onChange={(e) => setIsActive(e.target.value)}
                        defaultValue={isActive}
                        options={[
                            { id: true, value: 'Active' },
                            { id: false, value: 'Inactive' },
                        ]}
                        select
                        isRequired
                    />

                    {formError && (
                        <p className="text-red-500 mt-2">
                            Please fill all the details!
                        </p>
                    )}
                    <div className="flex gap-2">
                        <button
                            onClick={handleCreateNew}
                            className="bg-pink mx-4 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                            type="button"
                        >
                            Save
                        </button>
                    </div>
                </div>
            </Modal>

            <div className="flex flex-wrap mt-4">
                <div className="w-full mb-12 px-4">
                    <DailyActivitiesTable handleOpenModal={handleOpenModal} />
                </div>
            </div>
        </>
    );
};

export default MindActivities;
