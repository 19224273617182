import { config } from './confg';
import { axiosCallAdvanced } from './main';

export const getNotification = async () => {
    console.log('inside api file');
    return (
        (
            await axiosCallAdvanced({
                baseURL: config.notification.root,
                path: config.notification.list,
            })
        )?.data || {}
    );
};

export const createNotification = async (data) => {
    return (
        (
            await axiosCallAdvanced({
                baseURL: config.notification.root,
                path: config.notification.createOne,
                body: { ...data },
                method: config.methodType.post,
            })
        )?.data || {}
    );
};

export const deleteNotification = async (id) => {
    return (
        (
            await axiosCallAdvanced({
                baseURL: config.notification.root,
                path: `${config.notification.deleteOne}/${id}`,
                method: config.methodType.delete,
            })
        )?.data || {}
    )
}
