import React, { useState } from 'react';
import Modal from 'lib/Modal';
import Input from 'lib/Input';
import PictureUpload from 'lib/PictureUpload';
import toast from 'react-hot-toast';
import Loader from 'components/Loader/Loader';
import StrotraTable from 'components/Cards/StrotraTable';
import { handleImageFile, handleMediaFile } from 'api/fileUploadAPI';
import { useDispatch } from 'react-redux';
import { createNewStrotra } from 'redux/strotraSlice';
import { constants } from 'utils/constants';

const Strotra = () => {
    const dispatch = useDispatch();
    const [modalOpen, setModalOpen] = useState(false);

    const [month, setMonth] = useState('');
    const [userCategory, setUserCategory] = useState('');
    const [strotraText, setStrotraText] = useState('');
    const [mediaURL, setMediaURL] = useState('');
    const [strotraMeaningText, setStrotraMeaningText] = useState('');
    const [mediaType, setMediaType] = useState('');
    const [isIntroLoader, setIsIntroLoader] = useState(false);
    const [isActive, setIsActive] = useState(true);
    const [formError, setFormError] = useState(false);

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleCreateNew = async () => {
        if (
            month.trim() !== '' &&
            userCategory !== '' &&
            strotraText.trim() !== '' &&
            strotraMeaningText.trim() !== ''
        ) {
            const newTimelineItem = {
                month: parseInt(month),
                userCategory: userCategory,
                strotraText: strotraText.trim(),
                strotraMeaningText: strotraMeaningText.trim(),
                mediaURL: mediaURL.trim(),
                mediaType: mediaType,
                isActive: isActive,
            };
            dispatch(createNewStrotra({ ...newTimelineItem }));
            setModalOpen(false);
            setMonth('');
            setStrotraText('');
            setStrotraMeaningText('');
            setMediaURL(['']);
            setFormError(false);
        } else {
            setFormError(true);
        }
    };

    function handleMediaType(value) {
        setMediaType(value);
        setMediaURL('');
    }

    const handleIntroMedia = async (file) => {
        if (file === undefined) {
            setMediaURL('');
            return;
        }

        if (mediaType === '1') {
            //media url accepts file as string
            try {
                setIsIntroLoader(true);
                const res = await handleImageFile(file);
                if (res.success) {
                    setMediaURL(res.file_url);

                    toast.success('Successfully Uploaded the Image!');
                } else {
                    throw new Error('Picture Not Successfully Uploaded');
                }
                setIsIntroLoader(false);
            } catch (error) {
                toast.error(error);
            }
        } else {
            try {
                setIsIntroLoader(true);
                const res = await handleMediaFile(file);
                if (res.success) {
                    setMediaURL(res.file_url);
                    mediaType === '2'
                        ? toast.success('Successfully Uploaded the Audio!')
                        : toast.success('Successfully Uploaded the video!');
                } else {
                    throw new Error('Picture Not Successfully Uploaded');
                }
                setIsIntroLoader(false);
            } catch (error) {
                toast.error(error);
            }
        }
    };

    return (
        <>
            <Modal isOpen={modalOpen} onClose={handleCloseModal}>
                <div>
                    <Input
                        onChange={(e) => setMonth(e.target.value)}
                        value={month}
                        label="Month"
                        isRequired
                        options={constants.monthsOptions}
                        select
                    />
                    <Input
                        onChange={(e) => setUserCategory(e.target.value)}
                        value={userCategory}
                        label="User Category"
                        isRequired
                        options={constants.category}
                        select
                    />
                    <Input
                        onChange={(e) => setStrotraText(e.target.value)}
                        value={strotraText}
                        label="Strotra"
                        isRequired
                    />
                    <Input
                        onChange={(e) => setStrotraMeaningText(e.target.value)}
                        value={strotraMeaningText}
                        label="Strotra Meaning"
                        isRequired
                    />

                    <Input
                        onChange={(e) => handleMediaType(e.target.value)}
                        value={mediaType}
                        label="Media Type"
                        isRequired
                        select
                        options={constants.mediaOption}
                    />
                    {isIntroLoader && <Loader />}
                    {mediaType === '0' ? null : ( // null for no media
                        <PictureUpload
                            onChange={handleIntroMedia}
                            value={mediaURL}
                            label={
                                mediaType === '3'
                                    ? 'Intro Video'
                                    : mediaType === '2'
                                    ? 'Intro Audio'
                                    : 'Intro Image'
                            }
                            accept="image/*, video/*"
                        />
                    )}
                    <Input
                        onChange={(e) => setIsActive(e.target.value)}
                        defaultValue={isActive}
                        options={[
                            { id: true, value: 'Active' },
                            { id: false, value: 'Inactive' },
                        ]}
                        select
                        label="Status"
                        isRequired
                    />
                    {formError && (
                        <p className="mx-4 text-red-500 mb-2">
                            Please fill all the details!
                        </p>
                    )}
                    <div className="flex gap-2">
                        <button
                            onClick={handleCreateNew}
                            className="bg-pink mx-4 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                            type="button"
                        >
                            Save
                        </button>
                    </div>
                </div>
            </Modal>

            <div className="flex flex-wrap mt-4">
                <div className="w-full mb-12 px-4">
                    <StrotraTable
                        handleOpenModal={handleOpenModal}
                        color="dark"
                    />
                </div>
            </div>
        </>
    );
};

export default Strotra;
