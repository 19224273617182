export default function Error() {
    return (
        <>
            <div className="flex flex-wrap mt-4">
                <div className="w-full mb-12 px-4">
                    <div className="absolute z-10 left-0 h-full w-full flex flex-col justify-center items-center">

                    <h3 className="font-semibold text-2xl text-blueGray-700">
                        Error 404: Not Found
                    </h3>
                    <p className="text-lg">
                        Page not found.
                    </p>
                    </div>
                </div>
            </div>
        </>
    );
}