import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import Modal from 'lib/Modal';
import Input from 'lib/Input';
import Loader from 'components/Loader/Loader';
import LiveSeminarTable from 'components/Cards/LiveSeminarTable';
import { constants } from 'utils/constants';
import PictureUpload from 'lib/PictureUpload';
import { handleImageFile } from 'api/fileUploadAPI';
import { createOne } from 'redux/liveSeminarSlice';

const Seminars = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [userCategory, setUserCategory] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [videoURL, setVideoURL] = useState('');
    const [imageURL, setImageURL] = useState('');
    const [seminarsDate, setSeminarsDate] = useState('');
    const [startsTime, setStartsTime] = useState('');
    const [endsTime, setEndsTime] = useState('');
    const [isActive, setIsActive] = useState(true);
    const [formError, setFormError] = useState(false);
    const dispatch = useDispatch();
    const [isIntroLoader, setIsIntroLoader] = useState(false);

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setUserCategory('');
        setTitle('');
        setVideoURL('');
        setStartsTime('');
        setEndsTime('');
        setFormError(false);
    };

    const handleAddSeminar = () => {
        if (
            userCategory.trim() !== '' &&
            title.trim() !== '' &&
            description.trim() !== '' &&
            imageURL.trim() !== '' &&
            videoURL.trim() !== '' &&
            seminarsDate.trim() !== '' &&
            startsTime.trim() !== '' &&
            endsTime.trim() !== ''
        ) {
            const newSeminar = {
                userCategory: userCategory,
                title: title,
                description: description,
                imageURL: imageURL,
                videoURL: videoURL,
                seminarDate: seminarsDate,
                startTime: startsTime,
                endTime: endsTime,
                isActive: isActive,
            };
            dispatch(createOne(newSeminar));
            setModalOpen(false);
            setUserCategory('');
            setTitle('');
            setVideoURL('');
            setSeminarsDate('');
            setStartsTime('');
            setEndsTime('');
            setFormError(false);
        } else {
            setFormError(true);
        }
    };

    const handleImageUrl = async (file) => {
        if (file === undefined) {
            setImageURL('');
            return;
        }

        try {
            setIsIntroLoader(true);
            const res = await handleImageFile(file);
            if (res.success) {
                setImageURL(res.file_url);

                toast.success('Successfully Uploaded the Image!');
            } else {
                throw new Error('Picture Not Successfully Uploaded');
            }
            setIsIntroLoader(false);
        } catch (error) {
            toast.error(error);
        }
    };

    return (
        <>
            <Modal isOpen={modalOpen} onClose={handleCloseModal}>
                <div>
                    <Input
                        label="Seminar Category"
                        onChange={(e) => setUserCategory(e.target.value)}
                        defaultValue={userCategory}
                        select
                        options={constants.LiveSeminarCategory}
                        isRequired
                    />
                    <Input
                        label="Seminar Title"
                        onChange={(e) => setTitle(e.target.value)}
                        value={title}
                        type="text"
                        isRequired
                    />

                    <Input
                        label="Seminar Description"
                        onChange={(e) => setDescription(e.target.value)}
                        defaultValue={description}
                        isRequired
                        textarea
                        row={4}
                    />
                    {isIntroLoader && <Loader />}
                    <PictureUpload
                        label="Image URL"
                        onChange={handleImageUrl}
                        defaultValue={imageURL}
                        isRequired
                    />
                    <Input
                        label="Seminar/Meeting URL"
                        onChange={(e) => setVideoURL(e.target.value)}
                        defaultValue={videoURL}
                        isRequired
                    />
                    <Input
                        label="Seminar Date"
                        onChange={(e) => setSeminarsDate(e.target.value)}
                        defaultValue={seminarsDate}
                        type="date"
                        isRequired
                    />
                    <Input
                        label="Start Time"
                        onChange={(e) => setStartsTime(e.target.value)}
                        defaultValue={startsTime}
                        type="time"
                        isRequired
                    />
                    <Input
                        label="End Time"
                        onChange={(e) => setEndsTime(e.target.value)}
                        defaultValue={endsTime}
                        type="time"
                        isRequired
                    />
                    <Input
                        label="Status"
                        onChange={(e) => setIsActive(e.target.value)}
                        defaultValue={isActive}
                        select
                        options={[
                            { id: true, value: 'Active' },
                            { id: false, value: 'Inactive' },
                        ]}
                        isRequired
                    />
                    {formError && (
                        <p className="text-red-500 mt-2">
                            Please fill mandatory fields!
                        </p>
                    )}
                    <div className="flex gap-2 mt-4">
                        <button
                            onClick={handleAddSeminar}
                            className="bg-pink text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                            type="button"
                        >
                            Save Seminar
                        </button>
                    </div>
                </div>
            </Modal>

            <div className="flex flex-wrap mt-4">
                <div className="w-full mb-12 px-4">
                    <LiveSeminarTable
                        handleOpenModal={handleOpenModal}
                        color="dark"
                    />
                </div>
            </div>
        </>
    );
};

export default Seminars;
