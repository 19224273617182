import React from "react";
import "../../assets/styles/trackerContainer.css";
import Stepper from "lib/Stepper";

const TrackOrder = (orderData) => {
  const steps = [
    "Order Placed",
    "Processing",
    "Shipped",
    "Out for Delivery",
    "Delivered",
  ];
  const activeStep = 2;
  return (
    <>
      <p className="header-container">Track Your Order</p>
      <div className="overflow-y-auto">
        <div className="px-4 py-6 flex items-center justify-between my-5">
          <h1 className="text-2xl font-semibold text-teal-600">
            Arriving Wednesday
          </h1>
          <img
            src={require("../../assets/img/gopreg-logo.png").default}
            alt="Product"
            className="w-20 h-20 rounded-2xl"
          />
        </div>
        <hr className="h-1 w-full bg-gray-300 my-5" />
        <Stepper steps={steps} activeStep={activeStep} />
      </div>
      <div className="container01">
        <div className="cards-container">
          <div className="card">
            <h2 style={{ fontWeight: "bold" }}>Shippind Address</h2>
            <p>Sunil Verma</p>
            <p>
              Sai Sadan Apartment
              <br />
              Mumbai,Malad West
              <br />
              Maharashtra 400095
            </p>
          </div>
          <div className="card">
            <h2 style={{ fontWeight: "bold" }}>Order Info</h2>
            <br />
            <a href="OrderDetail" style={{ color: "blue" }}>
              View Order details
            </a>
            <br />
            <a href="CancelOrder" style={{ color: "blue" }}>
              Cancel Order
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrackOrder;
