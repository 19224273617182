import React, { useEffect, useState, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'quill/dist/quill.snow.css';
import '../../assets/styles/richText.css';
import toast, { Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'lib/Modal';
import Input from 'lib/Input';
import SaveButton from 'lib/SaveButton';
import DeleteButton from 'lib/DeleteButton';
import PictureUpload from 'lib/PictureUpload';
import Loader from 'components/Loader/Loader';
import { constants } from 'utils/constants';
import { handleImageFile, handleMediaFile } from 'api/fileUploadAPI';

import { getList, updateNewYoga, deleteNewYoga } from 'redux/yogaSlice';

const YogaPracticesTable = ({ handleOpenModal, color }) => {
    const dispatch = useDispatch();
    const [openEditModal, setOpenEditModal] = useState(false);
    const [modalData, setModalData] = useState({});

    const [isThumbnailLoader, setIsThumbnailLoader] = useState(false);
    const [isIntroLoader, setIsIntroLoader] = useState(false);

    const Data = useSelector((state) => state.yoga.data);
    const loading = useSelector((state) => state.yoga.loading);

    const [thumbnailOption, setThumbnailOption] = useState(1);
    const [mediaURLOption, setMediaURLOption] = useState(1);

    const MAX_LENGTH = 50;

    const handleOpenEditModal = (item) => {
        setModalData(item);
        setOpenEditModal(true);
    };

    const handleUpdate = () => {
        const data = {
            userCategory: modalData?.userCategory,
            thumbnailURL: modalData?.thumbnailURL,
            month: modalData?.month,
            title: modalData?.title,
            content: modalData?.content,
            benefits: modalData?.benefits,
            mediaType: modalData?.mediaType,
            mediaURL: modalData?.mediaURL,
            isActive: modalData?.isActive,
        };

        dispatch(updateNewYoga({ id: modalData?.id, body: { ...data } }));
        setThumbnailOption(1);
        setMediaURLOption(1);
        setOpenEditModal(false);
    };

    const handleDeleteButton = (item) => {
        dispatch(deleteNewYoga(item?.id));
    };

    function handleEditButton() {
        handleUpdate();
    }

    const userCategory = [
        { value: 'Pre Pregnancy', id: '1' },
        { value: 'Pregnant', id: '2' },
        { value: 'Parenting', id: '3' },
    ];

    const mediaOption = [
        { value: 'No Media', id: '0' },
        { value: 'Image', id: '1' },
        { value: 'Audio', id: '2' },
        { value: 'Video', id: '3' },
    ];

    const editor = useRef(null);

    const handleQuillChange = (value) => {
        setModalData({ ...modalData, content: value });
    };

    const handleMediaType = (value) => {
        setModalData({ ...modalData, mediaType: value, mediaURL: '' });
    };

    const handleIntroMedia = async (file) => {
        if (file === undefined) {
            setModalData({ ...modalData, mediaURL: '' });
            return;
        }

        if (modalData?.mediaType === '1') {
            try {
                setIsIntroLoader(true);
                const res = await handleImageFile(file);
                if (res.success) {
                    setModalData({ ...modalData, mediaURL: res.file_url });
                    toast.success('Successfully Uploaded the Image!');
                } else {
                    throw new Error('Picture Not Successfully Uploaded');
                }
                setIsIntroLoader(false);
            } catch (error) {
                toast.error(error);
            }
        } else {
            //audio and video type are strings
            try {
                setIsIntroLoader(true);
                const res = await handleMediaFile(file);
                if (res.success) {
                    setModalData({ ...modalData, mediaURL: res.file_url });
                    modalData?.mediaType === '2'
                        ? toast.success('Successfully Uploaded the Audio!')
                        : toast.success('Successfully Uploaded the video!');
                } else {
                    throw new Error('Picture Not Successfully Uploaded');
                }
                setIsIntroLoader(false);
            } catch (error) {
                toast.error(error);
            }
        }
    };

    const handleThumbnail = async (file) => {
        if (file === undefined) {
            setModalData({ ...modalData, thumbnailURL: '' });
            return;
        }

        try {
            setIsThumbnailLoader(true);
            const res = await handleImageFile(file);
            if (res.success) {
                setModalData({ ...modalData, thumbnailURL: res.file_url });
                toast.success('Successfully Uploaded the Image!');
            } else {
                throw new Error('Picture Not Successfully Uploaded');
            }
            setIsThumbnailLoader(false);
        } catch (error) {
            toast.error(error);
        }
    };

    useEffect(() => {
        dispatch(getList());
    }, [dispatch]);

    return (
        <>
            {/* Edit modal */}
            <Toaster />
            <Modal
                isOpen={openEditModal}
                onClose={() => setOpenEditModal(false)}
            >
                <div>
                    <Input
                        onChange={(e) =>
                            setModalData({
                                ...modalData,
                                userCategory: e.target.value,
                            })
                        }
                        defaultValue={modalData.userCategory}
                        label="User Category"
                        isRequired
                        options={userCategory}
                        select
                    />
                    <Input
                        onChange={(e) =>
                            setModalData({
                                ...modalData,
                                month: e.target.value,
                            })
                        }
                        defaultValue={modalData.month}
                        label="month"
                        isRequired
                        options={constants.monthsOptions}
                        select
                    />
                    <Input
                        label="Yoga Name"
                        isRequired
                        defaultValue={modalData.title}
                        onChange={(e) =>
                            setModalData({
                                ...modalData,
                                title: e.target.value,
                            })
                        }
                    />
                    <ReactQuill
                        style={{
                            marginLeft: 15,
                            marginRight: 15,
                            background: '#fff',
                        }}
                        className="ql-container ql-snow"
                        ref={editor}
                        modules={constants.modules}
                        formats={constants.formats}
                        defaultValue={modalData.content}
                        onChange={handleQuillChange}
                    />
                    <Input
                        label="Yoga Benefits"
                        isRequired
                        defaultValue={modalData.benefits}
                        onChange={(e) =>
                            setModalData({
                                ...modalData,
                                benefits: e.target.value,
                            })
                        }
                        textarea
                        rows={4}
                    />
                    <Input
                        label="Media Type"
                        isRequired
                        defaultValue={modalData?.mediaType}
                        onChange={(e) => handleMediaType(e.target.value)}
                        select
                        options={mediaOption}
                    />
                    {modalData?.mediaType === '0' ? null : (
                        <Input
                            label="Media Options"
                            value={mediaURLOption}
                            onChange={(e) =>
                                setMediaURLOption(parseInt(e.target.value))
                            }
                            options={[
                                { id: 1, value: 'Upload Media' },
                                { id: 2, value: 'Media URL' },
                            ]}
                            select
                            isRequired
                        />
                    )}
                    {isIntroLoader && <Loader />}
                    {modalData?.mediaType === '0' ? null : mediaURLOption === 1 ? (
                        <PictureUpload
                            onChange={handleIntroMedia}
                            value={modalData?.mediaURL}
                            label={
                                modalData?.mediaType === '3'
                                    ? 'Intro Video'
                                    : modalData?.mediaType === '2'
                                    ? 'Intro Audio'
                                    : 'Intro Image'
                            }
                            isRequired
                            accept="image/*, video/*"
                        />
                    ) : (
                        <Input
                            label="Media URL"
                            isRequired
                            value={modalData?.mediaURL}
                            onChange={(e) =>
                                setModalData({
                                    ...modalData,
                                    mediaURL: e.target.value,
                                })
                            }
                        />
                    )}
                    <Input
                        label="Thumbnail Options"
                        value={thumbnailOption}
                        onChange={(e) =>
                            setThumbnailOption(parseInt(e.target.value))
                        }
                        options={[
                            { id: 1, value: 'Upload Thumbnail' },
                            { id: 2, value: 'Thumbnail URL' },
                        ]}
                        select
                        isRequired
                    />
                    {isThumbnailLoader && <Loader />}
                    {thumbnailOption === 1 ? (
                        <PictureUpload
                            label="Thumbnail Image"
                            isRequired
                            onChange={handleThumbnail}
                            value={modalData?.thumbnailUrl}
                        />
                    ) : (
                        <Input
                            label="Thumbnail URL"
                            isRequired
                            value={modalData?.thumbnailURL}
                            onChange={(e) =>
                                setModalData({
                                    ...modalData,
                                    thumbnailURL: e.target.value,
                                })
                            }
                        />
                    )}
                    <Input
                        onChange={(e) =>
                            setModalData({
                                ...modalData,
                                isActive: e.target.value,
                            })
                        }
                        defaultValue={modalData.isActive}
                        options={[
                            { id: true, value: 'Active' },
                            { id: false, value: 'Inactive' },
                        ]}
                        select
                        label="IS ACTIVE"
                        isRequired
                    />
                    {/* Additional input fields */}
                    {/* ... */}
                    <div className="flex gap-2">
                        <SaveButton
                            handleEditButton={handleEditButton}
                            name={'Yoga Practices'}
                        ></SaveButton>
                    </div>
                </div>
            </Modal>
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
                <div className="rounded-t mb-0 px-4 py-4 border-0">
                    <div className="flex flex-wrap items-center">
                        <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                            <h3 className="font-semibold text-lg text-blueGray-700">
                                Yoga Practices
                            </h3>
                        </div>
                        <button
                            onClick={handleOpenModal}
                            className="bg-pink text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                            type="button"
                        >
                            Add New
                        </button>
                    </div>
                </div>
                <div className="block w-full overflow-x-auto">
                    {/* Table */}
                    <table className="w-full bg-transparent border-collapse">
                        <thead>
                            <tr>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    ID
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    User Category
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    month
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Yoga Name
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Yoga content
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Yoga Benefits
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Thumbnail Url
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Media URL
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Created At
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Created By
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Updated At
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Updated By
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Is Active
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        {loading ? (
                            <Loader />
                        ) : (
                            <tbody>
                                {Data?.map((item) => (
                                    <tr key={item.id}>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {item?.id}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {item?.userCategory === '1'
                                                ? 'Pre Pregnancy'
                                                : item?.userCategory === '2'
                                                ? 'Pregnant'
                                                : item?.userCategory === '3'
                                                ? 'Parenting'
                                                : 'N/A'}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {item?.month}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {item?.title}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {constants?.trimText(
                                                item.content,
                                                MAX_LENGTH
                                            )}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {constants?.trimText(
                                                item?.benefits,
                                                MAX_LENGTH
                                            )}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {item?.thumbnailURL
                                                ? item?.thumbnailURL
                                                : 'N/A'}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {item?.mediaURL
                                                ? item?.mediaURL
                                                : 'N/A'}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {new Date(
                                                item?.createdAt
                                            ).toLocaleString()}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {item?.createdBy}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {new Date(
                                                item?.updatedAt
                                            ).toLocaleString()}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {item?.updatedBy}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {item?.isActive
                                                ? 'Active'
                                                : 'Inactive'}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            <div className="flex justify-evenly">
                                                <button
                                                    onClick={() =>
                                                        handleOpenEditModal(
                                                            item
                                                        )
                                                    }
                                                    className="focus:outline-none	"
                                                    type="button"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="h-5 w-5 mr-2 "
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                        strokeWidth="2"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                                        />
                                                    </svg>
                                                </button>
                                                <DeleteButton
                                                    handleDeleteButton={() => {
                                                        handleDeleteButton(
                                                            item
                                                        );
                                                    }}
                                                ></DeleteButton>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        )}
                    </table>
                </div>
            </div>
        </>
    );
};

export default YogaPracticesTable;
