import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createNewDiet,
  getDiet,
  updateDiet,
  deleteDiet,
} from "api/dietAndNutritionAPI";

export const getList = createAsyncThunk('diet/list', async () => {
    try {
        const res = await getDiet();
        if (res.success) {
            return res.data;
        } else {
            throw new Error('Error fetching Diet.');
        }
    } catch (error) {
        throw error;
    }
});

export const createOne = createAsyncThunk('diet/create-one', async (data) => {
    try {
        const res = await createNewDiet(data);
        return res.data;
    } catch (error) {
        throw error;
    }
});

export const updateOne = createAsyncThunk('diet/update-one', async (data) => {
    try {
        const res = await updateDiet(data);
        if (res.success) {
            return res.data;
        } else {
            throw new Error('Error creating seminar.');
        }
    } catch (error) {
        throw error;
    }
});
export const deleteOne = createAsyncThunk('diet/delete-one', async (id) => {
    try {
        const res = await deleteDiet(id);
        if (res.success) {
            return id;
        } else {
            throw new Error('Error updating seminar.');
        }
    } catch (error) {
        throw error;
    }
});

const dataSlice = createSlice({
    name: 'diet',
    initialState: {
        data: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            //* GET
            .addCase(getList.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getList.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.data = action.payload;
            })
            .addCase(getList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            //* CREATE
            .addCase(createOne.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(createOne.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                if (action.payload) {
                    state.data.push(action.payload);
                }
            })
            .addCase(createOne.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            //* UPDATE
            .addCase(updateOne.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateOne.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                const updatedDietIndex = state.data.findIndex(
                    (diet) => diet.id === action.payload.id
                );
                if (updatedDietIndex !== -1) {
                    state.data[updatedDietIndex] = action.payload;
                }
            })
            .addCase(updateOne.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            //* DELETE
            .addCase(deleteOne.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteOne.fulfilled, (state, action) => {
                state.loading = false;
                state.data = state.data.filter(
                    (diet) => diet.id !== action.payload
                );
            })
            .addCase(deleteOne.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const dietAction = dataSlice.actions;
export default dataSlice;
