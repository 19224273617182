import React, { useState } from "react";
import Modal from "lib/Modal";
import Input from "lib/Input";
import DeleteButton from "lib/DeleteButton";
import { constants } from "utils/constants";
import SaveButton from "lib/SaveButton";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getNewGreetings,
  updateNewGreetings,
  deleteNewGreetings,
} from "redux/greetingSlice";
import Loader from "components/Loader/Loader";
import { Toaster } from "react-hot-toast";

const MessageFromSoulTable = ({ handleOpenModal }) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const dispatch = useDispatch();
  const [modalData, setModalData] = useState({});
  const MAX_LENGTH = 50;

  const Data = useSelector((state) => state.dailyGreeting.data);
  const loading = useSelector((state) => state.dailyGreeting.loading);

  const handleOpenEditModal = (item) => {
    setModalData(item);
    setOpenEditModal(true);
  };

  function handleEditButton() {
    handleUpdate();
  }
  function handleDeleteButton(id) {
    dispatch(deleteNewGreetings(id));
  }

  const handleUpdate = () => {
    const updatedData = {
      day: modalData?.day,
      month: modalData?.month,
      text: modalData?.text.trim(),
      isActive: modalData?.isActive,
    };
    dispatch(
      updateNewGreetings({ id: modalData?.id, body: { ...updatedData } })
    );
    setOpenEditModal(false);
  };

  useEffect(() => {
    dispatch(getNewGreetings());
  }, [dispatch]);

  const dayOptions = Array.from({ length: 31 }, (_, i) => ({
    value: i + 1,
    label: `Day ${i + 1}`,
  }));

  return (
    <>
      {/* Edit modal */}
      <Toaster />
      <Modal isOpen={openEditModal} onClose={() => setOpenEditModal(false)}>
        <div>
          <Input
            onChange={(e) =>
              setModalData({ ...modalData, day: parseInt(e.target.value) })
            }
            defaultValue={modalData?.day}
            label="Day"
            isRequired
            options={dayOptions}
            select
          />
          <Input
            onChange={(e) =>
              setModalData({ ...modalData, month: e.target.value })
            }
            value={modalData?.month}
            label="Month"
            isRequired
            options={constants.monthsOptions}
            select
          />
          <Input
            onChange={(e) =>
              setModalData({ ...modalData, text: e.target.value })
            }
            value={modalData?.text}
            label=" text"
            isRequired
            textarea
          />
          <Input
            onChange={(e) =>
              setModalData({ ...modalData, isActive: e.target.value })
            }
            defaultValue={modalData?.isActive}
            options={[
              { id: true, value: "Active" },
              { id: false, value: "Inactive" },
            ]}
            select
            label="Visibility State"
            isRequired
          />
          <div className="flex gap-2">
            <SaveButton handleEditButton={handleEditButton}></SaveButton>
          </div>
        </div>
      </Modal>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
        <div className="rounded-t mb-0 px-4 py-4 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3 className="font-semibold text-lg text-blueGray-700">
                Greeting
              </h3>
            </div>
            <button
              onClick={handleOpenModal}
              className="bg-pink text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
            >
              Add New
            </button>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Greetings table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  ID
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Day
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Month
                </th>

                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Greeting Text
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Created At
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Created By
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Updated At
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Updated By
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Is Active
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Actions
                </th>
              </tr>
            </thead>
            {loading ? (
              <Loader />
            ) : (
              <tbody>
                {Data?.map((item) => (
                  <tr key={item?.id}>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {item?.id}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {item?.day}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {item?.month}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {constants.trimText(item?.text, MAX_LENGTH)}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {new Date(item?.createdAt).toLocaleDateString()}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {item?.createdBy}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {new Date(item?.updatedAt).toLocaleDateString()}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {item?.updatedBy}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {item?.isActive ? "Active" : "Inactive"}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      <div className="flex justify-evenly">
                        <button
                          onClick={() => handleOpenEditModal(item)}
                          className="focus:outline-none	"
                          type="button"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 mr-2 "
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                            />
                          </svg>
                        </button>
                        <DeleteButton
                          handleDeleteButton={() => {
                            handleDeleteButton(item?.id);
                          }}
                        ></DeleteButton>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </>
  );
};

// MessageFromSoulTable.defaultProps = {
//   color: "light",
// };

// MessageFromSoulTable.propTypes = {
//   color: PropTypes.oneOf(["light", "dark"]),
//   data: PropTypes.array.isRequired,
//   handleOpenModal: PropTypes.func.isRequired,
// };

export default MessageFromSoulTable;
