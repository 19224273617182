import { config } from "./confg";
import { axiosCallAdvanced } from "./main";

export const getProduct = async () => {
    return (
        (
            await axiosCallAdvanced({
                baseURL: config.product.root,
                path: config.product.list,
            })
        )?.data || {}
    );
};

export const createNewProduct = async (data) => {
    return (
        (
            await axiosCallAdvanced({
                baseURL: config.product.root,
                path: config.product.createOne,
                body: { ...data },
                method: config.methodType.post,
            })
        )?.data || {}
    );
};

export const updateProduct = async (parameters) => {
    return (
        (
            await axiosCallAdvanced({
                baseURL: config.product.root,
                path: `${config.product.updateOne}/${parameters.id}`,
                body: parameters.data,
                method: config.methodType.put,
            })
        )?.data || {}
    );
};

export const deleteProduct = async (id) => {
    return (
        (
            await axiosCallAdvanced({
                baseURL: config.product.root,
                path: `${config.product.deleteOne}/${id}`,
                method: config.methodType.patch,
            })
        )?.data || {}
    );
};
