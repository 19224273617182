import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getStory, createStory, updateStory, deleteStory } from "api/storyAPI";

export const getStoryList = createAsyncThunk('story/list', async () => {
    try {
        const res = await getStory();
        if (res.success) {
            return res.data;
        } else {
            throw new Error('Error fetching Story.');
        }
    } catch (error) {
        throw error;
    }
});

export const createOneStory = createAsyncThunk(
    'story/create-one',
    async (data) => {
        try {
            const res = await createStory(data);
            if (res.success) {
                return res.data;
            } else {
                throw new Error('Error creating Story.');
            }
        } catch (error) {
            throw error;
        }
    }
);

export const updateOneStory = createAsyncThunk(
    'story/update-one',
    async (data) => {
        try {
            const res = await updateStory(data);
            if (res.success) {
                return res.data;
            } else {
                throw new Error('Error updating Story.');
            }
        } catch (error) {
            throw error;
        }
    }
);

export const deleteOneStory = createAsyncThunk(
    'story/delete-one',
    async (id) => {
        try {
            const res = await deleteStory(id);
            if (res.success) {
                return id;
            } else {
                throw new Error('Error deleting Story.');
            }
        } catch (error) {
            throw error;
        }
    }
);

const storySlice = createSlice({
    name: 'story',
    initialState: {
        data: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            //* GET
            .addCase(getStoryList.pending, (state) => {
                state.loading = true;
            })
            .addCase(getStoryList.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(getStoryList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            //* CREATE
            .addCase(createOneStory.pending, (state) => {
                state.loading = true;
            })
            .addCase(createOneStory.fulfilled, (state, action) => {
                state.loading = false;
                if (action.payload) {
                    state.data.push(action.payload);
                }
            })
            .addCase(createOneStory.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            //* UPDATE
            .addCase(updateOneStory.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateOneStory.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                const storyIndex = state.data.findIndex(
                    (story) => story.id === action.payload.id
                );
                if (storyIndex !== -1) {
                    state.data[storyIndex] = action.payload;
                }
            })
            .addCase(updateOneStory.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            //* DELETE
            .addCase(deleteOneStory.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteOneStory.fulfilled, (state, action) => {
                state.loading = false;
                state.data = state.data.filter(
                    (story) => story.id !== action.payload
                );
            })
            .addCase(deleteOneStory.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const storyAction = storySlice.actions;
export default storySlice;
