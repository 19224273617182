import { config } from './confg';
import { axiosCallAdvanced } from './main';

export const getOrder = async (parameters) => {
    return (
        (
            await axiosCallAdvanced({
                baseURL: config.order.root,
                path: `${config.order.list}/${parameters}`,
            })
        )?.data || {}
    );
};

export const updateOrder = async (parameters) => {
    return (
        (
            await axiosCallAdvanced({
                baseURL: config.order.root,
                path: `${config.order.updateOne}/${parameters.id}`,
                body: parameters.body,
                method: config.methodType.patch,
                customSuccessMessage: 'Successfully Updated!'
            })
        )?.data || {}
    );
};
