import React, { useState } from "react";
import Modal from "lib/Modal";
import Input from "lib/Input";
import CardTable from "components/Cards/CardTable.js";
import DatePicker from "react-datepicker";

export default function MasterTable() {
  const [modalOpen, setModalOpen] = useState(false);
  const [userCategoryData, setUserCategoryData] = useState([
    {
      id: 1,
      category: "Amrutdhara",
      createdAt: new Date(2023, 6, 15),
      createdBy: "demoText",
      updatedAt: new Date(2023, 6, 15),
      updatedBy: "demo text1",
      isActive: true,
    },
    {
      id: 2,
      category: "Amrutdhara",
      createdAt: new Date(2023, 6, 15),
      createdBy: "demoText",
      updatedAt: new Date(2023, 6, 15),
      updatedBy: "demo text1",
      isActive: true,
    },
  ]);
  const [category, setCategory] = useState("");
  const [createdAt, setCreatedAt] = useState(new Date());
  const [createdBy, setCreatedBy] = useState("");
  const [updatedAt, setUpdatedAt] = useState(new Date());
  const [updatedBy, setUpdatedBy] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [formError, setFormError] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleCreateNew = () => {
    if (
      category.trim() !== "" &&
      createdAt.trim() !== "" &&
      createdBy.trim() !== "" &&
      updatedAt.trim() !== "" &&
      updatedBy.trim() !== ""
    ) {
      const newCategory = {
        id: userCategoryData.length + 1,
        category: category.trim(),
        createdAt: createdAt.trim(),
        createdBy: createdBy.trim(),
        updatedAt: updatedAt.trim(),
        updatedBy: updatedBy.trim(),
        isActive: isActive,
      };
      setUserCategoryData([...userCategoryData, newCategory]);
      setModalOpen(false);
      setCategory("");
      setCreatedAt(new Date());
      setCreatedBy("");
      setUpdatedAt(new Date());
      setUpdatedBy("");
      setFormError(false);
    } else {
      setFormError(true);
    }
  };

  return (
    <>
      <Modal isOpen={modalOpen} onClose={handleCloseModal}>
        <div>
          <Input
            onChange={(e) => setCategory(e.target.value)}
            value={category}
            label="User Category"
          />
          <Input
            onChange={(e) => setIsActive(e.target.value)}
            defaultValue={isActive}
            options={[
              { id: true, value: "Active" },
              { id: false, value: "Inactive" },
            ]}
            select
            label="Visibility State"
          />
          {formError && (
            <p className="mx-4 text-red-500 mb-2">
              Please fill all the details!
            </p>
          )}
          <div className="flex gap-2 mt-4">
            <button
              onClick={handleCreateNew}
              className="bg-pink text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
            >
              Save
            </button>
            <button
              onClick={handleCloseModal}
              className="bg-gray-200 text-gray-700 active:bg-gray-300 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <CardTable
            handleOpenModal={handleOpenModal}
            data={userCategoryData}
            color="dark"
          />
        </div>
      </div>
    </>
  );
}
