import { config } from "./confg";
import { axiosCallAdvanced } from "./main";

export const getUserDetails = async (sortingMode) => {
  return (
    (
      await axiosCallAdvanced({
        baseURL: config.userDetails.root,
        path: config.userDetails.list,
        sort: sortingMode,
        sortColumn: "createdAt"
      })
    )?.data || {}
  );
};
export const deactivateUser = async (id) => {
  return (
    (
      await axiosCallAdvanced({
        baseURL: config.userDetails.root,
        path: `${config.userDetails.updateStatus}/${id}`,
        method: config.methodType.patch,
        customSuccessMessage: "User updated Successfully",
      })
    )?.data || {}
  );
};
export const userAdminStatus = async (id) => {
  return (
    (
      await axiosCallAdvanced({
        baseURL: config.userDetails.root,
        path: `${config.userDetails.updateAdminStatus}/${id}`,
        method: config.methodType.patch,
        customSuccessMessage: "User updated Succesfully",
      })
    )?.data || {}
  );
};

export const userSubscriptionUpdate = async (parameter) => {
  return (
    (
      await axiosCallAdvanced({
        baseURL: config.userDetailsSubscription.root,
        path: `${config.userDetailsSubscription.updateOne}/${parameter.id}`,
        body: parameter.body,
        method: config.methodType.put,
      })
    )?.data || {}
  )
};