import { config } from "./confg";
import { axiosCallAdvanced } from "./main";

export const getHomeRemediesAPI = async (sortingMode) => {
    return (
        (
            await axiosCallAdvanced({
                baseURL: config.homeRemedies.root,
                path: config.homeRemedies.listAll,
                sort: sortingMode,
                sortColumn: "createdAt"
            })
        )?.data || {}
    );
};
export const createdHomeRemediesAPI = async (parameter) => {
    return (
        (
            await axiosCallAdvanced({
                baseURL: config.homeRemedies.root,
                path: `${config.homeRemedies.create}`,
                method: config.methodType.post,
                body: parameter.body,
                customSuccessMessage: "HomeRemedies created Successfully",
            })
        )?.data || {}
    );
};
export const updateHomeRemediesAPI = async (parameter) => {
    return (
        (
            await axiosCallAdvanced({
                baseURL: config.homeRemedies.root,
                path: `${config.homeRemedies.update}/${parameter.id}`,
                method: config.methodType.put,
                body: parameter.body,
                customSuccessMessage: "HomeRemedies updated Successfully",
            })
        )?.data || {}
    );
};
export const deactivateHomeRemediesAPI = async (parameter) => {
    return (
        (
            await axiosCallAdvanced({
                baseURL: config.homeRemedies.root,
                path: `${config.homeRemedies.delete}/${parameter.id}`,
                method: config.methodType.post,
                customSuccessMessage: "HomeRemedies deleted Successfully",
            })
        )?.data || {}
    );
};
