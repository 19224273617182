import React from "react";
import CustomersTable from "components/Cards/CustomersTable";
// import { useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
const Customers = () => {
    // const dispatch = useDispatch();
    // const customerData = useSelector((state) => state.customers.data);
    // const [name, setName] = useState('');
    // const [avatar, setAvatar] = useState('');
    // const [lastVisit, setLastVisit] = useState('');
    // const [memberShip, setMemberShip] = useState('');
    // const [ltv, setLtv] = useState('');

    return (
        <>
            {/* <div className="relative flex flex-wrap min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg">
                <div className="flex-auto  p-4">
                    <div className="block max-w-sm px-4 py-3 bg-white border border-gray-200 rounded-lg shadow">
                        <div>
                            <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                                Total Clients
                            </h5>
                        </div>
                        <div className="flex justify-between">
                            <p className="font-semibold text-xl ">124</p>
                            <p className="text-sm mt-1">
                                <span className=" mr-2 text-emerald-500">
                                    <i className={'fas fa-arrow-up mr-1 '}></i>
                                    4.2%
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flex-auto p-4">
                    <div className="block max-w-sm px-4 py-3 bg-white border border-gray-200 rounded-lg shadow">
                        <div>
                            <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                                Members
                            </h5>
                        </div>
                        <div className="flex justify-between">
                            <p className="font-semibold text-xl ">65</p>
                            <div className="flex ">
                                <span className=" mr-2 flex font-semibold ">
                                    <div className="w-5 h-5 bg-red-400 rounded-full"></div>
                                    <p className="mr-2 ml-1">10</p>
                                </span>
                                <span className=" mr-2 flex font-semibold">
                                    <div className="w-5 h-5 bg-red-400 rounded-full"></div>
                                    <p className="mr-2 ml-1">15</p>
                                </span>
                                <span className=" mr-2 flex font-semibold">
                                    <div className="w-5 h-5 bg-red-400 rounded-full"></div>
                                    <p className="mr-2 ml-1">17</p>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex-auto p-4">
                    <div className="block max-w-sm px-4 py-3 bg-white border border-gray-200 rounded-lg shadow">
                        <div>
                            <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                                New/Returning
                            </h5>
                        </div>
                        <div className="flex justify-between">
                            <p className="font-semibold text-xl ">
                                <span className="text-warmGray-50">3</span>/23
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flex-auto p-4">
                    <div className="block max-w-sm px-4 py-3 bg-white border border-gray-200 rounded-lg shadow">
                        <div>
                            <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                                Active Members
                            </h5>
                        </div>
                        <div className="flex justify-between">
                            <p className="font-semibold text-xl ">
                                {customerData?.map((item) => {
                                    return (
                                        <span className="text-sm">
                                            {item?.count}
                                        </span>
                                    );
                                })}
                            </p>
                            <div className="flex">
                                {customerData.map((items) => {
                                    return (
                                        <>
                                            <img
                                                src={items.avatar}
                                                alt=""
                                                className="rounded-full "
                                                height={28}
                                                width={28}
                                            />
                                            <i className="fas fa-users"></i>
                                        </>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="flex flex-wrap mt-4">
                <div className="w-full mb-12 px-4">
                    <CustomersTable color="dark" />
                </div>
            </div>
        </>
    );
};

export default Customers;
