import { config } from './confg';
import { axiosCallAdvanced } from './main';

export const getConsultation = async () => {
    return (
        (
            await axiosCallAdvanced({
                baseURL: config.consultation.root,
                path: config.consultation.list,
            })
        )?.data || {}
    );
};

export const updateConsultation = async (parameters) => {
    return (
        (
            await axiosCallAdvanced({
                baseURL: config.consultation.root,
                path: `${config.consultation.updateOne}/${parameters.id}`,
                body: parameters.body,
                method: config.methodType.patch,
            })
        )?.data || {}
    );
};
