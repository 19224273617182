import { config } from "./confg";
import { axiosCallAdvanced } from "./main";

export const getExpectedDeliveryDate = async () => {
  return (
    (
      await axiosCallAdvanced({
        baseURL: config.deliveryDate.root,
        path: config.deliveryDate.list,
      })
    )?.data || {}
  );
};

export const updateExpectedDeliveryDate = async (id, data) => {
  return (
    (
      await axiosCallAdvanced({
        baseURL: config.deliveryDate.root,
        path: `${config.deliveryDate.updateOne}/${id}`,
        body: {
          date: data
        },
        method: config.methodType.patch,
      })
    )?.data || {}
  );
};
