import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { config } from "api/confg";
import { userLogin, verifyOtp } from "api/userAPI";

// import { useHistory } from "react-router-dom";

export const loginUser = createAsyncThunk('user/login', async (credentials) => {
    try {
        const res = await userLogin(credentials);
        return {
            response: res,
            mobile: credentials,
        };
    } catch (error) {
        return error;
    }
});

export const otpVerify = createAsyncThunk('user/otp', async (otp) => {
    try {
        const res = await verifyOtp(otp);
        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
});

const initialState = {
    userData: {},
    error: false,
    loading: false,
    isLoggedIn: false,
    screenId: 1,
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        // getUserData: (state) => {
        //   const data = localStorage.getItem("userData");
        //   state.userData = JSON.parse(data);
        // },
    },
    extraReducers: (builder) => {
        builder
            //* LOGIN REDUCERS
            .addCase(loginUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.loading = false;
                state.error = false;
                if (action.payload?.response?.success) {
                    state.screenId = 2;
                }
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            //* OTP REDUCERS
            .addCase(otpVerify.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(otpVerify.fulfilled, (state, action) => {
                state.loading = false;
                state.error = false;
                state.isLoggedIn = true;
                state.userData = action.payload;
                if (action.payload?.success) {
                    localStorage.setItem(
                        config.storageTokenKeyName,
                        action.payload.data.token
                    );
                    state.isLoggedIn = true;
                } else {
                    state.isLoggedIn = false;
                    state.error = true;
                }
            })
            .addCase(otpVerify.rejected, (state) => {
                state.loading = false;
                state.error = true;
            });
    },
});

export const userAction = userSlice.actions;
export default userSlice;
