import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createTestimonial,
  deleteTestimonial,
  getTestimonial,
  updateTestimonial,
} from "api/testimonialAPI";

export const getTestimonialList = createAsyncThunk(
  "testimonial/list",
  async () => {
    try {
      const res = await getTestimonial();
      if (res.success) {
        return res.data;
      } else {
        throw new Error("Error Fetching Data");
      }
    } catch (error) {
      throw error;
    }
  }
);
export const createTestimonials = createAsyncThunk(
  "testimonial/create-one",
  async (data) => {
    try {
      const res = await createTestimonial(data);
      if (res.success) {
        return res.data;
      } else {
        throw new Error("Error Creating Data");
      }
    } catch (error) {
      throw error;
    }
  }
);
export const updateTestimonials = createAsyncThunk(
  "testimonial/update-one",
  async ({ id, data }) => {
    try {
      const res = await updateTestimonial(id, data);
      if (res.success) {
        return res.data;
      } else {
        throw new Error("Error Updating Data");
      }
    } catch (error) {
      throw error;
    }
  }
);
export const deleteTestimonials = createAsyncThunk(
  "testimonial/delete-one",
  async (id) => {
    try {
      const res = await deleteTestimonial(id);
      if (res.success) {
        return id;
      } else {
        throw new Error("Error Deleting Data");
      }
    } catch (error) {
      throw error;
    }
  }
);

const testimonialSlice = createSlice({
  name: "testimonials",
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducer: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTestimonialList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTestimonialList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getTestimonialList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createTestimonials.pending, (state) => {
        state.loading = true;
      })
      .addCase(createTestimonials.fulfilled, (state, action) => {
        state.loading = false;
        state.data.push(action.payload);
      })
      .addCase(createTestimonials.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateTestimonials.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateTestimonials.fulfilled, (state, action) => {
        state.loading = false;
        const testimonialIndex = state.data.findIndex(
          (testimonial) => testimonial.id === action.payload.id
        );
        if (testimonialIndex !== -1) {
          state.data[testimonialIndex] = action.payload;
        }
      })
      .addCase(updateTestimonials.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteTestimonials.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteTestimonials.fulfilled, (state, action) => {
        state.loading = false;
        state.data = state.data.filter(
          (testimonial) => testimonial.id !== action.payload
        );
      })
      .addCase(deleteTestimonials.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const testimonialAction = testimonialSlice.actions;
export default testimonialSlice;
