import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createShlokas, getShlokas, updateShlokas, deleteShlokas } from "api/shlokas";

// Async thunk to fetch shlokas list
export const getList = createAsyncThunk("shlokas/list", async () => {
  try {
    const res = await getShlokas();
    if (res.success) {
      return res.data;
    } else {
      throw new Error("Error fetching shlokas");
    }
  } catch (error) {
    throw error;
  }
});

// Async thunk to create a new shloka
export const createNewShloka = createAsyncThunk(
  "shlokas/create-one",
  async (data) => {
    try {
      const res = await createShlokas(data);
      return res.data;
    } catch (error) {
      throw error;
    }
  }
);

// Async thunk to update a shloka
export const updateShloka = createAsyncThunk(
  "shlokas/update-one",
  async (data) => {
    try {
      const res = await updateShlokas(data);
      return res.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteShloka = createAsyncThunk(
  "shlokas/delete-one",
  async (id) => {
    try {
      const res = await deleteShlokas(id);
      console.log("res-api", res);
      return id;
    } catch (error) {
      throw error;
    }
  }
);

const initialState = {
  shlokas: [],
  loading: false,
  error: null,
};

const shlokaSlice = createSlice({
  name: "shlokas",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
        .addCase(getList.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(getList.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
            state.shlokas = action.payload;
        })
        .addCase(getList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        })

        .addCase(createNewShloka.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(createNewShloka.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
            if (action.payload) {
                state.shlokas.push(action.payload); // Add the newly created shloka to the list
            }
        })
        .addCase(createNewShloka.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        })

        .addCase(updateShloka.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(updateShloka.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
            // Update the existing shloka in the list with the updated data
            const updatedShlokaIndex = state.shlokas.findIndex(
                (shloka) => shloka.id === action.payload.id
            );
            if (updatedShlokaIndex !== -1) {
                state.shlokas[updatedShlokaIndex] = action.payload;
            }
        })
        .addCase(updateShloka.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        })

        .addCase(deleteShloka.pending, (state, action) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(deleteShloka.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
            state.shlokas = state.shlokas.filter(
                (shloka) => shloka.id !== action.payload
            );
        })
        .addCase(deleteShloka.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
  },
});

export const shlokaAction = shlokaSlice.actions;
export default shlokaSlice;
