import { config } from './confg';
import { axiosCallAdvanced } from './main';

export const getDiet = async () => {
    return (
        (
            await axiosCallAdvanced({
                baseURL: config.dietAndNutrition.root,
                path: config.dietAndNutrition.list,
            })
        )?.data || {}
    );
};

export const createNewDiet = async (data) => {
    return (
        (
            await axiosCallAdvanced({
                baseURL: config.dietAndNutrition.root,
                path: config.dietAndNutrition.createOne,
                body: { ...data },
                method: config.methodType.post,
            })
        )?.data || {}
    );
};

export const updateDiet = async (parameters) => {
    return (
        (
            await axiosCallAdvanced({
                baseURL: config.dietAndNutrition.root,
                path: `${config.dietAndNutrition.updateOne}/${parameters.id}`,
                body: parameters.body,
                method: config.methodType.put,
            })
        )?.data || {}
    );
};

export const deleteDiet = async (parameters) => {
    return (
        (
            await axiosCallAdvanced({
                baseURL: config.dietAndNutrition.root,
                path: `${config.dietAndNutrition.deleteOne}/${parameters}`,
                body: parameters.body,
                method: config.methodType.patch,
            })
        )?.data || {}
    );
};
