import React, { useState, useEffect, useRef } from "react";
import ReactQuill from 'react-quill';
import { Toaster, toast } from 'react-hot-toast';
import styles from '../../lib/Modal.module.css';
import { useDispatch, useSelector } from 'react-redux';
import Input from 'lib/Input';
import Modal from 'lib/Modal';
import SaveButton from 'lib/SaveButton';
import DeleteButton from 'lib/DeleteButton';
import PictureUpload from 'lib/PictureUpload';
import Loader from 'components/Loader/Loader';
import { updateOne, getList, deleteOne } from 'redux/productSlice';
import { constants } from 'utils/constants';
import { handleMediaFile } from 'api/fileUploadAPI';
import { getNewProductCategory } from 'redux/productCategorySlice';

const productVariantTemplate = {
    variantName: '',
    sku: '',
    actualPrice: '',
    displayPrice: '',
    discount: '',
    stockQuantity: '',
    isActive: true,
};

const ProductTable = ({ color, handleOpenModal }) => {
    const editor = useRef(null);
    const dispatch = useDispatch();
    const [modalData, setModalData] = useState(null);

    const [openEditModal, setOpenEditModal] = useState(false);
    const [variantEditModal, setVariantEditModal] = useState(false);
    const [imageEditModal, setImageEditModal] = useState(false);

    const [productVariants, setProductVariants] = useState(
        productVariantTemplate
    );
    const [isImageUploading, setIsImageUploading] = useState(false);
    const [newImage, setNewImage] = useState('');
    const [selectedCategoryId, setSelectedCategoryId] = useState([]);

    const Data = useSelector((state) => state.products.data);
    const loading = useSelector((state) => state.products.loading);
    const categoryList = useSelector((state) => state.productCategory.data);

    const MAX_LENGTH = 50;

    const handleOpenEditModal = (item) => {
        setModalData(item);
        setSelectedCategoryId(
            item?.productCategoryBridges.map((bridge) => bridge.category.id)
        );
        setOpenEditModal(true);
    };

    const formatDate = (dateString) => {
        return dateString
            ? new Date(dateString).toISOString().split('T')[0]
            : '';
    };

    useEffect(() => {
        dispatch(getList());
        dispatch(getNewProductCategory());
    }, [dispatch]);

    const handleUpdate = () => {
        const integerCategoryIds = selectedCategoryId.map((id) => parseInt(id));
        const updatedData = {
            product: {
                title: modalData?.product?.title,
                metaTitle: modalData?.product?.metaTitle,
                slug: modalData?.product?.slug,
                summary: modalData?.product?.summary,
                type: parseInt(modalData?.product?.type),
                publishedAt: modalData?.product?.publishedAt,
                startsAt: null || modalData?.product?.startsAt,
                endsAt: null || modalData?.product?.endsAt,
                content: modalData?.product?.content,
                isActive: true,
            },
            productCategoryBridges: integerCategoryIds.map((categoryId) => ({
                categoryId,
            })),
            productVariants: modalData?.productVariants,
            productImages: modalData?.productImages?.map((image) => ({
                imageURL: image.imageURL,
            })),
        };

        dispatch(updateOne({ id: modalData?.product?.id, data: updatedData }));
        setOpenEditModal(false);
    };

    const handleVariantChange = (e, index, name) => {
        e.persist();
        const value =
            name === 'discount' || name === 'stockQuantity'
                ? parseInt(e.target.value)
                : e.target.value;
        setModalData((prevModalData) => {
            const updatedVariants = prevModalData.productVariants.map(
                (variant, i) => {
                    if (i === index) {
                        return {
                            ...variant,
                            [name]: value,
                        };
                    }
                    return variant;
                }
            );
            return {
                ...prevModalData,
                productVariants: updatedVariants,
            };
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        
        const updatedVariants = modalData.productVariants.map((variant) => {
            return {
                variantName: variant.variantName,
                sku: variant.sku,
                actualPrice: parseInt(variant.actualPrice),
                displayPrice: parseInt(variant.displayPrice),
                discount: parseInt(variant.discount),
                stockQuantity: parseInt(variant.stockQuantity),
            };
        });

        if (
            productVariants?.variantName !== '' &&
            productVariants?.sku !== '' &&
            productVariants?.actualPrice !== '' &&
            productVariants?.displayPrice !== '' &&
            productVariants?.discount !== '' &&
            productVariants?.stockQuantity !== ''
        ) {
            updatedVariants.push(productVariants);
            toast.success('Variant added successfully!');
        }

        setModalData((prevVariant) => ({
            ...prevVariant,
            productVariants: updatedVariants,
        }));

        setProductVariants(productVariantTemplate);
        setVariantEditModal(false);
    };

    const handleDeleteVariant = (index) => {
        setModalData((prevModalData) => {
            const updatedVariants = [...prevModalData.productVariants];
            updatedVariants.splice(index, 1); // Remove the variant at the specified index
            toast.success('Variant deleted successfully!');

            return {
                ...prevModalData,
                productVariants: updatedVariants,
            };
        });
    };

    function handleEditButton() {
        handleUpdate();
    }

    const handleOpenEditVariant = (item) => {
        setModalData(item);
        setVariantEditModal(true);
    };
    const handleOpenEditImage = (index) => {
        setModalData(index);
        setImageEditModal(true);
    };

    const handleCloseEditImage = () => {
        setImageEditModal(false);
    };

    const handleCloseEditVariant = () => {
        setVariantEditModal(false);
    };

    const handleQuillChange = (value) => {
        setModalData((prevData) => ({
            ...prevData,
            product: { ...modalData.product, summary: value },
        }));
    };

    const handleDeleteImage = (index) => {
        setModalData((prev) => {
            return {
                ...prev,
                productImages: prev?.productImages?.filter(
                    (_, idx) => idx !== index
                ),
            };
        });
    };

    const handleProductImage = async (newFile, index) => {
        if (newFile === undefined) {
            setModalData((prevModalData) => ({
                ...prevModalData,
                productImages: '',
            }));
            return;
        }

        try {
            const res = await handleMediaFile(newFile);
            const newImageURL = res.file_url;
            const updatedImages = modalData?.productImages.map((image, idx) =>
                index === idx
                    ? { ...image, imageURL: newImageURL }
                    : image
            );
            setModalData((prevModalData) => ({
                ...prevModalData,
                productImages: updatedImages,
            }));

            toast.success('Image uploaded successfully!');
        } catch (error) {
            console.error('Error updating product image:', error);
        }
    };

    const handleNewImage = async (file) => {
        if (file === undefined) {
            setNewImage('');
            return;
        }

        try {
            setIsImageUploading(true);
            const res = await handleMediaFile(file);

            setNewImage(res.file_url);
            toast.success('Image uploaded successfully!');
            setIsImageUploading(false);
        } catch (error) {
            console.error('Error updating product image:', error);
        }
    };

    const handleSelectChange = (e) => {
        const selectedValues = Array.from(
            e.target.selectedOptions,
            (option) => option.value
        );
        setSelectedCategoryId(selectedValues);
    };

    const handleAddImage = () => {
        if (newImage !== '') {
            setModalData((prev) => {
                return {
                    ...prev,
                    productImages: [
                        ...prev.productImages,
                        { imageURL: newImage },
                    ],
                };
            });
            toast.success('Image added successfully!');
        }

        setNewImage('');
        setImageEditModal(false);
    };

    const handleDeleteProduct = async (id) => {
        dispatch(await deleteOne(id));
    };

    return (
        <>
            <Toaster />

            {/* Main Product Modal */}
            <Modal
                isOpen={openEditModal}
                onClose={() => setOpenEditModal(false)}
            >
                <div>
                    <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                    >
                        Product :
                    </label>
                    <Input
                        label="Product Title"
                        isRequired
                        defaultValue={modalData?.product?.title}
                        onChange={(e) =>
                            setModalData({
                                ...modalData,
                                product: {
                                    ...modalData.product,
                                    title: e.target.value,
                                },
                            })
                        }
                    />
                    <Input
                        defaultValue={modalData?.product?.metaTitle}
                        onChange={(e) =>
                            setModalData({
                                ...modalData,
                                product: {
                                    ...modalData.product,
                                    metaTitle: e.target.value,
                                },
                            })
                        }
                        label="Meta Title (same as title)"
                        isRequired
                    />
                    <Input
                        onChange={(e) =>
                            setModalData({
                                ...modalData,
                                product: {
                                    ...modalData.product,
                                    slug: e.target.value,
                                },
                            })
                        }
                        defaultValue={modalData?.product?.slug}
                        label="Slug (same as title)"
                        isRequired
                    />
                    <ReactQuill
                        style={{
                            marginLeft: 15,
                            marginRight: 15,
                            background: '#fff',
                        }}
                        id="quill-toolbar"
                        className="ql-container"
                        ref={editor}
                        modules={constants.modules}
                        formats={constants.formats}
                        defaultValue={modalData?.product?.summary}
                        onChange={handleQuillChange}
                    />
                    {/* <Input
                            onChange={(e) =>
                                setModalData({
                                ...modalData,
                                product: { ...modalData.product, type: e.target.value },
                                })
                            }
                            defaultValue={modalData?.product?.type}
                            label="Product Type"
                            /> */}
                    {/* <Input
                            type="date"
                            onChange={(e) =>
                                setModalData({
                                ...modalData,
                                product: { ...modalData.product, publishedAt: e.target.value },
                                })
                            }
                            defaultValue={formatDate(modalData?.product?.publishedAt)}
                            label="Published At"
                        /> */}
                    <Input
                        type="date"
                        onChange={(e) =>
                            setModalData({
                                ...modalData,
                                product: {
                                    ...modalData.product,
                                    startsAt: e.target.value,
                                },
                            })
                        }
                        defaultValue={formatDate(modalData?.product?.startsAt)}
                        label='Starts At (To show the product upon creation until stock ends, keep the "Starts At" and "Ends At" fields blank)'
                        isRequired
                    />
                    <Input
                        type="date"
                        onChange={(e) =>
                            setModalData({
                                ...modalData,
                                product: {
                                    ...modalData.product,
                                    endsAt: e.target.value,
                                },
                            })
                        }
                        defaultValue={formatDate(modalData?.product?.endsAt)}
                        label="Ends At"
                        isRequired
                    />
                    {/* <Input
                            onChange={(e) =>
                                setModalData({
                                ...modalData,
                                product: { ...modalData.product, content: e.target.value },
                                })
                            }
                            defaultValue={modalData?.product?.content}
                            label="Product Content"
                        /> */}
                    <div>
                        <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                        >
                            Product Category :
                        </label>
                        <div className="flex flex-wrap">
                            <div className="w-full lg:w-12/12 px-4">
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        Category List
                                    </label>
                                    {Array.isArray(categoryList) &&
                                    categoryList?.length > 0 ? (
                                        <select
                                            multiple
                                            onChange={handleSelectChange}
                                            defaultValue={
                                                modalData
                                                    ?.productCategoryBridges
                                                    ?.category || []
                                            }
                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        >
                                            <option disabled>
                                                Select Category
                                            </option>
                                            {categoryList?.map(
                                                (category, index) => (
                                                    <option
                                                        key={index}
                                                        value={category.id}
                                                    >
                                                        {category.title}
                                                    </option>
                                                )
                                            )}
                                        </select>
                                    ) : (
                                        <p>NO DATA</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                        >
                            Product Variant :
                            <button
                                className={styles.openButton}
                                onClick={() => handleOpenEditVariant(modalData)}
                            >
                                <i className="fas fa-plus"></i>
                            </button>
                        </label>
                    </div>
                    <div>
                        <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                        >
                            Product Images :
                            <button
                                className={styles.openButton}
                                onClick={() => handleOpenEditImage(modalData)}
                            >
                                <i className="fas fa-plus"></i>
                            </button>
                        </label>
                    </div>
                    <div className="flex gap-2">
                        <SaveButton
                            handleEditButton={handleEditButton}
                        ></SaveButton>
                    </div>
                </div>
            </Modal>

            {/* Product Variant Modal */}
            <Modal isOpen={variantEditModal} onClose={handleCloseEditVariant}>
                <div className="block w-full overflow-x-auto">
                    <table className="items-center w-full bg-transparent border-collapse">
                        <thead>
                            <tr>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Product ID
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Variant Name
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Sku
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Actual Price
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Display Price
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Discount
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Stock Quantity
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {modalData?.productVariants?.map(
                                (variant, index) => (
                                    <tr key={index}>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {modalData?.product?.id}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            <input
                                                type="text"
                                                style={{
                                                    lineHeight: '0.5rem',
                                                    borderRadius: '10px',
                                                }}
                                                name={`variantName-${index}`}
                                                defaultValue={
                                                    variant?.variantName
                                                }
                                                onChange={(e) =>
                                                    handleVariantChange(
                                                        e,
                                                        index,
                                                        'variantName'
                                                    )
                                                }
                                            />
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            <input
                                                style={{
                                                    lineHeight: '0.5rem',
                                                    borderRadius: '10px',
                                                }}
                                                type="text"
                                                name={`sku-${index}`}
                                                defaultValue={variant?.sku}
                                                onChange={(e) =>
                                                    handleVariantChange(
                                                        e,
                                                        index,
                                                        'sku'
                                                    )
                                                }
                                            />
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            <input
                                                style={{
                                                    lineHeight: '0.5rem',
                                                    borderRadius: '10px',
                                                }}
                                                type="text"
                                                name={`actualPrice-${index}`}
                                                defaultValue={
                                                    variant?.actualPrice
                                                }
                                                onChange={(e) =>
                                                    handleVariantChange(
                                                        e,
                                                        index,
                                                        'actualPrice'
                                                    )
                                                }
                                            />
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            <input
                                                style={{
                                                    lineHeight: '0.5rem',
                                                    borderRadius: '10px',
                                                }}
                                                type="text"
                                                name={`displayPrice-${index}`}
                                                defaultValue={
                                                    variant?.displayPrice
                                                }
                                                onChange={(e) =>
                                                    handleVariantChange(
                                                        e,
                                                        index,
                                                        'displayPrice'
                                                    )
                                                }
                                            />
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            <input
                                                style={{
                                                    lineHeight: '0.5rem',
                                                    borderRadius: '10px',
                                                }}
                                                type="text"
                                                name={`discount-${index}`}
                                                defaultValue={variant?.discount}
                                                onChange={(e) =>
                                                    handleVariantChange(
                                                        e,
                                                        index,
                                                        'discount'
                                                    )
                                                }
                                            />
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            <input
                                                style={{
                                                    lineHeight: '0.5rem',
                                                    borderRadius: '10px',
                                                }}
                                                type="text"
                                                name={`stockQuantity-${index}`}
                                                defaultValue={
                                                    variant?.stockQuantity
                                                }
                                                onChange={(e) =>
                                                    handleVariantChange(
                                                        e,
                                                        index,
                                                        'stockQuantity'
                                                    )
                                                }
                                            />
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            <DeleteButton
                                                handleDeleteButton={() =>
                                                    handleDeleteVariant(index)
                                                }
                                            ></DeleteButton>
                                        </td>
                                    </tr>
                                )
                            )}
                        </tbody>
                    </table>
                    <div className="mt-4">
                        <Input
                            label="Variant Name"
                            onChange={(e) =>
                                setProductVariants({
                                    ...productVariants,
                                    variantName: e.target.value,
                                })
                            }
                            value={productVariants?.variantName}
                            type="text"
                            isRequired
                        />
                        <Input
                            label="SKU"
                            onChange={(e) =>
                                setProductVariants({
                                    ...productVariants,
                                    sku: e.target.value,
                                })
                            }
                            value={productVariants?.sku}
                            type="text"
                            isRequired
                        />
                        <Input
                            label="Actual Price"
                            onChange={(e) =>
                                setProductVariants({
                                    ...productVariants,
                                    actualPrice: e.target.value,
                                })
                            }
                            value={productVariants?.actualPrice}
                            type="text"
                            isRequired
                        />
                        <Input
                            label="Display Price"
                            onChange={(e) =>
                                setProductVariants({
                                    ...productVariants,
                                    displayPrice: e.target.value,
                                })
                            }
                            value={productVariants?.displayPrice}
                            type="text"
                            isRequired
                        />
                        <Input
                            label="Discount"
                            onChange={(e) =>
                                setProductVariants({
                                    ...productVariants,
                                    discount: e.target.value,
                                })
                            }
                            value={productVariants?.discount}
                            type="text"
                            isRequired
                        />
                        <Input
                            label="Stock Quantity"
                            onChange={(e) =>
                                setProductVariants({
                                    ...productVariants,
                                    stockQuantity: e.target.value,
                                })
                            }
                            value={productVariants?.stockQuantity}
                            type="text"
                            isRequired
                        />
                    </div>
                    <div className="flex gap-2 mt-4">
                        <button
                            onClick={handleSubmit}
                            className="bg-pink text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                            type="button"
                        >
                            Save
                        </button>
                    </div>
                </div>
            </Modal>

            {/* Product Image Modal */}
            <Modal isOpen={imageEditModal} onClose={handleCloseEditImage}>
                <table className="items-center w-full bg-transparent border-collapse">
                    <thead>
                        <tr>
                            <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                Image Preview
                            </th>
                            <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                Upload New Image
                            </th>
                            <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                Delete Image
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {modalData?.productImages?.map((image, index) => (
                            <tr key={index}>
                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                    <div>
                                        <img
                                            src={image?.imageURL}
                                            className="w-20 aspect-square"
                                            alt={`product-${index}`}
                                        />
                                    </div>
                                </td>
                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                    <PictureUpload
                                        onChange={(newFile) => handleProductImage(newFile, index)}
                                        defaultValue={
                                            modalData?.productImages?.imageURL
                                        }
                                    />
                                </td>
                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                    <DeleteButton
                                        handleDeleteButton={() =>
                                            handleDeleteImage(index)
                                        }
                                    ></DeleteButton>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="mt-4">
                    {isImageUploading && <Loader />}
                    <PictureUpload
                        label="Image URL"
                        onChange={handleNewImage}
                        defaultImage={newImage}
                        isRequired
                    />
                </div>
                <button
                    onClick={handleAddImage}
                    disabled={isImageUploading}
                    className="bg-pink text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 mt-4 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    type="button"
                >
                    Save
                </button>
            </Modal>

            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
                <div className="rounded-t mb-0 px-4 py-4 border-0">
                    <div className="flex flex-wrap items-center">
                        <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                            <h3 className="font-semibold text-lg text-blueGray-700">
                                Products
                            </h3>
                        </div>
                        <button
                            onClick={handleOpenModal}
                            className="bg-pink text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                            type="button"
                        >
                            Add New
                        </button>
                    </div>
                </div>
                <div className="block w-full overflow-x-auto">
                    {/* Product table */}
                    <table className="items-center w-full bg-transparent border-collapse">
                        <thead>
                            <tr>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    ID
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Title
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Meta Title
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Slug
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Summary
                                </th>
                                {/* <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    PublishedAt
                                </th> */}
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Starts At
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Ends At
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Category Title
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Category Slug
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Category Details
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Variant Name
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    SKU
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Actual Price
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Display Price
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Discount
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Stock Quantity
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Image URL
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Is Active
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Created By
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Created At
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Updated At
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Updated By
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                            {loading && (
                                <tbody>
                                    <tr>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            <div className="flex items-center justify-center h-20">
                                                <Loader />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            )}
                            <tbody>
                                {Data?.map((item) => (
                                    <tr key={item?.product?.id}>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {item?.product?.id}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {item?.product?.title}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {item?.product?.metaTitle}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {item?.product?.slug}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {constants.trimText(
                                                item?.product?.summary,
                                                MAX_LENGTH
                                            )}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {item?.product?.startsAt
                                                ? item?.product?.startsAt
                                                : 'null'}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {item?.product?.endsAt
                                                ? item?.product?.endsAt
                                                : 'null'}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {item?.productCategoryBridges
                                                ?.map(
                                                    (bridge) =>
                                                        bridge?.category?.title
                                                )
                                                ?.join(', ')}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {item?.productCategoryBridges
                                                ?.map(
                                                    (bridge) =>
                                                        bridge?.category?.slug
                                                )
                                                ?.join(', ')}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {item?.productCategoryBridges
                                                ?.map(
                                                    (bridge) =>
                                                        bridge?.category
                                                            ?.categoryDetails
                                                )
                                                ?.join(', ')}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {item?.productVariants
                                                ?.map(
                                                    (bridge) =>
                                                        bridge?.variantName
                                                )
                                                ?.join(', ')}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {item?.productVariants
                                                ?.map((bridge) => bridge?.sku)
                                                ?.join(', ')}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {item?.productVariants
                                                ?.map(
                                                    (bridge) =>
                                                        bridge?.actualPrice
                                                )
                                                ?.join(', ')}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {item?.productVariants
                                                ?.map(
                                                    (bridge) =>
                                                        bridge?.displayPrice
                                                )
                                                ?.join(', ')}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {item?.productVariants
                                                ?.map(
                                                    (bridge) => bridge?.discount
                                                )
                                                ?.join(', ')}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {item?.productVariants
                                                ?.map(
                                                    (bridge) =>
                                                        bridge?.stockQuantity
                                                )
                                                ?.join(', ')}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {item?.productImages?.length ===
                                                0 && 'N/A'}
                                            {item?.productImages?.map(
                                                (image, index) => (
                                                    <div key={index}>
                                                        {image?.imageURL}
                                                    </div>
                                                )
                                            )}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {item?.product?.isActive
                                                ? 'Active'
                                                : 'Inactive'}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {item?.product?.createdBy}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {new Date(
                                                item?.product?.createdAt
                                            ).toLocaleString()}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {new Date(
                                                item?.product?.updatedAt
                                            ).toLocaleString()}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {item?.product?.updatedBy}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            <div className="flex justify-evenly">
                                                <button
                                                    onClick={() =>
                                                        handleOpenEditModal(
                                                            item
                                                        )
                                                    }
                                                    className="focus:outline-none	"
                                                    type="button"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="h-5 w-5 mr-2 "
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                        strokeWidth="2"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                                        />
                                                    </svg>
                                                </button>
                                                <DeleteButton
                                                    handleDeleteButton={() =>
                                                        handleDeleteProduct(
                                                            item?.product?.id
                                                        )
                                                    }
                                                ></DeleteButton>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default ProductTable;
