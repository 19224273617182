import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createDailyActivities,
  getDailyActivities,
  updateDailyActivities,
  deleteDailyActivities,
} from "api/dailyActivitiesAPI";

export const getNewDailyActivity = createAsyncThunk(
  "daily-activity/list",
  async () => {
    try {
      const res = await getDailyActivities();

      if (res.success) {
        return res.data;
      } else {
        throw new Error("Error fetching data.");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const createNewDailyActivity = createAsyncThunk(
  "daily-activity/create-one",
  async (data) => {
    try {
      const res = await createDailyActivities(data);
      return res.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateNewDailyActivity = createAsyncThunk(
  "daily-activity/update-one",
  async (data) => {
    try {
      const res = await updateDailyActivities(data);
      return res.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteNewDailyActivity = createAsyncThunk(
  "daily-activity/delete-one",
  async (id) => {
    try {
      const res = await deleteDailyActivities(id);
      if (res.success) {
        return id;
      } else {
        throw new Error("Error updating seminar.");
      }
    } catch (error) {
      throw error;
    }
  }
);

const dailyActivitiesslice = createSlice({
  name: "dailyactivity",
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducer: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNewDailyActivity.pending, (state) => {
        state.loading = true;
      })
      .addCase(getNewDailyActivity.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getNewDailyActivity.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createNewDailyActivity.pending, (state) => {
        state.loading = true;
      })
      .addCase(createNewDailyActivity.fulfilled, (state, action) => {
        state.loading = false;
        if(action.payload) {
          state.data.push(action.payload);
        }
      })
      .addCase(createNewDailyActivity.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateNewDailyActivity.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateNewDailyActivity.fulfilled, (state, action) => {
        state.loading = false;
        const dailyActivityIndex = state.data.findIndex(
          (dailyActivity) => dailyActivity.id === action.payload.id
        );
        if (dailyActivityIndex !== -1) {
          state.data[dailyActivityIndex] = action.payload;
        }
      })
      .addCase(updateNewDailyActivity.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteNewDailyActivity.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteNewDailyActivity.fulfilled, (state, action) => {
        state.loading = false;
        state.data = state.data.filter(
          (dailyActivity) => dailyActivity.id !== action.payload
        );
      })
      .addCase(deleteNewDailyActivity.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const dailyActivityAction = dailyActivitiesslice.actions;
export default dailyActivitiesslice;
