import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getOrder, updateOrder } from 'api/orderAPI';

export const getOrderList = createAsyncThunk(
    'order/list-all',
    async (status) => {
        try {
            const res = await getOrder(status);
            if (res.success) {
                return res.data;
            } else {
                throw new Error('Error fetching data');
            }
        } catch (error) {
            throw error;
        }
    }
);

export const updateOne = createAsyncThunk('order/update-one', async (data) => {
    const prevStatus = data.body?.prevStatus;

    try {
        const res = await updateOrder(data);
        if (res.success) {
            const allOrders = await getOrder(prevStatus);
            return allOrders.success ? allOrders.data : [];
        } else {
            throw new Error('Error updating a Order.');
        }
    } catch (error) {
        throw error;
    }
});

const orderSlice = createSlice({
    name: 'order',
    initialState: {
        data: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            //* GET
            .addCase(getOrderList.pending, (state) => {
                state.loading = true;
            })
            .addCase(getOrderList.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload || [];
            })
            .addCase(getOrderList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            //* UPDATE
            .addCase(updateOne.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateOne.fulfilled, (state, action) => {
                state.loading = false;
                if (action.payload) {
                    //! The data is fetched again from the server, so directly assign the data
                    state.data = action.payload;
                }
            })
            .addCase(updateOne.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const orderAction = orderSlice.actions;
export default orderSlice;


