export const constants = {
  monthsOptions: [
    { id: 1, value: "1" },
    { id: 2, value: "2" },
    { id: 3, value: "3" },
    { id: 4, value: "4" },
    { id: 5, value: "5" },
    { id: 6, value: "6" },
    { id: 7, value: "7" },
    { id: 8, value: "8" },
    { id: 9, value: "9" },
    { id: 10, value: "10" },
    { id: 11, value: "11" },
    { id: 12, value: "12" },
  ],

  trimText: (text, maxLength) => {
    if (text?.length <= maxLength) {
      return text;
    } else {
      return text?.slice(0, maxLength) + "...";
    }
  },

  category: [
    { value: "General", id: "0" },
    { value: "Pre Pregnancy", id: "1" },
    { value: "Pregnant", id: "2" },
    { value: "Parenting", id: "3" },
  ],
  blogCategory: [
    { value: "General", id: "0" },
    { value: "Pre Pregnancy", id: "1" },
    { value: "Pregnant", id: "2" },
    { value: "Parenting", id: "3" },
  ],
  DietUserCategory: [
    { value: "Pre Pregnancy", id: "1" },
    { value: "Pregnant", id: "2" },
    { value: "Parenting", id: "3" },
  ],
  dailyActivityCategory: [
    { value: "Pre Pregnancy", id: "1" },
    { value: "Pregnant", id: "2" },
    { value: "Post Pregnancy", id: "3" },
  ],
  LiveSeminarCategory: [
    { value: "Free User", id: "0" },
    { value: "Pre Pregnancy", id: "1" },
    { value: "Pregnant", id: "2" },
    { value: "Parenting", id: "3" },
  ],
  gender: [
    { value: "General", id: "0" },
    { value: "Female", id: "1" },
    { value: "Male", id: "2" },
  ],

  mediaOption: [
    { value: "No Media", id: "0" },
    { value: "Image", id: "1" },
    { value: "Audio", id: "2" },
    { value: "Video", id: "3" },
  ],
  garbhaSamvadCategory: [
    { value: "Pre-Pregnancy User", id: "1" },
    { value: "Pregnant User", id: "2" },
  ],

  garbhaType: [
    { value: "Monthly", id: "1" },
    { value: "Situational", id: "2" },
  ],

  garbhaMood: [
    { value: "Happy", id: "1" },
    { value: "Anxious", id: "2" },
    { value: "Sad", id: "3" },
    { value: "Excited", id: "4" },
    { value: "Curious", id: "5" },
    { value: "Romantic", id: "6" },
    { value: "Fatigue", id: "7" },
  ],
  type: [
    { value: "Only Website", id: "0" },
    { value: "Both Website & App", id: "1" },
  ],
  modules: {
    toolbar: [
      [{ header: "1" }, { header: "2" }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
      [
        {
          color: [
            "#000000",
            "#e60000",
            "#ff9900",
            "#ffff00",
            "#008a00",
            "#0066cc",
            "#9933ff",
            "#ffffff",
            "#facccc",
            "#ffebcc",
            "#ffffcc",
            "#cce8cc",
            "#cce0f5",
            "#ebd6ff",
            "#bbbbbb",
            "#f06666",
            "#ffc266",
            "#ffff66",
            "#66b966",
            "#66a3e0",
            "#c285ff",
            "#888888",
            "#a10000",
            "#b26b00",
            "#b2b200",
            "#006100",
            "#0047b2",
            "#6b24b2",
            "#444444",
            "#5c0000",
            "#663d00",
            "#666600",
            "#003700",
            "#002966",
            "#3d1466",
            "custom-color",
          ],
        },
      ],
    ],
  },

  formats: [
    "header",
    "height",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "color",
    "bullet",
    "indent",
    "link",
    "image",
    "align",
    "size",
  ],
};
