import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getBabyMessage,
  createBabyMessage,
  updateBabyMessage,
  deleteBabyMessage,
} from "api/babyMessageAPI";

// Async thunk to fetch baby Message list
export const getNewBabyMessage = createAsyncThunk(
  "babymessage/list",
  async () => {
    try {
      const res = await getBabyMessage();
      if (res.success) {
        return res.data;
      } else {
        throw new Error("Error fetching baby Message.");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const createNewBabyMessage = createAsyncThunk(
  "babymessage/create-one",
  async (data) => {
    try {
      const res = await createBabyMessage(data);
      return res.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateNewBabyMessage = createAsyncThunk(
  "babymessage/update-one",
  async (data) => {
    try {
      const res = await updateBabyMessage(data);
      return res.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteNewBabyMessage = createAsyncThunk(
  "babymessage/delete-one",
  async (id) => {
    try {
      const res = await deleteBabyMessage(id);
      if (res.success) {
        return id;
      } else {
        throw new Error("Error deleting Baby Message.");
      }
    } catch (error) {
      throw error;
    }
  }
);

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const babyMessageSlice = createSlice({
  name: "babyMessage",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNewBabyMessage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getNewBabyMessage.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.data = action.payload;
      })
      .addCase(getNewBabyMessage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createNewBabyMessage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createNewBabyMessage.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        if(action.payload) {
          state.data.push(action.payload);
        }
      })
      .addCase(createNewBabyMessage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateNewBabyMessage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateNewBabyMessage.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        const updatedbabyIndex = state.data.findIndex(
          (baby) => baby.id === action.payload.id
        );
        if (updatedbabyIndex !== -1) {
          state.data[updatedbabyIndex] = action.payload;
        }
      })
      .addCase(updateNewBabyMessage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteNewBabyMessage.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteNewBabyMessage.fulfilled, (state, action) => {
        state.loading = false;
        state.data = state.data.filter((baby) => baby.id !== action.payload);
      })
      .addCase(deleteNewBabyMessage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const babyMessageAction = babyMessageSlice.actions;

export default babyMessageSlice;
