import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createGarbha,
  getGarbha,
  updateGarbha,
  deleteGarbha,
} from "api/garbhaSamvadAPI";

export const getList = createAsyncThunk("garbha-samvad/list", async () => {
  try {
    const res = await getGarbha();
    if (res.success) {
      return res.data;
    } else {
      throw new Error("Error fetching Diet.");
    }
  } catch (error) {
    throw error;
  }
});

export const createNewGarbha = createAsyncThunk(
  "garbha-samvad/create-one",
  async (data) => {
    try {
      const res = await createGarbha(data);
      if (res.success) {
        return res.data;
      } else {
        throw new Error("Error creating seminar.");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const updateOneGarbha = createAsyncThunk(
  "garbha-samvad/update-one",
  async (data) => {
    try {
      const res = await updateGarbha(data);
      if (res.success) {
        return res.data;
      } else {
        throw new Error("Error updating seminar.");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const deleteOneGarbha = createAsyncThunk(
  "garbha-samvad/delete-one",
  async (id) => {
    try {
      const res = await deleteGarbha(id);
      if (res.success) {
        return id;
      } else {
        throw new Error("Error deleting seminar.");
      }
    } catch (error) {
      throw error;
    }
  }
);

const garbhaSlice = createSlice({
  name: "garbhaSamvad",
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(createNewGarbha.pending, (state) => {
        state.loading = true;
      })
      .addCase(createNewGarbha.fulfilled, (state, action) => {
        state.loading = false;
        if(action.payload) {
          state.data.push(action.payload);
        }
      })
      .addCase(createNewGarbha.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(updateOneGarbha.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateOneGarbha.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        const garbhaIndex = state.data.findIndex(
          (seminar) => seminar.id === action.payload.id
        );
        if (garbhaIndex !== -1) {
          state.data[garbhaIndex] = action.payload;
        }
      })
      .addCase(updateOneGarbha.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(deleteOneGarbha.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteOneGarbha.fulfilled, (state, action) => {
        state.loading = false;
        state.data = state.data.filter(
          (seminar) => seminar.id !== action.payload
        );
      })
      .addCase(deleteOneGarbha.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const garbhaAction = garbhaSlice.actions;
export default garbhaSlice;
