import { config } from "./confg";
import { axiosCallAdvanced } from "./main";

export const getBrainActivity = async () => {
  return (
    (
      await axiosCallAdvanced({
        baseURL: config.brainActivity.root,
        path: config.brainActivity.list,
      })
    )?.data || {}
  );
};
export const createBrainActivity = async (data) => {
  return (
    (
      await axiosCallAdvanced({
        baseURL: config.brainActivity.root,
        path: config.brainActivity.createOne,
        body: { ...data },
        method: config.methodType.post,
      })
    )?.data || {}
  );
};

export const deleteBrainActivity = async (parameters) => {
  return (
    (
      await axiosCallAdvanced({
        baseURL: config.brainActivity.root,
        path: `${config.brainActivity.deleteOne}/${parameters}`,
        method: config.methodType.patch,
      })
    )?.data || {}
  );
};

export const updateBrainActivity = async (id, data) => {
  return (
    (
      await axiosCallAdvanced({
        baseURL: config.brainActivity.root,
        path: `${config.brainActivity.updateOne}/${id}`,
        body: { ...data },
        method: config.methodType.put,
      })
    )?.data || {}
  );
};
