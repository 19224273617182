import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getExpectedDeliveryDate,
  updateExpectedDeliveryDate,
  deleteDeliveryDate,
} from "api/deliveryDateAPI";

export const getDeliveryDateList = createAsyncThunk(
  "deliveryDate/list",
  async () => {
    try {
      const res = await getExpectedDeliveryDate();
      if (res.success) {
        return res.data;
      } else {
        throw new Error("Error Fetching Data");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const updateDeliveryDate = createAsyncThunk(
  "deliveryDate/admin-update",
  async ({ id, data }) => {
    try {
      const res = await updateExpectedDeliveryDate(id, data.date);
      if (res.success) {
        return res.data;
      } else {
        throw new Error("Error Updating Data");
      }
    } catch (error) {
      throw error;
    }
  }
);

const deliveryDateSlice = createSlice({
  name: "deliveryDate",
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducer: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDeliveryDateList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDeliveryDateList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getDeliveryDateList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateDeliveryDate.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateDeliveryDate.fulfilled, (state, action) => {
        state.loading = false;

        //only update the date

        const deliveryDateIndex = state.data.findIndex(
          (date) => date.id === action.payload.id
        );
        if (deliveryDateIndex !== -1) {
          state.data[deliveryDateIndex] = {
            ...state.data[deliveryDateIndex],
            date: action.payload.date,
          };
        }
      })
      .addCase(updateDeliveryDate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const deliveryDateAction = deliveryDateSlice.actions;
export default deliveryDateSlice;
