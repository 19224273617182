import axios from "axios";
import { config } from "./confg";
import { axiosCallAdvanced } from "./main";

const data = localStorage.getItem("userData");

export const getSoulMessage = async () => {
  return (
    (
      await axiosCallAdvanced({
        baseURL: config.soulMessage.root,
        path: config.soulMessage.getSoulMessage,
      })
    )?.data || {}
  );
};

export const createSoulMessage = async (data) => {
  return (
    (
      await axiosCallAdvanced({
        baseURL: config.soulMessage.root,
        path: config.soulMessage.createSoulMessage,
        body: { ...data },
        method: config.methodType.post,
      })
    )?.data || {}
  );
};

export const updateSoulMessage = async (parameters) => {
  return (
    (
      await axiosCallAdvanced({
        baseURL: config.soulMessage.root,
        path: `${config.soulMessage.updateSoulMessage}/${parameters.id}`,
        body: parameters.body,
        method: config.methodType.put,
      })
    )?.data || {}
  );
};

export const deleteSoulMessage = async (id) => {
  return (
    (
      await axiosCallAdvanced({
        baseURL: config.soulMessage.root,
        path: `${config.soulMessage.deleteSoulMessage}/${id}`,
        method: config.methodType.patch,
      })
    )?.data || {}
  );
};
