import React, { useState } from "react";
import "../assets/styles/trackerContainer.css"; // Import your CSS file for styling

const Stepper = ({ steps, activeStep }) => {
  return (
    <div className="stepper-container">
      {steps.map((step, index) => (
        <div
          key={index}
          className={`step ${index === activeStep ? "active" : ""}`}
        >
          <div className="circle">{index + 1}</div>
          <div className="label">{step}</div>
        </div>
      ))}
    </div>
  );
};

export default Stepper;
