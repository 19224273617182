import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getBlog, createBlog, updateBlog, deleteBlog } from "api/blogsAPI";

export const getBlogList = createAsyncThunk("blogs/list", async () => {
  try {
    const res = await getBlog();
    if (res.success) {
      return res.data;
    } else {
      throw new Error("Error fetching Blogs.");
    }
  } catch (error) {
    throw error;
  }
});
export const createOneBlog = createAsyncThunk(
  "blogs/create-one",
  async (data) => {
    try {
      const res = await createBlog(data);
      if (res.success) {
        return res.data;
      } else {
        throw new Error("Error creating Blog.");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const deleteOneBlog = createAsyncThunk(
  "blogs/delete-one",
  async (id) => {
    try {
      const res = await deleteBlog(id);
      if (res.success) {
        return id;
      } else {
        throw new Error("Error deleting Blog.");
      }
    } catch (error) {
      throw error;
    }
  }
);
export const updateOneBlog = createAsyncThunk(
  "blogs/update-one",
  async ({ id, data }) => {
    try {
      const res = await updateBlog(id, data);
      if (res.success) {
        return res.data;
      } else {
        throw new Error("Error updating Blog.");
      }
    } catch (error) {
      throw error;
    }
  }
);

const blogsSlice = createSlice({
  name: "blogs",
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBlogList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBlogList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getBlogList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createOneBlog.pending, (state) => {
        state.loading = true;
      })
      .addCase(createOneBlog.fulfilled, (state, action) => {
        state.loading = false;
        state.data.push(action.payload);
      })
      .addCase(createOneBlog.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateOneBlog.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateOneBlog.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        const blogsIndex = state.data.findIndex(
          (blogs) => blogs.id === action.payload.id
        );
        if (blogsIndex !== -1) {
          state.data[blogsIndex] = action.payload;
        }
      })

      .addCase(updateOneBlog.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteOneBlog.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteOneBlog.fulfilled, (state, action) => {
        state.loading = false;
        state.data = state.data.filter((blogs) => blogs.id !== action.payload);
      })
      .addCase(deleteOneBlog.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const blogsAction = blogsSlice.actions;
export default blogsSlice;
