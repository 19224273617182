import React from "react";
import { Switch, Route, Redirect } from 'react-router-dom';
import Login from 'views/auth/Login.js';
// import OTP from "views/auth/OTP";

export default function Auth() {
    return (
        <>
            <main>
                <section className="relative w-full h-full py-40 min-h-screen">
                    <div
                        className="absolute top-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-full"
                        style={
                            {
                                // backgroundImage:
                                //   "url(" + require("assets/img/register_bg_2.png").default + ")",
                            }
                        }
                    ></div>
                    <Switch>
                        <Route exact path="/auth">
                            <Redirect to="/auth/login" />
                        </Route>
                        <Route 
                            path="/auth/login" 
                            exact 
                            component={Login} 
                        />
                        <Route
                            path="/auth/verify-otp"
                            exact
                            component={Login}
                        />
                    </Switch>
                    {/* <FooterSmall absolute /> */}
                </section>
            </main>
        </>
    );
}
