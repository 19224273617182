import { config } from "./confg";
import { axiosCallAdvanced } from "./main";

export const getPremiumUser = async () => {
  return (
    (
      await axiosCallAdvanced({
        baseURL: config.userDetails.root,
        path: config.userDetails.listPremium,
      })
    )?.data || {}
  );
};
