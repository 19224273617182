import React, { useState, useRef } from "react";
import ReactQuill from "react-quill";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import "assets/styles/richText.css";
import Modal from "lib/Modal";
import Input from "lib/Input";
import PictureUpload from "lib/PictureUpload";
import Loader from "components/Loader/Loader";
import StoryTable from "components/Cards/StoryTable";
import { constants } from "utils/constants";
import { handleImageFile, handleMediaFile } from "api/fileUploadAPI";
import { createOneStory } from "redux/storySlice";

const Story = () => {
    const dispatch = useDispatch();
    const [modalOpen, setModalOpen] = useState(false);

    const [day, setDay] = useState('');
    const [content, setContent] = useState('');
    const [title, setTitle] = useState('');
    const [thumbnailURL, setThumbnailURL] = useState('');
    const [mood, setMood] = useState('');
    const [userCategory, setUserCategory] = useState('');
    const [mediaURL, setMediaURL] = useState('');
    const [mediaType, setMediaType] = useState('');
    const [isActive, setIsActive] = useState(true);
    const [formError, setFormError] = useState(false);
    const [isIntroLoader, setIsIntroLoader] = useState(false);
    const [isMediaLoader, setIsMediaLoader] = useState(false);
    const editor = useRef(null);

    const [thumbnailOption, setThumbnailOption] = useState(1);
    const [mediaOption, setMediaOption] = useState(1);

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleCreateNew = () => {
        if (
            day !== '' &&
            content !== '' &&
            mood !== '' &&
            thumbnailURL !== '' &&
            title !== '' &&
            mediaType !== '' &&
            userCategory !== ''
        ) {
            const newStory = {
                userCategory: userCategory,
                day: parseInt(day),
                thumbnailURL: thumbnailURL,
                title: title,
                content: content,
                mediaType: mediaType,
                mediaURL: mediaURL,
                mood: mood,
                isActive: isActive,
            };
            dispatch(createOneStory(newStory));
            setModalOpen(false);
            setMood('');
            setDay('');
            setContent('');
            setTitle('');
            setUserCategory('');
            setThumbnailURL('');
            setMediaType('');
            setMediaURL('');
            setFormError(false);
        } else {
            setFormError(true);
        }
    };

    const handleIntroMedia = async (file) => {
        if (file === undefined) {
            setMediaURL('');
            return;
        }

        if (mediaType === '1') {
            try {
                setIsMediaLoader(true);
                const res = await handleImageFile(file);
                if (res.success) {
                    setMediaURL(res.file_url);

                    toast.success('Successfully Uploaded the Image!');
                } else {
                    throw new Error('Picture Not Successfully Uploaded');
                }
                setIsMediaLoader(false);
            } catch (error) {
                toast.error(error);
            }
        } else {
            try {
                setIsMediaLoader(true);
                const res = await handleMediaFile(file);
                if (res.success) {
                    setMediaURL(res.file_url);
                    mediaType === '2'
                        ? toast.success('Successfully Uploaded the Audio!')
                        : toast.success('Successfully Uploaded the video!');
                } else {
                    throw new Error('Picture Not Successfully Uploaded');
                }
                setIsMediaLoader(false);
            } catch (error) {
                toast.error(error);
            }
        }
    };

    const handleThumbnailUrl = async (file) => {
        if (file === undefined) {
            setThumbnailURL('');
            return;
        }

        try {
            setIsIntroLoader(true);
            const res = await handleImageFile(file);
            if (res.success) {
                setThumbnailURL(res.file_url);

                toast.success('Successfully Uploaded the Image!');
            } else {
                throw new Error('Picture Not Successfully Uploaded');
            }
            setIsIntroLoader(false);
        } catch (error) {
            toast.error(error);
        }
    };

    const handleSelectChange = (e) => {
        const selectedValues = Array.from(
            e.target.selectedOptions,
            (option) => option.value
        );
        setMood(selectedValues.toString());
    };

    const handleQuillChange = (value) => {
        setContent(value);
    };

    return (
        <>
            <Modal isOpen={modalOpen} onClose={handleCloseModal}>
                <div>
                    <Input
                        onChange={(e) => setUserCategory(e.target.value)}
                        defaultValue={userCategory}
                        label="User category"
                        isRequired
                        options={constants.dailyActivityCategory}
                        select
                    />
                    <Input
                        onChange={(e) => setDay(parseInt(e.target.value))}
                        defaultValue={day}
                        label="Day"
                        isRequired
                    />
                    <Input
                        label="Thumbnail Options"
                        value={thumbnailOption}
                        onChange={(e) =>
                            setThumbnailOption(parseInt(e.target.value))
                        }
                        options={[
                            { id: 1, value: 'Upload Thumbnail' },
                            { id: 2, value: 'Thumbnail URL' },
                        ]}
                        select
                        isRequired
                    />
                    {isIntroLoader && <Loader />}
                    {thumbnailOption === 1 ? (
                        <PictureUpload
                            label="Thumbnail URL"
                            isRequired
                            defaultValue={thumbnailURL}
                            onChange={handleThumbnailUrl}
                        />
                    ) : (
                        <Input
                            label="Thumbnail URL"
                            type="text"
                            onChange={(e) => setThumbnailURL(e.target.value)}
                            value={thumbnailURL}
                            isRequired
                        />
                    )}
                    <Input
                        onChange={(e) => setTitle(e.target.value)}
                        defaultValue={title}
                        label="Title"
                        isRequired
                    />
                    <div className="flex flex-wrap">
                        <div className="w-full lg:w-12/12 px-4">
                            <div className="relative w-full mb-3">
                                <label
                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                    htmlFor="grid-password"
                                >
                                    Content{' '}
                                    <span className="red-asterisk"></span>
                                </label>
                                <ReactQuill
                                    style={{
                                        background: '#fff',
                                    }}
                                    className="ql-container ql-snow"
                                    ref={editor}
                                    modules={constants.modules}
                                    formats={constants.formats}
                                    defaultValue={content}
                                    onChange={handleQuillChange}
                                />
                            </div>
                        </div>
                    </div>
                    <Input
                        onChange={(e) => setMediaType(e.target.value)}
                        label="Media Type"
                        isRequired
                        defaultValue={mediaType}
                        select
                        options={constants.mediaOption}
                    />
                    {mediaType === '0' ? null : (
                        <Input
                            label="Media Options"
                            value={mediaOption}
                            onChange={(e) =>
                                setMediaOption(parseInt(e.target.value))
                            }
                            options={[
                                { id: 1, value: 'Upload Media' },
                                { id: 2, value: 'Media URL' },
                            ]}
                            select
                            isRequired
                        />
                    )}
                    {isMediaLoader && <Loader />}
                    {mediaType === '0' ? null : mediaOption === 1 ? ( // null for no media
                        <PictureUpload
                            onChange={handleIntroMedia}
                            defaultValue={mediaURL}
                            label={
                                mediaType === '3'
                                    ? 'Intro Video'
                                    : mediaType === '2'
                                    ? 'Intro Audio'
                                    : 'Intro Image'
                            }
                            isRequired
                        />
                    ) : (
                        <Input
                            label={
                                mediaType === '3'
                                    ? 'Intro Video URL'
                                    : mediaType === '2'
                                    ? 'Intro Audio URL'
                                    : 'Intro Image URL'
                            }
                            type="text"
                            onChange={(e) => setMediaURL(e.target.value)}
                            value={mediaURL}
                            isRequired
                        />
                    )}
                    <div className="flex flex-wrap">
                        <div className="w-full lg:w-12/12 px-4">
                            <div className="relative w-full mb-3">
                                <label
                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                    htmlFor="grid-password"
                                >
                                    Mood
                                </label>
                                <select
                                    multiple
                                    onChange={handleSelectChange}
                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                >
                                    {constants.garbhaMood?.map((category) => (
                                        <option key={category.id}>
                                            {category.value}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <Input
                        onChange={(e) => setIsActive(e.target.value)}
                        defaultValue={isActive}
                        options={[
                            { id: true, value: 'Active' },
                            { id: false, value: 'Inactive' },
                        ]}
                        select
                        label="Visibility State"
                        isRequired
                    />
                    {formError && (
                        <p className="mx-4 text-red-500 mb-2">
                            Please fill all the details!
                        </p>
                    )}
                    <div className="flex gap-2">
                        <button
                            onClick={handleCreateNew}
                            className="bg-pink mx-4 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                            type="button"
                        >
                            Save
                        </button>
                    </div>
                </div>
            </Modal>

            <div className="flex flex-wrap mt-4">
                <div className="w-full mb-12 px-4">
                    <StoryTable
                        handleOpenModal={handleOpenModal}
                        color="dark"
                    />
                </div>
            </div>
        </>
    );
};

export default Story;
