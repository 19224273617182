import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, otpVerify } from "redux/userSlice";

export default function Login() {
  const [mobile, setMobile] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [otp, setOtp] = useState("");
  const { loading, error, screenId, isLoggedIn } = useSelector(
    (state) => state.user
  );
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    if (isLoggedIn || token) {
      history.push("/admin/dashboard");
    }
  }, [isLoggedIn, history]);

  const validateMobile = () => {
    const mobileRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    setIsValid(mobileRegex.test(mobile));
    return mobileRegex.test(mobile);
  };

  const onLogin = async () => {
    if (mobile) {
        if (validateMobile) {
            dispatch(loginUser(mobile, history));
        } else {
            alert('Invalid Mobile Number');
        }
    } else {
        alert('Please enter mobile number');
    }
  };

  const onSubmitOtp = () => {
    if (otp.length === 4) {
      dispatch(otpVerify(otp));
    } else {
      alert("Invalid");
    }
  };

  return (
    <>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0 mt-5">
                <div className="text-blueGray-400 text-center mb-3 font-bold">
                  {/* <small>Or sign in with credentials</small> */}
                </div>
                {screenId === 1 ? (
                  <form>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-grey text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Email or Phone
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Email or Phone"
                        value={mobile}
                        onChange={(e) => {
                          setMobile(e.target.value.trim());
                        }}
                      />
                      {error && <p>Something went wrong!</p>}
                    </div>
                    <div className="text-center mt-6">
                      <button
                        className="bg-green text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                        type="button"
                        onClick={onLogin}
                      >
                        {loading ? "Signing In" : "Sign In"}
                      </button>
                    </div>
                  </form>
                ) : (
                  <form>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-grey text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        One-Time Password (OTP)
                      </label>
                      <input
                        minLength={4}
                        onChange={(e) => setOtp(e.target.value.trim())}
                        type="text"
                        value={otp}
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="OTP"
                      />
                      {error && (
                        <div class="alert alert-danger" role="alert">
                          INVALID OTP.
                        </div>
                      )}
                    </div>

                    <div className="text-center mt-6">
                      <button
                        className="bg-green text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                        type="button"
                        onClick={onSubmitOtp}
                      >
                        {loading ? "Verifying OTP..." : "Verify OTP"}
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
