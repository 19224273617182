import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "lib/Modal";
import Input from "lib/Input";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import DeleteButton from "lib/DeleteButton";
import SaveButton from "lib/SaveButton";
import PictureUpload from "lib/PictureUpload";
import { getNewSoulMessage } from "redux/soulMessageSlice";
import { updateNewSoulMessage } from "redux/soulMessageSlice";
import toast, { Toaster } from "react-hot-toast";
import { handleImageFile } from "api/fileUploadAPI";
import Loader from "components/Loader/Loader";
import { deleteNewSoulMessage } from "redux/soulMessageSlice";
import { constants } from "utils/constants";

const MessageFromSoulTable = ({ color, handleOpenModal }) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [modalData, setModalData] = useState({});

  const dispatch = useDispatch();
  const [isIntroLoader, setIsIntroLoader] = useState(false);
  const MAX_LENGTH = 50;
  const soulMessageData = useSelector((state) => state.soulMessage?.data);
  const loading = useSelector((state) => state.soulMessage?.loading);

  const handleOpenEditModal = (item) => {
    setModalData(item);
    setOpenEditModal(true);
  };

  const handleDeleteButton = async (id) => {
    dispatch(await deleteNewSoulMessage(id));
  };

  function handleEditButton() {
    console.log(modalData)
    if (modalData?.day && modalData?.message && modalData?.imageURL) {
      const newSoulData = {
        day: parseInt(modalData?.day),
        message: modalData?.message.trim(),
        imageURL: modalData?.imageURL.trim(),
        isActive: modalData?.isActive,
      };
      dispatch(
        updateNewSoulMessage({ id: modalData.id, body: { ...newSoulData } })
      );
      setOpenEditModal(false);
    }
  }

  const handleIntroMedia = async (file) => {
    // const res = await handleMediaFile(file);
    // setModalData({ ...modalData, imageURL: res.file_url });
    if (file === undefined) {
      setModalData({ ...modalData, imageURL: "" });
      return;
    }
    
    try {
      setIsIntroLoader(true);
      const res = await handleImageFile(file);
      if (res.success) {
        setModalData({ ...modalData, imageURL: res.file_url });
        toast.success("Successfully Uploaded the Image!");
      } else {
        throw new Error("Picture Not Successfully Uploaded");
      }
      setIsIntroLoader(false);
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    dispatch(getNewSoulMessage());
  }, [dispatch]);

  return (
    <>
      {/* Edit modal */}
      <Toaster />
      <Modal isOpen={openEditModal} onClose={() => setOpenEditModal(false)}>
        <div>
          <Input
            defaultValue={modalData?.day}
            onChange={(e) =>
              setModalData({ ...modalData, day: parseInt(e.target.value) })
            }
            label="Day"
            typ="text"
            isRequired
          />
          <Input
            defaultValue={modalData?.message}
            onChange={(e) =>
              setModalData({ ...modalData, message: e.target.value })
            }
            disable
            label="Soul message"
            isRequired
          />

          {isIntroLoader && <Loader />}
          <PictureUpload
            onChange={handleIntroMedia}
            defaultValue={modalData?.imageURL}
            label="Upload Image (Don't upload if you want to keep the image same)"
            isRequired
          />
          <br/>
          <Input
            onChange={(e) =>
              setModalData({ ...modalData, isActive: e.target.value })
            }
            defaultValue={modalData?.isActive}
            options={[
              { id: true, value: "Active" },
              { id: false, value: "Inactive" },
            ]}
            select
            label="Visibility State"
            isRequired
          />
          {/* Additional input fields */}
          {/* ... */}
          <div className="flex gap-2">
            <SaveButton
              // name={"Baby Message"}
              handleEditButton={handleEditButton}
            ></SaveButton>
          </div>
        </div>
      </Modal>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
        <div className="rounded-t mb-0 px-4 py-4 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3 className="font-semibold text-lg text-blueGray-700">
                Message From The Soul
              </h3>
            </div>
            <button
              onClick={handleOpenModal}
              className="bg-pink text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
            >
              Add New
            </button>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  ID
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Day
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Soul message
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Image Url
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Created At
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Created By
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Updated At
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Updated By
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Is Active
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {loading && (
                <tr>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    <div className="flex items-center justify-center h-20">
                      <Loader />
                    </div>
                  </td>
                </tr>
              )}
              {soulMessageData?.map((item) => (
                <tr key={item?.id}>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item?.id}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item?.day}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {constants.trimText(item?.message, MAX_LENGTH)}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item?.imageURL}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {new Date(item?.createdAt).toLocaleString()}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item?.createdBy}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {new Date(item?.updatedAt).toLocaleString()}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item?.updatedBy}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item?.isActive ? "Active" : "Inactive"}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    <div className="flex justify-evenly">
                      <button
                        onClick={() => handleOpenEditModal(item)}
                        className="focus:outline-none	"
                        type="button"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5 mr-2 "
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                          />
                        </svg>
                      </button>
                      <DeleteButton
                        handleDeleteButton={() => {
                          handleDeleteButton(item?.id);
                        }}
                      ></DeleteButton>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

MessageFromSoulTable.defaultProps = {
  color: "light",
};

MessageFromSoulTable.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
  data: PropTypes.array.isRequired,
  handleOpenModal: PropTypes.func.isRequired,
};

export default MessageFromSoulTable;
