import { config } from './confg';
import { axiosCallAdvanced } from './main';

export const getSeminar = async () => {
    return (
        (
            await axiosCallAdvanced({
                baseURL: config.liveSeminar.root,
                path: config.liveSeminar.list,
            })
        )?.data || {}
    );
};
export const createSeminar = async (data) => {
    return (
        (
            await axiosCallAdvanced({
                baseURL: config.liveSeminar.root,
                path: config.liveSeminar.createOne,
                body: { ...data },
                method: config.methodType.post,
            })
        )?.data || {}
    );
};

export const updateSeminar = async (parameters) => {
    return (
        (
            await axiosCallAdvanced({
                baseURL: config.liveSeminar.root,
                path: `${config.liveSeminar.updateOne}/${parameters.id}`,
                body: parameters.body,
                method: config.methodType.put,
            })
        )?.data || {}
    );
};

export const deleteSeminar = async (id) => {
    return (
        (
            await axiosCallAdvanced({
                baseURL: config.liveSeminar.root,
                path: `${config.liveSeminar.deleteOne}/${id}`,
                method: config.methodType.patch,
            })
        )?.data || {}
    );
};
