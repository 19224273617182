import React, { useEffect, useState } from "react";
import Loader from "components/Loader/Loader";
import DeleteButton from "lib/DeleteButton";
import { useDispatch, useSelector } from "react-redux";
import { getTestimonialList } from "redux/testimonialSlice";
import { deleteTestimonials } from "redux/testimonialSlice";
import { updateTestimonials } from "redux/testimonialSlice";
import PictureUpload from "lib/PictureUpload";
import Modal from "lib/Modal";
import Input from "lib/Input";
import SaveButton from "lib/SaveButton";
import { handleImageFile } from "api/fileUploadAPI";
import { Toaster, toast } from "react-hot-toast";
import { constants } from "utils/constants";

const TestimonialsTable = ({ color, handleOpenModal }) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const dispatch = useDispatch();
  const testimonialData = useSelector((state) => state.testimonials.data);
  const loading = useSelector((state) => state.testimonials.loading);
  const [isIntroLoader, setIsIntroLoader] = useState(false);
  const handleOpenEditModal = (item) => {
    setModalData(item);
    setOpenEditModal(true);
  };

  useEffect(() => {
    dispatch(getTestimonialList());
  }, [dispatch]);

  function handleEditButton() {
    handleUpdate();
  }

  const handleUpdate = () => {
    const updatedData = {
      type: modalData?.type,
      name: modalData?.name,
      imageURL: modalData?.imageURL,
      content: modalData?.content,
      designation: modalData?.designation,
      isActive: modalData?.isActive,
    };

    dispatch(updateTestimonials({ id: modalData?.id, data: updatedData }));
    setOpenEditModal(false);
  };

  const handleImageURL = async (file) => {
    try {
      setIsIntroLoader(true);
      const res = await handleImageFile(file);
      if (res.success) {
        setModalData({ ...modalData, imageURL: res.file_url });
        toast.success("Successfully Uploaded the Image!");
      } else {
        throw new Error("Picture Not Successfully Uploaded");
      }
      setIsIntroLoader(false);
    } catch (error) {
      toast.error(error);
    }
  };

  const handleDeleteTestimonial = async (id) => {
    dispatch(await deleteTestimonials(id));
  };

  return (
    <>
      {/* Edit modal */}
      <Toaster />
      <Modal isOpen={openEditModal} onClose={() => setOpenEditModal(false)}>
        <div>
          <Input
            onChange={(e) =>
              setModalData({ ...modalData, type: e.target.value })
            }
            defaultValue={modalData?.type}
            label="Type"
            isRequired
            select
            options={constants.type}
          />
          <Input
            onChange={(e) =>
              setModalData({ ...modalData, name: e.target.value })
            }
            defaultValue={modalData?.name}
            label="Person Name"
            isRequired
          />
          {isIntroLoader && <Loader />}
          <PictureUpload
            onChange={handleImageURL}
            defaultValue={modalData?.imageURL}
            label="Person Image"
            isRequired
          />
          <Input
            onChange={(e) =>
              setModalData({ ...modalData, content: e.target.value })
            }
            value={modalData?.content}
            label="Content"
            isRequired
            textarea
            row={4}
          />
          <Input
            onChange={(e) =>
              setModalData({ ...modalData, designation: e.target.value })
            }
            defaultValue={modalData?.designation}
            label="Person Designation"
            isRequired
          />
          <Input
            onChange={(e) =>
              setModalData({ ...modalData, isActive: e.target.value })
            }
            defaultValue={modalData?.isActive}
            options={[
              { id: true, value: "Active" },
              { id: false, value: "Inactive" },
            ]}
            select
            label="Status"
            isRequired
          />
          <div className="flex gap-2">
            <SaveButton handleEditButton={handleEditButton}></SaveButton>
          </div>
        </div>
      </Modal>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
        <div className="rounded-t mb-0 px-4 py-4 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3 className="font-semibold text-lg text-blueGray-700">
                Testimonials
              </h3>
            </div>
            <button
              onClick={handleOpenModal}
              className="bg-pink text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
            >
              Add New
            </button>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  ID
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Person Name
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Type
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Person Image
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Content
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Person Designation
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Created At
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Created By
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Updated At
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Updated By
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Is Active
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {loading && (
                <tr>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    <div className="flex items-center justify-center h-20">
                      <Loader />
                    </div>
                  </td>
                </tr>
              )}
              {testimonialData?.map((item) => (
                <tr key={item?.id}>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item?.id}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item?.name}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item?.type === "0"
                      ? "Default"
                      : item?.type === "1"
                      ? "For App"
                      : "N/A"}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item?.imageURL}
                  </td>
                  <td
                    className="border-t-0 px-6 align-middle border-l-0 border-r
                0 text-xs whitespace-nowrap p-4"
                  >
                    {item?.content}
                  </td>
                  <td
                    className="border-t-0 px-6 align-middle border-l-0 border-r
                0 text-xs whitespace-nowrap p-4"
                  >
                    {item?.designation}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item?.createdAt.toLocaleString()}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item?.createdBy}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item?.updatedAt.toLocaleString()}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item?.updatedBy}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item?.isActive ? "Active" : "InActive"}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    <div className="flex justify-evenly">
                      <button
                        onClick={() => handleOpenEditModal(item)}
                        className="focus:outline-none	"
                        type="button"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5 mr-2 "
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                          />
                        </svg>
                      </button>
                      <DeleteButton
                        handleDeleteButton={() =>
                          handleDeleteTestimonial(item?.id)
                        }
                      ></DeleteButton>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default TestimonialsTable;
