import { config } from "./confg";
import { axiosCallAdvanced } from "./main";

export const getCareers = async () => {
  return (
    (
      await axiosCallAdvanced({
        baseURL: config.careers.root,
        path: config.careers.list,
      })
    )?.data || {}
  );
};

export const deleteCareers = async (parameters) => {
  return (
    (
      await axiosCallAdvanced({
        baseURL: config.careers.root,
        path: `${config.careers.deleteOne}/${parameters}`,
        method: config.methodType.patch,
      })
    )?.data || {}
  );
};
