import { config } from "./confg";
import { axiosCallAdvanced } from "./main";

export const getStrotra = async () => {
  return (
    (
      await axiosCallAdvanced({
        baseURL: config.strotra.root,
        path: config.strotra.getStrotraData,
      })
    )?.data || {}
  );
};

export const createStrotra = async (data) => {
  return (
    (
      await axiosCallAdvanced({
        baseURL: config.strotra.root,
        path: config.strotra.createNewStrotra,
        body: { ...data },
        method: config.methodType.post,
      })
    )?.data || {}
  );
};

export const updateStrotra = async (parameters) => {
  return (
    (
      await axiosCallAdvanced({
        baseURL: config.strotra.root,
        path: `${config.strotra.updateOne}/${parameters.id}`,
        body: parameters.body,
        method: config.methodType.put,
      })
    )?.data || {}
  );
};

export const deleteStrotra = async (id) => {
  return (
    (
      await axiosCallAdvanced({
        baseURL: config.strotra.root,
        path: `${config.strotra.deleteOne}/${id}`,
        method: config.methodType.patch,
      })
    )?.data || {}
  );
};

