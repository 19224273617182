import { useState } from "react";
import Modal from "./Modal";
const SaveButton = ({ handleEditButton, name }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const handleSave = () => {
    setModalOpen(false);
    handleEditButton();
  };
  return (
    <>
      <button
        onClick={() => {
          setModalOpen(true);
        }}
        className="bg-pink mx-4 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
      >
        Save
      </button>
      <Modal isOpen={modalOpen} onClose={handleCloseModal}>
        <p className="mb-4 text-lg text-gray-500 dark:text-gray-300 text-center	">
          Are you sure you want to Edit this {name}?
        </p>

        <div className="flex justify-center">
          <button
            onClick={handleCloseModal}
            className="py-2 mx-4 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
          >
            No
          </button>
          <button
            onClick={handleSave}
            className="py-2 mx-4 px-3 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-900"
          >
            Yes
          </button>
        </div>
      </Modal>
    </>
  );
};

export default SaveButton;
