import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";

// layouts

import Admin from "layouts/Admin.js";
import Auth from "layouts/Auth.js";

// views without layouts

import Profile from "views/Profile.js";
import { userActions } from "redux/userSlice";
import Login from "views/auth/Login";
import TrackOrder from "components/Cards/TrackOrder";

const App = () => {
  const userData = useSelector(({ user }) => user.userData);
  const dispatch = useDispatch();
  console.log(userData);
  // useEffect(() => {
  //   dispatch(userActions.getUserData());
  // }, []);

  return (
    <BrowserRouter>
      <Switch>
        {/* add routes with layouts */}
        {/* {!!userData === false && ( */}
        <Route exact path="/">
          <Redirect to="/auth/login" />
        </Route>
        {/* )} */}

        <Route path="/admin" component={Admin} />
        <Route path="/auth" component={Auth} />
        {/* add routes without layouts */}
        <Route path="/profile" exact component={Profile} />
        {/* <Route path="/" exact component={Landing} /> */}
        {/* add redirect for first page */}
        <Route path="/trackOrder" component={TrackOrder} />
        <Redirect from="*" to="/" />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
