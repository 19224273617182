import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getRaagSanskar, createRaagSanskar, updateRaagSanskar, deleteRaagSanskar } from "api/raagSanskarAPI";

export const getRaagSanskarList = createAsyncThunk("raag-sanskar/list", async () => {
  try {
    const res = await getRaagSanskar();
    if (res.success) {
      return res.data;
    } else {
      throw new Error("Error fetching Raag Sanskar.");
    }
  } catch (error) {
    throw error;
  }
});
export const createOneRaagSanskar = createAsyncThunk(
  "raag-sanskar/create-one",
  async (data) => {
    try {
      const res = await createRaagSanskar(data);
      if (res.success) {
        return res.data;
      } else {
        throw new Error("Error creating Raag Sanskar.");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const deleteOneRaagSanskar = createAsyncThunk(
  "raag-sanskar/delete-one",
  async (id) => {
    try {
      const res = await deleteRaagSanskar(id);
      if (res.success) {
        return id;
      } else {
        throw new Error("Error deleting Raag Sanskar.");
      }
    } catch (error) {
      throw error;
    }
  }
);
export const updateOneRaagSanskar = createAsyncThunk(
  "raag-sanskar/update-one",
  async ({ id, data }) => {
    try {
      const res = await updateRaagSanskar(id, data);
      if (res.success) {
        return res.data;
      } else {
        throw new Error("Error updating Raag Sanskar.");
      }
    } catch (error) {
      throw error;
    }
  }
);

const RaagSanskarSlice = createSlice({
  name: "raag-sanskar",
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRaagSanskarList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRaagSanskarList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getRaagSanskarList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createOneRaagSanskar.pending, (state) => {
        state.loading = true;
      })
      .addCase(createOneRaagSanskar.fulfilled, (state, action) => {
        state.loading = false;
        state.data.push(action.payload);
      })
      .addCase(createOneRaagSanskar.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateOneRaagSanskar.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateOneRaagSanskar.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        const RaagSanskarIndex = state.data.findIndex(
          (RaagSanskar) => RaagSanskar.id === action.payload.id
        );
        if (RaagSanskarIndex !== -1) {
          state.data[RaagSanskarIndex] = action.payload;
        }
      })

      .addCase(updateOneRaagSanskar.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteOneRaagSanskar.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteOneRaagSanskar.fulfilled, (state, action) => {
        state.loading = false;
        state.data = state.data.filter((RaagSanskar) => RaagSanskar.id !== action.payload);
      })
      .addCase(deleteOneRaagSanskar.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const RaagSanskarAction = RaagSanskarSlice.actions;
export default RaagSanskarSlice;
