import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./userSlice";
import faqSlice from "./faqSlice";
import seminarSlice from "./liveSeminarSlice";
import recordedSeminarSlice from "./recordedSeminarSlice";
import productCategorySlice from "./productCategorySlice";
import productSlice from "./productSlice";
import babyMessageSlice from "./babyMessageSlice";
import soulMessageSlice from "./soulMessageSlice";
import garbhaSlice from "./garbhaSamvadSlice";
import testimonialSlice from "./testimonialSlice";
import userDetailsSlice from "./userDetailsSlice";
import customerSlice from "./customerSlice";
import dailyActivitiesslice from "./dailyActivitiesslice";
import dailyGreetingSlice from "./greetingSlice";
import babyHealthSlice from "./babyHealthSlice";
import storySlice from "./storySlice";
import brainActivitySlice from "./brainActivitySlice";
import raagSanskarSlice from "./raagSanskarSlice";
import recipeSlice from "./recipeSlice";
import dietAndNutritionSlice from "./dietAndNutritionSlice";
import blogsSlice from "./blogsSlice";
import careersSlice from "./careersSlice";
import couponSlice from "./couponSlice";
import consultationSlice from "./consultationSlice";
import notificationSlice from "./notificationSlice";
import deliveryDateSlice from "./deliveryDateSlice";
import subscriptionOrderSlice from "./subscriptionOrderSlice";
import yogaSlice from "./yogaSlice";
import shlokaSlice from "./shlokaSlice";
import strotraSlice from "./strotraSlice";
import orderSlice from "./orderSlice";
import homeRemediesSlice from "./homeRemediesSlice";

const store = configureStore({
    reducer: {
        user: userSlice.reducer,
        faq: faqSlice.reducer,
        seminars: seminarSlice.reducer,
        recordedSeminar: recordedSeminarSlice.reducer,
        products: productSlice.reducer,
        productCategory: productCategorySlice.reducer,
        babyMessage: babyMessageSlice.reducer,
        soulMessage: soulMessageSlice.reducer,
        garbhaSamvad: garbhaSlice.reducer,
        testimonials: testimonialSlice.reducer,
        userDetails: userDetailsSlice.reducer,
        customers: customerSlice.reducer,
        dailyactivity: dailyActivitiesslice.reducer,
        dailyGreeting: dailyGreetingSlice.reducer,
        notification: notificationSlice.reducer,
        babyHealth: babyHealthSlice.reducer,
        story: storySlice.reducer,
        raagSanskar: raagSanskarSlice.reducer,
        diet: dietAndNutritionSlice.reducer,
        recipe: recipeSlice.reducer,
        blogs: blogsSlice.reducer,
        careers: careersSlice.reducer,
        brainActivity: brainActivitySlice.reducer,
        coupon: couponSlice.reducer,
        consultation: consultationSlice.reducer,
        deliveryDate: deliveryDateSlice.reducer,
        subscriptionOrder: subscriptionOrderSlice.reducer,
        shloka: shlokaSlice.reducer,
        strotra: strotraSlice.reducer,
        yoga: yogaSlice.reducer,
        order: orderSlice.reducer,
        homeRemedies: homeRemediesSlice.reducer
    },
});

export default store;
