import { config } from './confg';
import { axiosCallAdvanced } from './main';

export const getFaqs = async () => {
    console.log('inside api file');
    return (
        (
            await axiosCallAdvanced({
                baseURL: config.faq.root,
                path: config.faq.list,
            })
        )?.data || {}
    );
};

export const createOne = async (data) => {
    return (
        (
            await axiosCallAdvanced({
                baseURL: config.faq.root,
                path: config.faq.createOne,
                body: { ...data },
                method: config.methodType.post,
            })
        )?.data || {}
    );
};

export const updateOne = async (parameters) => {
    return (
        (
            await axiosCallAdvanced({
                baseURL: config.faq.root,
                path: `${config.faq.updateOne}/${parameters.id}`,
                body: parameters.body,
                method: config.methodType.put,
            })
        )?.data || {}
    );
};

export const deleteOne = async (id) => {
    return (
        (
            await axiosCallAdvanced({
                baseURL: config.faq.root,
                path: `${config.faq.deleteOne}/${id}`,
                method: config.methodType.patch,
            })
        )?.data || {}
    );
};
