import { config } from "./confg";
import { axiosCallAdvanced } from "./main";

export const getRecordedSeminar = async () => {
    return (
        (
            await axiosCallAdvanced({
                baseURL: config.recordedSeminar.root,
                path: config.recordedSeminar.list,
            })
        )?.data || {}
    );
};
export const createRecordedSeminar = async (data) => {
    return (
        (
            await axiosCallAdvanced({
                baseURL: config.recordedSeminar.root,
                path: config.recordedSeminar.createOne,
                body: { ...data },
                method: config.methodType.post,
            })
        )?.data || {}
    );
};

export const updateRecordedSeminar = async (parameters) => {
    return (
        (
            await axiosCallAdvanced({
                baseURL: config.recordedSeminar.root,
                path: `${config.recordedSeminar.updateOne}/${parameters.id}`,
                body: parameters.body,
                method: config.methodType.put,
            })
        )?.data || {}
    );
};

export const deleteRecordedSeminar = async (id) => {
    return (
        (
            await axiosCallAdvanced({
                baseURL: config.recordedSeminar.root,
                path: `${config.recordedSeminar.deleteOne}/${id}`,
                method: config.methodType.patch,
            })
        )?.data || {}
    );
};
