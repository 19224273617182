import React, { useState } from 'react';
import FaqTable from 'components/Cards/FaqTable';
import Modal from 'lib/Modal';
import Input from 'lib/Input';
import { useDispatch, useSelector } from 'react-redux';
import { createFaq } from 'redux/faqSlice';

const Faqs = () => {
    const dispatch = useDispatch();
    const [modalOpen, setModalOpen] = useState(false);

    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    const [categoryId, setCategoryId] = useState('');
    const faqCategory = useSelector((state) => state?.faq?.faqCategory);
    const [formError, setFormError] = useState(false);

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleCreateNew = () => {
        const data = {
            categoryId,
            question,
            answer,
        };

        if (question?.length && answer?.length && categoryId?.length) {
            dispatch(createFaq(data));
            setFormError(false);
            setModalOpen(false);
        } else {
            setFormError(true);
        }
    };

    return (
        <>
            <Modal isOpen={modalOpen} onClose={handleCloseModal}>
                <div>
                    <Input
                        onChange={(e) => setCategoryId(e.target.value)}
                        value={categoryId}
                        options={faqCategory.map((item) => {
                            return {
                                id: item?.id,
                                value: item?.faqCategory?.toUpperCase(),
                            };
                        })}
                        select
                        label="Categories"
                    />
                    <Input
                        onChange={(e) => setQuestion(e.target.value.trim())}
                        type="text"
                        label="Question"
                    />
                    <Input
                        onChange={(e) => setAnswer(e.target.value.trim())}
                        textarea
                        label="Answer"
                    />
                    {formError && (
                        <p className="mx-4 text-red-500 mb-2">
                            Please fill all the details!
                        </p>
                    )}
                    <div className="flex gap-2">
                        <button
                            onClick={handleCreateNew}
                            className="bg-pink mx-4 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                            type="button"
                        >
                            Save
                        </button>
                    </div>
                </div>
            </Modal>

            <div className="flex flex-wrap mt-4">
                <div className="w-full mb-12 px-4">
                    <FaqTable handleOpenModal={handleOpenModal} />
                </div>
            </div>
        </>
    );
};

export default Faqs;
