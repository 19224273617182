import React, { useRef, useState } from "react";
import Modal from "lib/Modal";
import Input from "lib/Input";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import ReactQuill from "react-quill";
import "quill/dist/quill.snow.css";
import "../../assets/styles/richText.css";
import PictureUpload from "lib/PictureUpload";
import Loader from "components/Loader/Loader";
import YogaPracticesTable from "components/Cards/YogaPracticeTable";
import { constants } from "utils/constants";
import { handleImageFile, handleMediaFile } from "api/fileUploadAPI";
import { createNewYoga } from "redux/yogaSlice";

const YogaPractices = () => {
    const dispatch = useDispatch();
    const [modalOpen, setModalOpen] = useState(false);

    const [userCategory, setUserCategory] = useState('');
    const [yogaName, setYogaName] = useState('');
    const [yogaDescription, setYogaDescription] = useState('');
    const [month, SetMonth] = useState('');
    const [yogaBenefits, setYogaBenefits] = useState('');
    const [mediaUrl, setMediaUrl] = useState('');
    const [thumbnailUrl, setThumbnailUrl] = useState('');
    const [introMediaType, setIntroMediaType] = useState('');
    const [isActive, setIsActive] = useState(true);
    const [formError, setFormError] = useState(false);
    const [isIntroLoader, setIsIntroLoader] = useState(false);
    const [isThumbnailLoader, setIsThumbnailLoader] = useState(false);

    const [thumbnailOption, setThumbnailOption] = useState(1);
    const [mediaURLOption, setMediaURLOption] = useState(1);

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setUserCategory('');
        setYogaName('');
        setYogaDescription('');
        setYogaBenefits('');
        setMediaUrl('');
        setThumbnailUrl('');
        setFormError(false);
    };

    const handleAddYogaPractice = () => {
        if (
            userCategory.trim() !== '' &&
            userCategory !== 'pre' &&
            yogaName.trim() !== '' &&
            yogaDescription.trim() !== '' &&
            yogaBenefits.trim() !== '' &&
            thumbnailUrl.trim() !== '' &&
            introMediaType.trim() !== ''
        ) {
            const newYogaPractice = {
                userCategory: userCategory,
                thumbnailURL: thumbnailUrl,
                month: month,
                title: yogaName,
                content: yogaDescription,
                benefits: yogaBenefits,
                mediaType: introMediaType,
                mediaURL: mediaUrl,
                isActive: isActive,
            };
            dispatch(createNewYoga({ ...newYogaPractice }));
            setModalOpen(false);
            setUserCategory('');
            setYogaName('');
            setYogaDescription('');
            setYogaBenefits('');
            setMediaUrl('');
            setIntroMediaType('');
            setThumbnailUrl('');
            setFormError(false);
            setThumbnailOption(1);
            setMediaURLOption(1);
        } else {
            setFormError(true);
        }
    };

    const editor = useRef(null);
    const handleQuillChange = (value) => {
        // setContent(value);
        setYogaDescription(value);
    };

    const handleIntroMedia = async (file) => {
        if (file === undefined) {
            setMediaUrl('');
            return;
        }

        if (introMediaType === '1') {
            try {
                setIsIntroLoader(true);
                const res = await handleImageFile(file);
                if (res.success) {
                    setMediaUrl(res.file_url);

                    toast.success('Successfully Uploaded the Image!');
                } else {
                    throw new Error('Picture Not Successfully Uploaded');
                }
                setIsIntroLoader(false);
            } catch (error) {
                toast.error(error);
            }
        } else {
            try {
                setIsIntroLoader(true);
                const res = await handleMediaFile(file);
                if (res.success) {
                    setMediaUrl(res.file_url);
                    introMediaType === '2'
                        ? toast.success('Successfully Uploaded the Audio!')
                        : toast.success('Successfully Uploaded the video!');
                } else {
                    throw new Error('Picture Not Successfully Uploaded');
                }
                setIsIntroLoader(false);
            } catch (error) {
                toast.error(error);
            }
        }
    };

    const handleThumbnail = async (file) => {
        if (file === undefined) {
            setThumbnailUrl('');
            return;
        }

        try {
            setIsThumbnailLoader(true);
            const res = await handleImageFile(file);
            if (res.success) {
                setThumbnailUrl(res.file_url);

                toast.success('Successfully Uploaded the Image!');
            } else {
                throw new Error('Picture Not Successfully Uploaded');
            }
            setIsThumbnailLoader(false);
        } catch (error) {
            toast.error(error);
        }
    };
    return (
        <>
            <Modal isOpen={modalOpen} onClose={handleCloseModal}>
                <div>
                    <Input
                        onChange={(e) => setUserCategory(e.target.value)}
                        value={userCategory}
                        label="User Category"
                        isRequired
                        options={constants.DietUserCategory}
                        select
                    />
                    <Input
                        onChange={(e) => SetMonth(parseInt(e.target.value))}
                        value={month}
                        label="Month"
                        isRequired
                        options={constants.monthsOptions}
                        select
                    />
                    <Input
                        onChange={(e) => setYogaName(e.target.value)}
                        value={yogaName}
                        label="Yoga Name"
                        isRequired
                    />
                    <ReactQuill
                        style={{
                            marginLeft: 15,
                            marginRight: 15,
                            background: '#fff',
                        }}
                        className="ql-container ql-snow"
                        ref={editor}
                        modules={constants.modules}
                        formats={constants.formats}
                        defaultValue={yogaDescription}
                        onChange={handleQuillChange}
                    />
                    <Input
                        label="Yoga Benefits"
                        onChange={(e) => setYogaBenefits(e.target.value)}
                        value={yogaBenefits}
                        textarea
                        rows={4}
                        isRequired
                    />
                    <Input
                        label="Intro Media Type"
                        onChange={(e) => setIntroMediaType(e.target.value)}
                        value={introMediaType}
                        select
                        options={constants.mediaOption}
                        isRequired
                    />
                    {introMediaType === '0' ? null : (
                        <Input
                            label="Media Option"
                            onChange={(e) =>
                                setMediaURLOption(parseInt(e.target.value))
                            }
                            value={mediaURLOption}
                            select
                            options={[
                                { id: 1, value: 'Upload Media' },
                                { id: 2, value: 'Media URL' },
                            ]}
                            isRequired
                        />
                    )}
                    {isIntroLoader && <Loader />}
                    {introMediaType !== '0' &&
                        (mediaURLOption === 1 ? (
                            <PictureUpload
                                onChange={handleIntroMedia}
                                value={mediaUrl}
                                label={
                                    introMediaType === '3'
                                        ? 'Intro Video'
                                        : introMediaType === '2'
                                        ? 'Intro Audio'
                                        : 'Intro Image'
                                }
                                isRequired
                            />
                        ) : (
                            <Input
                                onChange={(e) => setMediaUrl(e.target.value)}
                                value={mediaUrl}
                                label={
                                    introMediaType === '3'
                                        ? 'Intro Video'
                                        : introMediaType === '2'
                                        ? 'Intro Audio'
                                        : 'Intro Image'
                                }
                                isRequired
                            />
                        ))}
                    <Input
                        label="Thumbnail Option"
                        onChange={(e) =>
                            setThumbnailOption(parseInt(e.target.value))
                        }
                        value={thumbnailOption}
                        select
                        options={[
                            { id: 1, value: 'Upload Thumbnail' },
                            { id: 2, value: 'Thumbnail URL' },
                        ]}
                        isRequired
                    />
                    {isThumbnailLoader && <Loader />}
                    {thumbnailOption === 1 ? (
                        <PictureUpload
                            label="Thumbnail Image"
                            isRequired
                            onChange={handleThumbnail}
                            value={thumbnailUrl}
                        />
                    ) : (
                        <Input
                            onChange={(e) => setThumbnailUrl(e.target.value)}
                            value={thumbnailUrl}
                            label="Thumbnail Image"
                            isRequired
                        />
                    )}
                    <Input
                        onChange={(e) => setIsActive(e.target.value)}
                        defaultValue={isActive}
                        options={[
                            { id: true, value: 'Active' },
                            { id: false, value: 'Inactive' },
                        ]}
                        select
                        label="Status"
                        isRequired
                    />
                    {formError && (
                        <p className="text-red-500 mt-2">
                            Please fill all the details!
                        </p>
                    )}
                    <div className="flex gap-2 mt-4">
                        <button
                            onClick={handleAddYogaPractice}
                            className="bg-pink text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                            type="button"
                        >
                            Save
                        </button>
                    </div>
                </div>
            </Modal>

            <div className="flex flex-wrap mt-4">
                <div className="w-full mb-12 px-4">
                    <YogaPracticesTable
                        handleOpenModal={handleOpenModal}
                        color="dark"
                    />
                </div>
            </div>
        </>
    );
};

export default YogaPractices;
