import ContactTable from "components/Cards/ContactTable";
import React from "react";

const Contact = () => {
  const contactData = [
    {
      id: 1,
      name: "Person 1",
      email: "email@gmail.com",
      phoneNo: 9898989898,
      message: "Message 1",
      timeStamp: "12-06-2023",
      isResponse: true,
    },
    {
      id: 2,
      name: "Person 2",
      email: "email@gmail.com",
      phoneNo: 9898989898,
      message: "Message 2",
      timeStamp: "14-06-2023",
      isResponse: true,
    },
    {
      id: 3,
      name: "Person 3",
      email: "email@gmail.com",
      phoneNo: 9898989898,
      message: "Message 3",
      timeStamp: "17-06-2023",
      isResponse: true,
    },
    {
      id: 4,
      name: "Person 4",
      email: "email@gmail.com",
      phoneNo: 9898989898,
      message: "Message 4",
      timeStamp: "01-07-2023",
      isResponse: false,
    },
  ];
  return (
    <>
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-2xl font-bold">Contact</h2>
          </div>
          <ContactTable data={contactData} />
        </div>
      </div>
    </>
  );
};

export default Contact;
