import { config } from "./confg";
import { axiosCallAdvanced } from "./main";

export const getTestimonial = async () => {
  return (
    (
      await axiosCallAdvanced({
        baseURL: config.testimonial.root,
        path: config.testimonial.list,
      })
    )?.data || {}
  );
};
export const createTestimonial = async (data) => {
  return (
    (
      await axiosCallAdvanced({
        baseURL: config.testimonial.root,
        path: config.testimonial.createOne,
        body: { ...data },
        method: config.methodType.post,
      })
    )?.data || {}
  );
};
export const updateTestimonial = async (id, data) => {
  return (
    (
      await axiosCallAdvanced({
        baseURL: config.testimonial.root,
        path: `${config.testimonial.updateOne}/${id}`,
        body: { ...data },
        method: config.methodType.put,
      })
    )?.data || {}
  );
};
export const deleteTestimonial = async (id) => {
  return (
    (
      await axiosCallAdvanced({
        baseURL: config.testimonial.root,
        path: `${config.testimonial.deleteOne}/${id}`,
        method: config.methodType.patch,
      })
    )?.data || {}
  );
};
