import React, { useState } from "react";
import "jspdf-autotable";
import { Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import Modal from "lib/Modal";
import Input from "lib/Input";
import CouponsTable from "components/Cards/CouponsTable";
import { createOne } from "redux/couponSlice";

const Coupons = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [doctorName, setDoctorName] = useState("");
  const [couponsRequired, setCouponsRequired] = useState("");
  const [couponsDiscount, setCouponsDiscount] = useState("");
  const [validFrom, setValidFrom] = useState(null);
  const [validTill, setValidTill] = useState(null);
  const [generatedCoupons, setGeneratedCoupons] = useState([]);
  const [doctorEmail, setDoctorEmail] = useState("");
  const dispatch = useDispatch();

  // const generateCoupons = () => {
  //   if (doctorName.trim() !== "" && couponsRequired.trim() !== "") {
  //     const prefix = doctorName.substring(0, 3).toUpperCase(); // Initial of the doctor's name
  //     const count = parseInt(couponsRequired);

  //     const currentDate = new Date();
  //     const day = String(currentDate.getDate()).padStart(2, "0");
  //     const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  //     const year = String(currentDate.getFullYear()).slice(-2);

  //     const formattedDate = `${day}${month}${year}`;

  //     const newCoupons = Array.from(
  //       { length: count },
  //       () => `${prefix}-${formattedDate}${generateRandomNumbers()}`
  //     );

  //     setGeneratedCoupons(newCoupons);
  //   }
  // };

  // const generateRandomNumbers = () => {
  //   return Math.floor(1000 + Math.random() * 9000).toString();
  // };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setDoctorName("");
    setDoctorEmail("");
    setCouponsRequired("");
    setCouponsDiscount("");
    setValidFrom("");
    setValidTill("");
    setGeneratedCoupons([]);
  };

  const handleCreateNew = async () => {
    if (
      doctorName.trim() !== "" &&
      doctorEmail.trim() !== "" &&
      couponsRequired.trim() !== "" &&
      couponsDiscount.trim() !== "" &&
      validFrom &&
      validTill
    ) {
      const newCoupon = {
        name: doctorName.trim(),
        email: doctorEmail.trim(),
        noOfCoupons: parseInt(couponsRequired),
        discount: parseInt(couponsDiscount),
        validFrom: validFrom,
        validTill: validTill,
        createdAt: new Date(),
        createdBy: "Admin",
        updatedAt: new Date(),
        updatedBy: "Admin",
        isActive: true,
      };
      console.log(newCoupon);
      dispatch(createOne(newCoupon));
      setDoctorName("");
      setCouponsRequired("");
      setValidFrom("");
      setValidTill("");
      setModalOpen(false);
    }
  };

  return (
    <>
      <Toaster />
      <Modal isOpen={modalOpen} onClose={handleCloseModal}>
        <div>
          <Input
            onChange={(e) => setDoctorName(e.target.value)}
            value={doctorName}
            label="Doctor's Name"
          />
          <Input
            onChange={(e) => setDoctorEmail(e.target.value)}
            value={doctorEmail}
            label="Doctor's Email"
            type="email"
          />
          <Input
            onChange={(e) => setCouponsRequired(e.target.value)}
            value={couponsRequired}
            label="No. of Coupons Required (Eg- 10 or 20..)"
          />
          <Input
            onChange={(e) => setCouponsDiscount(e.target.value)}
            value={couponsDiscount}
            label="Coupons Discount (Eg- 200rs or 300rs.."
          />
          <Input 
            onChange={(e) => setValidFrom(e.target.value)}
            value={validFrom}
            type='date'
            label = 'valid from date'
            isRequired
          />
          <Input 
            onChange={(e) => setValidTill(e.target.value)}
            value={validTill}
            type='date'
            label = 'valid till date'
            isRequired
          />
          <div className="flex gap-2">
            <button
              onClick={handleCreateNew}
              className="bg-pink mx-4 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
            >
              Generate
            </button>
          </div>
        </div>
      </Modal>

      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <button
            onClick={handleOpenModal}
            className="bg-pink text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            type="button"
          >
            Add New Coupon
          </button>
        </div>
        <div className="w-full">
          <CouponsTable handleOpenModal={handleOpenModal} />
        </div>
      </div>
    </>
  );
};

export default Coupons;
