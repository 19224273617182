import { useState } from "react";
import Modal from "./Modal";
const DeleteButton = ({ handleDeleteButton }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const handleDelete = ({}) => {
    setModalOpen(false);
    handleDeleteButton();
  };
  return (
    <>
      <button
        className="focus:outline-none"
        onClick={() => {
          setModalOpen(true);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 mr-2"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
          />
        </svg>
      </button>

      <Modal isOpen={modalOpen} onClose={handleCloseModal}>
        <p className="mb-4 text-lg text-gray-500 dark:text-gray-300 text-center	">
          Are you sure you want to delete?
        </p>
        <div className="flex justify-center">
          <button
            onClick={handleCloseModal}
            className="py-2 mx-4 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
          >
            No
          </button>
          <button
            onClick={handleDelete}
            className="py-2 mx-4 px-3 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-900"
          >
            Yes
          </button>
        </div>
      </Modal>
    </>
  );
};

export default DeleteButton;
