import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'lib/Modal';
import Input from 'lib/Input';
import SaveButton from 'lib/SaveButton';
import DeleteButton from 'lib/DeleteButton';
import PictureUpload from 'lib/PictureUpload';
import Loader from 'components/Loader/Loader';
import {
    getList,
    updateOne,
    deleteOne,
} from 'redux/liveSeminarSlice';
import { constants } from 'utils/constants';
import { handleImageFile } from 'api/fileUploadAPI';

const LiveSeminarTable = ({ color, handleOpenModal }) => {
  const dispatch = useDispatch();

    const [openEditModal, setOpenEditModal] = useState(false);
    const [modalData, setModalData] = useState(null);

    const seminarData = useSelector((state) => state.seminars.data);
    const loading = useSelector((state) => state.seminars.loading);
    const [isIntroLoader, setIsIntroLoader] = useState(false);

    const handleOpenEditModal = (item) => {
        setModalData(item);
        setOpenEditModal(true);
    };

    const handleImageUrl = async (file) => {
        console.log(file);
        if (file === undefined) {
            setModalData({ ...modalData, imageURL: '' });
            return;
        }

        try {
            setIsIntroLoader(true);
            const res = await handleImageFile(file);
            if (res.success) {
                setModalData({ ...modalData, imageURL: res.file_url });
                toast.success('Successfully Uploaded the Image!');
            } else {
                throw new Error('Picture Not Successfully Uploaded');
            }
            setIsIntroLoader(false);
        } catch (error) {
            toast.error(error);
        }
    };

    function handleEditButton() {
        handleUpdate();
    }

    const handleUpdate = () => {
        const updatedData = {
            userCategory: modalData?.userCategory,
            title: modalData?.title,
            description: modalData?.description,
            imageURL: modalData?.imageURL,
            videoURL: modalData?.videoURL,
            seminarDate: modalData?.seminarDate,
            startTime: modalData?.startTime,
            endTime: modalData?.endTime,
            isActive: modalData?.isActive,
        };
        dispatch(updateOne({ id: modalData?.id, body: updatedData }));
        setOpenEditModal(false);
    };

    const handleDeleteSeminar = async (id) => {
        dispatch(await deleteOne(id));
    };

    useEffect(() => {
        dispatch(getList());
    }, [dispatch]);

    return (
        <>
            {/* Edit modal */}
            <Toaster />
            <Modal
                isOpen={openEditModal}
                onClose={() => setOpenEditModal(false)}
            >
                <div>
                    <Input
                        onChange={(e) =>
                            setModalData({
                                ...modalData,
                                userCategory: e.target.value,
                            })
                        }
                        defaultValue={modalData?.userCategory}
                        label="Seminar Category"
                        isRequired
                        options={constants.LiveSeminarCategory}
                        select
                    />
                    <Input
                        onChange={(e) =>
                            setModalData({
                                ...modalData,
                                title: e.target.value,
                            })
                        }
                        defaultValue={modalData?.title}
                        label="Seminar Title"
                        isRequired
                    />

                    <Input
                        onChange={(e) =>
                            setModalData({
                                ...modalData,
                                description: e.target.value,
                            })
                        }
                        defaultValue={modalData?.description}
                        label="Seminar Description"
                        isRequired
                        textarea
                        row={4}
                    />
                    {isIntroLoader && <Loader />}
                    <PictureUpload
                        onChange={handleImageUrl}
                        value={modalData?.imageURL}
                        label="Image URL"
                        isRequired
                    />

                    <Input
                        onChange={(e) =>
                            setModalData({
                                ...modalData,
                                videoURL: e.target.value,
                            })
                        }
                        defaultValue={modalData?.videoURL}
                        label="Seminar/Meeting URL"
                        isRequired
                    />
                    <Input
                        onChange={(e) =>
                            setModalData({
                                ...modalData,
                                seminarDate: e.target.value,
                            })
                        }
                        defaultValue={modalData?.seminarDate}
                        label="Start Time"
                        isRequired
                        type="date"
                    />
                    <Input
                        onChange={(e) =>
                            setModalData({
                                ...modalData,
                                startTime: e.target.value,
                            })
                        }
                        defaultValue={modalData?.startTime}
                        label="Start Time"
                        isRequired
                        type="time"
                    />
                    <Input
                        onChange={(e) =>
                            setModalData({
                                ...modalData,
                                endTime: e.target.value,
                            })
                        }
                        defaultValue={modalData?.endTime}
                        label="End Time"
                        isRequired
                        type="time"
                    />
                    <Input
                        onChange={(e) =>
                            setModalData({
                                ...modalData,
                                isActive: e.target.value,
                            })
                        }
                        defaultValue={modalData?.isActive}
                        options={[
                            { id: true, value: 'Active' },
                            { id: false, value: 'Inactive' },
                        ]}
                        select
                        label="Status"
                        isRequired
                    />
                    <div className="flex gap-2">
                        <SaveButton
                            handleEditButton={handleEditButton}
                        ></SaveButton>
                    </div>
                </div>
            </Modal>
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
                <div className="rounded-t mb-0 px-4 py-4 border-0">
                    <div className="flex flex-wrap items-center">
                        <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                            <h3 className="font-semibold text-lg text-blueGray-700">
                                Live Seminars
                            </h3>
                        </div>
                        <button
                            onClick={handleOpenModal}
                            className="bg-pink text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                            type="button"
                        >
                            Add New
                        </button>
                    </div>
                </div>
                <div className="block w-full overflow-x-auto">
                    {/* Table */}
                    <table className="items-center w-full bg-transparent border-collapse">
                        <thead>
                            <tr>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    ID
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    User Category
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Seminar Title
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Description
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Image URL
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Seminar/Meeting URL
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Seminar Date
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Start Time
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    End Time
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Created At
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Created By
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Updated At
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Updated By
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Is Active
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading && (
                                <tr>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        <div className="flex items-center justify-center h-20">
                                            <Loader />
                                        </div>
                                    </td>
                                </tr>
                            )}
                            {seminarData?.map((item) => (
                                <tr key={item?.id}>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.id}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.userCategory === '0'
                                            ? 'Free User'
                                            : item?.userCategory === '1'
                                            ? 'Pre Pregnancy'
                                            : item?.userCategory === '2'
                                            ? 'Pregnant'
                                            : item?.userCategory === '3'
                                            ? 'Parenting'
                                            : 'N/A'}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.title}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.description}
                                    </td>
                                    <td
                                        className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                    >
                                        {item?.imageURL}
                                    </td>
                                    <td
                                        className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                    >
                                        <a
                                            href={item?.videoURL}
                                            target="_blank"
                                            rel="noopener noreferrer" // Recommended for security reasons
                                        >
                                            {item?.videoURL}
                                        </a>
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.seminarDate}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.startTime}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.endTime}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {new Date(
                                            item?.createdAt
                                        ).toLocaleString()}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {new Date(
                                            item?.createdBy
                                        ).toLocaleString()}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {new Date(
                                            item?.updatedAt
                                        ).toLocaleString()}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {new Date(
                                            item?.updatedBy
                                        ).toLocaleString()}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.isActive ? 'Active' : 'InActive'}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        <div className="flex justify-evenly">
                                            <button
                                                onClick={() =>
                                                    handleOpenEditModal(item)
                                                }
                                                className="focus:outline-none	"
                                                type="button"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="h-5 w-5 mr-2 "
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    strokeWidth="2"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                                    />
                                                </svg>
                                            </button>
                                            <DeleteButton
                                                handleDeleteButton={() =>
                                                    handleDeleteSeminar(
                                                        item?.id
                                                    )
                                                }
                                            ></DeleteButton>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default LiveSeminarTable;
