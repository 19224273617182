import React from "react";
import UserDetails from "./UserDetails";
import HomeRemediesTable from "components/Cards/HomeRemediesTable";

export default function HomeRemediesPage() {
    return (
        <>
            <div className="flex flex-wrap mt-4">
                <div className="w-full mb-12 px-4">
                    <HomeRemediesTable />
                </div>
            </div>
        </>
    );
}
