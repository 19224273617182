import React, { useState, useEffect } from "react";
import {
    getUsers,
    getSubscribers,
    getNewOrders,
    getShippedOrders,
} from 'api/statsAPI';

// components

import CardStats from "components/Cards/CardStats.js";


export default function HeaderStats() {
  const [userCount, setUserCount] = useState(0);
  const [subscriberCount, setSubscriberCount] = useState(0);
  const [newOrderCount, setNewOrderCount] = useState(0);
  const [shippedOrderCount, setShippedOrderCount] = useState(0);

  useEffect(() => {
    getUsers().then((res) => {
      setUserCount(res?.count);
    })

    getSubscribers().then((res) => {
      setSubscriberCount(res?.count);
    })

    getNewOrders().then((res) => {
      setNewOrderCount(res?.count);
    })

    getShippedOrders().then((res) => {
      setShippedOrderCount(res?.count);
    })
  }, [userCount, subscriberCount, newOrderCount, shippedOrderCount])

  return (
    <>
      {/* Header */}
      <div className="relative bg-darkGreen md:pt-32 pb-32 pt-12">
        <div className="px-4 md:px-10 mx-auto w-full">
          <div>
            {/* Card stats */}
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="Total Users"
                  statTitle={userCount}
                  // statArrow="up"
                  // statPercent="3.48"
                  // statPercentColor="text-emerald-500"
                  // statDescripiron="Since last month"
                  statIconName="fas fa-users"
                  statIconColor="bg-red-500"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="Subscribers"
                  statTitle={subscriberCount}
                  // statArrow="down"
                  // statPercent="3.48"
                  // statPercentColor="text-red-500"
                  // statDescripiron="Since last week"
                  statIconName="fas fa-user"
                  statIconColor="bg-orange-500"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="New Orders Received"
                  statTitle={newOrderCount}
                  // statArrow="down"
                  // statPercent="1.10"
                  // statPercentColor="text-orange-500"
                  // statDescripiron="Since yesterday"
                  statIconName="fas fa-shopping-bag"
                  statIconColor="bg-pink-500"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="Orders in Transit"
                  statTitle={shippedOrderCount}
                  // statArrow="up"
                  // statPercent="12"
                  // statPercentColor="text-emerald-500"
                  // statDescripiron="Since last month"
                  statIconName="fa fa-truck"
                  statIconColor="bg-lightBlue-500"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
