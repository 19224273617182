import React from "react";
import { useState } from "react";
import TestimonialsTable from "components/Cards/TestimonialsTable";
import PictureUpload from "lib/PictureUpload";
import Modal from "lib/Modal";
import Input from "lib/Input";
import { createTestimonials } from "redux/testimonialSlice";
import { useDispatch } from "react-redux";
import { handleImageFile } from "api/fileUploadAPI";
import { constants } from "utils/constants";
// import  from "assets/styles/formValidation.css";

const Testimonials = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [type, setType] = useState("");
  const [name, setName] = useState("");
  const [imageURL, setImageURL] = useState("");
  const [content, setContent] = useState("");
  const [designation, setDesignation] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [formError, setFormError] = useState(false);
  const dispatch = useDispatch();

  const handleOpenModal = () => {
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleImageURL = async (file) => {
    const res = await handleImageFile(file);
    setImageURL(res.file_url);
  };

  const handleAddTestimonial = () => {
    if (
      type !== "" &&
      name.trim() !== "" &&
      content.trim() !== "" &&
      designation.trim() !== ""
    ) {
      const newTestimonial = {
        type: type,
        name: name,
        imageURL: imageURL || null,
        content: content,
        designation: designation,
        isActive: isActive,
      };
      dispatch(createTestimonials(newTestimonial));
      setModalOpen(false);
    } else {
      setFormError(true);
    }
  };
  return (
    <>
      <Modal isOpen={modalOpen} onClose={handleCloseModal}>
        <div>
          <Input
            onChange={(e) => setType(e.target.value)}
            defaultValue={type}
            label="Type"
            isRequired
            select
            options={constants.type}
          />
          <Input
            onChange={(e) => setName(e.target.value)}
            defaultValue={name}
            label="Person Name"
            isRequired
          />
          <PictureUpload
            onChange={handleImageURL}
            defaultValue={imageURL}
            label="Person Image"
          />
          <Input
            onChange={(e) => setContent(e.target.value)}
            defaultValue={content}
            label="Content"
            isRequired
            textarea
            row={4}
          />
          <Input
            onChange={(e) => setDesignation(e.target.value)}
            defaultValue={designation}
            label="Designation"
            isRequired
          />
          <Input
            onChange={(e) => setIsActive(e.target.value)}
            defaultValue={isActive}
            options={[
              { id: true, value: "Active" },
              { id: false, value: "Inactive" },
            ]}
            select
            label="Status"
            isRequired
          />
          {formError && (
            <p className="text-red-500 mt-2">Please fill all the details!</p>
          )}
          <div className="flex gap-2 mt-4">
            <button
              onClick={handleAddTestimonial}
              className="bg-pink text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
            >
              Save
            </button>
          </div>
        </div>
      </Modal>
      <div className="flex flex-wrap mt-4">
        <div className="w-full">
          <TestimonialsTable handleOpenModal={handleOpenModal} />
        </div>
      </div>
    </>
  );
};

export default Testimonials;
