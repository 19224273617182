export const config = {
    root: 'https://server.gopreg.com',
    auth: {
        root: '/api/auth',
        login: '/admin/login',
        verifyOtp: '/verify-otp',
    },
    faqCategories: {
        root: '/api/faq/category',
        list: '/list',
        createOne: '/create-one',
        updateOne: '/update-one',
        deleteOne: '/delete-one',
    },
    faq: {
        root: '/api/faq',
        list: '/list',
        createOne: '/create-new',
        updateOne: '/update-one',
        deleteOne: '/delete-one',
    },
    seminars: {
        allSeminarsList: '/api/seminars',
        createNew: 'api/seminars/seminars',
        updateOne: 'api/seminars/seminars/:id',
    },
    seminarCategories: {
        createSeminarCategory: 'api/seminars/seminars-categories',
        getSeminarCategories: 'api/seminars/seminars-categories/:id',
    },
    userDetails: {
        list: 'api/user/details',
    },
    babyMessage: {
        root: '/api/baby-message',
        getBabyMessage: '/list',
        createBabyMessage: '/create-one',
        updateBabyMessage: '/update-one',
        deleteBabyMessage: '/delete-one',
    },
    soulMessage: {
        root: '/api/soul-message',
        getSoulMessage: '/list',
        createSoulMessage: '/create-one',
        updateSoulMessage: '/update-one',
        deleteSoulMessage: '/delete-one',
    },
    storageTokenKeyName: 'accessToken',

    methodType: {
        get: 'GET',
        post: 'POST',
        put: 'PUT',
        patch: 'PATCH',
        delete: 'DELETE',
    },
    shlokas: {
        root: '/api/shlokas',
        allShlokasData: '/list',
        createNew: '/create-one',
        updateOne: '/update-one',
        deleteOne: '/delete-one',
    },
    strotra: {
        root: '/api/strotras',
        getStrotraData: '/list',
        createNewStrotra: '/create-one',
        updateOne: '/update-one',
        deleteOne: '/delete-one',
    },
    dietAndNutrition: {
        root: '/api/diet',
        list: '/list',
        createOne: '/create-one',
        updateOne: '/update-one',
        deleteOne: '/delete-one',
    },
    fileUpload: {
        root: '/api',
        upload: '/image-upload',
    },
    product: {
        root: '/api/product',
        list: '/list',
        categoryList: '/api/product-category',
        productCategoryList: '/list',
        createOne: '/create-one',
        updateOne: '/update-one',
        deleteOne: '/delete-one',
    },

    productCategory: {
        root: '/api/product-category',
        getProductCategory: '/list',
        createProductCategory: '/create-one',
        updateProductCategory: '/update-one',
        deleteProductCategory: '/delete-one',
    },

    liveSeminar: {
        root: '/api/live-seminars',
        list: '/list',
        createOne: '/create-one',
        updateOne: '/update-one',
        deleteOne: '/delete-one',
    },

    recordedSeminar: {
        root: '/api/recorded-seminar',
        list: '/list',
        createOne: '/create-one',
        updateOne: '/update-one',
        deleteOne: '/delete-one',
    },

    yogaPractices: {
        root: '/api/yoga',
        list: '/list',
        createNewYoga: '/create-one',
        updateOne: '/update-one',
        deleteOne: '/delete-one',
    },
    garbhaSamvad: {
        root: '/api/garbha-samvad',
        list: '/list',
        createNewGarbhaSamvad: '/create-one',
        updateOne: '/update-one',
        deleteOne: '/delete-one',
    },
    fileUpload: {
        imageUpload: '/api/image-upload',
        mediaUpload: '/api/media-upload',
    },
    testimonial: {
        root: '/api/testimonial',
        list: '/list',
        createOne: '/create-one',
        updateOne: '/update-one',
        deleteOne: '/delete-one',
    },
    userDetails: {
        root: '/api/users-details',
        list: '/list',
        updateStatus: '/deactivate',
        updateAdminStatus: '/make-admin',
        listPremium: '/premium',
    },
    userDetailsSubscription: {
        root: '/api/subscription',
        updateOne: '/update-one',
    },
    dailyActivities: {
        root: '/api/daily-activity',
        list: '/list',
        createOne: '/create-one',
        updateOne: '/update-one',
        deleteOne: '/delete-one',
    },
    dailyGreetings: {
        root: '/api/greeting',
        list: '/list',
        createOne: '/create-one',
        updateOne: '/update-one',
        deleteOne: '/delete-one',
    },
    notification: {
        root: '/api/notification',
        list: '/list-all',
        createOne: '/create-one',
        deleteOne: '/delete-one',
    },
    babyHealth: {
        root: '/api/baby-health',
        list: '/list',
        createOne: '/create-one',
        updateOne: '/update-one',
        deleteOne: '/delete-one',
    },
    story: {
        root: '/api/story',
        list: '/list',
        createOne: '/create-one',
        updateOne: '/update-one',
        deleteOne: '/delete-one',
    },
    raagSanskar: {
        root: '/api/raag-sanskar',
        list: '/list',
        createOne: '/create-one',
        updateOne: '/update-one',
        deleteOne: '/delete-one',
    },
    brainActivity: {
        root: '/api/brain-activity',
        list: '/list',
        createOne: '/create-one',
        updateOne: '/update-one',
        deleteOne: '/delete-one',
    },
    recipe: {
        root: '/api/daily-recipe',
        list: '/list',
        createOne: '/create-one',
        updateOne: '/update-one',
        deleteOne: '/delete-one',
    },
    blogs: {
        root: '/api/blogs',
        list: '/list',
        createOne: '/create-one',
        updateOne: '/update-one',
        deleteOne: '/delete-one',
    },
    careers: {
        root: '/api/join-us',
        list: '/list',
        deleteOne: '/delete-one',
    },
    coupon: {
        root: '/api/coupon',
        list: '/list',
        createOne: '/create-one',
        deleteOne: '/delete-one',
    },
    consultation: {
        root: '/api/consultation',
        list: '/list',
        updateOne: '/update-one',
    },
    deliveryDate: {
        root: '/api/delivery-date',
        list: '/list',
        updateOne: '/update-one',
    },
    subscriptionOrder: {
        root: '/api/order',
        list: '/list-subscriptions',
    },
    order: {
        root: '/api/order',
        list: '/list-all',
        updateOne: '/status',
    },
    stats: {
        root: '/api/stats',
        allUsers: '/all-users',
        allSubscribers: '/all-subscribers',
        newOrders: '/new-orders',
        shippedOrders: '/shipped-orders',
    },
    homeRemedies: {
        root: '/api/home-remidies',
        listAll: '/list-all',
        create: '/create',
        update: '/update',
        delete: '/delete',
    },
};