import axios from "axios";
import { config } from "./confg";
import { axiosCallAdvanced } from "./main";

const storedToken = window.localStorage.getItem(config.storageTokenKeyName);

//for uploading image
export const handleImageFile = async (file) => {
  const formData = new FormData();
  formData.append("file", file);
  const res = await axios.post(
    config.root + config.fileUpload.imageUpload,
    formData,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
         Authorization: storedToken,
      },
      withCredentials: true,
    }
  );
  return res.data;
};


//for uploading audio and video
export const handleMediaFile = async (file) => {
  const formData = new FormData();
  formData.append("file", file);
  const res = await axios.post(
    config.root + config.fileUpload.mediaUpload,
    formData,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
        Authorization: storedToken,
      },
      withCredentials: true,
    }
  );
  return res.data;
};

