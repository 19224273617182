import React, { useState, useRef } from "react";
import toast from 'react-hot-toast';
import 'assets/styles/richText.css';
import ReactQuill from 'react-quill';
import { useDispatch } from 'react-redux';
import Modal from 'lib/Modal';
import Input from 'lib/Input';
import PictureUpload from 'lib/PictureUpload';
import Loader from 'components/Loader/Loader';
import RecordedSeminarsTable from 'components/Cards/RecordedSeminarsTable';
import { constants } from 'utils/constants';
import { handleImageFile, handleMediaFile } from 'api/fileUploadAPI';
import { createOne } from 'redux/recordedSeminarSlice';

const RecordedSeminars = () => {
    const editor = useRef(null);
    const dispatch = useDispatch();
    const [modalOpen, setModalOpen] = useState(false);

    const [userCategory, setUserCategory] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    
    const [videoURL, setVideoURL] = useState('');
    const [videoOption, setVideoOption] = useState(1);

    const [thumbnailURL, setThumbnailURL] = useState('');
    const [thumbnailOption, setThumbnailOption] = useState(1);

    const [isActive, setIsActive] = useState(true);
    const [formError, setFormError] = useState(false);
    const [isIntroLoader, setIsIntroLoader] = useState(false);

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setUserCategory('');
        setTitle('');
        setVideoURL('');
        setFormError(false);
    };

    const handleAddSeminar = () => {
        if (
            userCategory.trim() !== '' &&
            title.trim() !== '' &&
            description.trim() !== '' &&
            thumbnailURL.trim() !== '' &&
            videoURL.trim() !== ''
        ) {
            const newSeminar = {
                userCategory: userCategory,
                title: title,
                description: description,
                thumbnailURL: thumbnailURL,
                videoURL: videoURL,
                isActive: isActive,
            };
            dispatch(createOne(newSeminar));
            setModalOpen(false);
            setUserCategory('');
            setTitle('');
            setTitle('');
            setThumbnailURL('');
            setVideoURL('');
            setThumbnailOption(1);
            setVideoOption(1);
            setFormError(false);
        } else {
            setFormError(true);
        }
    };

    const handleThumbnailURL = async (file) => {
        if (file === undefined) {
            setThumbnailURL('');
            return;
        }

        try {
            setIsIntroLoader(true);
            const res = await handleImageFile(file);
            if (res.success) {
                setThumbnailURL(res.file_url);
                toast.success('Successfully Uploaded the Image!');
            } else {
                throw new Error('Picture Not Successfully Uploaded');
            }
            setIsIntroLoader(false);
        } catch (error) {
            toast.error(error);
        }
    };

    const handleQuillChange = (value) => {
        setDescription(value);
    };

    const handleIntroMedia = async (file) => {
        if (file === undefined) {
            setVideoURL('');
            return;
        }

        try {
            setIsIntroLoader(true);
            const res = await handleMediaFile(file);
            if (res.success) {
                setVideoURL(res.file_url);
                toast.success('Successfully Uploaded the Video!');
            } else {
                throw new Error('Video Not Successfully Uploaded');
            }
            setIsIntroLoader(false);
        } catch (error) {
            toast.error(error);
        }
    };

    return (
        <>
            <Modal isOpen={modalOpen} onClose={handleCloseModal}>
                <div>
                    <Input
                        onChange={(e) => setUserCategory(e.target.value)}
                        defaultValue={userCategory}
                        label="Recorded Seminar Category"
                        isRequired
                        options={constants.LiveSeminarCategory}
                        select
                    />
                    <Input
                        onChange={(e) => setTitle(e.target.value)}
                        defaultValue={title}
                        label="Recorded Seminar Title"
                        isRequired
                    />
                    <div className="flex flex-wrap">
                        <div className="w-full lg:w-12/12 px-4">
                            <div className="relative w-full mb-3">
                                <label
                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                    htmlFor="grid-password"
                                >
                                    Description{' '}
                                    <span className="red-asterisk"></span>
                                </label>
                                <ReactQuill
                                    style={{
                                        background: '#fff',
                                    }}
                                    className="ql-container ql-snow"
                                    ref={editor}
                                    modules={constants.modules}
                                    formats={constants.formats}
                                    defaultValue={description}
                                    onChange={handleQuillChange}
                                />
                            </div>
                        </div>
                    </div>
                    <Input
                        label="Thumbnail Option"
                        onChange={(e) => setThumbnailOption(parseInt(e.target.value))}
                        value={thumbnailOption}
                        options={[
                            { id: 1, value: 'Upload Thumbnail' },
                            { id: 2, value: 'Thumbnail URL' },
                        ]}
                        select
                        isRequired
                    />
                    {isIntroLoader && <Loader />}
                    {thumbnailOption === 1 ? (
                        <PictureUpload
                            onChange={handleThumbnailURL}
                            defaultValue={thumbnailURL}
                            label="Thumbnail URL"
                            isRequired
                        />
                    ) : (
                        <Input
                            onChange={(e) =>
                                setThumbnailURL(e.target.value)
                            }
                            value={thumbnailURL}
                            label="Thumbnail URL"
                            type="text"
                            isRequired
                        />
                    )}
                    <Input
                        label="Video Option"
                        onChange={(e) => setVideoOption(parseInt(e.target.value))}
                        value={videoOption}
                        options={[
                            { id: 1, value: 'Upload Video' },
                            { id: 2, value: 'Video URL' },
                        ]}
                        select
                        isRequired
                    />
                    {isIntroLoader && <Loader />}
                    {videoOption === 1 ? (
                        <PictureUpload
                            onChange={handleIntroMedia}
                            accept="video/*"
                            defaultValue={videoURL}
                            label="Upload Recorded Seminar"
                            isRequired
                        />
                    ) : (
                        <Input
                            onChange={(e) => setVideoURL(e.target.value)}
                            value={videoURL}
                            label="Recorded Seminar URL"
                            type="text"
                            isRequired
                        />
                    )}
                    <Input
                        onChange={(e) => setIsActive(e.target.value)}
                        defaultValue={isActive}
                        options={[
                            { id: true, value: 'Active' },
                            { id: false, value: 'Inactive' },
                        ]}
                        select
                        label="Status"
                        isRequired
                    />
                    {formError && (
                        <p className="text-red-500 mt-2">
                            Please fill mandatory fields!
                        </p>
                    )}
                    <div className="flex gap-2 mt-4">
                        <button
                            onClick={handleAddSeminar}
                            className="bg-pink text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                            type="button"
                        >
                            Save
                        </button>
                    </div>
                </div>
            </Modal>
            <div className="flex flex-wrap mt-4">
                <div className="w-full mb-12 px-4">
                    <RecordedSeminarsTable
                        handleOpenModal={handleOpenModal}
                        color="dark"
                    />
                </div>
            </div>
        </>
    );
};

export default RecordedSeminars;
