import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getBabyHealth,
  createBabyHealth,
  updateBabyHealth,
  deleteBabyHealth,
} from "api/babyHealthAPI";

export const getBabyHealthList = createAsyncThunk(
  "babyHealth/list",
  async () => {
    try {
      const res = await getBabyHealth();
      if (res.success) {
        return res.data;
      } else {
        throw new Error("Error fetching Baby Health.");
      }
    } catch (error) {
      throw error;
    }
  }
);
export const createOneBabyHealth = createAsyncThunk(
  "babyHealth/create-one",
  async (data) => {
    try {
      const res = await createBabyHealth(data);
      if (res.success) {
        return res.data;
      } else {
        throw new Error("Error creating Baby Health.");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const deleteOneBabyHealth = createAsyncThunk(
  "babyHealth/delete-one",
  async (id) => {
    try {
      const res = await deleteBabyHealth(id);
      if (res.success) {
        return id;
      } else {
        throw new Error("Error deleting baby Health.");
      }
    } catch (error) {
      throw error;
    }
  }
);
export const updateOneBabyHealth = createAsyncThunk(
  "babyHealth/update-one",
  async ({ id, data }) => {
    try {
      const res = await updateBabyHealth(id, data);
      if (res.success) {
        return res.data;
      } else {
        throw new Error("Error updating baby Health.");
      }
    } catch (error) {
      throw error;
    }
  }
);

const babyHealthSlice = createSlice({
  name: "babyHealth",
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBabyHealthList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBabyHealthList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getBabyHealthList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createOneBabyHealth.pending, (state) => {
        state.loading = true;
      })
      .addCase(createOneBabyHealth.fulfilled, (state, action) => {
        state.loading = false;
        state.data.push(action.payload);
      })
      .addCase(createOneBabyHealth.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateOneBabyHealth.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateOneBabyHealth.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        const babyIndex = state.data.findIndex(
          (baby) => baby.id === action.payload.id
        );
        if (babyIndex !== -1) {
          state.data[babyIndex] = action.payload;
        }
      })

      .addCase(updateOneBabyHealth.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteOneBabyHealth.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteOneBabyHealth.fulfilled, (state, action) => {
        state.loading = false;
        state.data = state.data.filter((baby) => baby.id !== action.payload);
      })
      .addCase(deleteOneBabyHealth.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const babyHealthAction = babyHealthSlice.actions;
export default babyHealthSlice;
