import React, { useState } from "react";
import Modal from "lib/Modal";
import Input from "lib/Input";
import Loader from 'components/Loader/Loader';
import GarbhaSamvadTable from 'components/Cards/GarbhaSamvadTable';
import PictureUpload from 'lib/PictureUpload';
import { constants } from 'utils/constants';
import { useDispatch } from 'react-redux';
import { createNewGarbha } from 'redux/garbhaSamvadSlice';
import toast from 'react-hot-toast';
import { handleImageFile, handleMediaFile } from 'api/fileUploadAPI';

const GarbhaSamvad = () => {
    const dispatch = useDispatch();

    const [modalOpen, setModalOpen] = useState(false);
    const [userCategory, setUserCategory] = useState('');
    const [type, setType] = useState('');
    const [content, setContent] = useState('');
    const [title, setTitle] = useState('');
    const [month, setMonth] = useState('');
    const [videoURL, setVideoURL] = useState('');
    const [audioURL, setAudioURL] = useState('');
    const [thumbnailURL, setThumbnailURL] = useState('');
    const [mood, setMood] = useState('');
    const [isActive, setIsActive] = useState(true);
    const [formError, setFormError] = useState(false);

    const [videoOption, setVideoOption] = useState(1);
    const [thumbnailOption, setThumbnailOption] = useState(1);
    const [audioOption, setAudioOption] = useState(1);

    const [isVideoIntroLoader, setVideoIsIntroLoader] = useState(false);
    const [isAudioIntroLoader, setAudioIsIntroLoader] = useState(false);
    const [isIntroLoader, setIsIntroLoader] = useState(false);

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setMonth('');
        setUserCategory('');
        setType('');
        setContent('');
        setVideoURL('');
        setTitle('');
        setThumbnailURL('');
        setMood('');
        setFormError(false);
    };

    const handleSaveGarbhaSamvadData = () => {
        if (
            // day.trim() !== "" &&
            thumbnailURL.trim() !== '' &&
            mood.trim() !== '' &&
            type.trim() !== '' &&
            title.trim() !== '' &&
            audioURL.trim() !== '' &&
            content.trim() !== '' &&
            userCategory !== ''
        ) {
            const newPractice = {
                //request body
                month: parseInt(month),
                thumbnailURL: thumbnailURL,
                mood: mood,
                type: type,
                userCategory: userCategory,
                content: content,
                audioURL: audioURL,
                videoURL: videoURL,
                title: title,
                isActive: isActive,
            };
            dispatch(createNewGarbha(newPractice)); //

            setModalOpen(false);
            setMonth('');
            setTitle('');
            setContent('');
            setVideoURL('');
            setAudioURL('');
            setThumbnailURL('');
            setMood('');
            setIsActive(true);
            setFormError(false);
            setThumbnailOption(1);
            setVideoOption(1);
            setAudioOption(1);
        } else {
            setFormError(true);
        }
    };

    const handleSelectChange = (e) => {
        const selectedValues = Array.from(
            e.target.selectedOptions,
            (option) => option.value
        );
        setMood(selectedValues.toString());
    };

    const handleIntroMedia = async (file) => {
        if (file === undefined) {
            setThumbnailURL('');
            return;
        }
        try {
            setIsIntroLoader(true);
            const res = await handleImageFile(file);
            if (res.success) {
                setThumbnailURL(res.file_url);
                toast.success('Successfully Uploaded the Image!');
            } else {
                throw new Error('Picture Not Successfully Uploaded');
            }
            setIsIntroLoader(false);
        } catch (error) {
            toast.error(error);
        }
    };

    const handleMedia = async (file) => {
        if (file === undefined) {
            setVideoURL('');
            return;
        }

        try {
            setVideoIsIntroLoader(true);
            const res = await handleMediaFile(file);
            if (res.success) {
                setVideoURL(res.file_url);

                toast.success('Successfully Uploaded the Video!');
            } else {
                throw new Error('Picture Not Successfully Uploaded');
            }
            setVideoIsIntroLoader(false);
        } catch (error) {
            toast.error(error);
        }
    };

    const handleMediaAudio = async (file) => {
        if (file === undefined) {
            setAudioURL('');
            return;
        }

        try {
            setAudioIsIntroLoader(true);
            const res = await handleMediaFile(file);
            if (res.success) {
                setAudioURL(res.file_url);
                toast.success('Successfully Uploaded the Audio!');
            } else {
                throw new Error('Picture Not Successfully Uploaded');
            }
            setAudioIsIntroLoader(false);
        } catch (error) {
            toast.error(error);
        }
    };

    return (
        <>
            <Modal isOpen={modalOpen} onClose={handleCloseModal}>
                <div>
                    <Input
                        onChange={(e) => setUserCategory(e.target.value)}
                        value={userCategory}
                        label="User Category"
                        options={constants.garbhaSamvadCategory}
                        select
                        isRequired
                    />
                    <Input
                        onChange={(e) => setType(e.target.value)}
                        value={type}
                        label="Type"
                        options={constants.garbhaType}
                        select
                        isRequired
                    />
                    {type === '1' ? (
                        <Input
                            onChange={(e) => setMonth(e.target.value)}
                            value={month}
                            label="Month"
                        />
                    ) : null}
                    <Input
                        label="Thumbnail Option"
                        onChange={(e) =>
                            setThumbnailOption(parseInt(e.target.value))
                        }
                        value={thumbnailOption}
                        options={[
                            { id: 1, value: 'Upload Thumbnail' },
                            { id: 2, value: 'Thumbnail URL' },
                        ]}
                        select
                        isRequired
                    />
                    {isIntroLoader && <Loader />}
                    {thumbnailOption === 1 ? (
                        <PictureUpload
                            onChange={handleIntroMedia}
                            value={thumbnailURL}
                            label="Thumbnail URL"
                            isRequired
                        />
                    ) : (
                        <Input
                            onChange={(e) => setThumbnailURL(e.target.value)}
                            value={thumbnailURL}
                            label="Thumbnail URL"
                            isRequired
                        />
                    )}
                    <Input
                        onChange={(e) => setTitle(e.target.value)}
                        value={title}
                        label="Title"
                        isRequired
                    />
                    <Input
                        onChange={(e) => setContent(e.target.value)}
                        value={content}
                        label="Content"
                        textarea
                        rows={4}
                        isRequired
                    />
                    <Input
                        label="Video Option"
                        onChange={(e) =>
                            setVideoOption(parseInt(e.target.value))
                        }
                        value={videoOption}
                        options={[
                            { id: 1, value: 'Upload Video' },
                            { id: 2, value: 'Video URL' },
                        ]}
                        select
                    />
                    {isVideoIntroLoader && <Loader />}
                    {videoOption === 1 ? (
                        <PictureUpload
                            onChange={handleMedia}
                            value={videoURL}
                            label="Video Url"
                            accept="image/*, video/*"
                        />
                    ) : (
                        <Input
                            onChange={(e) => setVideoURL(e.target.value)}
                            value={videoURL}
                            label="Video Url"
                        />
                    )}
                    <Input
                        label="Audio Option"
                        onChange={(e) =>
                            setAudioOption(parseInt(e.target.value))
                        }
                        value={audioOption}
                        options={[
                            { id: 1, value: 'Upload Audio' },
                            { id: 2, value: 'Audio URL' },
                        ]}
                        select
                        isRequired
                    />
                    {isAudioIntroLoader && <Loader />}
                    {audioOption === 1 ? (
                        <PictureUpload
                            onChange={handleMediaAudio}
                            value={audioURL}
                            isRequired
                            label="Audio Url"
                            accept="image/*, video/*"
                        />
                    ) : (
                        <Input
                            onChange={(e) => setAudioURL(e.target.value)}
                            value={audioURL}
                            label="Audio Url"
                            isRequired
                        />
                    )}
                    <div>
                        <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                        >
                            Mood :
                        </label>
                        <div className="flex flex-wrap">
                            <div className="w-full lg:w-12/12 px-4">
                                <div className="relative w-full mb-3">
                                    <select
                                        multiple
                                        onChange={handleSelectChange}
                                        isRequired
                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    >
                                        {constants.garbhaMood?.map(
                                            (category) => (
                                                <option key={category.id}>
                                                    {category.value}
                                                </option>
                                            )
                                        )}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Input
                        onChange={(e) => setIsActive(e.target.value)}
                        defaultValue={isActive}
                        options={[
                            { id: true, value: 'Active' },
                            { id: false, value: 'Inactive' },
                        ]}
                        select
                        isRequired
                        label="Visibility State"
                    />
                    {formError && (
                        <p className="text-red-500 mt-2">
                            Please fill all the details!
                        </p>
                    )}
                    <div className="flex gap-2 mt-4">
                        <button
                            onClick={handleSaveGarbhaSamvadData}
                            className="bg-pink text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                            type="button"
                        >
                            Save
                        </button>
                    </div>
                </div>
            </Modal>

            <div className="flex flex-wrap mt-4">
                <div className="w-full mb-12 px-4">
                    <GarbhaSamvadTable handleOpenModal={handleOpenModal} />
                </div>
            </div>
        </>
    );
};

export default GarbhaSamvad;
