import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getGreetings,
  createGreetings,
  updateGreetings,
  deleteGreetings,
} from "api/greetingAPI"; //file name

export const getNewGreetings = createAsyncThunk("greeting/list", async () => {
  try {
    const res = await getGreetings();
    if (res.success) {
      return res.data;
    } else {
      throw new Error("Error fetching data");
    }
  } catch (error) {
    throw error; //action.error.message goes
  }
});

export const createNewGreetings = createAsyncThunk(
  "greeting/create-one",
  async (data) => {
    try {
      const res = await createGreetings(data);
      return res.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateNewGreetings = createAsyncThunk(
  "greeting/update-one",
  async (data) => {
    try {
      const res = await updateGreetings(data);
      return res.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteNewGreetings = createAsyncThunk(
  "greeting/delete-one",
  async (id) => {
    try {
      const res = await deleteGreetings(id);
      return id;
    } catch (error) {
      throw error;
    }
  }
);

const dailyGreetingSlice = createSlice({
  name: "dailyGreeting",
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducer: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNewGreetings.pending, (state) => {
        state.loading = true;
      })
      .addCase(getNewGreetings.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getNewGreetings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(createNewGreetings.pending, (state) => {
        state.loading = true;
      })
      .addCase(createNewGreetings.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.data.push(action.payload);
        }
      })
      .addCase(createNewGreetings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(updateNewGreetings.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateNewGreetings.fulfilled, (state, action) => {
        state.loading = false;
        const testimonialIndex = state.data.findIndex(
          (daily) => daily.id === action.payload.id
        );
        if (testimonialIndex !== -1) {
          state.data[testimonialIndex] = action.payload;
        }
      })
      .addCase(updateNewGreetings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteNewGreetings.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteNewGreetings.fulfilled, (state, action) => {
        state.loading = false;
        state.data = state.data.filter(
          (testimonial) => testimonial.id !== action.payload
        );
      })
      .addCase(deleteNewGreetings.rejected, (state, action) => {
        state.loading = false;
        state.data = action.error.message;
      });
  },
});

export const dailyGreetingAction = dailyGreetingSlice.actions;
export default dailyGreetingSlice;
