import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Modal from "lib/Modal";
import Input from "lib/Input";
import PictureUpload from "lib/PictureUpload";

import ShlokaTable from "components/Cards/ShlokaTable";
import { createNewShloka } from "redux/shlokaSlice";
import { constants } from "utils/constants";
import toast from "react-hot-toast";
import { handleImageFile, handleMediaFile } from "api/fileUploadAPI";
import Loader from "components/Loader/Loader";

const Shloka = () => {
  const [modalOpen, setModalOpen] = useState(false);
  
  const [userCategory, setUserCategory] = useState("");
  const [day, setDay] = useState("");
  const [shlokaText, setShlokaText] = useState("");
  const [mediaType, setMediaType] = useState("");
  const [mediaURL, setMediaURL] = useState("");
  const [shlokaMeaningText, setShlokaMeaningText] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [isIntroLoader, setIsIntroLoader] = useState(false);
  const [formError, setFormError] = useState(false);

  const dispatch = useDispatch();

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleCreateNew = async () => {
    if (
      day.trim() !== "" &&
      userCategory !== "" &&
      shlokaText.trim() !== "" &&
      shlokaMeaningText.trim() !== ""
    ) {
      const data = {
        day: parseInt(day),
        userCategory: userCategory,
        shlokaText: shlokaText.trim(),
        shlokaMeaningText: shlokaMeaningText.trim(),
        mediaURL: mediaURL.trim(),
        mediaType: mediaType,
        isActive: isActive,
      };

      dispatch(createNewShloka({ ...data }));
      setModalOpen(false);
      setDay("");
      setShlokaText("");
      setShlokaMeaningText("");
      setMediaURL([""]);
      setFormError(false);
    } else {
      setFormError(true);
    }
  };

  function handleMediaType(type) {
    setMediaType(type);
    setMediaURL("");
  }

  const handleIntroMedia = async (file) => {
    if (file === undefined) {
      setMediaURL("");
      return;
    }
    
    if (mediaType === "1") {
      //media url accepts file as string
      try {
        setIsIntroLoader(true);
        const res = await handleImageFile(file);
        if (res.success) {
          setMediaURL(res.file_url);

          toast.success("Successfully Uploaded the Image!");
        } else {
          throw new Error("Picture Not Successfully Uploaded");
        }
        setIsIntroLoader(false);
      } catch (error) {
        toast.error(error);
      }
    } else {
      try {
        setIsIntroLoader(true);
        const res = await handleMediaFile(file);
        if (res.success) {
          setMediaURL(res.file_url);
          mediaType === "2"
            ? toast.success("Successfully Uploaded the Audio!")
            : toast.success("Successfully Uploaded the video!");
        } else {
          throw new Error("Picture Not Successfully Uploaded");
        }
        setIsIntroLoader(false);
      } catch (error) {
        toast.error(error);
      }
    }
  };
  return (
    <>
      <Modal isOpen={modalOpen} onClose={handleCloseModal}>
        <div>
          <Input
            onChange={(e) => setDay(e.target.value)}
            value={day}
            label="Day"
            isRequired
          />
          <Input
            onChange={(e) => setUserCategory(e.target.value)}
            value={userCategory}
            label="User Category"
            isRequired
            options={constants.category}
            select
          />
          <Input
            onChange={(e) => setShlokaText(e.target.value)}
            value={shlokaText}
            label="Shloka"
            isRequired
          />
          <Input
            onChange={(e) => setShlokaMeaningText(e.target.value)}
            value={shlokaMeaningText}
            label="Shloka Meaning"
            isRequired
          />

          <Input
            onChange={(e) => handleMediaType(e.target.value)}
            label="Media Type"
            isRequired
            value={mediaType}
            select
            options={constants.mediaOption}
          />
          {isIntroLoader && <Loader />}
          {mediaType === "0" ? null : ( // null for no media
            <PictureUpload
              onChange={handleIntroMedia}
              value={mediaURL}
              label={
                mediaType === "3"
                  ? "Intro Video"
                  : mediaType === "2"
                  ? "Intro Audio"
                  : "Intro Image"
              }
              accept="image/*, video/*"
            />
          )}

          <Input
            onChange={(e) => setIsActive(e.target.value)}
            defaultValue={isActive}
            options={[
              { id: true, value: "Active" },
              { id: false, value: "Inactive" },
            ]}
            select
            label="Status"
            isRequired
          />
          {formError && (
            <p className="mx-4 text-red-500 mb-2">
              Please fill all the details!
            </p>
          )}
          <div className="flex gap-2">
            <button
              onClick={handleCreateNew}
              className="bg-pink mx-4 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
            >
              Save
            </button>
          </div>
        </div>
      </Modal>

      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <ShlokaTable
            handleOpenModal={handleOpenModal}
            color="dark"
          />
        </div>
      </div>
    </>
  );
};

export default Shloka;
