import { config } from "./confg";
import { axiosCallAdvanced } from "./main";

export const getBabyHealth = async () => {
  return (
    (
      await axiosCallAdvanced({
        baseURL: config.babyHealth.root,
        path: config.babyHealth.list,
      })
    )?.data || {}
  );
};
export const createBabyHealth = async (data) => {
  return (
    (
      await axiosCallAdvanced({
        baseURL: config.babyHealth.root,
        path: config.babyHealth.createOne,
        body: { ...data },
        method: config.methodType.post,
      })
    )?.data || {}
  );
};

export const deleteBabyHealth = async (parameters) => {
  return (
    (
      await axiosCallAdvanced({
        baseURL: config.babyHealth.root,
        path: `${config.babyHealth.deleteOne}/${parameters}`,
        method: config.methodType.patch,
      })
    )?.data || {}
  );
};

export const updateBabyHealth = async (id, data) => {
  return (
    (
      await axiosCallAdvanced({
        baseURL: config.babyHealth.root,
        path: `${config.babyHealth.updateOne}/${id}`,
        body: { ...data },
        method: config.methodType.put,
      })
    )?.data || {}
  );
};
