/*eslint-disable*/
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";

import NotificationDropdown from "components/Dropdowns/NotificationDropdown.js";
import UserDropdown from "components/Dropdowns/UserDropdown.js";

import Logo from "../../assets/img/gopreg-logo-light.png";
import "assets/styles/formValidation.css";
import Modal from "lib/Modal";

export default function Sidebar() {
    const [collapseShow, setCollapseShow] = React.useState("hidden");
    const [modalOpen, setModalOpen] = useState(false);
    const history = useHistory();

    const handleCloseModal = () => {
        setModalOpen(false);
    };
    const handleLogout = () => {
        localStorage.clear("mobile", "accessToken");
        if (
            !localStorage.getItem("mobile") &&
            !localStorage.getItem("accessToken")
        ) {
            setModalOpen(false);
            history.push("/auth/login");
        }
    };
    return (
        <>
            <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-grey flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6">
                <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
                    {/* Toggler */}
                    <button
                        className="cursor-pointer text-white opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                        type="button"
                        onClick={() => setCollapseShow('bg-grey m-2 py-3 px-6')}
                    >
                        <i className="fas fa-bars"></i>
                    </button>
                    {/* Brand */}
                    <div className="md:block text-left md:pb-2 text-white mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0">
                        <img src={Logo} alt="GoPreg Logo" className="h-16" />
                    </div>
                    {/* User */}
                    <ul className="md:hidden items-center flex flex-wrap list-none">
                        <li className="inline-block relative">
                            <NotificationDropdown />
                        </li>
                        <li className="inline-block relative">
                            <UserDropdown />
                        </li>
                    </ul>
                    {/* Collapse */}
                    <div
                        className={
                            'md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded ' +
                            collapseShow
                        }
                    >
                        {/* Collapse header */}
                        <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
                            <div className="flex flex-wrap">
                                <div className="w-6/12">
                                    <img
                                        src={Logo}
                                        alt="GoPreg Logo"
                                        className="h-10"
                                    />
                                </div>
                                <div className="w-6/12 flex justify-end">
                                    <button
                                        type="button"
                                        className="cursor-pointer text-white opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                                        onClick={() => setCollapseShow('hidden')}
                                    >
                                        <i className="fas fa-times"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/* Form */}
                        {/* <form className="mt-6 mb-4 md:hidden">
                <div className="mb-3 pt-0">
                    <input
                    type="text"
                    placeholder="Search"
                    className="border-0 px-3 py-2 h-12 border border-solid  border-blueGray-500 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
                    />
                </div>
                </form> */}

                        {/* Divider */}
                        <hr className="my-4 md:min-w-full" />
                        {/* Heading */}
                        <h6 className="md:min-w-full text-white text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                            Pages
                        </h6>
                        {/* Navigation */}

                        <ul className="md:flex-col md:min-w-full flex flex-col list-none">
                            <li className="items-center">
                                <Link
                                    className={
                                        'text-xs uppercase py-3 font-bold block ' +
                                        (window.location.href.indexOf(
                                            '/admin/dashboard'
                                        ) !== -1
                                            ? 'text-pink'
                                            : 'text-white')
                                    }
                                    to="/admin/dashboard"
                                >
                                    <i
                                        className={
                                            'fas fa-tv mr-2 text-sm ' +
                                            (window.location.href.indexOf(
                                                '/admin/dashboard'
                                            ) !== -1
                                                ? 'opacity-75'
                                                : 'text-white')
                                        }
                                    ></i>{' '}
                                    Dashboard
                                </Link>
                            </li>

                            {/* <li className="items-center">
                                    <Link
                                    className={
                                        "text-xs uppercase py-3 font-bold block " +
                                        (window.location.href.indexOf("/admin/settings") !== -1
                                        ? "text-pink"
                                        : "text-white")
                                    }
                                    to="/admin/settings"
                                    >
                                    <i
                                        className={
                                        "fas fa-tools mr-2 text-sm " +
                                        (window.location.href.indexOf("/admin/settings") !== -1
                                            ? "opacity-75"
                                            : "text-white")
                                        }
                                    ></i>{" "}
                                    Settings
                                    </Link>
                                </li> */}

                            {/* Divider */}
                            <hr className="my-4 md:min-w-full" />
                            {/* Heading */}
                            <h6 className="md:min-w-full text-white text-xs uppercase text-align-center font-bold block pt-1 pb-4 no-underline">
                                Tables
                            </h6>
                            {/* <li className="items-center">
                                <Link
                                className={
                                    "text-xs uppercase py-3 font-bold block " +
                                    (window.location.href.indexOf("/admin/master-tables") !== -1
                                    ? "text-pink"
                                    : "text-white")
                                }
                                to="/admin/master-tables"
                                > */}
                            {/* <i class="fa-light fa-list-tree"></i> */}
                            {/* <i class="fa-solid fa-list-tree"></i> */}
                            {/* <span> */}
                            {/* <MdOutlineAccountTree
                                className={
                                    "fas fa-table mr-2 text-sm" +
                                    (window.location.href.indexOf("/admin/tables") !== -1
                                    ? "opacity-75"
                                    : "text-white")
                                }
                                /> */}
                            {/* <i
                                        className={
                                            "fas fa-users mr-2 text-sm" +
                                            (window.location.href.indexOf("/admin/user-tables") !==
                                            -1
                                            ? "opacity-75"
                                            : "text-white")
                                        }
                                        ></i>{" "}
                                    </span>
                                    User Category
                                    </Link>
                                </li> */}

                            <li className="items-center">
                                <Link
                                    className={
                                        'text-xs uppercase py-3 font-bold block ' +
                                        (window.location.href.indexOf(
                                            '/admin/user-tables'
                                        ) !== -1
                                            ? 'text-pink'
                                            : 'text-white')
                                    }
                                    to="/admin/user-tables"
                                >
                                    <i
                                        className={
                                            'fas fa-users mr-2 text-sm' +
                                            (window.location.href.indexOf(
                                                '/admin/user-tables'
                                            ) !== -1
                                                ? 'opacity-75'
                                                : 'text-white')
                                        }
                                    ></i>{' '}
                                    Users
                                </Link>
                            </li>

                            <li className="items-center">
                                <Link
                                    className={
                                        'text-xs uppercase py-3 font-bold block ' +
                                        (window.location.href.indexOf(
                                            '/admin/Customer'
                                        ) !== -1
                                            ? 'text-pink'
                                            : 'text-white')
                                    }
                                    to="/admin/Customer"
                                >
                                    <i
                                        className={
                                            'fas fa-map-marked mr-2 text-sm ' +
                                            (window.location.href.indexOf(
                                                '/admin/Customer'
                                            ) !== -1
                                                ? 'opacity-75'
                                                : 'text-white')
                                        }
                                    ></i>{' '}
                                    Subscribers
                                </Link>
                            </li>

                            <li className="items-center">
                                <Link
                                    className={
                                        'text-xs uppercase py-3 font-bold block ' +
                                        (window.location.href.indexOf(
                                            '/admin/orders/subscription'
                                        ) !== -1
                                            ? 'text-pink'
                                            : 'text-white')
                                    }
                                    to="/admin/orders/subscription"
                                >
                                    <i
                                        className={
                                            'fas fa-map-marked mr-2 text-sm ' +
                                            (window.location.href.indexOf(
                                                '/admin/orders/subscription'
                                            ) !== -1
                                                ? 'opacity-75'
                                                : 'text-white')
                                        }
                                    ></i>{' '}
                                    Subscription Orders
                                </Link>
                            </li>

                            <li className="items-center">
                                <Link
                                    className={
                                        'text-xs uppercase py-3 font-bold block ' +
                                        (window.location.href.indexOf(
                                            '/admin/delivery-dates'
                                        ) !== -1
                                            ? 'text-pink'
                                            : 'text-white')
                                    }
                                    to="/admin/delivery-dates"
                                >
                                    <i
                                        className={
                                            'fas fa-map-marked mr-2 text-sm ' +
                                            (window.location.href.indexOf(
                                                '/admin/delivery-dates'
                                            ) !== -1
                                                ? 'opacity-75'
                                                : 'text-white')
                                        }
                                    ></i>{' '}
                                    Delivery-Dates
                                </Link>
                            </li>
                            <br />
                            {/* <br /> */}

                            <h6 className="md:min-w-full text-white text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                                Aahar
                            </h6>

                            <li className="items-center">
                                <Link
                                    className={
                                        'text-xs uppercase py-3 font-bold block ' +
                                        (window.location.href.indexOf(
                                            '/admin/message-from-soul'
                                        ) !== -1
                                            ? 'text-pink'
                                            : 'text-white')
                                    }
                                    to="/admin/message-from-soul"
                                >
                                    <i
                                        className={
                                            'fas fa-map-marked mr-2 text-sm ' +
                                            (window.location.href.indexOf(
                                                '/admin/message-from-soul'
                                            ) !== -1
                                                ? 'opacity-75'
                                                : 'text-white')
                                        }
                                    ></i>{' '}
                                    Message From The Soul
                                </Link>
                            </li>

                            <li className="items-center">
                                <Link
                                    className={
                                        'text-xs uppercase py-3 font-bold block ' +
                                        (window.location.href.indexOf(
                                            '/admin/message-from-baby'
                                        ) !== -1
                                            ? 'text-pink'
                                            : 'text-white')
                                    }
                                    to="/admin/message-from-baby"
                                >
                                    <i
                                        className={
                                            'fas fa-map-marked mr-2 text-sm ' +
                                            (window.location.href.indexOf(
                                                '/admin/message-from-baby'
                                            ) !== -1
                                                ? 'opacity-75'
                                                : 'text-white')
                                        }
                                    ></i>{' '}
                                    Message From The Baby
                                </Link>
                            </li>

                            <li className="items-center">
                                <Link
                                    className={
                                        'text-xs uppercase py-3 font-bold block ' +
                                        (window.location.href.indexOf(
                                            '/admin/baby-health'
                                        ) !== -1
                                            ? 'text-pink'
                                            : 'text-white')
                                    }
                                    to="/admin/baby-health"
                                >
                                    <i
                                        className={
                                            'fas fa-map-marked mr-2 text-sm ' +
                                            (window.location.href.indexOf(
                                                '/admin/baby-health'
                                            ) !== -1
                                                ? 'opacity-75'
                                                : 'text-white')
                                        }
                                    ></i>{' '}
                                    Baby's Health
                                </Link>
                            </li>

                            <li className="items-center">
                                <Link
                                    className={
                                        'text-xs uppercase py-3 font-bold block ' +
                                        (window.location.href.indexOf(
                                            '/admin/recipe-of-the-day'
                                        ) !== -1
                                            ? 'text-pink'
                                            : 'text-white')
                                    }
                                    to="/admin/recipe-of-the-day"
                                >
                                    <i
                                        className={
                                            'fas fa-map-marked mr-2 text-sm ' +
                                            (window.location.href.indexOf(
                                                '/admin/recipe-of-the-day'
                                            ) !== -1
                                                ? 'opacity-75'
                                                : 'text-white')
                                        }
                                    ></i>{' '}
                                    Recipe of the day
                                </Link>
                            </li>

                            <li className="items-center">
                                <Link
                                    className={
                                        'text-xs uppercase py-3 font-bold block ' +
                                        (window.location.href.indexOf(
                                            '/admin/diet-and-nutrition'
                                        ) !== -1
                                            ? 'text-pink'
                                            : 'text-white')
                                    }
                                    to="/admin/diet-and-nutrition"
                                >
                                    <i
                                        className={
                                            'fas fa-map-marked mr-2 text-sm ' +
                                            (window.location.href.indexOf(
                                                '/admin/diet-and-nutrition'
                                            ) !== -1
                                                ? 'opacity-75'
                                                : 'text-white')
                                        }
                                    ></i>{' '}
                                    Diet And Nutrition
                                </Link>
                            </li>
                            <li className="items-center">
                                <Link
                                    className={
                                        'text-xs uppercase py-3 font-bold block ' +
                                        (window.location.href.indexOf(
                                            '/admin/greeting-table'
                                        ) !== -1
                                            ? 'text-pink'
                                            : 'text-white')
                                    }
                                    to="/admin/greeting-table"
                                >
                                    <i
                                        className={
                                            'fas fa-map-marked mr-2 text-sm ' +
                                            (window.location.href.indexOf(
                                                '/admin/greeting-table'
                                            ) !== -1
                                                ? 'opacity-75'
                                                : 'text-white')
                                        }
                                    ></i>{' '}
                                    Greeting
                                </Link>
                            </li>
                            <li className="items-center">
                                <Link
                                    className={
                                        'text-xs uppercase py-3 font-bold block ' +
                                        (window.location.href.indexOf(
                                            '/admin/notification'
                                        ) !== -1
                                            ? 'text-pink'
                                            : 'text-white')
                                    }
                                    to="/admin/notification"
                                >
                                    <i
                                        className={
                                            'fas fa-map-marked mr-2 text-sm ' +
                                            (window.location.href.indexOf(
                                                '/admin/notification'
                                            ) !== -1
                                                ? 'opacity-75'
                                                : 'text-white')
                                        }
                                    ></i>{' '}
                                    Notification
                                </Link>
                            </li>

                            <br />
                            <h6 className="md:min-w-full text-white text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                                Home Remedies
                            </h6>
                            <li className="items-center">
                                <Link
                                    className={
                                        'text-xs uppercase py-3 font-bold block ' +
                                        (window.location.href.indexOf(
                                            '/admin/home-remedies'
                                        ) !== -1
                                            ? 'text-pink'
                                            : 'text-white')
                                    }
                                    to="/admin/home-remedies"
                                >
                                    <i
                                        className={
                                            'fas fa-map-marked mr-2 text-sm ' +
                                            (window.location.href.indexOf(
                                                '/admin/home-remedies'
                                            ) !== -1
                                                ? 'opacity-75'
                                                : 'text-white')
                                        }
                                    ></i>{' '}
                                    Home Remedies
                                </Link>
                            </li>

                            <br />

                            <h6 className="md:min-w-full text-white text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                                Vihar
                            </h6>

                            <li className="items-center">
                                <Link
                                    className={
                                        'text-xs uppercase py-3 font-bold block ' +
                                        (window.location.href.indexOf(
                                            '/admin/shloka'
                                        ) !== -1
                                            ? 'text-pink'
                                            : 'text-white')
                                    }
                                    to="/admin/shloka"
                                >
                                    <i
                                        className={
                                            'fas fa-map-marked mr-2 text-sm ' +
                                            (window.location.href.indexOf(
                                                '/admin/shloka'
                                            ) !== -1
                                                ? 'opacity-75'
                                                : 'text-white')
                                        }
                                    ></i>{' '}
                                    Shloka
                                </Link>
                            </li>

                            <li className="items-center">
                                <Link
                                    className={
                                        'text-xs uppercase py-3 font-bold block ' +
                                        (window.location.href.indexOf(
                                            '/admin/strotra'
                                        ) !== -1
                                            ? 'text-pink'
                                            : 'text-white')
                                    }
                                    to="/admin/strotra"
                                >
                                    <i
                                        className={
                                            'fas fa-map-marked mr-2 text-sm ' +
                                            (window.location.href.indexOf(
                                                '/admin/strotra'
                                            ) !== -1
                                                ? 'opacity-75'
                                                : 'text-white')
                                        }
                                    ></i>{' '}
                                    Strotra
                                </Link>
                            </li>

                            <li className="items-center">
                                <Link
                                    className={
                                        'text-xs uppercase py-3 font-bold block ' +
                                        (window.location.href.indexOf(
                                            '/admin/garbha-samvad'
                                        ) !== -1
                                            ? 'text-pink'
                                            : 'text-white')
                                    }
                                    to="/admin/garbha-samvad"
                                >
                                    <i
                                        className={
                                            'fas fa-map-marked mr-2 text-sm ' +
                                            (window.location.href.indexOf(
                                                '/admin/garbha-samvad'
                                            ) !== -1
                                                ? 'opacity-75'
                                                : 'text-white')
                                        }
                                    ></i>{' '}
                                    Garbha Samvad
                                </Link>
                            </li>

                            <br />

                            <h6 className="md:min-w-full text-white text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                                Aachar
                            </h6>

                            <li className="items-center">
                                <Link
                                    className={
                                        'text-xs uppercase py-3 font-bold block ' +
                                        (window.location.href.indexOf(
                                            '/admin/daily-activities'
                                        ) !== -1
                                            ? 'text-pink'
                                            : 'text-white')
                                    }
                                    to="/admin/daily-activities"
                                >
                                    <i
                                        className={
                                            'fas fa-map-marked mr-2 text-sm ' +
                                            (window.location.href.indexOf(
                                                '/admin/daily-activities'
                                            ) !== -1
                                                ? 'opacity-75'
                                                : 'text-white')
                                        }
                                    ></i>{' '}
                                    Daily Activities
                                </Link>
                            </li>

                            <li className="items-center">
                                <Link
                                    className={
                                        'text-xs uppercase py-3 font-bold block ' +
                                        (window.location.href.indexOf(
                                            '/admin/yoga-practices'
                                        ) !== -1
                                            ? 'text-pink'
                                            : 'text-white')
                                    }
                                    to="/admin/yoga-practices"
                                >
                                    <i
                                        className={
                                            'fas fa-map-marked mr-2 text-sm ' +
                                            (window.location.href.indexOf(
                                                '/admin/yoga-practices'
                                            ) !== -1
                                                ? 'opacity-75'
                                                : 'text-white')
                                        }
                                    ></i>{' '}
                                    Yoga Practices
                                </Link>
                            </li>

                            <br />

                            <h6 className="md:min-w-full text-white text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                                Vichar
                            </h6>

                            <li className="items-center">
                                <Link
                                    className={
                                        'text-xs uppercase py-3 font-bold block ' +
                                        (window.location.href.indexOf(
                                            '/admin/story'
                                        ) !== -1
                                            ? 'text-pink'
                                            : 'text-white')
                                    }
                                    to="/admin/story"
                                >
                                    <i
                                        className={
                                            'fas fa-map-marked mr-2 text-sm ' +
                                            (window.location.href.indexOf(
                                                '/admin/story'
                                            ) !== -1
                                                ? 'opacity-75'
                                                : 'text-white')
                                        }
                                    ></i>{' '}
                                    Story
                                </Link>
                            </li>

                            <li className="items-center">
                                <Link
                                    className={
                                        'text-xs uppercase py-3 font-bold block ' +
                                        (window.location.href.indexOf(
                                            '/admin/brain-activity'
                                        ) !== -1
                                            ? 'text-pink'
                                            : 'text-white')
                                    }
                                    to="/admin/brain-activity"
                                >
                                    <i
                                        className={
                                            'fas fa-map-marked mr-2 text-sm ' +
                                            (window.location.href.indexOf(
                                                '/admin/brain-activity'
                                            ) !== -1
                                                ? 'opacity-75'
                                                : 'text-white')
                                        }
                                    ></i>{' '}
                                    Brain Activity
                                </Link>
                            </li>

                            <li className="items-center">
                                <Link
                                    className={
                                        'text-xs uppercase py-3 font-bold block ' +
                                        (window.location.href.indexOf(
                                            '/admin/rag-sanskar'
                                        ) !== -1
                                            ? 'text-pink'
                                            : 'text-white')
                                    }
                                    to="/admin/rag-sanskar"
                                >
                                    <i
                                        className={
                                            'fas fa-map-marked mr-2 text-sm ' +
                                            (window.location.href.indexOf(
                                                '/admin/rag-sanskar'
                                            ) !== -1
                                                ? 'opacity-75'
                                                : 'text-white')
                                        }
                                    ></i>{' '}
                                    Rag Sanskar
                                </Link>
                            </li>

                            <br />
                            <h6 className="md:min-w-full text-white text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                                Auryedic Mart
                            </h6>

                            <li className="items-center">
                                <Link
                                    className={
                                        'text-xs uppercase py-3 font-bold block ' +
                                        (window.location.href.indexOf(
                                            '/admin/produt-categories'
                                        ) !== -1
                                            ? 'text-pink'
                                            : 'text-white')
                                    }
                                    to="/admin/produt-categories"
                                >
                                    <i
                                        className={
                                            'fas fa-map-marked mr-2 text-sm ' +
                                            (window.location.href.indexOf(
                                                '/admin/produt-categories'
                                            ) !== -1
                                                ? 'opacity-75'
                                                : 'text-white')
                                        }
                                    ></i>{' '}
                                    Product Category
                                </Link>
                            </li>

                            <li className="items-center">
                                <Link
                                    className={
                                        'text-xs uppercase py-3 font-bold block ' +
                                        (window.location.href.indexOf(
                                            '/admin/product'
                                        ) !== -1
                                            ? 'text-pink'
                                            : 'text-white')
                                    }
                                    to="/admin/product"
                                >
                                    <i
                                        className={
                                            'fas fa-map-marked mr-2 text-sm ' +
                                            (window.location.href.indexOf(
                                                '/admin/product'
                                            ) !== -1
                                                ? 'opacity-75'
                                                : 'text-white')
                                        }
                                    ></i>{' '}
                                    Product
                                </Link>
                            </li>

                            <li className="items-center">
                                <Link
                                    className={
                                        'text-xs uppercase py-3 font-bold block ' +
                                        (window.location.href.indexOf(
                                            '/admin/coupons'
                                        ) !== -1
                                            ? 'text-pink'
                                            : 'text-white')
                                    }
                                    to="/admin/coupons"
                                >
                                    <i
                                        className={
                                            'fas fa-map-marked mr-2 text-sm ' +
                                            (window.location.href.indexOf(
                                                '/admin/coupons'
                                            ) !== -1
                                                ? 'opacity-75'
                                                : 'text-white')
                                        }
                                    ></i>{' '}
                                    Coupons
                                </Link>
                            </li>

                            <br />

                            <h6 className="md:min-w-full text-white text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                                Orders
                            </h6>

                            <li className="items-center">
                                <Link
                                    className={
                                        'text-xs uppercase py-3 font-bold block ' +
                                        (window.location.href.indexOf(
                                            '/admin/orders/confirm'
                                        ) !== -1
                                            ? 'text-pink'
                                            : 'text-white')
                                    }
                                    to="/admin/orders/confirm"
                                >
                                    <i
                                        className={
                                            'fas fa-map-marked mr-2 text-sm ' +
                                            (window.location.href.indexOf(
                                                '/admin/orders/confirm'
                                            ) !== -1
                                                ? 'opacity-75'
                                                : 'text-white')
                                        }
                                    ></i>{' '}
                                    Received
                                </Link>
                            </li>
                            <li className="items-center">
                                <Link
                                    className={
                                        'text-xs uppercase py-3 font-bold block ' +
                                        (window.location.href.indexOf(
                                            '/admin/orders/in-transit'
                                        ) !== -1
                                            ? 'text-pink'
                                            : 'text-white')
                                    }
                                    to="/admin/orders/in-transit"
                                >
                                    <i
                                        className={
                                            'fas fa-map-marked mr-2 text-sm ' +
                                            (window.location.href.indexOf(
                                                '/admin/orders/in-transit'
                                            ) !== -1
                                                ? 'opacity-75'
                                                : 'text-white')
                                        }
                                    ></i>{' '}
                                    In-transit
                                </Link>
                            </li>
                            <li className="items-center">
                                <Link
                                    className={
                                        'text-xs uppercase py-3 font-bold block ' +
                                        (window.location.href.indexOf(
                                            '/admin/orders/delivered'
                                        ) !== -1
                                            ? 'text-pink'
                                            : 'text-white')
                                    }
                                    to="/admin/orders/delivered"
                                >
                                    <i
                                        className={
                                            'fas fa-map-marked mr-2 text-sm ' +
                                            (window.location.href.indexOf(
                                                '/admin/orders/delivered'
                                            ) !== -1
                                                ? 'opacity-75'
                                                : 'text-white')
                                        }
                                    ></i>{' '}
                                    Delivered
                                </Link>
                            </li>
                            <li className="items-center">
                                <Link
                                    className={
                                        'text-xs uppercase py-3 font-bold block ' +
                                        (window.location.href.indexOf(
                                            '/admin/orders/cancelled'
                                        ) !== -1
                                            ? 'text-pink'
                                            : 'text-white')
                                    }
                                    to="/admin/orders/cancelled"
                                >
                                    <i
                                        className={
                                            'fas fa-map-marked mr-2 text-sm ' +
                                            (window.location.href.indexOf(
                                                '/admin/orders/cancelled'
                                            ) !== -1
                                                ? 'opacity-75'
                                                : 'text-white')
                                        }
                                    ></i>{' '}
                                    Cancelled
                                </Link>
                            </li>
                            <br />

                            <h6 className="md:min-w-full text-white text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                                Sessions and Seminars
                            </h6>

                            <li className="items-center">
                                <Link
                                    className={
                                        'text-xs uppercase py-3 font-bold block ' +
                                        (window.location.href.indexOf(
                                            '/admin/live-seminars'
                                        ) !== -1
                                            ? 'text-pink'
                                            : 'text-white')
                                    }
                                    to="/admin/live-seminars"
                                >
                                    <i
                                        className={
                                            'fas fa-map-marked mr-2 text-sm ' +
                                            (window.location.href.indexOf(
                                                '/admin/live-seminars'
                                            ) !== -1
                                                ? 'opacity-75'
                                                : 'text-white')
                                        }
                                    ></i>{' '}
                                    Live Seminars
                                </Link>
                            </li>

                            <li className="items-center">
                                <Link
                                    className={
                                        'text-xs uppercase py-3 font-bold block ' +
                                        (window.location.href.indexOf(
                                            '/admin/recorded-seminars'
                                        ) !== -1
                                            ? 'text-pink'
                                            : 'text-white')
                                    }
                                    to="/admin/recorded-seminars"
                                >
                                    <i
                                        className={
                                            'fas fa-map-marked mr-2 text-sm ' +
                                            (window.location.href.indexOf(
                                                '/admin/recorded-seminars'
                                            ) !== -1
                                                ? 'opacity-75'
                                                : 'text-white')
                                        }
                                    ></i>{' '}
                                    Recorded Seminars
                                </Link>
                            </li>

                            <br />

                            <h6 className="md:min-w-full text-white text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                                Website
                            </h6>

                            <li className="items-center">
                                <Link
                                    className={
                                        'text-xs uppercase py-3 font-bold block ' +
                                        (window.location.href.indexOf(
                                            '/admin/consultation'
                                        ) !== -1
                                            ? 'text-pink'
                                            : 'text-white')
                                    }
                                    to="/admin/consultation"
                                >
                                    <i
                                        className={
                                            'fas fa-map-marked mr-2 text-sm ' +
                                            (window.location.href.indexOf(
                                                '/admin/consultation'
                                            ) !== -1
                                                ? 'opacity-75'
                                                : 'text-white')
                                        }
                                    ></i>{' '}
                                    Consultation
                                </Link>
                            </li>

                            <li className="items-center">
                                <Link
                                    className={
                                        'text-xs uppercase py-3 font-bold block ' +
                                        (window.location.href.indexOf(
                                            '/admin/blogs'
                                        ) !== -1
                                            ? 'text-pink'
                                            : 'text-white')
                                    }
                                    to="/admin/blogs"
                                >
                                    <i
                                        className={
                                            'fas fa-map-marked mr-2 text-sm ' +
                                            (window.location.href.indexOf(
                                                '/admin/blogs'
                                            ) !== -1
                                                ? 'opacity-75'
                                                : 'text-white')
                                        }
                                    ></i>{' '}
                                    Blogs
                                </Link>
                            </li>

                            <li className="items-center">
                                <Link
                                    className={
                                        'text-xs uppercase py-3 font-bold block ' +
                                        (window.location.href.indexOf(
                                            '/admin/careers'
                                        ) !== -1
                                            ? 'text-pink'
                                            : 'text-white')
                                    }
                                    to="/admin/careers"
                                >
                                    <i
                                        className={
                                            'fas fa-map-marked mr-2 text-sm ' +
                                            (window.location.href.indexOf(
                                                '/admin/careers'
                                            ) !== -1
                                                ? 'opacity-75'
                                                : 'text-white')
                                        }
                                    ></i>{' '}
                                    Careers
                                </Link>
                            </li>

                            <li className="items-center">
                                <Link
                                    className={
                                        'text-xs uppercase py-3 font-bold block ' +
                                        (window.location.href.indexOf(
                                            '/admin/faqs'
                                        ) !== -1
                                            ? 'text-pink'
                                            : 'text-white')
                                    }
                                    to="/admin/faqs"
                                >
                                    <i
                                        className={
                                            'fas fa-map-marked mr-2 text-sm ' +
                                            (window.location.href.indexOf(
                                                '/admin/faqs'
                                            ) !== -1
                                                ? 'opacity-75'
                                                : 'text-white')
                                        }
                                    ></i>{' '}
                                    Faqs
                                </Link>
                            </li>

                            {/* <li className="items-center">
                                <Link
                                    className={
                                        'text-xs uppercase py-3 font-bold block ' +
                                        (window.location.href.indexOf(
                                            '/admin/contact'
                                        ) !== -1
                                            ? 'text-pink'
                                            : 'text-white')
                                    }
                                    to="/admin/contact"
                                >
                                    <i
                                        className={
                                            'fas fa-map-marked mr-2 text-sm ' +
                                            (window.location.href.indexOf(
                                                '/admin/contact'
                                            ) !== -1
                                                ? 'opacity-75'
                                                : 'text-white')
                                        }
                                    ></i>{' '}
                                    Inquiry
                                </Link>
                            </li> */}

                            <li className="items-center">
                                <Link
                                    className={
                                        'text-xs uppercase py-3 font-bold block ' +
                                        (window.location.href.indexOf(
                                            '/admin/testimonials'
                                        ) !== -1
                                            ? 'text-pink'
                                            : 'text-white')
                                    }
                                    to="/admin/testimonials"
                                >
                                    <i
                                        className={
                                            'fas fa-map-marked mr-2 text-sm ' +
                                            (window.location.href.indexOf(
                                                '/admin/testimonials'
                                            ) !== -1
                                                ? 'opacity-75'
                                                : 'text-white')
                                        }
                                    ></i>{' '}
                                    Testimonials
                                </Link>
                            </li>
                            <li className="items-center">
                                <Link
                                    className={
                                        'text-xs uppercase py-3 font-bold block text-pink'
                                    }
                                    to="/auth/login"
                                    onClick={handleLogout}
                                >
                                    <i className="fas fa-sign-out-alt"></i> LogOut
                                </Link>
                                {/* <Modal isOpen={modalOpen} onClose={handleCloseModal}>
                                    <p className="mb-4 text-lg text-gray-500 dark:text-gray-300 text-center	">
                                        Are you sure you want Logout
                                    </p>

                                    <div className="flex justify-center">
                                        <button
                                        onClick={handleCloseModal}
                                        className="py-2 mx-4 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                                        >
                                        No
                                        </button>
                                        <button
                                        onClick={handleLogout}
                                        className="py-2 mx-4 px-3 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-900"
                                        >
                                        Yes
                                        </button>
                                    </div>
                                    </Modal> */}
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
}
