import React, { useEffect, useState } from "react";
import MessageFromBabyTable from "components/Cards/MessageFromBabyTable";
import Modal from "lib/Modal";
import Input from "lib/Input";
import { useDispatch } from "react-redux";
import { createNewBabyMessage } from "redux/babyMessageSlice";
import PictureUpload from "lib/PictureUpload";
import { handleImageFile } from "api/fileUploadAPI";
import toast, { Toaster } from "react-hot-toast";
import Loader from "components/Loader/Loader";

const MessageFromBaby = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const [day, setDay] = useState("");
  const [message, setMessage] = useState("");
  const [imageURL, setImageURL] = useState("");
  const [isIntroLoader, setIsIntroLoader] = useState(false);

  const [formError, setFormError] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const dispatch = useDispatch();

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleCreateNew = () => {
    if (day !== "" && message.trim() !== "" && imageURL.trim() !== "") {
      const newBabyData = {
        day: parseInt(day),
        message: message.trim(),
        imageURL: imageURL,
        isActive: isActive,
      };
      dispatch(createNewBabyMessage(newBabyData));
      setDay("");
      setImageURL("");
      setMessage("");
      setIsActive(true);
      setModalOpen(false);
    } else {
      setFormError(true);
    }
  };

  const handleIntroMedia = async (file) => {
    // const res = await handleMediaFile(file);
    // setImageURL(res.file_url);

    if(file === undefined) {
      setImageURL("");
      return;
    }
    
    try {
      setIsIntroLoader(true);
      const res = await handleImageFile(file);
      if (res.success) {
        setImageURL(res.file_url);

        toast.success("Successfully Uploaded the Image!");
      } else {
        throw new Error("Picture Not Successfully Uploaded");
      }
      setIsIntroLoader(false);
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <>
      <Modal isOpen={modalOpen} onClose={handleCloseModal}>
        <div>
          <Input
            onChange={(e) => setDay(e.target.value)}
            value={day}
            label="Day"
            type="text"
            isRequired
          />
          <Input
            onChange={(e) => setMessage(e.target.value)}
            defaultValue={message}
            label="Baby Message"
            isRequired
          />
          {isIntroLoader && <Loader />}
          <PictureUpload
            onChange={handleIntroMedia}
            defaultValue={imageURL}
            label="Baby Image URL"
            isRequired
          />
          <Input
            onChange={(e) => setIsActive(e.target.value)}
            defaultValue={isActive}
            options={[
              { id: true, value: "Active" },
              { id: false, value: "Inactive" },
            ]}
            select
            label="Status"
            isRequired
          />
          {formError && (
            <p className="mx-4 text-red-500 mb-2">
              Please fill all the details!
            </p>
          )}
          <div className="flex gap-2 mt-4">
            <button
              onClick={handleCreateNew}
              className="bg-pink text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
            >
              Save
            </button>
          </div>
        </div>
      </Modal>

      <div className="flex flex-wrap mt-4">
        <div className="w-full">
          <MessageFromBabyTable
            handleOpenModal={handleOpenModal}
            color="dark"
          />
        </div>
      </div>
    </>
  );
};

export default MessageFromBaby;
