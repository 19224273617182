import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Modal from "lib/Modal";
import Input from "lib/Input";
import PictureUpload from "lib/PictureUpload";
import DeleteButton from "lib/DeleteButton";
import { useDispatch, useSelector } from "react-redux";
import { constants } from "utils/constants";
import { handleImageFile, handleMediaFile } from "api/fileUploadAPI";
import { Toaster, toast } from "react-hot-toast";
import Loader from "components/Loader/Loader";
import { deleteOneBlog, updateOneBlog, getBlogList } from "redux/blogsSlice";
import SaveButton from "lib/SaveButton";
import ReactQuill from "react-quill";

const BlogsTable = ({ color, data, handleOpenModal }) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [isIntroLoader, setIsIntroLoader] = useState(false);
  const [isMediaLoader, setIsMediaLoader] = useState(false);
  const dispatch = useDispatch();
  const MAX_LENGTH = 50;
  const editor = useRef(null);
  const blogsData = useSelector((state) => state?.blogs?.data);
  const loading = useSelector((state) => state?.blogs?.loading);

  const handleOpenEditModal = (item) => {
    setModalData(item);
    setOpenEditModal(true);
  };

  function handleEditButton() {
    handleUpdate();
  }

  const handleUpdate = () => {
    const formattedPublishDate = new Date(modalData.publishDate).toISOString().slice(0, 10);
    
    const updatedData = {
      userCategory: modalData?.userCategory,
      thumbnailURL: modalData?.thumbnailURL,
      title: modalData?.title,
      content: modalData?.content,
      mediaType: modalData?.mediaType,
      mediaURl: modalData?.mediaURL,
      slugURL: modalData?.slugURL,
      metaDescription: modalData?.metaDescription,
      metaKeywords: modalData?.metaKeywords,
      publishDate: formattedPublishDate,
      publishTime: modalData?.publishTime,
      isActive: modalData?.isActive,
    };
    console.log(updatedData)
    dispatch(updateOneBlog({ id: modalData?.id, data: updatedData }));
    setOpenEditModal(false);
  };

  const handleDeleteBlog = async (id) => {
    dispatch(await deleteOneBlog(id));
  };

  const handleIntroMedia = async (file) => {
    if(file === undefined) {
      setModalData({ ...modalData, mediaURL: "" });
      return;
    }

    if (modalData?.mediaType === "1") {
      try {
        setIsIntroLoader(true);
        const res = await handleImageFile(file);
        if (res.success) {
          setModalData({ ...modalData, mediaURL: res.file_url });

          toast.success("Successfully Uploaded the Image!");
        } else {
          throw new Error("Picture Not Successfully Uploaded");
        }
        setIsIntroLoader(false);
      } catch (error) {
        toast.error(error);
      }
    } else {
      try {
        setIsMediaLoader(true);
        const res = await handleMediaFile(file);
        if (res.success) {
          setModalData({ ...modalData, mediaURL: res.file_url });
          modalData?.mediaType === "2"
            ? toast.success("Successfully Uploaded the Audio!")
            : toast.success("Successfully Uploaded the video!");
        } else {
          throw new Error("Picture Not Successfully Uploaded");
        }
        setIsMediaLoader(false);
      } catch (error) {
        toast.error(error);
      }
    }
  };

  const handleThumbnailUrl = async (file) => {
    if(file === undefined) {
      setModalData({ ...modalData, thumbnailURL: "" });
      return;
    }

    try {
      setIsMediaLoader(true);
      const res = await handleImageFile(file);
      if (res.success) {
        setModalData({ ...modalData, thumbnailURL: res.file_url });

        toast.success("Successfully Uploaded the Image!");
      } else {
        throw new Error("Picture Not Successfully Uploaded");
      }
      setIsMediaLoader(false);
    } catch (error) {
      toast.error(error);
    }
  };

  const handleQuillChange = (value) => {
    setModalData({ ...modalData, content: value });
  };

  function formatDate(dateString) {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }
  

  useEffect(() => {
    dispatch(getBlogList());
  }, [dispatch]);

  return (
    <>
      {/* Edit modal */}
      <Toaster />
      <Modal isOpen={openEditModal} onClose={() => setOpenEditModal(false)}>
        <div>
          <Input
            onChange={(e) =>
              setModalData({
                ...modalData,
                userCategory: e.target.value,
              })
            }
            label="UserCategory"
            isRequired
            defaultValue={modalData?.userCategory}
            select
            options={constants.blogCategory}
          />
          {isIntroLoader && <Loader />}
          <PictureUpload
            label="Thumbnail  URL (400px x 250px)" 
            isRequired
            onChange={handleThumbnailUrl}
          />
          <Input
            defaultValue={modalData?.title}
            label="Title"
            isRequired
            onChange={(e) =>
              setModalData({ ...modalData, title: e.target.value })
            }
          />
          <div className="flex flex-wrap">
            <div className="w-full lg:w-12/12 px-4">
              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Content <span className="red-asterisk"></span>
                </label>
                <ReactQuill
                  style={{
                    background: "#fff",
                  }}
                  className="ql-container ql-snow"
                  ref={editor}
                  modules={constants.modules}
                  formats={constants.formats}
                  defaultValue={modalData?.content}
                  onChange={handleQuillChange}
                />
              </div>
            </div>
          </div>
          {isMediaLoader && <Loader />}
          <Input
            onChange={(e) =>
              setModalData({ ...modalData, mediaType: e.target.value })
            }
            label="Media Type"
            isRequired
            defaultValue={modalData?.mediaType}
            select
            options={constants.mediaOption}
          />
          {modalData?.mediaType === "0" ? null : (
            <PictureUpload
              onChange={handleIntroMedia}
              defaultValue={modalData?.mediaURL}
              label={
                modalData?.mediaType === "3"
                  ? "Intro Video"
                  : modalData?.mediaType === "2"
                  ? "Intro Audio"
                  : "Intro Image"
              }
              isRequired
            />
          )}
          <Input
            onChange={(e) =>
              setModalData({ ...modalData, slugURL: e.target.value })
            }
            value={modalData?.slugURL}
            label="Slug URL"
            isRequired
          />
          <Input
            onChange={(e) =>
              setModalData({ ...modalData, metaDescription: e.target.value })
            }
            value={modalData?.metaDescription}
            label="Meta Description"
            isRequired
          />
          <Input
            onChange={(e) =>
              setModalData({ ...modalData, metaKeywords: e.target.value })
            }
            value={modalData?.metaKeywords}
            label="Meta Keywords"
            isRequired
          />
          <Input
            onChange={(e) =>
              setModalData({ ...modalData, publishDate: e.target.value })
            }
            value={modalData?.publishDate}
            type="date"
            label="Publish Date"
            isRequired
          />
          <Input
            onChange={(e) =>
              setModalData({ ...modalData, publishTime: e.target.value })
            }
            value={modalData?.publishTime}
            type="time"
            label="Publish Time"
            isRequired
          />
          <Input
            onChange={(e) =>
              setModalData({ ...modalData, isActive: e.target.value })
            }
            defaultValue={modalData?.isActive}
            options={[
              { id: true, value: "Active" },
              { id: false, value: "Inactive" },
            ]}
            select
            label="Visibility State"
            isRequired
          />
          <div className="flex gap-2">
            <SaveButton handleEditButton={handleEditButton}></SaveButton>
          </div>
        </div>
      </Modal>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
        <div className="rounded-t mb-0 px-4 py-4 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3 className="font-semibold text-lg text-blueGray-700">Blogs</h3>
            </div>
            <button
              onClick={handleOpenModal}
              className="bg-pink text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
            >
              Add New
            </button>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Table */}
          <table className="w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  ID
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  User Category
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Thumbnail URL
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Title
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Content
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Media Type
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Media URL
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Slug URL
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Meta Description
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Meta Keywords
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Publish Date
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Publish Time
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Created At
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Created By
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Updated At
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Updated By
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  IS ACTIVE
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {loading && (
                <tr>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    <div className="flex items-center justify-center h-20">
                      <Loader />
                    </div>
                  </td>
                </tr>
              )}
              {blogsData?.map((item) => (
                <tr key={item.id}>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item.id}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item?.userCategory === "0"
                      ? "General"
                      : item?.userCategory === "1"
                      ? "Pre Pregnancy"
                      : item?.userCategory === "2"
                      ? "Pregnant"
                      : item?.userCategory === "3"
                      ? "Post Pregnancy"
                      : "N/A"}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item?.thumbnailURL}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item.title}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {constants.trimText(item?.content, MAX_LENGTH)}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item?.mediaType === "1"
                      ? "Image"
                      : item?.mediaType === "2"
                      ? "Audio"
                      : item?.mediaType === "3"
                      ? "Video"
                      : item?.mediaType === "0"
                      ? "No Media"
                      : "N/A"}
                  </td>
                  <td className="border-t-0{ite px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item?.mediaURL || "N/A"}
                  </td>
                  <td className="border-t-0{ite px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item?.slugURL || "N/A"}
                  </td>
                  <td className="border-t-0{ite px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item?.metaDescription}
                  </td>
                  <td className="border-t-0{ite px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item?.metaKeywords}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {(item?.publishDate).toLocaleString()}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {(item?.publishTime).toLocaleString()}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {new Date(item?.createdAt).toLocaleString()}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item?.createdBy}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {new Date(item?.updatedAt).toLocaleString()}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item?.updatedBy}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item.isActive ? "Active" : "Inactive"}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    <div className="flex justify-evenly">
                      <button
                        onClick={() => handleOpenEditModal(item)}
                        className="focus:outline-none	"
                        type="button"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5 mr-2 "
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                          />
                        </svg>
                      </button>
                      <DeleteButton
                        handleDeleteButton={() => handleDeleteBlog(item?.id)}
                      ></DeleteButton>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

BlogsTable.propTypes = {
  data: PropTypes.array.isRequired,
};

export default BlogsTable;
