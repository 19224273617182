import React, { useState, useEffect } from "react";
import Modal from "lib/Modal";
import Input from "lib/Input";
import UserDetailsTable from "components/Cards/UserDetailsTable";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
// import { getAllUserDetails } from "redux/userDetailsSlice";

const UserDetails = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [userName, setUserName] = useState("");
  const [userType, setUserType] = useState("");
  const [gender, setGender] = useState("");
  const [age, setAge] = useState("");
  const [dob, setDob] = useState(new Date());
  const [isMarried, setIsMarried] = useState("");
  const [marriageDate, setMarriageDate] = useState(new Date());
  const [spouceName, setSpouceName] = useState("");
  const [spouceDob, setSpouceDob] = useState(new Date());
  const [profession, setProfession] = useState("");
  const [address, setAddress] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [lmpDate, setLmpDate] = useState(new Date());
  const [isPregnant, setIsPregnant] = useState("");
  const [isPlanningForChild, setIsPlanningForChild] = useState("");
  const [planDuration, setPlanDuration] = useState("");
  const [medicalCondition, setMedicalCondition] = useState("");
  const [deliveryDate, setDeliveryDate] = useState(new Date());
  const [subscriptionStartDate, setSubscriptionStartDate] = useState(
    new Date()
  );
  const [subscriptionType, setSubscriptionType] = useState("");
  const [subscriptionEndDate, setSubscriptionEndDate] = useState(new Date());
  const [lastActiveTime, setLastActiveTime] = useState(new Date());
  const [createdAt, setCreatedAt] = useState(new Date());
  const [createdBy, setCreatedBy] = useState("");
  const [updatedAt, setUpdatedAt] = useState(new Date());
  const [updatedBy, setUpdatedBy] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [isAdmin, setIsAdmin] = useState("");
  const [formError, setFormError] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <UserDetailsTable
            handleOpenModal={handleOpenModal}
            // data={userDetailsData}
            color="dark"
          />
        </div>
      </div>
    </>
  );
};

export default UserDetails;
