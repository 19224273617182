import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { userAdminStatus } from "api/userDetailAPI";
import { deactivateUser } from "api/userDetailAPI";
import { getUserDetails } from "api/userDetailAPI";
import { userSubscriptionUpdate } from 'api/userDetailAPI';

export const getUserDetailsList = createAsyncThunk(
    'userDetails/list',
    async (sortingMode) => {
        try {
            const res = await getUserDetails(sortingMode);
            if (res.success) {
                return res.data;
            } else {
                throw new Error('Error Fetching Data');
            }
        } catch (error) {
            throw error;
        }
    }
);

export const updateUserStatus = createAsyncThunk(
    'userDetails/deactivate',
    async (id) => {
        try {
            const res = await deactivateUser(id);
            if (res.success) {
                return res;
            } else {
                throw new Error('Error Updating Status');
            }
        } catch (error) {
            throw error;
        }
    }
);
export const updateUserAdminStatus = createAsyncThunk(
    'userDetails/make-admin',
    async (id) => {
        try {
            const res = await userAdminStatus(id);
            if (res.success) {
                return res;
            } else {
                throw new Error('Error Updating Status');
            }
        } catch (error) {
            throw error;
        }
    }
);

export const updateUserSubscription = createAsyncThunk(
    'subscription/update-one',
    async (data) => {
        try {
            const res = await userSubscriptionUpdate(data);
            console.log(res);
            if (res.success) {
                return res.data;
            } else {
                throw new Error('Error Updating Subscription');
            }
        } catch (error) {
            throw error;
        }
    }
);

const userDetailsSlice = createSlice({
    name: 'userDetails',
    initialState: {
        data: [],
        loading: false,
        error: null,
    },
    reducer: {},
    extraReducers: (builder) => {
        builder
            .addCase(getUserDetailsList.pending, (state) => {
                state.loading = true;
            })
            .addCase(getUserDetailsList.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(getUserDetailsList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(updateUserStatus.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateUserStatus.fulfilled, (state, action) => {
                state.loading = false;
                const userId = action?.payload?.user?.id;
                state.data = state.data.map((user) =>
                    user.id === userId
                        ? { ...user, isActive: !user.isActive }
                        : user
                );
            })
            .addCase(updateUserStatus.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(updateUserAdminStatus.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateUserAdminStatus.fulfilled, (state, action) => {
                state.loading = false;
                // state.data = state.data.filter((user) => user.id !== action.payload);
                const userId = action?.payload?.user?.id;
                state.data = state.data.map((user) =>
                    user.id === userId
                        ? { ...user, userType: !user.userType }
                        : user
                );
            })
            .addCase(updateUserAdminStatus.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(updateUserSubscription.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateUserSubscription.fulfilled, (state, action) => {
                state.loading = false;

                const userId = state.data.findIndex(
                    (user) => user?.Subscription?.id === action.payload.id
                );

                if (userId !== -1) {
                    state.data[userId].Subscription = action.payload;
                }
            })
            .addCase(updateUserSubscription.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});
export const userDetailAction = userDetailsSlice.actions;
export default userDetailsSlice;
