import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getSoulMessage,
  createSoulMessage,
  updateSoulMessage,
  deleteSoulMessage,
} from "api/soulMessageAPI";

// Async thunk to fetch soul Message list
export const getNewSoulMessage = createAsyncThunk(
  "soulmessage/list",
  async () => {
    try {
      const res = await getSoulMessage();
      if (res.success) {
        return res.data;
      } else {
        throw new Error("Error fetching soul Message.");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const createNewSoulMessage = createAsyncThunk(
  "soulmessage/create-one",
  async (data) => {
    try {
      const res = await createSoulMessage(data);
      return res.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateNewSoulMessage = createAsyncThunk(
  "soulmessage/update-one",
  async (data) => {
    try {
      const res = await updateSoulMessage(data);
      return res.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteNewSoulMessage = createAsyncThunk(
  "soulmessage/delete-one",
  async (id) => {
    try {
      const res = await deleteSoulMessage(id);
      if (res.success) {
        return id;
      } else {
        throw new Error("Error deleting Soul Message.");
      }
    } catch (error) {
      throw error;
    }
  }
);

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const soulMessageSlice = createSlice({
  name: "soulMessage",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNewSoulMessage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getNewSoulMessage.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.data = action.payload;
      })
      .addCase(getNewSoulMessage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createNewSoulMessage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createNewSoulMessage.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;

        // console.log("action.payload", action.payload);
        if(action.payload) {
          state.data.push(action.payload);
        }
      })
      .addCase(createNewSoulMessage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateNewSoulMessage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateNewSoulMessage.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        const updatedSoulIndex = state.data.findIndex(
          (soul) => soul.id === action.payload.id
        );
        if (updatedSoulIndex !== -1) {
          state.data[updatedSoulIndex] = action.payload;
        }
      })
      .addCase(updateNewSoulMessage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteNewSoulMessage.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteNewSoulMessage.fulfilled, (state, action) => {
        state.loading = false;
        state.data = state.data.filter((soul) => soul.id !== action.payload);
      })
      .addCase(deleteNewSoulMessage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const soulMessageAction = soulMessageSlice.actions;

export default soulMessageSlice;
