import ReceivedTable from 'components/Cards/ReceivedTable';
import React from 'react';

const Received = () => {
    return (
        <>
            <div className="flex flex-wrap mt-4">
                <div className="w-full mb-12 px-4">
                    <ReceivedTable />
                </div>
            </div>
        </>
    );
};

export default Received;
