import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../assets/styles/richText.css';

import Modal from 'lib/Modal';
import Input from 'lib/Input';
import SaveButton from 'lib/SaveButton';
import PictureUpload from 'lib/PictureUpload';
import DeleteButton from 'lib/DeleteButton';
import { getList, updateShloka, deleteShloka } from 'redux/shlokaSlice';
import { constants } from 'utils/constants';

import toast, { Toaster } from 'react-hot-toast';
import { handleImageFile, handleMediaFile } from 'api/fileUploadAPI';
import Loader from 'components/Loader/Loader';

export default function ShlokaTable({ color, handleOpenModal }) {
    const dispatch = useDispatch();
    const [openEditModal, setOpenEditModal] = useState(false);
    const [modalData, setModalData] = useState({});
    const [isIntroLoader, setIsIntroLoader] = useState(false);

    const Data = useSelector((state) => state.shloka.shlokas);
    const loading = useSelector((state) => state.shloka.loading);

    const handleOpenEditModal = (item) => {
        setModalData(item);
        setOpenEditModal(true);
    };

    useEffect(() => {
        dispatch(getList());
    }, [dispatch]);

    const handleUpdate = async () => {
        const newShloka = {
            day: parseInt(modalData?.day),
            userCategory: modalData?.userCategory,
            shlokaText: modalData?.shlokaText.trim(),
            shlokaMeaningText: modalData?.shlokaMeaningText.trim(),
            mediaURL: modalData?.mediaURL.trim(),
            mediaType: modalData?.mediaType,
            isActive: modalData?.isActive,
        };

        await dispatch(
            updateShloka({ id: modalData?.id, body: { ...newShloka } })
        );
        setOpenEditModal(false);
    };

    const handleDeleteButton = async (item) => {
        dispatch(deleteShloka(item?.id));
    };

    function handleEditButton() {
        handleUpdate();
    }

    const mediaOption = [
        { value: 'No Media', id: '0' },
        { value: 'Image', id: '1' },
        { value: 'Audio', id: '2' },
        { value: 'Video', id: '3' },
    ];

    function handleMediaType(type) {
        setModalData({ ...modalData, mediaType: type, mediaURL: '' });
    }

    const handleIntroMedia = async (file) => {
        if (file === undefined) {
            setModalData({ ...modalData, mediaURL: '' });
            return;
        }

        if (modalData?.mediaType === '1') {
            try {
                setIsIntroLoader(true);
                const res = await handleImageFile(file);
                if (res.success) {
                    setModalData({ ...modalData, mediaURL: res.file_url });
                    toast.success('Successfully Uploaded the Image!');
                } else {
                    throw new Error('Picture Not Successfully Uploaded');
                }
                setIsIntroLoader(false);
            } catch (error) {
                toast.error(error);
            }
        } else {
            //audio and video type are strings
            try {
                setIsIntroLoader(true);
                const res = await handleMediaFile(file);
                if (res.success) {
                    setModalData({ ...modalData, mediaURL: res.file_url });
                    modalData?.mediaType === '2'
                        ? toast.success('Successfully Uploaded the Audio!')
                        : toast.success('Successfully Uploaded the video!');
                } else {
                    throw new Error('Picture Not Successfully Uploaded');
                }
                setIsIntroLoader(false);
            } catch (error) {
                toast.error(error);
            }
        }
    };

    return (
        <>
            {/* Edit modal */}
            <Toaster />
            <Modal
                isOpen={openEditModal}
                onClose={() => setOpenEditModal(false)}
            >
                <div>
                    <Input
                        label="User Category"
                        isRequired
                        value={modalData?.userCategory}
                        onChange={(e) =>
                            setModalData({
                                ...modalData,
                                userCategory: e.target.value,
                            })
                        }
                        options={constants.category}
                        select
                    />
                    <Input
                        label="Day"
                        isRequired
                        value={modalData?.day}
                        onChange={(e) =>
                            setModalData({ ...modalData, day: e.target.value })
                        }
                    />
                    <Input
                        onChange={(e) =>
                            setModalData({
                                ...modalData,
                                shlokaText: e.target.value,
                            })
                        }
                        value={modalData?.shlokaText || ''}
                        label="Shloka"
                        isRequired
                    />
                    <Input
                        onChange={(e) =>
                            setModalData({
                                ...modalData,
                                shlokaMeaningText: e.target.value,
                            })
                        }
                        value={modalData?.shlokaMeaningText || ''}
                        label="Shloka Meaning"
                        isRequired
                    />
                    <Input
                        label="Media Type"
                        isRequired
                        defaultValue={modalData?.mediaType}
                        onChange={(e) => handleMediaType(e.target.value)}
                        select
                        options={mediaOption}
                    />
                    {isIntroLoader && <Loader />}
                    {modalData?.mediaType === '0' ? null : (
                        <PictureUpload
                            onChange={handleIntroMedia}
                            value={modalData?.mediaURL}
                            label={
                                modalData?.mediaType === '3'
                                    ? 'Intro Video'
                                    : modalData?.mediaType === '2'
                                    ? 'Intro Audio'
                                    : 'Intro Image'
                            }
                            isRequired
                            accept="image/*, video/*"
                        />
                    )}

                    <Input
                        onChange={(e) =>
                            setModalData({
                                ...modalData,
                                isActive: e.target.value,
                            })
                        }
                        defaultValue={modalData.isActive}
                        options={[
                            { id: true, value: 'Active' },
                            { id: false, value: 'Inactive' },
                        ]}
                        select
                        label="IS ACTIVE"
                        isRequired
                    />
                    <div className="flex gap-2">
                        <SaveButton
                            handleEditButton={handleEditButton}
                        ></SaveButton>
                    </div>
                </div>
            </Modal>
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
                <div className="rounded-t mb-0 px-4 py-4 border-0">
                    <div className="flex flex-wrap items-center">
                        <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                            <h3 className="font-semibold text-lg text-blueGray-700">
                                Daily Shloka
                            </h3>
                        </div>
                        <button
                            onClick={handleOpenModal}
                            className="bg-pink text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                            type="button"
                        >
                            Add New
                        </button>
                    </div>
                </div>
                <div className="block w-full overflow-x-auto">
                    {/* Timeline table */}
                    <table className="items-center w-full bg-transparent border-collapse">
                        <thead>
                            <tr>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    ID
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    User Category
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Day
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Shloka
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Shloka Meaning
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    File URL
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Created At
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Created By
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Updated At
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Updated By
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Is Active
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        {loading ? (
                            <Loader />
                        ) : (
                            <tbody>
                                {Data.map((item) => (
                                    <tr key={item.id}>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {item?.id}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {constants.category
                                                .find(
                                                    (cat) =>
                                                        cat.id ===
                                                        item?.userCategory
                                                )
                                                ?.value?.toUpperCase()}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {item?.day}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {item?.shlokaText}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {item?.shlokaMeaningText}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {item?.mediaURL
                                                ? item?.mediaURL
                                                : 'N/A'}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {new Date(
                                                item?.createdAt
                                            ).toLocaleString()}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {item?.createdBy}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {new Date(
                                                item?.updatedAt
                                            ).toLocaleString()}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {item?.updatedBy}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {item.isActive
                                                ? 'Active'
                                                : 'Inactive'}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            <div className="flex justify-evenly">
                                                <button
                                                    onClick={() =>
                                                        handleOpenEditModal(
                                                            item
                                                        )
                                                    }
                                                    className="focus:outline-none	"
                                                    type="button"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="h-5 w-5 mr-2 "
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                        strokeWidth="2"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                                        />
                                                    </svg>
                                                </button>
                                                <DeleteButton
                                                    handleDeleteButton={() => {
                                                        handleDeleteButton(
                                                            item
                                                        );
                                                    }}
                                                ></DeleteButton>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        )}
                    </table>
                </div>
            </div>
        </>
    );
}
