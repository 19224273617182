import CareersTable from "components/Cards/CareersTable";
import React from "react";

const Careers = () => {
  return (
    <>
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <CareersTable />
        </div>
      </div>
    </>
  );
};

export default Careers;
