import React, { useState } from "react";
import ReactSwitch from "react-switch";
import { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getUserDetailsList } from "redux/userDetailsSlice";
import { updateUserStatus } from "redux/userDetailsSlice";
import {
    updateUserAdminStatus,
    updateUserSubscription,
} from 'redux/userDetailsSlice';
import Loader from 'components/Loader/Loader';
import Modal from 'lib/Modal';
import Input from 'lib/Input';
import SaveButton from 'lib/SaveButton';
import { config } from "api/confg";

const UserDetailsTable = () => {
    const dispatch = useDispatch();

    const userDetailsData = useSelector((state) => state.userDetails.data);
    const loading = useSelector((state) => state.userDetails.loading);

    const [openEditModal, setOpenEditModal] = useState(false);
    const [modalData, setModalData] = useState({});
    const [sortingMode, setSortingMode] = useState('ASC');

    useEffect(() => {
        dispatch(getUserDetailsList(sortingMode));
    }, [dispatch]);

    const handleUserDeactivation = async (id) => {
        const response = await dispatch(updateUserStatus(id));
        if (response.payload.success) {
            dispatch(getUserDetailsList());
        }
    };

    const handleUserAdmin = async (id) => {
        const response = await dispatch(updateUserAdminStatus(id));
        if (response.payload.success) {
            dispatch(getUserDetailsList());
        }
    };

    const handleOpenEditModal = (item) => {
        setModalData(item);
        setOpenEditModal(true);
    };

    function handleEditButton() {
        handleUpdate();
    }

    const handleUpdate = async () => {
        const updateData = {
            subscriptionName: modalData?.Subscription?.subscriptionName,
        };

        await dispatch(
            updateUserSubscription({
                id: modalData?.Subscription?.userId,
                body: { ...updateData },
            })
        );
        setOpenEditModal(false);
    };

    const downloadUserDetailsCSV = async () => {
        const storedToken = window.localStorage.getItem(config.storageTokenKeyName);
        const response = await fetch(`${config.root}/api/users-details/download-csv`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: storedToken,
            }
        }
        );
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'user-details.csv';
        document.body.appendChild(a);
        a.click();
        a.remove();
    };

    return (
        <>
            <Toaster />
            <Modal
                isOpen={openEditModal}
                onClose={() => setOpenEditModal(false)}
            >
                <div>
                    <Input
                        onChange={(e) =>
                            setModalData({
                                ...modalData,
                                Subscription: {
                                    ...modalData?.Subscription,
                                    subscriptionName: e.target.value,
                                },
                            })
                        }
                        defaultValue={modalData?.Subscription?.subscriptionName}
                        label="Select Subscription Type"
                        isRequired
                        options={[
                            { id: 'TRIAL', value: 'TRIAL' },
                            { id: 'TRIMESTER', value: 'TRIMESTER' },
                            { id: 'SAATHI', value: 'SAATHI' },
                        ]}
                        select
                    />
                    <div className="flex gap-2">
                        <SaveButton
                            handleEditButton={handleEditButton}
                        ></SaveButton>
                    </div>
                </div>
            </Modal>
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
                <div className="rounded-t mb-0 px-4 py-4 border-0 flex justify-between">
                    <div className="flex flex-wrap items-center">
                        <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                            <h3 className="font-semibold text-lg text-blueGray-700">
                                User Table
                            </h3>
                        </div>
                    </div>
                    <div className=" flex justify-center items-center ">
                        <button className="rounded-lg px-4 py-2 font-bold text-xs text-white flex items-center justify-center gap-2 bg-pink active:bg-lightBlue-600"
                            // style={{
                            //     backgroundColor: "rgb(8, 51, 68)",

                            // }}
                            onClick={() => {
                                var sMode = sortingMode;
                                if (sMode == "ASC") {
                                    sMode = "DESC";
                                }
                                else {
                                    sMode = "ASC";
                                }
                                dispatch(getUserDetailsList(sMode));
                                setSortingMode(sMode);
                            }}
                            disabled={loading ? true : false}
                        >
                            {sortingMode == "ASC" ?
                                <span>Latest</span> : <span>Oldest</span>}
                            <span
                                style={{ transform: `${sortingMode == "ASC" ? "rotate(0deg)" : "rotate(180deg)"}` }}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width={20} height={16} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={1} strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-arrow-down">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M12 5l0 14" />
                                    <path d="M18 13l-6 6" />
                                    <path d="M6 13l6 6" />
                                </svg>
                            </span>
                        </button>
                        <button className="rounded-lg px-3 py-2 text-white font-bold text-xs flex items-center justify-center gap-2 ml-3 bg-pink active:bg-lightBlue-600"
                            onClick={() => {
                                downloadUserDetailsCSV();
                            }}
                        // disabled={loading ? true : false}
                        >
                            Download CSV
                        </button>
                    </div>

                </div>
                <div className="block w-full overflow-x-auto">
                    <table className="items-center w-full bg-transparent border-collapse">
                        <thead>
                            <tr>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    ID
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    User Name
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Email
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Phone No.
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Gender
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Age
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    DOB
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Medical Description
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Is Married
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Marriage Date
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Spouse Name
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Spouse Dob
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Profession
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Address
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Height
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Weight
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    LMP Date
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Is Pregnant
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Is Planning For Child
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Plan Duration
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Medical Condition
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Delivery Date
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Subscription Type
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Subscription Name
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Subscription Start Date
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Subscription End Date
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Trial Active
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Created At
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Created By
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Updated At
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Updated By
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Admin
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Is Active
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading && (
                                <tr>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        <div className="flex items-center justify-center h-20">
                                            <Loader />
                                        </div>
                                    </td>
                                </tr>
                            )}
                            {userDetailsData?.map((item) => (
                                <tr key={item?.user?.id}>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.user?.id}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.user?.firstName +
                                            ' ' +
                                            item?.user?.lastName}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.user?.email}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.user?.mobile}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.UserDetails[0]?.gender === 0
                                            ? 'Female'
                                            : 'Male'}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.UserDetails[0]?.age}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {new Date(
                                            item?.UserDetails[0]?.dob
                                        ).toLocaleDateString()}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.UserMedicalConditions?.length ===
                                            1 &&
                                            item?.UserMedicalConditions[0]
                                                ?.description?.length > 0
                                            ? item?.UserMedicalConditions[0]?.description.join(
                                                ', '
                                            )
                                            : 'N/A'}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.UserDetails[0]?.isMarried
                                            ? 'Yes'
                                            : 'No'}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.UserDetails[0]?.marriageDate ===
                                            null
                                            ? 'N/A'
                                            : new Date(
                                                item?.UserDetails[0]?.marriageDate
                                            ).toLocaleDateString()}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.UserDetails[0]?.spouceName ===
                                            null
                                            ? 'N/A'
                                            : item?.UserDetails[0]?.spouceName}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.UserDetails[0]?.spouceDob ===
                                            null
                                            ? 'N/A'
                                            : new Date(
                                                item?.UserDetails[0]?.spouceDob
                                            ).toLocaleDateString()}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.UserDetails[0]?.profession ===
                                            null
                                            ? 'N/A'
                                            : item?.UserDetails[0]?.profession}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.UserDetails[0]?.address === null
                                            ? 'N/A'
                                            : item?.UserDetails[0]?.address}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.UserBodyConstitutions[0]
                                            ?.height === null
                                            ? 'N/A'
                                            : item?.UserBodyConstitutions[0]
                                                ?.height}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.UserBodyConstitutions[0]
                                            ?.weight === null
                                            ? 'N/A'
                                            : item?.UserBodyConstitutions[0]
                                                ?.weight}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.UserBodyConstitutions[0]
                                            ?.lmpDate === null
                                            ? 'N/A'
                                            : item?.UserBodyConstitutions[0]
                                                ?.lmpDate}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.UserBodyConstitutions[0]
                                            ?.isPregnant === null
                                            ? 'N/A'
                                            : item?.UserBodyConstitutions[0]
                                                ?.isPregnant
                                                ? 'Yes'
                                                : 'No'}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.UserBodyConstitutions[0]
                                            ?.isPlanningForChild === null
                                            ? 'N/A'
                                            : item?.UserBodyConstitutions[0]
                                                ?.isPlanningForChild
                                                ? 'Yes'
                                                : 'No'}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.UserBodyConstitutions[0]
                                            ?.planDuration === null
                                            ? 'N/A'
                                            : item?.UserBodyConstitutions[0]
                                                ?.planDuration}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.UserBodyConstitutions[0]
                                            ?.hasMedicalCondition === null
                                            ? 'N/A'
                                            : item?.UserBodyConstitutions[0]
                                                ?.hasMedicalCondition}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.UserBodyConstitutions[0]
                                            ?.deliveryDate === null
                                            ? 'N/A'
                                            : item?.UserBodyConstitutions[0]
                                                ?.deliveryDate}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.Subscription?.subscriptionType}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.Subscription?.subscriptionName}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.Subscription?.trialStartDate}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.Subscription?.trialEndDate}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.Subscription?.isTrialActive ===
                                            null
                                            ? 'N/A'
                                            : item?.Subscription?.isTrialActive
                                                ? 'Yes'
                                                : 'No'}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.user?.createdAt}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.user?.createdBy}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.user?.updatedAt}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.user?.updatedBy}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        <ReactSwitch
                                            onChange={() =>
                                                handleUserAdmin(item?.user?.id)
                                            }
                                            checked={
                                                item?.user?.userType === '1'
                                            }
                                        />
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        <ReactSwitch
                                            onChange={() =>
                                                handleUserDeactivation(
                                                    item?.user?.id
                                                )
                                            }
                                            checked={
                                                item?.user?.isActive === '1'
                                            }
                                        />
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        <div className="flex justify-evenly">
                                            <button
                                                onClick={() =>
                                                    handleOpenEditModal(item)
                                                }
                                                className="focus:outline-none	"
                                                type="button"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="h-5 w-5 mr-2 "
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    strokeWidth="2"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div >
        </>
    );
};
export default UserDetailsTable;
