import React, { useEffect, useState, useRef } from "react";
import toast, { Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import DeleteButton from 'lib/DeleteButton';
import Modal from 'lib/Modal';
import Input from 'lib/Input';
import {
    getList,
    updateOne,
    deleteOne,
} from 'redux/recordedSeminarSlice';
import Loader from 'components/Loader/Loader';
import { constants } from 'utils/constants';
import PictureUpload from 'lib/PictureUpload';
import SaveButton from 'lib/SaveButton';
import { handleImageFile, handleMediaFile } from 'api/fileUploadAPI';
import ReactQuill from 'react-quill';

const RecordedSeminarsTable = ({ color, handleOpenModal }) => {
    const dispatch = useDispatch();
    const editor = useRef(null);
    const [openEditModal, setOpenEditModal] = useState(false);

    const [videoOption, setVideoOption] = useState(1);
    const [thumbnailOption, setThumbnailOption] = useState(1);

    const [modalData, setModalData] = useState(null);
    const [isIntroLoader, setIsIntroLoader] = useState(false);

    const Data = useSelector((state) => state.recordedSeminar.data);
    const loading = useSelector((state) => state.recordedSeminar.loading);

    const handleOpenEditModal = (item) => {
        setModalData(item);
        setOpenEditModal(true);
    };

    const handleThumbnailURL = async (file) => {
        if (file === undefined) {
            setModalData({ ...modalData, thumbnailURL: '' });
            return;
        }

        try {
            setIsIntroLoader(true);
            const res = await handleImageFile(file);
            if (res.success) {
                setModalData({ ...modalData, thumbnailURL: res.file_url });
                toast.success('Successfully Uploaded the Image!');
            } else {
                throw new Error('Picture Not Successfully Uploaded');
            }
            setIsIntroLoader(false);
        } catch (error) {
            toast.error(error);
        }
    };

    function handleEditButton() {
        handleUpdate();
    }
    const handleUpdate = () => {
        const updatedData = {
            userCategory: modalData?.userCategory,
            title: modalData?.title,
            description: modalData?.description,
            thumbnailURL: modalData?.thumbnailURL,
            videoURL: modalData?.videoURL,
            isActive: modalData?.isActive,
        };
        dispatch(updateOne({ id: modalData?.id, body: updatedData }));
        setOpenEditModal(false);
        setThumbnailOption(1);
        setVideoOption(1);
    };
    const handleDeleteRecordedSeminar = async (id) => {
        dispatch(await deleteOne(id));
    };

    const handleQuillChange = (value) => {
        setModalData({ ...modalData, description: value });
    };

    const handleIntroMedia = async (file) => {
        if (file === undefined) {
            setModalData({ ...modalData, videoURL: '' });
            return;
        }

        try {
            setIsIntroLoader(true);
            const res = await handleMediaFile(file);
            if (res.success) {
                setModalData({ ...modalData, videoURL: res.file_url });
                toast.success('Successfully Uploaded the video!');
            } else {
                throw new Error('Video Not Successfully Uploaded');
            }
            setIsIntroLoader(false);
        } catch (error) {
            toast.error(error);
        }
    };

    useEffect(() => {
        dispatch(getList());
    }, [dispatch]);

    return (
        <>
            <Toaster />
            {/* Edit modal */}
            <Modal
                isOpen={openEditModal}
                onClose={() => setOpenEditModal(false)}
            >
                <div>
                    <Input
                        onChange={(e) =>
                            setModalData({
                                ...modalData,
                                userCategory: e.target.value,
                            })
                        }
                        defaultValue={modalData?.userCategory}
                        label="Recorded Seminar Category"
                        isRequired
                        options={constants.LiveSeminarCategory}
                        select
                    />
                    <Input
                        onChange={(e) =>
                            setModalData({
                                ...modalData,
                                title: e.target.value,
                            })
                        }
                        defaultValue={modalData?.title}
                        label="Recorded Seminar Title"
                        isRequired
                    />

                    <Input
                        onChange={(e) =>
                            setModalData({
                                ...modalData,
                                description: e.target.value,
                            })
                        }
                        defaultValue={modalData?.description}
                        label="Recorded Seminar Description"
                        isRequired
                        textarea
                        row={4}
                    />
                    <div className="flex flex-wrap">
                        <div className="w-full lg:w-12/12 px-4">
                            <div className="relative w-full mb-3">
                                <label
                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                    htmlFor="grid-password"
                                >
                                    Content{' '}
                                    <span className="red-asterisk"></span>
                                </label>
                                <ReactQuill
                                    style={{
                                        background: '#fff',
                                    }}
                                    className="ql-container ql-snow"
                                    ref={editor}
                                    modules={constants.modules}
                                    formats={constants.formats}
                                    defaultValue={modalData?.description}
                                    onChange={handleQuillChange}
                                />
                            </div>
                        </div>
                    </div>
                    <Input
                        label="Thumbnail Option"
                        onChange={(e) =>
                            setThumbnailOption(parseInt(e.target.value))
                        }
                        value={thumbnailOption}
                        options={[
                            { id: 1, value: 'Upload Thumbnail' },
                            { id: 2, value: 'Thumbnail URL' },
                        ]}
                        select
                        isRequired
                    />
                    {isIntroLoader && <Loader />}
                    {thumbnailOption === 1 ? (
                        <PictureUpload
                            onChange={handleThumbnailURL}
                            defaultValue={modalData?.thumbnailURL}
                            label="Image URL"
                            isRequired
                        />
                    ) : (
                        <Input
                            label="Image URL"
                            type="text"
                            onChange={(e) =>
                                setModalData({
                                    ...modalData,
                                    thumbnailURL: e.target.value,
                                })
                            }
                            value={modalData?.thumbnailURL}
                            isRequired
                        />
                    )}
                    <Input
                        label="Recorded Video Option"
                        onChange={(e) =>
                            setVideoOption(parseInt(e.target.value))
                        }
                        value={videoOption}
                        options={[
                            { id: 1, value: 'Upload Video' },
                            { id: 2, value: 'Video URL' },
                        ]}
                        select
                        isRequired
                    />
                    {videoOption === 1 ? (
                        <PictureUpload
                            onChange={handleIntroMedia}
                            accept="video/*"
                            defaultValue={modalData?.videoURL}
                            label="Recorded Seminar URL"
                            isRequired
                        />
                    ) : (
                        <Input
                            label="Recorded Seminar URL"
                            type="text"
                            onChange={(e) =>
                                setModalData({
                                    ...modalData,
                                    videoURL: e.target.value,
                                })
                            }
                            value={modalData?.videoURL}
                            isRequired
                        />
                    )}
                    <Input
                        label="Status"
                        onChange={(e) =>
                            setModalData({
                                ...modalData,
                                isActive: e.target.value,
                            })
                        }
                        defaultValue={modalData?.isActive}
                        select
                        options={[
                            { id: true, value: 'Active' },
                            { id: false, value: 'Inactive' },
                        ]}
                        isRequired
                    />
                    <div className="flex gap-2">
                        <SaveButton
                            handleEditButton={handleEditButton}
                        ></SaveButton>
                    </div>
                </div>
            </Modal>
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
                <div className="rounded-t mb-0 px-4 py-4 border-0">
                    <div className="flex flex-wrap items-center">
                        <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                            <h3 className="font-semibold text-lg text-blueGray-700">
                                Recorded Seminars
                            </h3>
                        </div>
                        <button
                            onClick={handleOpenModal}
                            className="bg-pink text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                            type="button"
                        >
                            Add New
                        </button>
                    </div>
                </div>
                <div className="block w-full overflow-x-auto">
                    {/* Projects table */}
                    <table className="items-center w-full bg-transparent border-collapse">
                        <thead>
                            <tr>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    ID
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    User Category
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Recorded Seminar Title
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Description
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Thumbnail URL
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Recorded Seminar URL
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Created At
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Created By
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Updated At
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Updated By
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Is Active
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading && (
                                <tr>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        <div className="flex items-center justify-center h-20">
                                            <Loader />
                                        </div>
                                    </td>
                                </tr>
                            )}
                            {Data?.map((item) => (
                                <tr key={item.id}>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.id}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.userCategory === '0'
                                            ? 'Free User'
                                            : item?.userCategory === '1'
                                            ? 'Pre Pregnancy'
                                            : item?.userCategory === '2'
                                            ? 'Pregnant'
                                            : item?.userCategory === '3'
                                            ? 'Parenting'
                                            : 'N/A'}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.title}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.description}
                                    </td>
                                    <td
                                        className="border-t-0 px-6 align-middle border-l-0 border-r
                    0 text-xs whitespace-nowrap p-4"
                                    >
                                        {item?.thumbnailURL}
                                    </td>
                                    <td
                                        className="border-t-0 px-6 align-middle border-l-0 border-r
                    0 text-xs whitespace-nowrap p-4"
                                    >
                                        <a
                                            href={item?.videoURL}
                                            target="_blank"
                                            rel="noopener noreferrer" // Recommended for security reasons
                                        >
                                            {item?.videoURL}
                                        </a>
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {new Date(
                                            item?.createdAt
                                        ).toLocaleString()}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.createdBy}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {new Date(
                                            item?.createdBy
                                        ).toLocaleString()}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.updatedBy}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item.isActive ? 'Active' : 'Inactive'}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        <div className="flex gap-2">
                                            <button
                                                onClick={() =>
                                                    handleOpenEditModal(item)
                                                }
                                                className="focus:outline-none	"
                                                type="button"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="h-5 w-5 mr-2 "
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    strokeWidth="2"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                                    />
                                                </svg>
                                            </button>
                                            <DeleteButton
                                                handleDeleteButton={() =>
                                                    handleDeleteRecordedSeminar(
                                                        item?.id
                                                    )
                                                }
                                            ></DeleteButton>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default RecordedSeminarsTable;
