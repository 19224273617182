import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getRecipe, createRecipe, updateRecipe, deleteRecipe } from "api/recipeAPI";

export const getRecipeList = createAsyncThunk('recipe/list', async () => {
    try {
        const res = await getRecipe();
        if (res.success) {
            return res.data;
        } else {
            throw new Error('Error fetching Recipe.');
        }
    } catch (error) {
        throw error;
    }
});

export const createOneRecipe = createAsyncThunk(
    'recipe/create-one',
    async (data) => {
        try {
            const res = await createRecipe(data);
            if (res.success) {
                return res.data;
            } else {
                throw new Error('Error creating Recipe.');
            }
        } catch (error) {
            throw error;
        }
    }
);

export const updateOneRecipe = createAsyncThunk(
    'recipe/update-one',
    async (parameters) => {
        try {
            const res = await updateRecipe(parameters);
            if (res.success) {
                return res.data;
            } else {
                throw new Error('Error updating Recipe.');
            }
        } catch (error) {
            throw error;
        }
    }
);

export const deleteOneRecipe = createAsyncThunk(
    'recipe/delete-one',
    async (id) => {
        try {
            const res = await deleteRecipe(id);
            if (res.success) {
                return id;
            } else {
                throw new Error('Error deleting Recipe.');
            }
        } catch (error) {
            throw error;
        }
    }
);

const recipeSlice = createSlice({
    name: 'recipe',
    initialState: {
        data: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            //* GET
            .addCase(getRecipeList.pending, (state) => {
                state.loading = true;
            })
            .addCase(getRecipeList.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(getRecipeList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            //* CREATE
            .addCase(createOneRecipe.pending, (state) => {
                state.loading = true;
            })
            .addCase(createOneRecipe.fulfilled, (state, action) => {
                state.loading = false;
                state.data.push(action.payload);
            })
            .addCase(createOneRecipe.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            //* UPDATE
            .addCase(updateOneRecipe.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateOneRecipe.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                const recipeIndex = state.data.findIndex(
                    (recipe) => recipe.id === action.payload.id
                );
                if (recipeIndex !== -1) {
                    state.data[recipeIndex] = action.payload;
                }
            })
            .addCase(updateOneRecipe.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            //* DELETE
            .addCase(deleteOneRecipe.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteOneRecipe.fulfilled, (state, action) => {
                state.loading = false;
                state.data = state.data.filter(
                    (recipe) => recipe.id !== action.payload
                );
            })
            .addCase(deleteOneRecipe.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const recipeAction = recipeSlice.actions;
export default recipeSlice;
