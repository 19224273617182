import React, { useState } from 'react';
import Modal from 'lib/Modal';
import Input from 'lib/Input';
import Loader from 'components/Loader/Loader';
import SaveButton from 'lib/SaveButton';
import { Toaster } from 'react-hot-toast';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getConsultationList, updateOne } from 'redux/consultationSlice';

const ConsultationTable = () => {
    const dispatch = useDispatch();
    const Data = useSelector((state) => state.consultation.data);
    const loading = useSelector((state) => state.consultation.loading);

    const [openEditModal, setOpenEditModal] = useState(false);
    const [modalData, setModalData] = useState({});

    
    const handleOpenEditModal = (item) => {
        setModalData(item);
        setOpenEditModal(true);
    };

    function handleEditButton() {
        handleUpdate();
    }

    const handleUpdate = async () => {
        const updatedData = {
            status: modalData?.status,
        };
        await dispatch(updateOne({ id: modalData?.id, body: { ...updatedData } }));
        dispatch(getConsultationList());
        setOpenEditModal(false);
    };
    
    useEffect(() => {
        dispatch(getConsultationList());
    }, [dispatch]);
    
    return (
        <>
            <Toaster />
            {/* Edit modal */}
            <Modal
                isOpen={openEditModal}
                onClose={() => setOpenEditModal(false)}
            >
                <div>
                    <Input
                        onChange={(e) =>
                            setModalData({
                                ...modalData,
                                status: e.target.value,
                            })
                        }
                        defaultValue={modalData?.status}
                        label="Status"
                        isRequired
                        options={[
                            { id: true, value: 'Confirmed' },
                            { id: false, value: 'Pending' },
                        ]}
                        select
                    />
                    <div className="flex gap-2">
                        <SaveButton
                            handleEditButton={handleEditButton}
                        ></SaveButton>
                    </div>
                </div>
            </Modal>
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
                <div className="rounded-t mb-0 px-4 py-4 border-0">
                    <div className="flex flex-wrap items-center">
                        <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                            <h3 className="font-semibold text-lg text-blueGray-700">
                                Consultation
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="block w-full overflow-x-auto">
                    {/* Table */}
                    <table className="w-full bg-transparent border-collapse">
                        <thead>
                            <tr>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    ID
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Status
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Name
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Email
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Phone
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Date
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Time
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Message
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Created At
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading && (
                                <tr>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        <div className="flex items-center justify-center h-20">
                                            <Loader />
                                        </div>
                                    </td>
                                </tr>
                            )}
                            {Data?.map((item) => (
                                <tr key={item?.id}>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.id}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        <span
                                            style={
                                                item?.status
                                                    ? { backgroundColor:'#4ade80d9', }
                                                    : {backgroundColor:'#facc15d9', }
                                            }
                                            className="p-2 uppercase font-bold rounded-lg bg-yellow-400"
                                        >
                                            {item?.status === true
                                                ? 'Confirmed'
                                                : 'Pending'}
                                        </span>
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.name}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.email}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.phone}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.date}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.time}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item?.message}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {new Date(
                                            item?.createdAt
                                        ).toLocaleDateString()}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        <div className="flex justify-evenly">
                                            <button
                                                onClick={() =>
                                                    handleOpenEditModal(item)
                                                }
                                                className="focus:outline-none	"
                                                type="button"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="h-5 w-5 mr-2 "
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    strokeWidth="2"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default ConsultationTable;
