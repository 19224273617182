import React, { useState, useRef } from "react";
import "quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import "../../assets/styles/richText.css";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import Modal from "lib/Modal";
import Input from "lib/Input";
import PictureUpload from "lib/PictureUpload";
import Loader from "components/Loader/Loader";
import DietAndNutritionTable from "components/Cards/DietAndNutritionTable";
import { constants } from "utils/constants";
import { createOne } from "redux/dietAndNutritionSlice";
import { handleImageFile, handleMediaFile } from "api/fileUploadAPI";

const DietAndNutrition = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const [userCategory, setUserCategory] = useState("");
  const [month, setMonth] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [introMediaUrl, setIntroMediaUrl] = useState("");
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [formError, setFormError] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [introMediaType, setIntroMediaType] = useState("");
  const [isIntroLoader, setIsIntroLoader] = useState(false);
  const [isThumbnailLoader, setIsThumbnailLoader] = useState(false);

  const dispatch = useDispatch();
  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setUserCategory("");
    setTitle("");
    setDescription("");
    setIntroMediaUrl("");
    setThumbnailUrl("");
    setMonth("");
    // setGender("");
    setFormError(false);
  };

  const handleAddDiet = () => {
    if (
      userCategory !== "" &&
      month !== "" &&
      // gender !== "" &&
      title !== "" &&
      description !== "" &&
      thumbnailUrl !== "" &&
      introMediaType !== "" 
    ) {
      const data = {
        userCategory: userCategory,
        month: month,
        // gender: gender,
        title: title,
        content: description,
        thumbnailURL: thumbnailUrl,
        mediaType: introMediaType,
        mediaURL: introMediaUrl,
        isActive: isActive,
      };

      dispatch(createOne(data));
      setModalOpen(false);
      setUserCategory("");
      setMonth("");
      // setGender("");
      setTitle("");
      setDescription("");
      setIntroMediaUrl("");
      setIntroMediaType("");
      setThumbnailUrl("");
      setFormError(false);
    } else {
      setFormError(true);
    }
  };

  const editor = useRef(null);
  const handleQuillChange = (value) => {
    setDescription(value);
  };

  const handleIntroMedia = async (file) => {
    if (file === undefined) {
      setIntroMediaUrl("");
      return;
    }

    if (introMediaType === "1") {
      //media url accepts file as string
      try {
        setIsIntroLoader(true);
        const res = await handleImageFile(file);
        if (res.success) {
          setIntroMediaUrl(res.file_url);

          toast.success("Successfully Uploaded the Image!");
        } else {
          throw new Error("Picture Not Successfully Uploaded");
        }
        setIsIntroLoader(false);
      } catch (error) {
        toast.error(error);
      }
    } else {
      try {
        setIsIntroLoader(true);
        const res = await handleMediaFile(file);
        if (res.success) {
          setIntroMediaUrl(res.file_url);
          introMediaType === "2"
            ? toast.success("Successfully Uploaded the Audio!")
            : toast.success("Successfully Uploaded the video!");
        } else {
          throw new Error("Picture Not Successfully Uploaded");
        }
        setIsIntroLoader(false);
      } catch (error) {
        toast.error(error);
      }
    }
  };

  const handleThumbnail = async (file) => {
    if (file === undefined) {
        setThumbnailUrl("");
        return;
    }
    
    try {
      setIsThumbnailLoader(true);
      const res = await handleImageFile(file);
      if (res.success) {
        setThumbnailUrl(res.file_url);

        toast.success("Successfully Uploaded the Image!");
      } else {
        throw new Error("Picture Not Successfully Uploaded");
      }
      setIsThumbnailLoader(false);
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <>
      <Modal isOpen={modalOpen} onClose={handleCloseModal}>
        <div>
          <Input
            label="User Category"
            onChange={(e) => setUserCategory(e.target.value)}
            value={userCategory}
            select
            options={constants.DietUserCategory}
            isRequired
          />
          <Input
            label="Month"
            onChange={(e) => setMonth(parseInt(e.target.value))}
            value={month}
            select
            options={constants.monthsOptions}
            isRequired
          />
          {/* <Input
                onChange={(e) => setGender(e.target.value)}
                value={gender}
                label="Gender"
                isRequired
                options={constants.gender}
                select
              /> */}
          <Input
            label="Diet Title"
            onChange={(e) => setTitle(e.target.value)}
            value={title}
            isRequired
          />
          <ReactQuill
            style={{
              marginLeft: 15,
              marginRight: 15,
              background: "#fff",
            }}
            className="ql-container ql-snow"
            ref={editor}
            modules={constants.modules}
            formats={constants.formats}
            defaultValue={description}
            onChange={handleQuillChange}
          />
          <Input
            label="Intro Media Type"
            onChange={(e) => setIntroMediaType(e.target.value)}
            value={introMediaType}
            select
            options={constants.mediaOption}
            isRequired
          />
          {isIntroLoader && <Loader />}
          {introMediaType !== "0" && (
            <PictureUpload
              onChange={handleIntroMedia}
              defaultValue={introMediaUrl}
              label={
                introMediaType === "3"
                  ? "Intro Video"
                  : introMediaType === "2"
                  ? "Intro Audio"
                  : "Intro Image"
              }
              accept="image/*, video/*"
            />
          )}
          {isThumbnailLoader && <Loader />}
          <PictureUpload
            label="Thumbnail Image"
            onChange={handleThumbnail}
            value={thumbnailUrl}
            isRequired
          />
          <Input
            label="Status"
            onChange={(e) => setIsActive(e.target.value)}
            defaultValue={isActive}
            select
            options={[
              { id: true, value: "Active" },
              { id: false, value: "Inactive" },
            ]}
            isRequired
          />
          {formError && (
            <p className="text-red-500 mt-2">Please fill all the details!</p>
          )}
          <div className="flex gap-2 mt-4">
            <button
              onClick={handleAddDiet}
              disabled={isThumbnailLoader || isIntroLoader}
              className="bg-pink text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
            >
              Save
            </button>
          </div>
        </div>
      </Modal>

      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <DietAndNutritionTable
            handleOpenModal={handleOpenModal}
          />
        </div>
      </div>
    </>
  );
};

export default DietAndNutrition;
