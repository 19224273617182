import { config } from './confg';
import { axiosCallAdvanced } from './main';

export const getOrder = async () => {
    return (
        (
            await axiosCallAdvanced({
                baseURL: config.subscriptionOrder.root,
                path: config.subscriptionOrder.list,
            })
        )?.data || {}
    );
};
