// ** Axios
import axios from "axios";
import toast from "react-hot-toast";

// ** Config
import { config } from "./confg";

export const axiosCallAdvanced = async ({
  path,
  baseURL,
  method = "get",
  body,
  search = "",
  sort = "asc",
  sortColumn = "id",
  page = 1,
  limit = 10,
  parameter,
  customSuccessMessage,
}) => {
  const params = { ...parameter };

  // if (method && method === "get" && path.split("list").length > 1) {
  //   const sorting = sort === "asc" ? sortColumn : `-${sortColumn}`;

  //   params.search = search;
  //   params.sort = sorting;
  //   params.page = page;
  //   params.limit = limit;
  // }

  const url = config.root + baseURL + path + `${method == "get" ? `?sort=${sortColumn}&mode=${sort}` : ''}`;

  const storedToken = window.localStorage.getItem(config.storageTokenKeyName);

  try {
    const response = await axios({
      url,
      params,
      method,
      data: body,
      headers: {
        "Content-Type": "application/json",
        Authorization: storedToken,
      },
    });
    const successMessages = {
      post: "Successfully Created!",
      put: "Successfully Updated!",
      patch: "Successfully Deleted!",
    };

    const httpMethod = method.toLowerCase();
    const status = response.status;

    if (customSuccessMessage && status >= 200 && status < 300) {
      toast.success(customSuccessMessage, { position: "top-right" });
    } else if (successMessages[httpMethod] && status >= 200 && status < 300) {
      toast.success(successMessages[httpMethod], { position: "top-right" });
    }
    return response;
  } catch (error) {
    toast.error(
      error?.response?.data?.message || error.message || error?.error.message
    );
    return error.message;
  }
};

export default {
  axiosCallAdvanced,
};
