import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getNotification, createNotification, deleteNotification } from 'api/notificationAPI';

export const getNotificationList = createAsyncThunk(
    'notification/list-all',
    async () => {
        try {
            const res = await getNotification();
            if (res.success) {
                return res.data;
            } else {
                throw new Error('Error fetching data');
            }
        } catch (error) {
            throw error; //action.error.message goes
        }
    }
);

export const createNewNotification = createAsyncThunk(
    'notification/create-one',
    async (data) => {
        try {
            const res = await createNotification(data);
            if(res.success) {
                return res.data;
            }
            else {
                throw new Error('Error creating a new Notification.');
            }
        } catch (error) {
            throw error;
        }
    }
);

export const deleteOne = createAsyncThunk(
    'notification/delete-one',
    async (id) => {
        try {
            const res = await deleteNotification(id);
            if (res.success) {
                return id;
            } else {
                throw new Error('Error deleting a Notification.');
            }
        } catch (error) {
            throw error;
        }
    }
)

const notificationSlice = createSlice({
    name: 'notification',
    initialState: {
        data: [],
        loading: false,
        error: null,
    },
    reducer: {},
    extraReducers: (builder) => {
        builder
            .addCase(getNotificationList.pending, (state) => {
                state.loading = true;
            })
            .addCase(getNotificationList.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(getNotificationList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(createNewNotification.pending, (state) => {
                state.loading = true;
            })
            .addCase(createNewNotification.fulfilled, (state, action) => {
                state.loading = false;
                state.data.push(action.payload);
            })
            .addCase(createNewNotification.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(deleteOne.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteOne.fulfilled, (state, action) => {
                state.loading = false;
                state.data = state.data.filter(
                    (notification) => notification.id !== action.payload
                );
            })
            .addCase(deleteOne.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const notificationAction = notificationSlice.actions;
export default notificationSlice;
