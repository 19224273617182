import ProductCategoryTable from "components/Cards/ProductCategoryTable";
import Input from "lib/Input";
import React, { useEffect } from "react";
import { useState } from "react";
import Modal from "lib/Modal";
import { useDispatch } from "react-redux";
import { getProductCategory } from "api/productCategoryAPI";
import { createNewProductCategory } from "redux/productCategorySlice";

const ProductCategory = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [categoryDetails, setCategoryDetails] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [formError, setFormError] = useState(false);

  const dispatch = useDispatch();

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleCreateNew = () => {
    if (
      title.trim() !== "" &&
      metaTitle.trim() !== "" &&
      slug.trim() !== "" &&
      categoryDetails.trim() !== ""
    ) {
      const newcategory = {
        parentId: null,
        title: title.trim(),
        metaTitle: metaTitle.trim(),
        slug: slug.trim(),
        categoryDetails: categoryDetails.trim(),
        isActive: isActive,
      };
      dispatch(createNewProductCategory(newcategory));
      setModalOpen(false);
      setTitle("");
      setMetaTitle("");
      setSlug("");
      setCategoryDetails("");
      setFormError(false);
    } else {
      setFormError(true);
    }
  };
  return (
    <>
      <Modal isOpen={modalOpen} onClose={handleCloseModal}>
        <div>
          <Input
            onChange={(e) => setTitle(e.target.value)}
            value={title}
            label={
              <span>
                Title <span className="red-asterisk"></span>
              </span>
            }
          />
          <Input
            onChange={(e) => setMetaTitle(e.target.value)}
            value={metaTitle}
            label={
              <span>
                Meta Title <span className="red-asterisk"></span>
              </span>
            }
          />
          <Input
            onChange={(e) => setSlug(e.target.value)}
            value={slug}
            label={
              <span>
                Slug <span className="red-asterisk"></span>
              </span>
            }
          />
          <Input
            onChange={(e) => setCategoryDetails(e.target.value)}
            value={categoryDetails}
            label={
              <span>
                Category Details <span className="red-asterisk"></span>
              </span>
            }
          />
          {formError && (
            <p className="mx-4 text-red-500 mb-2">
              Please fill all the details!
            </p>
          )}
          <Input
            onChange={(e) => setIsActive(e.target.value)}
            defaultValue={isActive}
            options={[
              { id: true, value: "Active" },
              { id: false, value: "Inactive" },
            ]}
            select
            label={
              <span>
                Status <span className="red-asterisk"></span>
              </span>
            }
          />
          <div className="flex gap-2">
            <button
              onClick={handleCreateNew}
              className="bg-pink mx-4 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
            >
              Save
            </button>
          </div>
        </div>
      </Modal>
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <ProductCategoryTable
            handleOpenModal={handleOpenModal}
            color="dark"
          />
        </div>
      </div>
    </>
  );
};

export default ProductCategory;
