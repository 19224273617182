import React, { useState } from 'react';
import NotificationTable from 'components/Cards/NotificationTable';
import Modal from 'lib/Modal';
import Input from 'lib/Input';
import { useDispatch } from 'react-redux';
import { createNewNotification } from 'redux/notificationSlice';


const Notification = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [formError, setFormError] = useState(false);

    const [type, setType] = useState('GENERAL');
    const [content, setContent] = useState('');
    const [category, setCategory] = useState('0');
    const [title, setTitle] = useState('');

    const dispatch = useDispatch();

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleCreateNew = async () => {
        if (content.trim() !== '') {
            const newNotification = {
                title: title.trim(),
                content: content.trim(),
                type: type,
                category: category
            };
            dispatch(await createNewNotification(newNotification));

            setTitle('');
            setContent('');
            setType('GENERAL');

            setModalOpen(false);
            setFormError(false);
        } else {
            setFormError(true);
        }
    };

    const [userCategories, setuserCategories] = useState([
        { id: 0, value: 'General' },
        { id: 1, value: 'pre-pregnancy' },
        { id: 2, value: 'pregnancy' },
        { id: 3, value: 'post-pregnancy' }
    ])

    return (
        <>
            <Modal isOpen={modalOpen} onClose={handleCloseModal}>
                <div>
                    <Input
                        onChange={(e) => setTitle(e.target.value)}
                        value={title}
                        label="Title"
                        type="text"
                        isRequired
                    />
                    <Input
                        onChange={(e) => setContent(e.target.value)}
                        value={content}
                        label="Content"
                        type="text"
                        isRequired
                    />
                    <div className="flex flex-wrap">
                        <div className="w-full lg:w-12/12 px-4">
                            <div className="relative w-full mb-3">
                                <label
                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                    htmlFor="grid-password"
                                >
                                    User Category <span className="red-asterisk"></span>
                                </label>
                                <select
                                    name="user category"
                                    id="userCategoryId"
                                    // multiple
                                    onChange={(e) => {
                                        const category = e.target.value;
                                        console.log(category);

                                        if (category) {
                                            setCategory(e.target.value)
                                        }
                                    }}
                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                >
                                    {userCategories?.map((category) => (
                                        <option key={category.id} value={category.id}>{category.value}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>

                    {/* <Input 
                        value={'GENERAL'} 
                        label="Type" 
                        isRequired 
                    /> */}
                    {formError && (
                        <p className="mx-4 text-red-500 mb-2">
                            Please fill all the details!
                        </p>
                    )}
                    <div className="flex gap-2">
                        <button
                            onClick={handleCreateNew}
                            className="bg-pink mx-4 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                            type="button"
                        >
                            Save
                        </button>
                    </div>
                </div>
            </Modal>

            <div className="flex flex-wrap mt-4">
                <div className="w-full mb-12 px-4">
                    <NotificationTable handleOpenModal={handleOpenModal} />
                </div>
            </div>
        </>
    );
};

export default Notification;
