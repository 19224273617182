import React, { useState } from "react";
import Input from "lib/Input";
import Modal from "lib/Modal";
import BabyHealthTable from "components/Cards/BabyHealthTable";
import PictureUpload from "lib/PictureUpload";
import { createOneBabyHealth } from "redux/babyHealthSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-hot-toast";
import { handleImageFile } from "api/fileUploadAPI";
import Loader from "components/Loader/Loader";

const BabyHealth = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [week, setWeek] = useState("");
  const [size, setSize] = useState("");
  const [description, setDescription] = useState("");
  const [weight, setWeight] = useState("");
  const [imageURL, setImageURL] = useState("");
  const [sizeImgURL, setSizeImgURL] = useState("");
  const [height, setHeight] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [formError, setFormError] = useState(false);
  const [isIntroLoader, setIsIntroLoader] = useState(false);

  const dispatch = useDispatch();
  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleSave = () => {
    if (
      week.trim() !== "" &&
      size.trim() !== "" &&
      description.trim() !== "" &&
      weight.trim() !== "" &&
      imageURL !== "" &&
      height.trim() !== ""
    ) {
      const newBabyData = {
        week: parseInt(week),
        size: size,
        description: description,
        weight: weight,
        height: height,
        imageURL: imageURL,
        sizeImgURL: sizeImgURL,
        isActive: isActive,
      };
      dispatch(createOneBabyHealth(newBabyData));
      setModalOpen(false);
      setWeek("");
      setHeight("");
      setWeight("");
      setImageURL("");
      setSize("");
      setDescription("");
      setFormError(false);
    } else {
      setFormError(true);
    }
  };

  const handleImageUrl = async (file) => {

    if(file === undefined) {
      setImageURL("");
      return;
    }

    try {
      setIsIntroLoader(true);
      const res = await handleImageFile(file);
      if (res.success) {
        setImageURL(res.file_url);

        toast.success("Image Uploaded Successfully!");
      } else {
        toast.error("Picture Not Uploaded");
      }
      setIsIntroLoader(false);
    } catch (error) {
      toast.error(error);
    }
  };

  const handleSizeUrl = async (file) => {

    if(file === undefined) {
      setSizeImgURL("");
      return;
    }
    
    try {
      setIsIntroLoader(true);
      const res = await handleImageFile(file);
      if (res.success) {
        setSizeImgURL(res.file_url);

        toast.success("Image Uploaded Successfully!");
      } else {
        toast.error("Picture Not Uploaded");
      }
      setIsIntroLoader(false);
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <>
      <Modal isOpen={modalOpen} onClose={handleCloseModal}>
        <div>
          <Input
            label="Week"
            isRequired
            defaultValue={week}
            onChange={(e) => setWeek(e.target.value)}
            type="text"
          />
          <Input
            label="Baby Size"
            isRequired
            defaultValue={size}
            onChange={(e) => setSize(e.target.value)}
          />
          <Input
            label="Baby Description (Don't add more than 200 characters)"
            isRequired
            defaultValue={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <Input
            label="Baby Weight (in KG's)"
            isRequired
            defaultValue={weight}
            onChange={(e) => setWeight(e.target.value)}
          />
          <Input
            label="Baby Height (in CM)"
            isRequired
            defaultValue={height}
            onChange={(e) => setHeight(e.target.value)}
          />
          {isIntroLoader && <Loader />}
          <PictureUpload
            label="Baby Image Url (64x64 recommended)"
            isRequired
            defaultValue={imageURL}
            onChange={handleImageUrl}
          />
          {isIntroLoader && <Loader />}
          <PictureUpload
            label="Baby Size Image (40x40 recommended)"
            isRequired
            defaultValue={sizeImgURL}
            onChange={handleSizeUrl}
          />

          <Input
            onChange={(e) => setIsActive(e.target.value)}
            defaultValue={true}
            options={[
              { id: true, value: "Active" },
              { id: false, value: "Inactive" },
            ]}
            select
            label="Visibility State"
            isRequired
          />
          {formError && (
            <p className="mx-4 text-red-500 mb-2">
              Please fill all the details!
            </p>
          )}

          <div className="flex gap-2 mt-4">
            <button
              onClick={handleSave}
              className="bg-pink text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
            >
              Save
            </button>
          </div>
        </div>
      </Modal>

      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <BabyHealthTable handleOpenModal={handleOpenModal} color="dark" />
        </div>
      </div>
    </>
  );
};

export default BabyHealth;
