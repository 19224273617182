import React, { useRef, useState } from "react";
import ReactSwitch from "react-switch";
import { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
    getHomeRemediesList,
    createHomeRemedies,
    updateHomeRemedies,
    deleteHomeRemedies
} from 'redux/homeRemediesSlice';
import Loader from 'components/Loader/Loader';
import Modal from 'lib/Modal';
import Input from 'lib/Input';
import SaveButton from 'lib/SaveButton';
import { config } from "api/confg";
import DeleteButton from 'lib/DeleteButton';
import ReactQuill from 'react-quill';
import { constants } from "utils/constants";



const HomeRemediesTable = () => {
    const editor = useRef(null);
    const dispatch = useDispatch();

    const homeRemediesData = useSelector((state) => state.homeRemedies.data);
    const loading = useSelector((state) => state.homeRemedies.loading);

    const [openEditModal, setOpenEditModal] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [modalData, setModalData] = useState({
        title: '',
        content: '',
        userCategory: '0',
        isActive: 1,
        createdBy: '',
        updatedBy: '',
        createdAt: '',
        updatedAt: ''
    });
    const [sortingMode, setSortingMode] = useState('ASC');

    useEffect(() => {
        dispatch(getHomeRemediesList(sortingMode));
    }, [dispatch]);


    const handleDeactivation = async (id) => {
        const response = await dispatch(deleteHomeRemedies({ id }));
        if (response.payload.success) {
            dispatch(getHomeRemediesList());
        }
    };

    const handleOpenEditModal = (item) => {
        setModalData(item);
        setOpenEditModal(true);
        setEditMode(true);
    };

    const handleCreate = async () => {

        var createData = {
            title: modalData.title,
            content: modalData.content,
            userCategory: modalData.userCategory,
        }

        const response = await dispatch(
            createHomeRemedies({
                body: { ...createData },
            })
        );
        if (response.payload.success) {
            dispatch(getHomeRemediesList());
        }
        setOpenEditModal(false);
    };

    const handleUpdate = async (editData) => {
        var editDumData = {};
        if (editData) {
            editDumData = editData;
        }
        else {
            editDumData = { ...modalData };
        }
        var createData = {
            title: editDumData.title,
            content: editDumData.content,
            userCategory: editDumData.userCategory,
            isActive: editDumData.isActive,
        }

        const response = await dispatch(
            updateHomeRemedies({
                id: editDumData.id,
                body: { ...createData },
            })
        );
        if (response.payload.success) {
            dispatch(getHomeRemediesList());
        }
        setOpenEditModal(false);
    };

    function segregateUserCategory(userCategory) {
        if (userCategory == 0) {
            return 'All';
        }
        else if (userCategory == 1) {
            return 'Pre-pregnancy';
        } else if (userCategory == 2) {
            return 'Pregnancy';
        } else if (userCategory == 3) {
            return 'Post-pregnancy';
        }
        else {
            return 'None';
        }
    }

    return (
        <>
            <Toaster />
            <Modal
                isOpen={openEditModal}
                onClose={() => setOpenEditModal(false)}
            >

                <div>
                    <div className="mb-3 w-full lg:w-12/12 px-4">
                        <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor={"title"}
                        >
                            Title
                        </label>
                        <input
                            name={"title"}
                            type="text"
                            value={modalData.title}
                            placeholder="Title"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            onChange={(e) => setModalData({ ...modalData, title: e.target.value })}
                        />
                    </div>
                    {/* <div className="mb-3 w-full lg:w-12/12 px-4">
                        <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor={"Content"}
                        >
                            Content
                        </label>
                        <input
                            name={"content"}
                            type="text"
                            value={modalData.content}
                            placeholder="Content"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            onChange={(e) => setModalData({ ...modalData, content: e.target.value })}
                        />
                    </div> */}
                    <div className="flex flex-wrap">
                        <div className="w-full lg:w-12/12 px-4">
                            <div className="relative w-full mb-3">
                                <label
                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                    htmlFor="grid-password"
                                >
                                    Content{' '}
                                    <span className="red-asterisk"></span>
                                </label>
                                <ReactQuill
                                    style={{
                                        background: '#fff',
                                    }}
                                    className="ql-container ql-snow"
                                    ref={editor}
                                    modules={constants.modules}
                                    formats={constants.formats}
                                    // defaultValue={modalData?.content}
                                    value={modalData?.content ? modalData.content : ""}
                                    onChange={(value) => setModalData({ ...modalData, content: value })}
                                />
                            </div>
                        </div>
                    </div>
                    <Input
                        onChange={(e) =>
                            setModalData({
                                ...modalData,
                                userCategory: e.target.value
                            })
                        }
                        defaultValue={modalData?.userCategory}
                        label="Select Category"
                        isRequired
                        options={[
                            { id: '0', value: 'All' },
                            { id: '1', value: 'Pre-pregnancy' },
                            { id: '2', value: 'Pregnant' },
                            { id: '3', value: 'Post-pregnancy' },
                        ]}
                        select
                    />
                    <div className="flex gap-2">
                        {loading ?
                            <div className="flex items-center justify-center h-12">
                                <Loader />
                            </div>
                            :
                            <button
                                onClick={() => {
                                    if (editMode) {
                                        handleUpdate();
                                    }
                                    else {
                                        handleCreate();
                                    }
                                }}
                                className="bg-pink mx-4 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                            >
                                Save
                            </button>
                        }
                    </div>
                </div>
            </Modal>
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
                <div className="rounded-t mb-0 px-4 py-4 border-0 flex justify-between">
                    <div className="flex flex-wrap items-center">
                        <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                            <h3 className="font-semibold text-lg text-blueGray-700">
                                Home Remedies Table
                            </h3>
                        </div>
                    </div>
                    <div className=" flex justify-center items-center ">
                        {/* <button className="rounded-lg px-4 py-2 font-bold text-xs text-white flex items-center justify-center gap-2 bg-pink active:bg-lightBlue-600"
                            onClick={() => {
                                var sMode = sortingMode;
                                if (sMode == "ASC") {
                                    sMode = "DESC";
                                }
                                else {
                                    sMode = "ASC";
                                }
                                dispatch(getHomeRemediesList(sMode));
                                setSortingMode(sMode);
                            }}
                            disabled={loading ? true : false}
                        >
                            {sortingMode == "ASC" ?
                                <span>Latest</span> : <span>Oldest</span>}
                            <span
                                style={{ transform: `${sortingMode == "ASC" ? "rotate(0deg)" : "rotate(180deg)"}` }}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width={20} height={16} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={1} strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-arrow-down">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M12 5l0 14" />
                                    <path d="M18 13l-6 6" />
                                    <path d="M6 13l6 6" />
                                </svg>
                            </span>
                        </button> */}
                        <button className="rounded-lg px-3 py-2 text-white font-bold text-xs flex items-center justify-center gap-2 ml-3 bg-pink active:bg-lightBlue-600"
                            onClick={() => {
                                setOpenEditModal(true);
                            }}
                        >
                            Add
                        </button>
                    </div>

                </div>
                <div className="block w-full overflow-x-auto">
                    <table className="items-center w-full bg-transparent border-collapse">
                        <thead>
                            <tr>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    ID
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Title
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Content
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    User Category
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Created At
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Created By
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Updated At
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Updated By
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Is Active
                                </th>
                                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                                    Actions
                                </th>
                            </tr>
                        </thead>

                        {loading ? (
                            <tr>
                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                    <div className="flex items-center justify-center h-20">
                                        <Loader />
                                    </div>
                                </td>
                            </tr>
                        ) :
                            <tbody>
                                {homeRemediesData && homeRemediesData.length > 0 ?
                                    <>
                                        {homeRemediesData?.map((item) => (
                                            <tr key={item?.id}>
                                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                    {item?.id}
                                                </td>
                                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                    {item?.title}
                                                </td>
                                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                    {item?.content.length > 20 ? (item?.content.slice(0, 20) + "...") : item?.content}
                                                </td>
                                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                    {segregateUserCategory(item?.userCategory)}
                                                </td>
                                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                    {item?.createdAt}
                                                </td>
                                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                    {item?.createdBy}
                                                </td>
                                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                    {item?.updatedAt}
                                                </td>
                                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                    {item?.updatedBy}
                                                </td>
                                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                    <ReactSwitch
                                                        onChange={() => {
                                                            var editData = { ...item };
                                                            editData.isActive = item?.isActive ? "false" : "true";
                                                            handleUpdate(editData);
                                                        }
                                                        }
                                                        checked={
                                                            item?.isActive == '1'
                                                        }
                                                    />
                                                </td>
                                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                    <div className="flex justify-evenly">
                                                        <button
                                                            onClick={() =>
                                                                handleOpenEditModal(item)
                                                            }
                                                            className="focus:outline-none	"
                                                            type="button"
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="h-5 w-5 mr-2 "
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke="currentColor"
                                                                strokeWidth="2"
                                                            >
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                                                />
                                                            </svg>
                                                        </button>
                                                        <DeleteButton
                                                            handleDeleteButton={() => {
                                                                handleDeactivation(item?.id);
                                                                // handleDeleteButton(
                                                                //     item?.id
                                                                // );
                                                            }}
                                                        ></DeleteButton>
                                                    </div>
                                                </td>

                                            </tr>
                                        ))}
                                    </>
                                    : <></>}
                            </tbody>
                        }
                    </table>
                </div>
            </div >
        </>
    );
};
export default HomeRemediesTable;
