import { config } from "./confg";
import { axiosCallAdvanced } from "./main";

export const userLogin = async (mobile) => {
  try {
    return await axiosCallAdvanced({
      baseURL: config.auth.root,
      path: config.auth.login,
      body: {
        mobile: mobile,
      },
      method: config.methodType.post,
    }).then((response) => {
      localStorage.setItem("mobile", mobile);
      return response.data;
    });
  } catch (error) {
    return error.message;
  }
};

export const verifyOtp = async (otp) => {
  try {
    return await axiosCallAdvanced({
      baseURL: config.auth.root,
      path: config.auth.verifyOtp,
      body: {
        otp: otp,
        mobile: localStorage.getItem("mobile"),
      },
      method: config.methodType.post,
    }).then((response) => {
      return response.data;
    });
  } catch (error) {
    return error.message;
  }
};
