import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getPremiumUser } from "api/customerAPI";

export const getCustomerList = createAsyncThunk(
  "userDetails/premium",
  async () => {
    try {
      const res = await getPremiumUser();
      if (res.success) {
        return res.data;
      } else {
        throw new Error("Error Fetching Data");
      }
    } catch (error) {
      throw error;
    }
  }
);

const customerSlice = createSlice({
  name: "customers",
  initialState: {
    data: [],
    loading: false,
    error: null,
    selectedCustomerIds: [],
    selectAll: false,
  },
  reducers: {
    toggleCustomerSelection: (state, action) => {
      const customerId = action.payload;
      if (state.selectedCustomerIds.includes(customerId)) {
        state.selectedCustomerIds = state.selectedCustomerIds.filter(
          (id) => id !== customerId
        );
      } else {
        state.selectedCustomerIds.push(customerId);
      }
      state.selectAll =
        state.selectedCustomerIds?.length === state.data?.length;
    },
    toggleSelectAll: (state) => {
      state.selectAll = !state.selectAll;
      state.selectedCustomerIds = state.selectAll
        ? state.data.map((customer) => customer?.id)
        : [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomerList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCustomerList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getCustomerList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { toggleCustomerSelection, toggleSelectAll } =
  customerSlice.actions;
export default customerSlice;
