import React, { useState, useRef } from "react";
import Modal from "lib/Modal";
import Input from "lib/Input";
import BlogsTable from "components/Cards/BlogsTable";
import PictureUpload from "lib/PictureUpload";
import { constants } from "utils/constants";
import Loader from "components/Loader/Loader";
import { useDispatch } from "react-redux";
import { handleImageFile } from "api/fileUploadAPI";
import { handleMediaFile } from "api/fileUploadAPI";
import { toast } from "react-hot-toast";
import { createOneBlog } from "redux/blogsSlice";
import ReactQuill from "react-quill";
import "assets/styles/richText.css";

const Blogs = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [content, setContent] = useState("");
  const [title, setTitle] = useState("");
  const [thumbnailURL, setThumbnailURL] = useState("");
  const [userCategory, setUserCategory] = useState("");
  const [mediaURL, setMediaURL] = useState("");
  const [mediaType, setMediaType] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [formError, setFormError] = useState(false);
  const [isIntroLoader, setIsIntroLoader] = useState(false);
  const [isMediaLoader, setIsMediaLoader] = useState(false);
  const [slugURL, setSlugURL] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [metaKeywords, setMetaKeywords] = useState("");
  const [publishDate, setPublishDate] = useState("");
  const [publishTime, setPublishTime] = useState("");
  const dispatch = useDispatch();
  const editor = useRef(null);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleCreateNew = () => {
    if (
      content !== "" &&
      thumbnailURL !== "" &&
      title !== "" &&
      mediaType !== "" &&
      userCategory !== "" &&
      slugURL !== "" &&
      metaDescription !== "" &&
      metaKeywords !== "" &&
      publishDate !== "" &&
      publishTime !== ""
    ) {
      const formattedPublishDate = new Date(publishDate).toISOString().slice(0, 10);

      const newBlog = {
        userCategory: userCategory,
        thumbnailURL: thumbnailURL,
        title: title,
        content: content,
        mediaType: mediaType,
        mediaURL: mediaURL,
        isActive: isActive,
        slugURL: slugURL,
        metaDescription: metaDescription,
        metaKeywords: metaKeywords,
        publishDate: formattedPublishDate,
        publishTime: publishTime,
      };
      console.log(formattedPublishDate)
      dispatch(createOneBlog(newBlog));
      setModalOpen(false);
      setContent("");
      setTitle("");
      setUserCategory("");
      setThumbnailURL("");
      setMediaType("");
      setMediaURL("");
      setSlugURL("");
      setMetaDescription("");
      setMetaKeywords("");
      setPublishDate("");
      setPublishTime("");
      setFormError(false);
    } else {
      setFormError(true);
    }
  };

  const handleIntroMedia = async (file) => {
    if (file === undefined) {
      setMediaURL("");
      return;
    }

    if (mediaType === "1") {
      try {
        setIsMediaLoader(true);
        const res = await handleImageFile(file);
        if (res.success) {
          setMediaURL(res.file_url);

          toast.success("Successfully Uploaded the Image!");
        } else {
          throw new Error("Picture Not Successfully Uploaded");
        }
        setIsMediaLoader(false);
      } catch (error) {
        toast.error(error);
      }
    } else {
      try {
        setIsMediaLoader(true);
        const res = await handleMediaFile(file);
        if (res.success) {
          setMediaURL(res.file_url);
          mediaType === "2"
            ? toast.success("Successfully Uploaded the Audio!")
            : toast.success("Successfully Uploaded the video!");
        } else {
          throw new Error("Picture Not Successfully Uploaded");
        }
        setIsMediaLoader(false);
      } catch (error) {
        toast.error(error);
      }
    }
  };

  const handleThumbnailUrl = async (file) => {
    if (file === undefined) {
      setThumbnailURL("");
      return;
    }

    try {
      setIsIntroLoader(true);
      const res = await handleImageFile(file);
      if (res.success) {
        setThumbnailURL(res.file_url);
        toast.success("Successfully Uploaded the Image!");
      } else {
        throw new Error("Picture Not Successfully Uploaded");
      }
      setIsIntroLoader(false);
    } catch (error) {
      toast.error(error);
    }
  };

  const handleQuillChange = (value) => {
    setContent(value);
  };

  return (
    <>
      <Modal isOpen={modalOpen} onClose={handleCloseModal}>
        <div>
          <Input
            onChange={(e) => setUserCategory(e.target.value)}
            defaultValue={userCategory}
            label="User category"
            isRequired
            options={constants.blogCategory}
            select
          />
          {isIntroLoader && <Loader />}
          <PictureUpload
            label="Thumbnail  URL (400px x 250px)"
            isRequired
            defaultValue={thumbnailURL}
            onChange={handleThumbnailUrl}
          />
          <Input
            onChange={(e) => setTitle(e.target.value)}
            value={title}
            label="Title"
            isRequired
          />
          <div className="flex flex-wrap">
            <div className="w-full lg:w-12/12 px-4">
              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Content <span className="red-asterisk"></span>
                </label>
                <ReactQuill
                  style={{
                    background: "#fff",
                  }}
                  className="ql-container ql-snow"
                  ref={editor}
                  modules={constants.modules}
                  formats={constants.formats}
                  defaultValue={content}
                  onChange={handleQuillChange}
                />
              </div>
            </div>
          </div>
          <Input
            onChange={(e) => setMediaType(e.target.value)}
            label="Media Type"
            isRequired
            defaultValue={mediaType}
            select
            options={constants.mediaOption}
          />
          {isMediaLoader && <Loader />}
          {mediaType === "0" ? null : ( // null for no media
            <PictureUpload
              onChange={handleIntroMedia}
              defaultValue={mediaURL}
              label={
                mediaType === "3"
                  ? "Intro Video"
                  : mediaType === "2"
                  ? "Intro Audio"
                  : "Intro Image"
              }
              isRequired
              accept="image/*, video/*"
            />
          )}
          <Input
            onChange={(e) => setSlugURL(e.target.value)}
            value={slugURL}
            label="Slug URL"
            isRequired
          />
          <Input
            onChange={(e) => setMetaDescription(e.target.value)}
            value={metaDescription}
            label="Meta Description"
            isRequired
          />
          <Input
            onChange={(e) => setMetaKeywords(e.target.value)}
            value={metaKeywords}
            label="Meta Keywords"
            isRequired
          />
          <Input
            onChange={(e) => setPublishDate(e.target.value)}
            value={publishDate}
            type="date"
            label="Publish Date"
            isRequired
          />
          <Input
            onChange={(e) => setPublishTime(e.target.value)}
            value={publishTime}
            type="time"
            label="Publish Time"
            isRequired
          />
          <Input
            onChange={(e) => setIsActive(e.target.value)}
            defaultValue={isActive}
            options={[
              { id: true, value: "Active" },
              { id: false, value: "Inactive" },
            ]}
            select
            label="Visibility State"
            isRequired
          />
          {formError && (
            <p className="text-red-500 mt-2">Please fill all the details!</p>
          )}
          <div className="flex gap-2">
            <button
              onClick={handleCreateNew}
              className="bg-pink mx-4 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
            >
              Save
            </button>
          </div>
        </div>
      </Modal>

      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <BlogsTable
            handleOpenModal={handleOpenModal}
            color="dark"
          />
        </div>
      </div>
    </>
  );
};

export default Blogs;
